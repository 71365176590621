import React, { useEffect, useState } from "react";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";

export default function RedirectionLoading({ route }) {
  const routeParams = route?.params || {};
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (routeParams.onInit) {
      routeParams.onInit({
        setRedirectionLoading: setLoading,
      });
    }
  };

  return <LoadingContainer loading={loading}></LoadingContainer>;
}
