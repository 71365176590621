import React from "react";
import { TrackOrderCards } from "presto-react-components";

export default function TrackOrderCard(props) {
  return <TrackOrderCards.TrackOrderCard2 {...props} />;
}
// TODO(refactor): move the below code to Presto-react-components
/*   
export default function TrackOrderCard({
  order,
  onTrackOrderPressed,
  noButton = false,
  padding,
  bgColor,
  actionTitle,
  onPayPressed,
  disablePayBtn = false,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <DropShadow
      style={{
        ...theme.darkShadow,
        borderRadius: theme.cardRadius,
      }}
    >
      <View
        style={{
          padding: padding || 15,
          width: "100%",
          backgroundColor: bgColor || "#FFFFFF",
          borderRadius: theme.cardRadius,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <PrestoText size={14} color="rgb(142, 142, 142)">
              {order.orderTitle}
              <PrestoText fontStyle="bold" color="rgb(142, 142, 142)">
                {order.orderIdWithTitle}
              </PrestoText>
              {order.stringWith}
              <PrestoText fontStyle="bold" color="rgb(142, 142, 142)">
                {order.countDetails}
              </PrestoText>
              {order.deliveryMessage}
            </PrestoText>
          </View>
          <View
            style={{
              width: 100,
              paddingLeft: 15,
              justifyContent: "center",
              alignItems: "flex-end",
              paddingHorizontal: 5,
            }}
          >
            <PrestoIcon
              icon={
                <Image
                  source={images.track_truck}
                  style={{
                    width: 50,
                    height: 50,
                    resizeMode: "contain",
                  }}
                />
              }
            />
          </View>
        </View>
        <VerticalSpacing size={20} />
        <View style={{ flexDirection: "row-reverse" }}>
          {order.orderStatus === "DISPATCHED" ? (
            <PrestoSolidButton
              size="normal"
              buttonStyle="primary"
              width={100}
              titleColor={"#fff"}
              titleExtraStyle={{ textTransform: "uppercase" }}
              onPress={onPayPressed}
              title={actionTitle || order.payOrderButton}
            />
          ) : (
            <PrestoSolidButton
              size="normal"
              buttonStyle="primary"
              width={100}
              titleColor={"#fff"}
              titleExtraStyle={{ textTransform: "uppercase" }}
              onPress={onTrackOrderPressed}
              title={actionTitle || order.trackOrderButton}
            />
          )}
        </View>
      </View>
    </DropShadow>
  );
}
 */
