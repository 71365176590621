import React from "react";
import { View, Text, StyleSheet, TextInput } from "react-native";

export default function TextInputComponent(props) {
  const { theme } = props;
  const styles = getTheme(theme);
  return (
    <View style={styles.container}>
      <View style={{ ...styles.labelContainer }}>
        <Text style={{ ...styles.label }}>{props.labelText || ""}</Text>
      </View>

      <View style={styles.textinputContainer}>
        <TextInput
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          placeholderTextColor={styles.placeHolder.color}
          {...props}
          style={styles.textInputStyle}
        />
      </View>
    </View>
  );
}

function getTheme(theme) {
  return {
    container: {
      width: "100%",
    },
    labelContainer: {
      paddingBottom: 5,
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    label: {
      fontSize: 12,
      width: "100%",
      color: theme.textInputLabelColor,
    },
    textInputStyle: {
      width: "100%",
      height: 100,
      borderWidth: 0,
      borderRadius: 15,
      fontSize: 14,
      paddingHorizontal: 10,
      borderColor: theme.textInputBorderColor,
      paddingTop: 10,
    },
    placeHolder: {
      color: "#999999",
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: 100,
      borderWidth: 0,
      borderRadius: 4,
      fontFamily: theme.primaryMediumFont,
      backgroundColor: theme.appBackgroundColor,
      color: theme.textInputColor,
    },
  };
}
