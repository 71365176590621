import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import AppointmentResource from "./AppointmentResource";
import PrestoSdk from "../../global/PrestoSdk";
import AppointmentObject from "../../data_models/AppointmentObject";
import $ from "../../global/util";

const AppointmentManager = function (
  Utils,
  ErrorConstants,
  AppointmentResource,
  PrestoSdk,
  AppointmentObject
) {
  var currentActiveAppointment = "";
  var allAppointments = [];

  function bookAppointment(appointment, successCallback, errorCallback) {
    var validationResponse = appointment.validateObject();
    if (validationResponse.success) {
      var params = {};
      params.merchant_id = PrestoSdk.getMerchantId();
      params.app_id = PrestoSdk.getAppId();
      params.appointment = appointment;
      AppointmentResource.bookAppointment(params).then(
        function (bookAppointmentResponse) {
          bookAppointmentResponse.message = Utils.getSuccessMessage(
            "appointment_booked"
          );
          successCallback(bookAppointmentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function updateAppointment(appointment, successCallback, errorCallback) {
    AppointmentResource.updateAppointment(appointment).then(
      function (updateAppointmentResponse) {
        updateAppointmentResponse.message = Utils.getSuccessMessage(
          "appointment_updated"
        );
        successCallback(updateAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelAppointment(appointment, successCallback, errorCallback) {
    if (appointment && !Utils.isEmpty(appointment.id)) {
      AppointmentResource.cancelAppointment(appointment).then(
        function (cancelAppointmentResponse) {
          cancelAppointmentResponse.message = Utils.getSuccessMessage(
            "appointment_cancelled"
          );
          successCallback(cancelAppointmentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    }
  }

  function getAppointment(successCallback, errorCallback) {
    AppointmentResource.getAppointment().then(
      function (appointmentResponse) {
        if (appointmentResponse.data.appointment) {
          var appointmentObj = new AppointmentObject();
          appointmentObj.buildObject(appointmentResponse.data.appointment);
          appointmentObj.tax_amount *= 100;
          appointmentObj.bill_amount *= 100;
          appointmentObj.discount *= 100;
          appointmentObj.total_amount *= 100;
          appointmentObj.gross_amount *= 100;
          appointmentResponse.data.appointment = appointmentObj;
        }
        successCallback(appointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function clearAppointment(params, successCallback, errorCallback) {
    if (params && !Utils.isEmpty(params.userId)) {
      AppointmentResource.clearAppointment(params).then(
        function (clearAppointmentResponse) {
          successCallback(clearAppointmentResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.USER_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    }
  }

  function getAllAppointments(successCallback, errorCallback, params) {
    AppointmentResource.getAllAppointments(params).then(
      function (allAppointmentsResponse) {
        if (allAppointmentsResponse.data) {
          var allAppointments = [];
          for (
            var i = 0;
            i < allAppointmentsResponse.data.my_appointments.length;
            i++
          ) {
            var appointmentObj = new AppointmentObject();
            appointmentObj.buildObject(
              allAppointmentsResponse.data.my_appointments[i]
            );
            allAppointments.push(appointmentObj);
          }
          allAppointmentsResponse.data.my_appointments = allAppointments;
        }
        successCallback(allAppointmentsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getMoreAppointments(params, successCallback, errorCallback) {
    AppointmentResource.getMoreAppointments(params).then(
      function (allAppointmentsResponse) {
        if (allAppointmentsResponse.data) {
          var allAppointments = [];
          for (
            var i = 0;
            i < allAppointmentsResponse.data.my_appointments.length;
            i++
          ) {
            var appointmentObj = new AppointmentObject();
            appointmentObj.buildObject(
              allAppointmentsResponse.data.my_appointments[i]
            );
            allAppointments.push(appointmentObj);
          }
          allAppointmentsResponse.data.my_appointments = allAppointments;
        }
        successCallback(allAppointmentsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAppointmentDetials(params, successCallback, errorCallback) {
    AppointmentResource.getAppointmentDetials(params).then(
      function (appointmentsResponse) {
        if (appointmentsResponse.data) {
          var appointmentObj = new AppointmentObject();
          appointmentObj.buildObject(appointmentsResponse.data);
        }
        successCallback(appointmentObj);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getUserAppointmentDetials(params, successCallback, errorCallback) {
    AppointmentResource.getUserAppointmentDetials(params).then(
      function (appointmentsResponse) {
        if (appointmentsResponse.data) {
          var appointmentObj = new AppointmentObject();
          appointmentObj.buildObject(appointmentsResponse.data);
        }
        successCallback(appointmentObj);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function acceptReceivedBill(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      AppointmentResource.acceptReceivedBill(params).then(
        function (billConfirmationResponse) {
          successCallback(billConfirmationResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function rejectReceivedBill(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      AppointmentResource.rejectReceivedBill(params).then(
        function (billRejectionResponse) {
          successCallback(billRejectionResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function payByCash(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var error = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
        false
      );
      error = Utils.updateErrorObject(error);
      errorCallback(error);
    } else {
      AppointmentResource.payByCash(params).then(
        function (payByCashResponse) {
          successCallback(payByCashResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function createCompositePayment(params, successCallback, errorCallback) {
    AppointmentResource.createCompositePayment(params).then(
      function (createCompositePaymentResponse) {
        successCallback(createCompositePaymentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getCompositePayment(id, successCallback, errorCallback) {
    AppointmentResource.getCompositePayment(id).then(
      function (createCompositePaymentResponse) {
        successCallback(createCompositePaymentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getResourceSchedule(params, successCallback, errorCallback) {
    AppointmentResource.getResourceSchedule(params).then(
      function (scheduleResponse) {
        successCallback(scheduleResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function bookResource(params, successCallback, errorCallback) {
    AppointmentResource.bookResource(params).then(
      function (resourceResponse) {
        successCallback(resourceResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeAppointment(params, successCallback, errorCallback) {
    AppointmentResource.updateEmployeeAppointment(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function rejectCustomer(params, successCallback, errorCallback) {
    AppointmentResource.rejectCustomer(params).then(
      function (rejectCustomerResponse) {
        successCallback(rejectCustomerResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getEmployeeAppointments(
    startTime,
    endTime,
    successCallback,
    errorCallback
  ) {
    AppointmentResource.getEmployeeAppointments(startTime, endTime).then(
      function (getEmployeeAppointmentsResponse) {
        successCallback(getEmployeeAppointmentsResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function acceptAppointment(params, successCallback, errorCallback) {
    AppointmentResource.acceptAppointment(params).then(
      function (acceptAppointmentResponse) {
        successCallback(acceptAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function acceptEmployeeAppointment(params, successCallback, errorCallback) {
    AppointmentResource.acceptEmployeeAppointment(params).then(
      function (acceptEmployeeAppointmentResponse) {
        successCallback(acceptEmployeeAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function completeOtpAppointment(params, successCallback, errorCallback) {
    AppointmentResource.completeOtpAppointment(params).then(
      function (completeOtpAppointmentResponse) {
        successCallback(completeOtpAppointmentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createSubscription(params, successCallback, errorCallback) {
    AppointmentResource.createSubscription(params).then(
      function (createSubscriptionResponse) {
        successCallback(createSubscriptionResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelSubscription(params, successCallback, errorCallback) {
    AppointmentResource.cancelSubscription(params).then(
      function (cancelSubscriptionResponse) {
        successCallback(cancelSubscriptionResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function getUserAppointments(params, successCallback, errorCallback) {
    AppointmentResource.getUserAppointments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        // TODO for better tracing the issue
        console.log("AppointmentManager->getUserAppointments->error", error);
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function updateService(params, successCallback, errorCallback) {
    AppointmentResource.updateService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        console.log("error", error);
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateAppointmentDetails(params, successCallback, errorCallback) {
    AppointmentResource.updateAppointmentDetails(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function cancelAppointmentByCustomer(params, successCallback, errorCallback) {
    AppointmentResource.cancelAppointmentByCustomer(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createPayment(params, successCallback, errorCallback) {
    AppointmentResource.createPayment(params).then(
      function (createPaymentResponse) {
        successCallback(createPaymentResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    bookAppointment: bookAppointment,
    updateAppointment: updateAppointment,
    cancelAppointment: cancelAppointment,
    getAppointment: getAppointment,
    clearAppointment: clearAppointment,
    getAllAppointments: getAllAppointments,
    rejectReceivedBill: rejectReceivedBill,
    acceptReceivedBill: acceptReceivedBill,
    payByCash: payByCash,
    createCompositePayment: createCompositePayment,
    getResourceSchedule: getResourceSchedule,
    bookResource: bookResource,
    updateEmployeeAppointment: updateEmployeeAppointment,
    rejectCustomer: rejectCustomer,
    getEmployeeAppointments: getEmployeeAppointments,
    acceptAppointment: acceptAppointment,
    getAppointmentDetials: getAppointmentDetials,
    createSubscription: createSubscription,
    cancelSubscription: cancelSubscription,
    acceptEmployeeAppointment: acceptEmployeeAppointment,
    completeOtpAppointment: completeOtpAppointment,
    getMoreAppointments: getMoreAppointments,
    getUserAppointments: getUserAppointments,
    getUserAppointmentDetials: getUserAppointmentDetials,
    updateService: updateService,
    updateAppointmentDetails: updateAppointmentDetails,
    cancelAppointmentByCustomer,
    createPayment: createPayment,
    getCompositePayment: getCompositePayment,
  };
};

export default AppointmentManager(
  Utils,
  ErrorConstants,
  AppointmentResource,
  PrestoSdk,
  AppointmentObject
);
