import React, { useContext, useState, useCallback } from "react";
import {
  Dimensions,
  View,
  Pressable,
  Image,
  StyleSheet,
  Platform,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import OrderManager from "@presto-services/features/orders/OrderManager";
import UserContext from "@presto-contexts/UserContext";
import { useFocusEffect } from "@react-navigation/native";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartContext from "@presto-contexts/CartContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import I18n from "i18n-js";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import utils from "../../utils";
import { Surface } from "react-native-paper";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import UserModel from "@presto-component-models/UserModel";
import { useAssets } from "presto-react-components";
import AnalyticsManager from "../../common/AnalyticsManager";
import config from "@presto-common/config";

export default function ProfileHighlight(props) {
  const {
    isDesktop,
    loyaltyTiers = [],
    setParentLoading,
    orderNowClicked,
  } = props;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const { reOrder } = useContext(CartContext);
  const [order, setOrder] = useState(null);
  const windowWidth = Dimensions.get("window").width;
  const [wallet, setWallet] = useState(undefined);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { images, svgs } = useAssets();
  const styles = getStyles(theme);
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);

  const getOrder = (orderId) => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        setOrder(response.data);
      },
      (error) => {}
    );
  };

  const goToOrderDetails = () => {
    setPaneState(true);
    if (orderNowClicked) {
      orderNowClicked("ACCOUNT");
    }
    AnalyticsManager.send("home_track_order");
    Navigator.emit({
      event: "jumpTab",
      params: {
        tabName: "ACCOUNT",
        screenName: "TRACK_ORDER_SCREEN",
        screenParams: { orderId: order?.id },
      },
    });
  };

  const goToLoyaltyPoints = () => {
    setPaneState(true);
    if (orderNowClicked) {
      orderNowClicked("ACCOUNT");
    }
    AnalyticsManager.send("home_zip_points");
    Navigator.emit({
      event: "jumpTab",
      params: {
        tabName: "ACCOUNT",
        screenName: "LOYALTY_POINT_TRANSACTIONS_SCREEN",
      },
    });
  };

  useFocusEffect(
    useCallback(() => {
      OrderManager.getMyOrders(
        {},
        (response) => {
          const filteredOrders = _.filter(response.data.orders, (order) => {
            const firstItem = _.first(order.items);
            return firstItem?.name !== "Wallet Recharge Pack";
          });
          const deliveredOrder = _.first(
            _.filter(filteredOrders, (o) => o.created_at !== null)
          );
          if (deliveredOrder) {
            getOrder(deliveredOrder.id);
          }
        },
        () => {}
      );
    }, [user])
  );

  // useEffect(() => {
  //   WalletManager.getTransactionHistory(
  //     {},
  //     (response) => {
  //       setTransactions(response.transactions);
  //     },
  //     () => {}
  //   );
  // }, [user]);

  // useEffect(() => {
  //   let res = _.filter(
  //     transactions,
  //     (item) =>
  //       item?.expiry_date != null &&
  //       item?.expiry_date != "" &&
  //       item?.expired === false
  //   );

  //   var afterAmonth = moment().add(30, "days");
  //   if (res?.length > 0) {
  //     let exp = _.filter(
  //       res,
  //       (item) => moment(item?.expiry_date) <= afterAmonth
  //     );
  //     setExpiresThisMonth(exp);
  //   }
  // }, [transactions]);

  useFocusEffect(
    useCallback(() => {
      WalletManager.getWalletInfo(
        { walletId: user?.wallet_id },
        (response) => {
          setWallet(response.data);
        },
        () => {}
      );
    }, [user])
  );

  const deliveryProgress = () => {
    if (order?.delivered_at) return "100%";
    else if (order?.dispatched_at) return "75%";
    else if (order?.accepted_at) return "50%";
    else if (order?.created_at) return "25%";
  };

  const getOrderTotalAmount = () => {
    return utils.formattedPriceRoundOff(order?.total_amount || 0, noRoundOff);
  };

  const deliveryStatus = () => {
    if (order?.status == "REJECTED")
      return I18n.t("screen_messages.rejected_str");
    else if (order?.delivered_at)
      return I18n.t("screen_messages.tracking_delivered_str");
    else if (order?.dispatched_at)
      return I18n.t("screen_messages.tracking_dispatched_str");
    else if (order?.accepted_at)
      return I18n.t("screen_messages.tracking_accepted_str");
    else if (order?.created_at)
      return I18n.t("screen_messages.tracking_created_str");
  };

  const isOrderCancelled = () => {
    return order?.status == "REJECTED";
  };

  const onReOrderClicked = async () => {
    setParentLoading(true);
    if (orderNowClicked) {
      orderNowClicked("CART");
    }
    await reOrder(order);
    setParentLoading(false);
    if (Platform.OS === "web" && isDesktop) {
      setPaneState(true);
    }
    Navigator.emit({
      event: "switchTab",
      params: {
        tabName: "CART",
      },
    });
  };

  const getUserLoyaltyTier = () => {
    let text = I18n.t("screen_messages.loyalty_tier.title_choices.notier"),
      bgColor = theme.white,
      borderWidth = 1,
      borderColor = theme.primary,
      textColor = "#6D6D6D",
      titleColor = "#6D6D6D",
      icon = null,
      bgImage = null,
      titleGropped = null;
    if (user && !_.isEmpty(loyaltyTiers)) {
      const userModel = new UserModel({ user });
      userModel.setLoyaltyTiers(loyaltyTiers);
      const tierLimitValue = userModel.loyaltyTierLimitValue;
      if (tierLimitValue > 0 && userModel.isLoyaltyTierValid) {
        const tierName = userModel.loyaltyTierName;
        const tierNameLowerCase = _.toLower(tierName);

        titleColor = textColor = theme.white;
        borderColor = "transparent";
        borderWidth = 0;
        text = I18n.t(
          `screen_messages.loyalty_tier.title_choices.${tierNameLowerCase}`
        );
        if (tierNameLowerCase === "gold") {
          bgImage = images.loyaltyTierGoldBackground;
          icon = (
            <svgs.GoldCardIcon
              width="30px"
              height="30px"
              stopOpacity1={1}
              stopOpacity2={1}
            />
          );
        } else if (tierNameLowerCase === "silver") {
          bgImage = images.loyaltyTierSilverBackground;
          icon = <svgs.SilverCardIcon width="30px" height="30px" />;
        } else if (tierNameLowerCase === "bronze") {
          bgImage = images.loyaltyTierBronzeBackground;
          icon = <svgs.BronzeCardIcon width="30px" height="30px" />;
        }
      }
    }
    return {
      text,
      titleGropped,
      bgColor,
      borderColor,
      borderWidth,
      textColor,
      titleColor,
      icon,
      bgImage,
    };
  };

  const showPayButton = () => {
    let flag = false;

    let paymentObj = order?.payments_list;
    let lastPayment = paymentObj.slice(-1);

    if (
      lastPayment[0]?.status == "initiated" ||
      order?.payment_status == "succeeded"
    ) {
      flag = false;
    } else if (order?.sub_status == "PAYMENT_REQUESTED") {
      flag = true;
    }

    return flag;
  };

  const onPressPay = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "TRACK_ORDER_SCREEN",
        screenParams: {
          orderId: order?.id,
          showPayModal: true,
          orderData: order,
        },
      },
    });
  };

  const HighlightCard = (props) => {
    const {
      backgroundColor = theme.error,
      otherColor = theme.white,
      data,
      width,
      type,
      title,
      subTitle,
      onPress,
      info,
      infoColor,
      subTitleColor,
      containerStyles = {},
      tierStyles = {},
      titleGropped,
    } = props || {};
    return (
      <Surface
        style={{
          backgroundColor:
            type === "Order" && !_.isEmpty(order?.delivered_at)
              ? "#F3F3F3"
              : backgroundColor,
          width: width,
          height: "100%",
          borderRadius: 8,
          ...containerStyles,
        }}
        elevation={2}
      >
        {tierStyles.bgImage ? (
          <View
            style={{
              position: "absolute",
              width: isDesktop ? "100%" : width,
              height: "100%",
              borderRadius: 8,
              overflow: "hidden",
            }}
          >
            <Image
              source={tierStyles.bgImage}
              style={{
                width: isDesktop ? "100%" : width,
                height: "100%",
              }}
            />
          </View>
        ) : null}

        {type === "Order" ? (
          <Pressable
            onPress={
              !_.isEmpty(order?.delivered_at)
                ? onReOrderClicked
                : goToOrderDetails
            }
            style={{
              flex: 1,
              padding: 5,
              paddingHorizontal: 10,
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {!_.isEmpty(order?.delivered_at) ? (
              <>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ width: "80%" }}>
                    <PrestoText
                      fontStyle="400.normal"
                      size={10}
                      color="#727272"
                    >
                      {I18n.t("screen_messages.repeat_order_title", {
                        amount: getOrderTotalAmount(),
                      })}
                    </PrestoText>
                  </View>
                  <View>
                    <PrestoIcon
                      icon={<svgs.ZipIcon width={20} height={22} />}
                      style={{ paddingTop: 2 }}
                    />
                  </View>
                </View>
                <View style={{ paddingTop: !tierStyles.bgImage ? 5 : 0 }}>
                  <PrestoText
                    fontStyle="400.normal"
                    size={10}
                    color={theme.primary}
                  >
                    {I18n.t("screen_messages.orders.order_now")}
                  </PrestoText>
                </View>
              </>
            ) : (
              <>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                  }}
                >
                  <PrestoIcon
                    icon={
                      <svgs.TruckIcon
                        width={22}
                        height={22}
                        color={isOrderCancelled() ? theme.white : theme.primary}
                      />
                    }
                  />
                  <View>
                    <PrestoText
                      fontStyle="400.normal"
                      size={12}
                      color={otherColor}
                      extraStyle={{ textAlign: "right" }}
                    >
                      #{order?.friendly_id}
                    </PrestoText>
                    <PrestoText
                      fontStyle="700.bold"
                      size={10}
                      color={otherColor}
                      extraStyle={{ textAlign: "right" }}
                    >
                      {deliveryStatus()}
                    </PrestoText>
                  </View>
                </View>
                <View
                  style={{
                    backgroundColor: "#646464",
                    borderRadius: 7,
                    height: 5,
                    width: "100%",
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      width: deliveryProgress(),
                      backgroundColor: isOrderCancelled()
                        ? theme.white
                        : theme.primary,
                      height: 5,
                      borderRadius: 7,
                    }}
                  />
                </View>
                {showPayButton() ? (
                  <Pressable onPress={onPressPay}>
                    <View style={{ paddingTop: 5 }}>
                      <PrestoText
                        fontStyle="400.normal"
                        size={14}
                        color={theme.primary}
                      >
                        {I18n.t("screen_messages.payments.pay_now_text")}
                      </PrestoText>
                    </View>
                  </Pressable>
                ) : null}
              </>
            )}
          </Pressable>
        ) : (
          <Pressable
            onPress={onPress}
            style={{
              padding: 5,
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 1,
            }}
          >
            <View>
              {title ? (
                <PrestoText
                  fontStyle="600.semibold"
                  size={16}
                  color={otherColor}
                  extraStyle={{
                    letterSpacing: -0.4,
                  }}
                >
                  {title}
                </PrestoText>
              ) : null}
              {subTitle ? (
                <PrestoText fontStyle="700.bold" size={8} color={subTitleColor}>
                  {subTitle}
                </PrestoText>
              ) : null}
              {titleGropped ? (
                <View style={styles.row}>
                  <View>
                    <PrestoText
                      fontStyle="400.normal"
                      size={11}
                      color={otherColor}
                    >
                      {titleGropped.title1}
                      <PrestoText
                        fontStyle="600.semibold"
                        size={11}
                        color={otherColor}
                      >
                        {titleGropped.title2}
                      </PrestoText>
                    </PrestoText>
                  </View>
                </View>
              ) : null}
            </View>
            {info ? (
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <View>
                    <View style={styles.row}>
                      <View>
                        <PrestoText
                          fontStyle="700.bold"
                          extraStyle={{ textAlign: "right" }}
                          color={infoColor}
                        >
                          {info}
                        </PrestoText>
                      </View>

                      <View style={{ paddingLeft: 5 }}>
                        <PrestoText
                          size={11}
                          fontStyle="400.normal"
                          color={infoColor}
                        >
                          {I18n.t(`screen_messages.common.pts`)}
                        </PrestoText>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            ) : null}
          </Pressable>
        )}
      </Surface>
    );
  };

  const userLoyaltyTier = getUserLoyaltyTier();

  return !user ? null : (
    <View
      style={{
        paddingHorizontal:
          props.paddingHorizontal || theme.containerPadding || 15,
        paddingVertical: isDesktop ? 15 : 0,
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {order ? (
        <HighlightCard
          type="Order"
          width={isDesktop ? "47%" : windowWidth / 2 - 20}
          backgroundColor={isOrderCancelled() ? "#FE6767" : theme.white}
          otherColor={isOrderCancelled() ? theme.white : theme.paragraph}
        />
      ) : null}
      <HighlightCard
        title={userLoyaltyTier.text}
        titleGropped={userLoyaltyTier.titleGropped}
        subTitleColor={theme.primary}
        info={
          Math.round(wallet?.current_balance / 100) > 0
            ? utils.formattedPriceRoundOff.call(
                { currency: "" },
                wallet?.current_balance / 100,
                false
              )
            : "0"
        }
        width={
          isDesktop ? "47%" : !order ? windowWidth - 40 : windowWidth / 2 - 30
        }
        backgroundColor={theme.white}
        otherColor={userLoyaltyTier.titleColor}
        infoColor={userLoyaltyTier.textColor}
        onPress={goToLoyaltyPoints}
        tierStyles={{
          bgImage: userLoyaltyTier.bgImage,
          icon: userLoyaltyTier.icon,
        }}
        containerStyles={{
          borderColor: userLoyaltyTier.borderColor,
          borderWidth: userLoyaltyTier.borderWidth,
        }}
      />
    </View>
  );
}

function getStyles(theme) {
  return StyleSheet.create({
    row: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "flex-end",
    },
  });
}

// === Keeping this Ligic for Now  @Ranvijay

{
  /* <HighlightCard
  title={I18n.t("screen_messages.zip_club_points")}
  subTitle={
    expiresThisMonth?.length > 0 ? I18n.t("screen_messages.redeem_now") : null
  }
  subTitleColor={expiresThisMonth?.length > 0 ? theme.white : theme.primary}
  info={
    Math.round(wallet?.current_balance / 100) > 0
      ? utils.formatPriceToLocal(wallet?.current_balance / 100)
      : "0"
  }
  width={!order ? windowWidth - 40 : windowWidth / 2 - 30}
  backgroundColor={expiresThisMonth?.length > 0 ? "#FE6767" : theme.white}
  otherColor={expiresThisMonth?.length > 0 ? theme.white : theme.paragraph}
  infoColor={expiresThisMonth?.length > 0 ? theme.white : theme.primary}
  onPress={goToLoyaltyPoints}
/> */
}
