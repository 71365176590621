import React, { useContext, useEffect } from "react";
import { View, Image, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import PrestoIcon from "@presto-components/PrestoIcon";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import Config from "@presto-common/config";
import SessionManager from "@presto-services/features/session/SessionManager";
import UserContext from "@presto-contexts/UserContext";
import { useAssets } from "presto-react-components";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import { SafeAreaView } from "react-native-safe-area-context";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function OrderSucccessScreen({}) {
  const { images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { cart } = useContext(CartContext);
  const insets = useSafeAreaInsets();
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { setUser, user } = useContext(UserContext);
  const isZambiaApp = Config.app === "ziphealthzambia";

  const onClose = () => {
    AnalyticsManager.send("thank_you_screen");
    setPaneState(false);
    Navigator.emit({ event: "goBack" });
    if (canUserLogout()) {
      doLogout();
    } else {
      setTimeout(() => {
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: "HOME",
          },
        });
      }, 300);
    }
  };

  const doLogout = () => {
    SessionManager.logout(
      () => {
        setUser(null);
        Navigator.emit({
          event: "jumTab",
          params: {
            tabName: "HOME",
          },
        });
      },
      () => {}
    );
  };

  useEffect(() => {
    if (canUserLogout()) {
      setTimeout(() => {
        doLogout();
      }, 180000);
    }
  }, []);

  const canUserLogout = () => {
    return Config && Config.search_config && Config.search_config.length > 0;
  };

  return (
    <LoadingContainer loading={false}>
      <SafeAreaView
        style={{
          flex: 1,
        }}
      >
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={""}
          hideBack={true}
        />

        <View
          style={{
            flex: 1,
            backgroundColor: theme.backgroundColor,
          }}
        >
          <VerticalSpacing size={15} />
          <FullWidthHorizontalPaddedBox>
            <PrestoText fontStyle="semibold" color={theme.primary} size={30}>
              {I18n.t("screen_messages.order_success_thank_you")}
            </PrestoText>
            <VerticalSpacing size={15} />
            <PrestoText
              fontStyle="semibold"
              color={theme.primaryTextColor}
              size={28}
            >
              {I18n.t("screen_messages.order_success_screen_title")}
            </PrestoText>
            <VerticalSpacing size={15} />
            {!isZambiaApp && (
              <PrestoText
                fontStyle="medium"
                color={theme.primaryTextColor}
                size={14}
              >
                {I18n.t("screen_messages.orders.order_success_delievery_msg")}
              </PrestoText>
            )}
            <VerticalSpacing size={20} />
          </FullWidthHorizontalPaddedBox>
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoIcon
              icon={
                <Image
                  source={images.ZipPlusTruck}
                  style={{ width: 300, height: 300, resizeMode: "contain" }}
                />
              }
            />
          </View>
          <View
            style={{
              width: "100%",
              height: 60,
              position: "absolute",
              bottom: 0,
              left: 0,
            }}
          >
            <FullWidthHorizontalPaddedBox>
              <PrestoSolidButton
                size="large"
                title={
                  canUserLogout()
                    ? I18n.t("screen_messages.common.done")
                    : I18n.t("screen_messages.common.close")
                }
                buttonStyle="primary"
                onPress={onClose}
                titleColor={theme.white}
              />
            </FullWidthHorizontalPaddedBox>
          </View>
        </View>
      </SafeAreaView>
    </LoadingContainer>
  );
}
