import React, {
  useState,
  useContext,
  useReducer,
  useCallback,
  useEffect,
  memo,
  useRef,
} from "react";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import {
  View,
  KeyboardAvoidingView,
  FlatList,
  Pressable,
  ScrollView,
  Dimensions,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import { useSafeArea } from "react-native-safe-area-context";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import useFocusEffect from "@presto-common/useFocusEffect";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import CartItemCard from "@presto-screen-components/CartItemCard/CartItemCard";
import SearchManager from "@presto-services/features/search/SearchManager";
import utils from "../../utils/index";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useMediaQuery } from "react-responsive";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import UserContext from "@presto-contexts/UserContext";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import { SafeAreaView } from "react-native-safe-area-context";
import AnalyticsManager from "../../common/AnalyticsManager";
import config from "@presto-common/config";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import LoginHelper from "@presto-helpers/LoginHelper";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };

    case "SET_CATEGORY_ITEMS":
      const itemsMap = _.keyBy(payload, "id");
      return { ...state, items: itemsMap };
  }
  return state;
}

const MemoizedCartItemCard = memo(
  ({ itemPromotions, lineItem, variation, wallet, item }) => {
    return (
      <CartItemCard
        itemPromotions={itemPromotions}
        key={lineItem.id}
        item={item}
        variation={variation}
        showWhite={true}
        wallet={wallet}
      />
    );
  }
);

export default function Cart({ route }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { setPaneState } = useContext(RightPaneContext);
  const { cart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const isDesktop = useMediaQuery({ minWidth: 941 });
  const windowHeight = Dimensions.get("window").height;
  const [state, dispatch] = useReducer(reducer, {
    items: {},
  });
  const extraOptions = useRef({
    items: {},
  });
  const { items } = state;
  const { user } = useContext(UserContext);
  const [wallet, setWallet] = useState(null);
  const [itemPromotions, setItemPromotions] = useState(undefined);
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);

  const isItemPromotionFeatureEnabled = config.features.item_promotions;

  useEffect(() => {
    WalletManager.getWalletInfo(
      { walletId: user.wallet_id },
      (response) => {
        setWallet(response.data);
      },
      () => {}
    );
  }, []);

  useFocusEffect(
    useCallback(() => {
      getCategoryItems();
    }, [cart])
  );

  useEffect(() => {
    if ((cart?.items || []).length > 0) {
      checkCartItemPromtion();
    }
    associateCartItems();
  }, [cart?.items]);

  const associateCartItems = () => {
    let tempCartItems = cart?.items || [];
    if (!_.isEmpty(extraOptions.current.items)) {
      tempCartItems = _.compact(
        _.filter(cart?.items, (item) => {
          return !_.isEmpty(extraOptions.current.items[item.item_id]);
        })
      );
    }
    setCartItems(tempCartItems);
  };

  const checkCartItemPromtion = () => {
    if (!isItemPromotionFeatureEnabled) {
      return;
    }
    setUpdating(true);
    ServerCartManager.getProbableItemPromotions(
      {},
      (response) => {
        setItemPromotions(response.data);
        setUpdating(false);
      },
      (error) => {
        console.log("promotion error ", error);
        setUpdating(false);
      }
    );
  };

  const keyExtractor = (item) => {
    return item.id;
  };

  const renderCartItem = useCallback(({ item: lineItem }) => {
    const item = extraOptions.current.items[lineItem.item_id];
    const variation = lineItem.variation;

    if (item) {
      return (
        <MemoizedCartItemCard
          key={lineItem.id}
          lineItem={lineItem}
          itemPromotions={itemPromotions}
          item={item}
          variation={variation}
          showWhite={true}
          wallet={wallet}
        />
      );
    } else {
      return null;
    }
  }, []);

  const getCategoryItems = () => {
    const ids = _.uniq(
      _.map(cart?.items || [], (lineItem) => lineItem.item_id)
    );
    if (ids.length == 0) {
      return;
    }

    const merchantId = LoginHelper.getUserMerchantId(user);
    SearchManager.searchCategoryItem(
      {
        bulk_ids: ids,
        size: ids?.length,
        is_available: true,
        post_merchant_id: merchantId,
      },
      (response) => {
        if (response.hits.hits.length > 0) {
          const categoryItems = _.map(
            response.hits.hits || [],
            (it) => it._source
          );
          let itemsHash = _.reduce(
            categoryItems,
            (result, item, key) => {
              result[item.id] = item;
              return result;
            },
            {}
          );
          dispatch({
            type: "SET_CATEGORY_ITEMS",
            payload: categoryItems,
          });
          extraOptions.current.items = itemsHash;

          associateCartItems();
        }
      },
      () => {}
    );
  };

  const onBrowse = () => {
    AnalyticsManager.send("browse_cart");
    if (isDesktop) {
      setPaneState(false);
    } else {
      Navigator.emit({
        event: "switchTab",
        params: {
          tabName: "CATEGORIES",
        },
      });
    }
  };

  const renderEmptyCart = () => {
    return (
      <View style={{ flex: 1, marginBottom: 20 }}>
        <VerticalSpacing size={15} />
        <FullWidthHorizontalPaddedBox>
          <PrestoText fontStyle={"semibold"} color={theme.darkText} size={22}>
            {I18n.t("screen_messages.cart.cart_empty_title")}
          </PrestoText>

          <VerticalSpacing size={15} />
          <PrestoText fontStyle={"medium"} color={theme.darkText} size={14}>
            {I18n.t("screen_messages.cart_empty_message")}
          </PrestoText>
        </FullWidthHorizontalPaddedBox>
        <View
          style={{
            width: "100%",
            height: 60,
            position: "absolute",
            bottom: 0,
            left: 0,
          }}
        >
          <FullWidthHorizontalPaddedBox>
            <PrestoSolidButton
              size="large"
              title={I18n.t("screen_messages.browse_str")}
              titleColor={theme.white}
              buttonStyle="primary"
              onPress={onBrowse}
              titleExtraStyle={{ textTransform: "uppercase" }}
            />
          </FullWidthHorizontalPaddedBox>
        </View>
      </View>
    );
  };

  const gotoCheckout = () => {
    AnalyticsManager.send("cart_continue");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "CART_CHECKOUT_SCREEN",
      },
    });
  };

  const getProductDiscount = () => {
    const val = Number(cart?.item_promotion_discounts);

    return utils.formattedCurrencyToRoundOff(
      val,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    );
  };

  const getTotalSavings = () => {
    const val = Number(cart?.aggregate_discounts);

    return utils.formattedCurrencyToRoundOff(
      val,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    );
  };

  const getCouponDiscount = () => {
    const val = Number(cart?.total_discounts);

    return utils.formattedCurrencyToRoundOff(
      val,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    );
  };

  const renderCartReview = () => {
    let savings = 0;
    let savingOnOnefreeItem = 0;
    let freeItemAmount = 0;
    if (cart && cart.items) {
      _.forEach(cart.items, (product) => {
        if (product?.other_data?.variation_free_quantity) {
          savingOnOnefreeItem +=
            (product?.quantity *
              parseInt(product?.other_data?.variation_free_quantity) *
              (product?.other_data?.old_price - product?.rate / 100)) /
            (parseInt(product?.other_data?.variation_quantity) +
              parseInt(product?.other_data?.variation_free_quantity));
          freeItemAmount +=
            (product?.quantity *
              parseInt(product?.other_data?.variation_free_quantity) *
              (product?.rate / 100)) /
            (parseInt(product?.other_data?.variation_quantity) +
              parseInt(product?.other_data?.variation_free_quantity));
        }
        const parsedOldPrice = parseFloat(product.other_data?.old_price);
        const oldPrice = isNaN(parsedOldPrice) ? 0 : parsedOldPrice;
        let oldTotal = 0;
        if (oldPrice) {
          oldTotal = oldPrice * product.quantity * 100;
          savings +=
            oldTotal - (product.rate * product.quantity - product.total_tax);
        }
      });
    }

    let sumedSavings = savingOnOnefreeItem + freeItemAmount * 100;
    if (isNaN(sumedSavings)) {
      sumedSavings = 0;
    }

    let cartInfo = {
      [I18n.t(
        "screen_messages.total_price_str"
      )]: utils.formattedCurrencyToRoundOff(
        cart?.original_gross_amount || cart?.sub_total_price || 0,
        I18n.t("screen_messages.common.currency"),
        noRoundOff
      ),
      ...(sumedSavings > 0
        ? {
            [I18n.t(
              "screen_messages.cart.save_on_free_item"
            )]: utils.formattedCurrencyToRoundOff(
              sumedSavings,
              I18n.t("screen_messages.common.currency"),
              noRoundOff
            ),
          }
        : {}),
      ...(cart?.discounts?.length > 0
        ? {
            [I18n.t("screen_messages.coupon_discount")]: getCouponDiscount(),
          }
        : {}),
      [I18n.t("screen_messages.product_discount")]: getProductDiscount(),

      [I18n.t("screen_messages.total_savings")]: getTotalSavings(),
      [I18n.t("screen_messages.taxes_str")]: utils.formattedCurrencyToRoundOff(
        cart?.total_taxes || 0,
        I18n.t("screen_messages.common.currency"),
        noRoundOff
      ),
      [I18n.t(
        "screen_messages.common.you_pay"
      )]: utils.formattedCurrencyToRoundOff(
        cart?.total_price || 0,
        I18n.t("screen_messages.common.currency"),
        noRoundOff
      ),
    };

    return (
      <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding" enabled>
        <FlatList
          style={{ width: "100%" }}
          contentContainerStyle={{
            paddingTop: 20,
            paddingBottom: 60,
          }}
          ListHeaderComponent={
            <FullWidthHorizontalPaddedBox>
              <PrestoText
                color={theme.paragraph}
                size={theme.h6FontSize}
                extraStyle={{ marginBottom: 15, marginTop: -10 }}
              >
                {I18n.t("screen_messages.orders.details_title")}
              </PrestoText>
              <KeyValueList dict={cartInfo} />
              <VerticalSpacing size={30} />
              <Pressable onPress={() => gotoCheckout()}>
                <PrestoSolidButton
                  size="large"
                  buttonStyle="primary"
                  title={I18n.t("screen_messages.common.continue")}
                  titleColor={theme.white}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  onPress={gotoCheckout}
                />
              </Pressable>
              <VerticalSpacing size={30} />
              <PrestoText size={theme.h6FontSize} color={theme.paragraph}>
                {I18n.t("screen_messages.items_in_order_str")}
              </PrestoText>
            </FullWidthHorizontalPaddedBox>
          }
          data={cartItems}
          keyExtractor={keyExtractor}
          renderItem={renderCartItem}
        />
      </KeyboardAvoidingView>
    );
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.cart.review_cart")}
          hideBack={true}
        />
        <ScrollView
          style={{ height: windowHeight - 100 }}
          contentContainerStyle={{
            width: "100%",
            paddingBottom: 100,
            paddingTop: theme.containerPadding,
            flex: 1,
          }}
        >
          {cart != null && !_.isEmpty(cartItems)
            ? renderCartReview()
            : renderEmptyCart()}
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
