import React, { useContext, useState, useRef } from "react";
import { View, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import DefaultModal from "@presto-components/Modals/Modal.bookmychef";
import _ from "lodash";
import LoadingContainer from "@presto-components/LoadingContainer";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { prestoAlert } from "../../common/Alert";
import utils from "../../utils";
import RadioButtonGroup from "@presto-components/RadioButtons/RadioButtonGroup.bookmychef";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import AppointmentManager from "@presto-services/features/appointment/AppointmentManager";

let defaultPaymentOptions = [
  {
    name: "Airtel Money",
    description: "Pay to: 77 379 8262",
    key: "AIRTEL",
    type: "WALLET",
  },
  {
    name: "MTN Money",
    description: "Pay to: 96 230 2451",
    key: "MTN",
    type: "WALLET",
  },
  {
    name: "Zamtel Money",
    description: "Pay to: 95 249 4723",
    key: "ZAMTEL",
    type: "WALLET",
  },
  {
    name: "Bank Transfer",
    description: "Pay to: 5927542500166 (ZANACO Bank)",
    key: "BANK",
    type: "NET_BANKING",
  },
  {
    name: "POS - Visa/Mastercard",
    description: "(on Delivery, Lusaka only)",
    key: "CARD",
    type: "CARD",
  },
  {
    name: "Cash",
    description: "(on Delivery, Lusaka only)",
    key: "CASH",
    type: "CASH",
  },
];

let interCityPaymentOptions = [
  {
    name: "Airtel Money",
    description: "Pay to: 77 379 8262",
    key: "AIRTEL",
    type: "WALLET",
  },
  {
    name: "MTN Money",
    description: "Pay to: 96 230 2451",
    key: "MTN",
    type: "WALLET",
  },
  {
    name: "Zamtel Money",
    description: "Pay to: 95 249 4723",
    key: "ZAMTEL",
    type: "WALLET",
  },
  {
    name: "Bank Transfer",
    description: "Pay to: 5927542500166 (ZANACO Bank)",
    key: "BANK",
    type: "NET_BANKING",
  },
];

export default function OnlinePayment(props) {
  const { order, paymentCallback } = props?.route?.params;
  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { Navigator } = useContext(NavigatorContext);
  const modalRef = useRef();
  const isDesktop = utils.isDesktop();
  const [referenceId, setReferenceId] = useState("");
  const [focusedItem, setFocussedItem] = useState("");

  let paymentOptions =
    order?.address?.city == "Lusaka"
      ? defaultPaymentOptions
      : interCityPaymentOptions;

  const [paymentType, setPaymentType] = useState(undefined);
  const [payments, setPaymentOptions] = useState(paymentOptions);

  const onChange = ({ nativeEvent: { text } }) => {
    setReferenceId(text);
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onInitiatePayment = () => {
    if (!paymentType) {
      alert(I18n.t("error_messages.payments.choose_payment_method"));
      return;
    }
    if (
      paymentType.type !== "CASH" &&
      paymentType.type !== "CARD" &&
      !referenceId
    ) {
      alert(I18n.t("error_messages.payments.add_payment_reference_id"));
      return;
    }
    setLoading(true);
    let params = {
      composite_payment: {
        merchant_id: order.merchant_id,
        source: "MOBILE",
        targets: [
          {
            id: order?.id,
            type: "order",
          },
        ],
      },
    };

    AppointmentManager.createCompositePayment(
      params,
      (response) => {
        if (response.data) {
          createPayment(response.data);
          setLoading(false);
        }
      },
      (error) => {
        console.log("createCompositePayment error", error);
        paymentFailure(error);
        setLoading(false);
      }
    );
  };

  const createPayment = (data) => {
    let refId = "";
    if (paymentType?.type == "WALLET") {
      let id = paymentType?.value + "_" + referenceId;
      refId = id;
    } else {
      refId = referenceId;
    }

    let createParams = {
      payable_type: "CompositePayment",
      payable_id: data.id,
      type: "PG",
      provider: paymentType?.value,
      reference_id: refId,
    };
    setLoading(true);

    AppointmentManager.createPayment(
      createParams,
      (response) => {
        if (response?.data) {
          if (paymentType.type !== "CASH" && paymentType.type !== "CARD") {
            alert(I18n.t("screen_messages.payments.payment_registered"));
          } else {
            alert(
              I18n.t("screen_messages.payments.payment_preference_regsitered")
            );
          }
          if (paymentCallback) {
            paymentCallback(true);
            goBack();
          }
        }
        setLoading(false);
      },
      (error) => {
        console.log("create payment error", error);
        paymentFailure(error);
        setLoading(false);
      }
    );
  };

  const paymentFailure = (error) => {
    alert(I18n.t("error_messages.server_error"));
    setLoading(false);
    if (paymentCallback) {
      paymentCallback(false);
      goBack();
    }
  };

  const onSelectPaymnetType = (value) => {
    let paymentType = {
      name: value.name,
      value: value.key,
      type: value.type,
    };
    setPaymentType(paymentType);
    setReferenceId("");
  };

  return (
    <LoadingContainer
      bgColor={isDesktop ? theme.white : "#00000070"}
      loading={loading}
    >
      <>
        <DefaultModal
          modalRef={modalRef}
          goBack={goBack}
          height={isDesktop?"100%":"80%"}
          width={"100%"}
          bgColor={theme.white}
        >
          <ScrollView
            style={{
              flex: 1,
              paddingHorizontal: theme.containerPadding,
              marginTop: 30,
              height: "100%",
            }}
          >
            <View
              style={{
                flex: 1,
                backgroundColor: theme.white,
              }}
            >
              <PrestoText
                fontStyle="600.semibold"
                size={20}
                color={theme.title}
              >
                {I18n.t("screen_messages.payments.payment_options_text")}
              </PrestoText>
              <VerticalSpacing size={20} />

              <RadioButtonGroup
                data={payments}
                onPressItem={onSelectPaymnetType}
                theme={theme}
                paddingHorizontal={0}
                style={{ borderBottomColor: "#CCCCCC" }}
                descriptionTextStyles={{
                  color: theme.title,
                  fontStyle: "600.semibold",
                }}
              />

              <VerticalSpacing size={30} />
              {paymentType ? (
                <>
                  <PrestoText>Enter Payment Reference id</PrestoText>
                  <VerticalSpacing size={20} />
                  <PrestoTextInput
                    style={{
                      borderWidth: 1,
                      borderColor:
                        focusedItem === "id" ? theme.primary : "#CCCCCC",
                      textInputPaddingHorizontal: 10,
                      borderRadius: 5,
                    }}
                    theme={theme}
                    placeholder={I18n.t(
                      "screen_messages.payments.payment_ref_id"
                    )}
                    value={referenceId}
                    keyboardType={"default"}
                    onChange={onChange}
                    backgroundColor={theme.white}
                    borderWidth={1}
                    textContentType={"name"}
                    autoCompleteType={"name"}
                    importantForAutofill={"yes"}
                    onFocus={() => setFocussedItem("id")}
                    onBlur={() => setFocussedItem("")}
                    textAlignVertical="center"
                  />
                  <VerticalSpacing size={100} />
                </>
              ) : null}
            </View>
          </ScrollView>

          <View style={styles.footerContainer}>
            <View style={styles.footerInner}>
              <View style={{ width: "100%" }}>
                <PrestoSolidButton
                  size="extra-large"
                  title={I18n.t("screen_messages.pay_text")}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  titleColor={theme.white}
                  onPress={() => onInitiatePayment()}
                  buttonStyle="primary"
                />
              </View>
            </View>
          </View>
        </DefaultModal>
      </>
    </LoadingContainer>
  );
}

function getStyles(theme) {
  return {
    footerContainer: {
      paddingHorizontal: 20,
      position: "absolute",
      width: "100%",
      bottom: 0,
      top: null,
      backgroundColor: theme.white,
      paddingVertical: theme.containerPadding,
    },
    footerInner: {
      flexDirection: "row",
      flex: 1,
    },
  };
}
