import Qs from "qs";
import axios from "axios";

function shouldNotIncludeCredentials(url) {
  return (
    url.indexOf("search-prestostaging") >= 0 ||
    url.indexOf("staging-websites") >= 0 ||
    url.indexOf("search-presto-search-prod") >= 0 ||
    url.indexOf("search-search1-prod-presto-apps") >= 0
  );
}

function get(url, params, headers) {
  var isStaging = shouldNotIncludeCredentials(url);
  if (params && params.params) {
    console.log("here : ");
    url = url + "?" + Qs.stringify(params.params);
  }
  return new Promise((success, failure) => {
    axios({
      url: url,
      params: params && params.params,
      responseType: "json",
      withCredentials: isStaging ? false : true,
      headers: { "Content-Type": "application/json", ...headers },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          success({ data: response.data });
        } else {
          failure({ data: response.data });
        }
      })
      .catch((error) => {
        failure({ data: error });
      });
  });
}

function deleteRequest(url) {
  var isStaging = shouldNotIncludeCredentials(url);

  return new Promise((success, failure) => {
    fetch(url, {
      method: "DELETE",
      cache: "no-cache",
      credentials: isStaging ? undefined : "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => [response.status, response.text()])
      .then(([status, text]) => {
        return [status, text.length ? JSON.parse(text) : {}];
      })
      .then(([status, response]) => {
        if (status >= 200 && status < 300) {
          success({ data: response });
        } else {
          failure({ data: response });
        }
      });
  });
}

function post(url, object, headers = undefined, body = {}) {
  var isStaging = shouldNotIncludeCredentials(url);

  return new Promise((success, failure) => {
    fetch(url, {
      method: "POST",
      cache: "no-cache",
      credentials: isStaging ? undefined : "include",
      headers: { "Content-Type": "application/json", ...headers },
      // headers: { "Content-Type": "application/json" },
      responseType: "json",
      body: JSON.stringify(object),
    })
      .then((response) => {
        console.log("URL : ", url, response);
        // captureRazorPayPaymentV1 doesn't return a json
        if (
          url.endsWith("/razor") ||
          url.endsWith("/razorpay/payment_response")
        ) {
          return Promise.all([response.status, {}]);
        } else {
          return Promise.all([response.status, response.json()]);
        }
      })
      .then(([status, response]) => {
        console.log("status-- ", status);
        console.log("response ", response);
        if (status >= 200 && status < 300) {
          success({ data: response });
        } else {
          console.log("status : ", status);
          console.log(response);
          failure({ data: response });
        }
      });
  });
}

function put(url, params) {
  var isStaging = shouldNotIncludeCredentials(url);
  console.log("is staging", isStaging);
  return new Promise((success, failure) => {
    fetch(url, {
      method: "PUT",
      cache: "no-cache",
      credentials: isStaging ? undefined : "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    })
      .then((response) => {
        console.log("Put Response : ", url, response);
        return Promise.all([response.status, response.json()]);
      })
      .then(([status, response]) => {
        if (status >= 200 && status < 300) {
          success({ data: response });
        } else {
          failure({ data: response });
        }
      });
  });
}
const defaults = {};

export default {
  get,
  post,
  put,
  delete: deleteRequest,
  defaults: defaults,
};
