import React, { useContext, useState } from "react";
import { View, StyleSheet, FlatList, Pressable } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import I18n from "i18n-js";
import { useAssets, Components } from "presto-react-components";
import NavigatorContext from "@presto-contexts/NavigatorContext";

export default function GiftRedemption() {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const styles = getStyles(theme);
  const [openedItem, setOpenedItem] = useState(null);
  const rows = [
    {
      title: I18n.t("screen_messages.gift_redemption.text1"),
    },
    {
      title: I18n.t("screen_messages.gift_redemption.text2"),
    },
    {
      title: I18n.t("screen_messages.gift_redemption.text3"),
    },
  ];

  const renderItem = ({ item, index }) => {
    return (
      <View
        key={index}
        style={{
          flexDirection: "row",
          paddingTop: theme.primaryPadding,
        }}
      >
        <View>
          <PrestoText
            fontStyle="400.normal"
            color="#757474"
            extraStyle={{ position: "relative", top: 2 }}
          >
            {"* "}
          </PrestoText>
        </View>
        <View style={{ width: "100%" }}>
          <PrestoText fontStyle="400.normal" color="#757474">
            {item.title}
          </PrestoText>
        </View>
      </View>
    );
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          paddingHorizontal: theme.containerPadding,
        }}
      >
        <FlatList data={rows} renderItem={renderItem} />
      </View>
    </View>
  );
}
function getStyles(theme) {
  return StyleSheet.create({
    container: {},
    row: {
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "space-between",
    },
    heading: {
      color: theme.darkText,
    },
  });
}
