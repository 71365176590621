import React, { useState, useContext, useEffect } from "react";
import { View, Dimensions, ScrollView } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { RowButton } from "@presto-components/PrestoButton";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import OrderManager from "@presto-services/features/orders/OrderManager";
import TrackOrderCard from "@presto-cards/TrackOrderCard/TrackOrderCard";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import TrackOrderModel from "@presto-component-models/TrackOrderModel";
import { useAssets } from "presto-react-components";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import utils from "../../utils/index";
import UserContext from "@presto-contexts/UserContext";
import Config from "@presto-common/config";

const windowHeight = Dimensions.get("window").height;

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

const DELIVERY_OPTIONS = [
  {
    label: "screen_messages.common.accepted",
    key: "accepted_at",
  },
  {
    label: "screen_messages.common.dispatched",
    key: "dispatched_at",
  },
  {
    label: "screen_messages.common.delivered",
    key: "delivered_at",
  },
];

export default function OrdersListScreen({ route }) {
  const { svgs } = useAssets();
  const params = route.params || {};
  const { orderId, showPayModal, orderData } = params;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const isMobileWeb = utils.isMobileWeb();
  const lusakaOrder = order?.address?.city == "Lusaka";
  const noRoundOff = _.get(Config, "merchant_config.noRoundOff", false);

  useEffect(() => {
    getOrder();
    if (showPayModal) {
      onPayPressed();
    }
  }, [orderId]);

  const getOrder = () => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        setOrder(response.data);
        setLoading(false);
      },
      (error) => {
        console.log("OrderManager.getOrder Error : ", error);
        alert(I18n.t("error_messages.error_loading_order"));
      }
    );
  };

  const renderProgressIcon = (checked) => {
    return (
      <View
        style={{
          width: 30,
          height: 30,
          borderRadius: 16,
          borderWidth: 2,
          justifyContent: "center",
          alignItems: "center",
          borderColor: theme.primary,
          backgroundColor: checked ? theme.primary : "white",
        }}
      >
        {checked ? <svgs.TickIcon primaryColor={theme.primary} /> : undefined}
      </View>
    );
  };

  const renderLineProgressBar = (order) => {
    const options = DELIVERY_OPTIONS;
    let components = [];
    _.forEach(options, (option, index) => {
      let done = false;
      if (option.key == "accepted_at") {
        done =
          !!order["accepted_at"] ||
          !!order["dispatched_at"] ||
          !!order["delivered_at"];
      } else if (option.key == "dispatched_at") {
        done = !!order["dispatched_at"] || !!order["delivered_at"];
      } else if (option.key === "delivered_at") {
        done = !!order["delivered_at"];
      }
      const lineHeight = index === DELIVERY_OPTIONS.length - 1 ? 0 : "100%";
      components.push(
        <View
          key={option.key}
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            paddingHorizontal: 15,
            height: 60,
          }}
        >
          <View
            style={{
              height: "100%",
              width: 51,
              alignItems: "center",
            }}
          >
            <View
              style={{
                backgroundColor: theme.primary,
                height: lineHeight,
                width: 3,
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 24,
              }}
            ></View>
            <View>{option.label ? renderProgressIcon(done) : undefined}</View>
          </View>

          <View
            style={{
              flex: 1,
              height: 60,
              paddingLeft: 15,
            }}
          >
            <View
              style={{ flexDirection: "row", alignItems: "center", height: 30 }}
            >
              <PrestoText fontStyle="medium" color={theme.darkText} size={14}>
                {I18n.t(option.label)}
              </PrestoText>
            </View>

            <VerticalSpacing size={4} />
          </View>
          <View
            style={{
              width: "120%",
              position: "absolute",
              left: 0,
              top: "75%",
              borderBottomWidth: 1,
              borderColor: "#DADADA",
            }}
          ></View>
        </View>
      );
    });

    return components;
  };

  const onAction = () => {
    Navigator.emit({
      event: "switchTab",
      params: {
        tabName: "ACCOUNT",
        pop: true,
      },
    });

    Navigator.emit({
      event: "push",
      params: {
        screenName: "ORDER_DETAILS_SCREEN",
        screenParams: { orderId: order.id },
      },
    });
  };

  const onPayPressed = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "PAYMENT_OPTIONS",
        screenParams: {
          order: showPayModal ? orderData : order,
          paymentCallback: paymentCallback,
        },
      },
    });
  };

  const paymentCallback = (flag) => {
    if (flag) {
      getOrder();
    }
  };

  const onPressReport = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "REPORT_A_PROBLEM",
        screenParams: { orderId: order.id },
      },
    });
  };

  let items = order?.items;
  if (order?.line_items) {
    items = _.map(_.keys(item.line_items), (k) => item.line_items[k]);
  }
  const filteredItems = _.filter(
    items,
    (item) => item?.reference_id !== "DELIVERY_CHARGE"
  );
  const count = _.sumBy(filteredItems, (i) => i.quantity);
  const deliveryLineItem = _.filter(
    items,
    (item) => item?.reference_id == "DELIVERY_CHARGE"
  );
  let deliveryCharge = deliveryLineItem[0]?.total_price || 0;
  let cartInfo = {
    [I18n.t("screen_messages.total_price_str")]: utils.formattedPriceRoundOff(
      order?.original_gross_amount - deliveryCharge || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),

    [I18n.t("screen_messages.delivery_charges")]: utils.formattedPriceRoundOff(
      lusakaOrder ? 75 : deliveryCharge,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),

    [I18n.t("screen_messages.taxes_str")]: utils.formattedPriceRoundOff(
      order?.tax_amount || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
    [I18n.t("screen_messages.total_text")]: utils.formattedPriceRoundOff(
      order?.total_amount || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
  };
  if (order?.balance_amount && order?.total_amount - order?.balance_amount / 100 > 0) {
    cartInfo = {
      ...cartInfo,
      [I18n.t("screen_messages.payments.points_used")]: (order?.total_amount - order.balance_amount / 100).toFixed(2) || 0,
      [I18n.t("screen_messages.payments.amount_to_be_payed")]: utils.formattedPriceRoundOff(
        order?.balance_amount / 100 || 0,
        I18n.t("screen_messages.common.currency"),
        noRoundOff
      )
    }
  }

  const strikeThrough = (key) => {
    return key === I18n.t("screen_messages.delivery_charges") && lusakaOrder;
  };
  const showPayButton = () => {
    let flag = false;

    let paymentObj = order?.payments_list;
    let lastPayment = paymentObj.slice(-1);

    if (
      lastPayment[0]?.status == "initiated" ||
      order?.payment_status == "succeeded"
    ) {
      flag = false;
    } else if (order?.sub_status == "PAYMENT_REQUESTED") {
      flag = true;
    }

    return flag;
  };

  const showPaymentMsg = () => {
    let paymentObj = order?.payments_list;
    let lastPayment = paymentObj.slice(-1);

    let msg = "";
    if (
      lastPayment[0]?.status == "initiated" &&
      lastPayment[0]?.provider !== "CASH" &&
      lastPayment[0]?.provider !== "CARD"
    ) {
      msg = I18n.t("screen_messages.payments.payment_initiated_text");
    } else if (
      lastPayment[0]?.status == "failed" ||
      lastPayment[0]?.status == "cancelled"
    ) {
      msg = I18n.t("screen_messages.payments.payment_failure_text");
    }
    return msg;
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={
            order
              ? I18n.t("screen_messages.orders.track_order_screen_title", {
                order_id: order.friendly_id,
              })
              : ""
          }
          onBack={() => {
            if (isMobileWeb) {
              Navigator.emit({
                event: "push",
                params: {
                  tabName: "ACCOUNT",
                  pop: true,
                },
              });
            }
            Navigator.emit({
              event: "popToTop",
              params: {
                tabName: "ACCOUNT",
              },
            });
          }}
        />
        <ScrollView
          style={{ height: windowHeight - 100, width: "100%" }}
          contentContainerStyle={{
            paddingTop: 20,
            paddingBottom: 60,
          }}
        >
          {order ? (
            <>
              <View style={{ width: "100%" }}>
                <View
                  style={{
                    paddingHorizontal: theme.containerPadding,
                    paddingVertical: theme.containerPadding - 5,
                  }}
                >
                  {Config.features.enable_payment_options && showPayButton() ? (
                    <TrackOrderCard
                      actionTitle={I18n.t("screen_messages.common.pay")}
                      order={
                        new TrackOrderModel({ order: order, count: count })
                      }
                      onTrackOrderPressed={onPayPressed}
                      outstandingAmount={
                        order?.balance_amount > 0
                          ? utils.formattedPriceRoundOff(
                            order?.balance_amount / 100
                          )
                          : ""
                      }
                    />
                  ) : (
                    <TrackOrderCard
                      actionTitle={I18n.t(
                        "screen_messages.view_items_button_str"
                      )}
                      order={
                        new TrackOrderModel({ order: order, count: count })
                      }
                      onTrackOrderPressed={onAction}
                      onPayPressed={onAction}
                    />
                  )}
                  <VerticalSpacing size={20} />

                  {showPaymentMsg() ? (
                    <View>
                      <PrestoText
                        color={theme.error}
                        size={theme.h6FontSize}
                        extraStyle={{ marginBottom: 15 }}
                      >
                        {showPaymentMsg()}
                      </PrestoText>
                    </View>
                  ) : null}
                </View>
                {order.status !== "REJECTED"
                  ? renderLineProgressBar(order)
                  : null}
              </View>
              <VerticalSpacing size={30} />
              <View
                style={{
                  paddingHorizontal: theme.containerPadding,
                }}
              >
                <PrestoText
                  color={theme.paragraph}
                  size={theme.h6FontSize}
                  extraStyle={{ marginBottom: 15, marginTop: -10 }}
                >
                  {I18n.t("screen_messages.order_details_title")}
                </PrestoText>
                <KeyValueList dict={cartInfo} strikeThrough={strikeThrough} />
              </View>
              <VerticalSpacing size={30} />
              <RowButton
                title={I18n.t("screen_messages.report_problem_label_str")}
                showDisclosure={true}
                onPress={() => onPressReport()}
              />
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            </>
          ) : null}
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
