import _ from "lodash";

export default class VariantCategoryItem {
  static createVariantCategoryItems(item, leafVariantType) {
    if (_.isEmpty(item.name)) {
      return [];
    }
    if (_.isEmpty(item.variations)) {
      return [new VariantCategoryItem(item)];
    }

    const leafVariations = _.flatMap(item.variations, (variation) => {
      const isLeafVariation = leafVariantType
        ? variation.type === leafVariantType
        : _.isEmpty(
            _.filter(
              item.variations,
              (v) => v.outer_variation_id == variation.id
            )
          );

      if (isLeafVariation) {
        return new VariantCategoryItem(item, variation);
      } else {
        return null;
      }
    }).filter((v) => !!v);

    const firstUnsoldVariation = _.find(
      leafVariations,
      (x) => !x.isAllVariationsSoldOut()
    );
    return firstUnsoldVariation
      ? [firstUnsoldVariation]
      : _.first(leafVariations);
    // return leafVariations;
  }

  static isLeafVariation(item, variation) {
    return new VariantCategoryItem(item, variation).isLeafVariation();
  }

  constructor(categoryItem, variant) {
    this.categoryItem = categoryItem;
    this.variation = variant;
  }

  get gradeVariant() {
    if (this.variation) {
      const heirarchy = this.getVariationHeirarchyForVariant();
      return _.first(_.filter(heirarchy, { type: "Grade" }));
    } else {
      return null;
    }
  }

  get colorVariant() {
    if (this.variation) {
      const heirarchy = this.getVariationHeirarchyForVariant();
      return _.first(_.filter(heirarchy, { type: "Color" }));
    } else {
      return null;
    }
  }

  get firstColorVariant() {
    if (this.variation) {
      return this.colorVariant;
    } else {
      const gradeVariant = _.first(this.getGradeVariants());
      if (_.isEmpty(gradeVariant)) return null;
      const colorVariant = _.first(this.getColorVariants(gradeVariant.id));
      if (_.isEmpty(colorVariant)) return null;
      return colorVariant;
    }
  }

  get sizeVariant() {
    if (this.variation) {
      const heirarchy = this.getVariationHeirarchyForVariant();
      return _.first(_.filter(heirarchy, { type: "Size" }));
    } else {
      return null;
    }
  }

  getGradeVariants() {
    return _.filter(this.categoryItem.variations || [], { type: "Grade" });
  }

  getColorVariants(forParentVariationId) {
    const childrenVariations = _.filter(
      this.categoryItem.variations || [],
      (va) => va.outer_variation_id == forParentVariationId
    );
    return _.filter(childrenVariations, { type: "Color" });
  }

  getSizeVariants(forParentVariationId) {
    const childrenVariations = _.filter(
      this.categoryItem.variations || [],
      (va) => va.outer_variation_id == forParentVariationId
    );
    return _.filter(childrenVariations, { type: "Size" });
  }

  getDisplaySizeVariant(forParentVariationId) {
    const sizeVariants = this.getSizeVariants(forParentVariationId) || [];
    const firstUnsoldSizeVariant = _.find(
      sizeVariants,
      (x) => x.stock_count != 0 && x.sold_out == 0
    );
    return firstUnsoldSizeVariant || _.first(sizeVariants);
  }

  get grade() {
    if (this.variation) {
      return this.gradeVariant?.value;
    } else {
      return this.other_data?.grade;
    }
  }

  get color() {
    if (this.variation) {
      return this.colorVariant?.value;
    } else {
      return this.other_data?.color;
    }
  }

  get size() {
    if (this.variation) {
      return this.sizeVariant?.value;
    } else {
      return this.other_data?.size;
    }
  }

  get ref_id() {
    if (this.variation) {
      return this.variation.id;
    } else {
      return this.id;
    }
  }

  get catalog_id() {
    return this.categoryItem.catalog_id;
  }

  get old_price() {
    if (this.variation) {
      return this.variation.other_data?.old_price || this.other_data?.old_price;
    } else {
      return this.other_data?.old_price;
    }
  }

  get base_price() {
    if (this.variation) {
      return this.variation.price?.base_price || this.price?.base_price;
    } else {
      return this.price?.base_price;
    }
  }

  get min_qty() {
    if (this.variation) {
      return this.variation.other_data?.min_qty || this.other_data?.min_qty;
    } else {
      return this.other_data?.min_qty;
    }
  }

  get sold_out() {
    if (this.variation) {
      return this.variation.sold_out || this.variation.stock_count == 0;
    } else {
      return this.product.sold_out;
    }
  }

  findAllLeafVariations = (variation) => {
    const vItem = new VariantCategoryItem(this.product, variation);
    if (vItem.isLeafVariation()) return [variation];

    const childVariations = _.filter(
      this.categoryItem.variations,
      (v) => v.outer_variation_id == variation.id
    );

    return _.flatMap(childVariations, (cv) => this.findAllLeafVariations(cv));
  };

  isAllVariationsSoldOut = () => {
    if (this.variation) {
      if (this.isLeafVariation()) {
        return this.variation.sold_out || this.variation.stock_count == 0;
      } else {
        const leafVariations = this.findAllLeafVariations(this.variation);
        return _.every(
          leafVariations,
          (lv) => new VariantCategoryItem(this.product, lv).sold_out
        );
      }
    } else {
      return this.product.sold_out || this.product.stock_count == 0;
    }
  };

  get max_qty() {
    if (this.variation) {
      return this.variation.other_data?.max_qty || this.other_data?.max_qty;
    } else {
      return this.other_data?.max_qty;
    }
  }

  get product() {
    return this.categoryItem;
  }

  get id() {
    return this.categoryItem.id;
  }

  get category_id() {
    return this.categoryItem.category_id;
  }

  get variations() {
    return this.categoryItem.variations;
  }

  get other_data() {
    return this.categoryItem.other_data;
  }

  get pictures() {
    return this.categoryItem.pictures;
  }

  get pic_url() {
    return this.categoryItem.pic_url;
  }

  getItemVariationForId(id) {
    if (!this.variationMap) {
      this.variationMap = {};
      _.forEach(this.variations, (variation) => {
        this.variationMap[variation.id] = variation;
      });
    }
    return this.variationMap[id];
  }

  getVariationHeirarchyForVariant() {
    if (this.variation) {
      let vid = this.variation.outer_variation_id;

      const heirarchy = [this.variation];
      while (vid !== null) {
        const variation = this.getItemVariationForId(vid);
        if (variation) {
          heirarchy.push(variation);
          vid = variation.outer_variation_id;
        } else break;
      }
      return _.reverse(heirarchy);
    } else {
      return undefined;
    }
  }

  get name() {
    return this.categoryItem.name;
    if (this.variation) {
      const heirarchy = this.getVariationHeirarchyForVariant();
      const names = _.map(heirarchy, "value");
      return this.categoryItem.name + " " + _.join(names, " ");
    } else {
      return this.categoryItem.name;
    }
  }

  get isLot() {
    return this.categoryItem.isLot;
  }

  get price() {
    return this.categoryItem.price;
  }

  isLeafVariation() {
    if (!this.variation) return true;
    if (_.isEmpty(this.categoryItem.variations)) return true;
    return _.isEmpty(
      _.filter(
        this.categoryItem.variations,
        (v) => v.outer_variation_id == this.variation.id
      )
    );
  }
}
