import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import Spring from "@presto-components/Spring/Spring";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import { useSafeArea, SafeAreaView } from "react-native-safe-area-context";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
import SearchManager from "@presto-services/features/search/SearchManager";
const AsyncGalleryManager = asyncify(GalleryManager);
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";

const priceRanges = [
  {
    title: "0-100",
    gte: 0,
    lte: 100,
  },
  {
    title: "100-300",
    gte: 100,
    lte: 300,
  },
  {
    title: "300-500",
    gte: 300,
    lte: 500,
  },
  {
    title: "500-1000",
    gte: 500,
    lte: 1000,
  },
  {
    title: "1000+",
    gte: 1000,
    lte: 10000000,
  },
];
export default function SortOptionsScreen({ route, navigation }) {
  const {
    selectedManufacturer,
    onOffer,
    onFilterChange,
    selectedPrice,
  } = route.params;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [close, setClose] = useState(false);
  const [manufacturers, setManufacturers] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState(
    selectedPrice || null
  );

  const [chosenManufacturer, setChosenManufacturer] = useState(
    selectedManufacturer
  );

  const [offer, setOffer] = useState(onOffer);

  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    console.log(
      "SPONSORED_PRODUCTS Error, response : ",
      name,
      response.data?.media_items
    );
    setManufacturers(response.data?.media_items);
  };

  useEffect(() => {
    SearchManager.getZipHealthFilteredOtherDataAttributes(
      {},
      "company_name",
      (response) => {
        setManufacturers(response.aggregations.distinct_company_name.buckets);
      },
      (error) => { }
    );
  }, []);

  const chooseManufacturer = (man) => {
    setChosenManufacturer(man);
  };

  const onApply = () => {
    onFilterChange({
      company_name: chosenManufacturer,
      on_offer: offer,
      price_range: selectedPriceRange,
    });
    navigation.goBack();
  };

  const insets = useSafeArea();

  const renderActionSheet = ({ margin }) => {
    return (
      <ScrollView style={{}}>
        <LoadingContainer bgColor={theme.white} loading={false}>
          <SafeAreaView
            style={{
              backgroundColor: theme.white,
              marginBottom: margin,
              flex: 1,
            }}
          >
            <TitleHeaderWithBackWithGradientBackground
              user={user}
              title={I18n.t("screen_messages.common.filter")}
            />
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                paddingRight: theme.primaryPadding,
                marginVertical: 20,
              }}
            >
              <PrestoFramedButton
                buttonStyle="primary"
                size="normal"
                width={80}
                title={I18n.t("screen_messages.common.apply")}
                onPress={onApply}
              />
            </View>

            <ScrollView
              contentContainerStyle={{
                paddingHorizontal: theme.primaryPadding,
                flex: 1,
                flexDirection: "column",
              }}
            >
              <PrestoText
                fontStyle={"semibold"}
                color={theme.primary}
                size={16}
              >
                {I18n.t("screen_messages.manufacturers")}
              </PrestoText>
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {_.map(manufacturers, (manufacturer) => {
                  if (chosenManufacturer !== manufacturer.key) {
                    return (
                      <View
                        style={{
                          paddingRight: 10,
                          marginBottom: 10,
                          // marginHorizontal: 5,
                          // borderWidth:1,
                        }}
                      >
                        <PrestoFramedButton
                          buttonStyle="primary"
                          size="normal"
                          width="100%"
                          title={manufacturer.key}
                          onPress={() => chooseManufacturer(manufacturer.key)}
                        />
                      </View>
                    );
                  } else {
                    return (
                      <View
                        style={{
                          paddingRight: 20,
                          marginBottom: 10,
                        }}
                      >
                        <PrestoSolidButton
                          size="normal"
                          width="120%"
                          titleColor={theme.white}
                          buttonStyle="primary"
                          titleFontStyle="700.bold"
                          title={manufacturer.key}
                          onPress={() => chooseManufacturer(manufacturer.key)}
                        />
                      </View>
                    );
                  }
                })}
              </View>
              <VerticalSpacing size={40} />
              <PrestoText
                fontStyle={"semibold"}
                color={theme.primary}
                size={16}
              >
                {I18n.t("screen_messages.offers")}
              </PrestoText>
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: 10,
                }}
              >
                {offer === true ? (
                  <View style={{ paddingRight: 10, marginBottom: 10 }}>
                    <PrestoSolidButton
                      size="normal"
                      width="100%"
                      titleColor={theme.white}
                      title={I18n.t("screen_messages.with_offers")}
                      onPress={() => setOffer(true)}
                      buttonStyle="primary"
                    />
                  </View>
                ) : (
                  <View style={{ paddingRight: 10, marginBottom: 10 }}>
                    <PrestoFramedButton
                      size="normal"
                      width="100%"
                      title={I18n.t("screen_messages.with_offers")}
                      onPress={() => setOffer(true)}
                      buttonStyle="primary"
                    />
                  </View>
                )}
                {offer === false ? (
                  <View style={{ paddingRight: 10 }}>
                    <PrestoSolidButton
                      size="normal"
                      width="100%"
                      titleColor={theme.white}
                      title={I18n.t("screen_messages.without_offers")}
                      onPress={() => setOffer(false)}
                      buttonStyle="primary"
                    />
                  </View>
                ) : (
                  <View style={{ paddingRight: 10 }}>
                    <PrestoFramedButton
                      size="normal"
                      width="100%"
                      title={I18n.t("screen_messages.without_offers")}
                      onPress={() => setOffer(false)}
                      buttonStyle="primary"
                    />
                  </View>
                )}
              </View>
              <VerticalSpacing size={40} />
              <PrestoText
                fontStyle={"semibold"}
                color={theme.primary}
                size={16}
              >
                {I18n.t("screen_messages.common.price")}
              </PrestoText>
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
              <View
                style={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {_.map(priceRanges, (item) => {
                  if (
                    selectedPriceRange &&
                    selectedPriceRange.title === item.title
                  ) {
                    return (
                      <View style={{ paddingRight: 10, marginTop: 10 }}>
                        <PrestoSolidButton
                          size="normal"
                          width="100%"
                          titleColor={theme.white}
                          title={item.title}
                          onPress={() => setOffer(null)}
                          buttonStyle="primary"
                        />
                      </View>
                    );
                  } else {
                    return (
                      <View style={{ paddingRight: 10, marginTop: 10 }}>
                        <PrestoFramedButton
                          width="100%"
                          size="normal"
                          title={item.title}
                          onPress={() => setSelectedPriceRange(item)}
                          buttonStyle="primary"
                        />
                      </View>
                    );
                  }
                })}
              </View>
              <VerticalSpacing size={40} />
            </ScrollView>
          </SafeAreaView>
        </LoadingContainer>
      </ScrollView>
    );
  };

  const fromSpring = close ? { margin: 0 } : { margin: -600 };
  const toSpring = close ? { margin: -600 } : { margin: 0 };

  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => renderActionSheet(props)}
    </Spring>
  );
}
