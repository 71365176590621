import React, { useContext } from "react";
import { View } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import SearchComponent from "@presto-screen-components/SearchComponent/SearchComponent";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";

export default function SearchHeaderWithBack({
  onBack,
  onSearch,
  onSearchFocus,
  placeholder,
  onEndSearchFocusEnd,
  query,
  inputref,
  showSoftInputOnFocus,
  onSearchChange,
  ...props
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { backIconColor = theme.primary, icon, containerStyle } = props;
  return (
    <View
      style={{
        width: "100%",
        height: 50,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flex: 0,
      }}
    >
      <TouchableWithoutFeedback onPress={onBack}>
        <View
          style={{
            width: 50,
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoIcon
            icon={<svgs.LeftArrow primaryColor={backIconColor} />}
            width={50}
            height={50}
          />
        </View>
      </TouchableWithoutFeedback>
      <View
        style={{
          flex: 1,
          paddingRight: theme.primaryPadding,
        }}
      >
        <SearchComponent
          onSearchChange={onSearchChange}
          query={query}
          onSearch={onSearch}
          onSearchFocus={onSearchFocus}
          onEndSearchFocusEnd={onEndSearchFocusEnd}
          placeholder={placeholder}
          inputref={inputref}
          showSoftInputOnFocus={showSoftInputOnFocus}
          icon={icon}
          containerStyle={containerStyle}
        />
      </View>
    </View>
  );
}
