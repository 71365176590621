import React, { useContext } from "react";
import { StatusBar, Platform } from "react-native";
import NavigationManager from "../../common/NavigationManager";
import WebNavigationManager from "../../common/WebNavigationManager";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useMediaQuery } from "react-responsive";
import Config from "@presto-common/config";
import LoadingContainer from "../../components/LoadingContainer";

export default function Main() {
  console.log("Web -- NavigationManager Ref : ", WebNavigationManager.ref);
  const { authState, isUserLoggedIn } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  if (theme.statusBarColor && Platform.OS === "android") {
    StatusBar.setBackgroundColor(theme.statusBarColor);
    StatusBar.setBarStyle("dark-content");
  }
  const isDesktop = useMediaQuery({ minWidth: 941 });

  if (isDesktop) {
    if (authState === "unknown") {
      return <LoadingContainer loading={true}></LoadingContainer>;
    } else if (
      authState === AuthState.userLoggedOut &&
      Config.landingPage &&
      !__DEV__
    ) {
      window.location.href = Config.landingPage;
    } else {
      return (
        <>
          {WebNavigationManager.renderTree(
            authState === AuthState.userLoggedIn,
            isDesktop
          )}
        </>
      );
    }
  } else {
    return (
      <>
        {NavigationManager.renderTree(
          authState === AuthState.userLoggedIn,
          isDesktop
        )}
      </>
    );
  }
}
