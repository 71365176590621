export const reviewServiceOptions = {
  label: "Review Service",
  data: [
    {
      label: "Quality",
      id: 1,
    },
    {
      label: "Overall Service",
      id: 2,
    },
    {
      label: "Professionalism",
      id: 3,
    },
  ],
};

export const reviewOrderOptions = {
  label: "Review Order",
  data: [
    {
      label: "Quality",
      id: 1,
    },
    {
      label: "Overall Service",
      id: 2,
    },
    {
      label: "Speed",
      id: 3,
    },
    {
      label: "Professionalism",
      id: 4,
    },
  ],
};

export const ratingsStar = {
  label: "Rate Merchant",
  data: [
    {
      label: "Very Poor",
      value: 1,
    },
    {
      label: "Poor",
      value: 2,
    },
    {
      label: "Average",
      value: 3,
    },
    {
      label: "Good",
      value: 4,
    },
    {
      label: "Excellent",
      value: 5,
    },
  ],
};

export const ratingsStarBookMyChef = {
  label: "Rate Merchant",
  data: [
    {
      label: "Very Poor",
      value: 1,
    },
    {
      label: "Poor",
      value: 2,
    },
    {
      label: "Average",
      value: 3,
    },
    {
      label: "Happy",
      value: 4,
    },
    {
      label: "Very Happy",
      value: 5,
    },
  ],
};

export const reviewServiceOptionsTwo = {
  label: "Review Service",
  data: [
    {
      label: "Quality",
      id: 1,
    },
    {
      label: "Service",
      id: 2,
    },
    {
      label: "Speed",
      id: 3,
    },
    {
      label: "Hygiene",
      id: 4,
    },
    {
      label: "Taste of food",
      id: 5,
    },
  ],
};
