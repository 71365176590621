import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Platform } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import { RowButton } from "@presto-components/PrestoButton";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import PrestoText from "@presto-components/PrestoText";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import OrderManager from "@presto-services/features/orders/OrderManager";
import AddressCard from "@presto-cards/AddressCard/AddressCard";
import _ from "lodash";
import * as Linking from "expo-linking";
import config from "@presto-common/config";
import AddressCardModel from "@presto-component-models/AddressCardModel";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function OrdersListScreen() {
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [lastOrder, setLastOrder] = useState(null);

  useEffect(() => {
    OrderManager.getMyOrders(
      {},
      (response) => {
        setLastOrder(_.first(response.data.orders));
      },
      () => {}
    );
  }, []);

  const lastUsedAddress = undefined;
  const remainingAddresses = _.filter(
    user.addresses,
    (add) => add.id !== lastUsedAddress?.id
  );
  const onPressRequest = () => {
    AnalyticsManager.send("add_new_outlet");
    Linking.openURL(config.constants.request_outlet_url);
  };
  return (
    <LoadingContainer loading={false}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.outlets_title")}
        />
        <ScrollView
          style={{
            flex: 1,
            width: "100%",
            height: "100%",
          }}
        >
          {lastUsedAddress ? (
            <>
              <View
                style={{
                  paddingHorizontal: theme.primaryPadding,
                  height: 40,
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrestoText
                  fontStyle={"semibold"}
                  color={theme.primary}
                  size={16}
                >
                  {I18n.t("screen_messages.last_used_outlet")}
                </PrestoText>
              </View>
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
              <AddressCard
                address={new AddressCardModel({ address: lastUsedAddress })}
              />
            </>
          ) : null}
          <>
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                height: 40,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrestoText
                fontStyle={"semibold"}
                color={theme.primary}
                size={16}
              >
                {lastUsedAddress
                  ? I18n.t("screen_messages.other_outlets")
                  : I18n.t("screen_messages.outlets")}
              </PrestoText>
            </View>
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            {_.map(remainingAddresses, (add) => {
              return (
                <>
                  <AddressCard
                    address={new AddressCardModel({ address: add })}
                  />
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                </>
              );
            })}
          </>
          <VerticalSpacing size={40} />
          <RowButton
            title={I18n.t("screen_messages.add_new")}
            showDisclosure={true}
            onPress={() => onPressRequest()}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <VerticalSpacing size={40} />
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
