import Montserrat_Bold from "./fonts/Montserrat-Bold.ttf";
import Montserrat_Medium from "./fonts/Montserrat-Medium.ttf";
import Montserrat_Regular from "./fonts/Montserrat-Regular.ttf";
import Montserrat_SemiBold from "./fonts/Montserrat-SemiBold.ttf";

export default {
  "Montserrat-Bold": Montserrat_Bold,
  "Montserrat-Medium": Montserrat_Medium,
  "Montserrat-Regular": Montserrat_Regular,
  "Montserrat-SemiBold": Montserrat_SemiBold
};
