import React, { useContext, useReducer } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import _, { min } from "lodash";

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_ITEM_COUNT":
      return {
        ...state,
        category_count: {
          ...state.category_count,
          ...{ [payload.cat_id]: payload.count },
        },
      };
  }
  return state;
}

export default function WebKeywordsBrowse({
  keyword,
  title,
  items,
  onNavigate,
}) {
  const { theme } = useContext(ThemeContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [state, dispatch] = useReducer(reducer, { category_count: {} });

  const windowHeight = Dimensions.get("window").height;
  const onItemPress = (item) => {
    onNavigate({ [keyword]: item.key });
  };
  return (
    <ScrollView style={{ maxHeight: min(windowHeight - 100, 700) }}>
      <>
        {_.map(items, (item, index) => {
          return (
            <View key={item.key + "index"}>
              <RowButton
                title={item.key}
                rightTitle={`${item.doc_count}`}
                onPress={() => {
                  onItemPress(item);
                }}
                showDisclosure={true}
                numberOfLines={2}
              />
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            </View>
          );
        })}
      </>
    </ScrollView>
  );
}
