import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";

function SectionHeader(props) {
  const { leftText } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <View style={styles.default}>
      {leftText ? (
        <PrestoText
          fontStyle="semibold"
          color={props.textColor || theme.header}
          extraStyle={[props.leftTextStyle || styles.leftTextStyle]}
        >
          {leftText}
        </PrestoText>
      ) : null}
    </View>
  );
}

export function SectionSubHeader(props) {
  const { leftText, theme } = props;
  return (
    <View style={styles.default}>
      {leftText ? (
        <PrestoText
          fontStyle="medium"
          color={theme.defaultTextColor}
          extraStyle={styles.leftTextStyle}
        >
          {leftText}
        </PrestoText>
      ) : null}
    </View>
  );
}

export default SectionHeader;

const styles = {
  default: {
    // paddingLeft: 10,
  },
  leftTextStyle: {
    fontWeight: "600",
    fontSize: 16,
    letterSpacing: 0,
  },
};
