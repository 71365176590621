import React, { useContext } from "react";
import { View } from "react-native";

import ThemeContext from "@presto-contexts/ThemeContext";
import { FontAwesome } from "@expo/vector-icons";

export default function SelectedCheckBox(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        width: props.size || 32,
        height: props.size || 32,
        backgroundColor: theme.appBackgroundColor,
        borderRadius: 16,
        borderWidth: 1,
        borderColor: theme.secondary,
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <FontAwesome
        name="circle"
        size={props.size ? props.size : 28}
        color={props.iconColor ? props.iconColor : theme.primary}
      />
    </View>
  );
}
