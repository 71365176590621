import React, { useContext } from "react";
import { Dimensions, View } from "react-native";
import { Card } from "@presto-components/Cards/Cards";
import ThemeContext from "@presto-contexts/ThemeContext";
import utils from "../../utils/index";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoText from "@presto-components/PrestoText";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";
import PrestoIcon from "@presto-components/PrestoIcon";
import config from "@presto-common/config";
import _ from "lodash";
import svgs from "@presto-common-assets/svgs";

export default function ItemCard({
  item,
  variation,
  isPartialdelivery,
  buttonProps = {},
}) {
  const product = item;
  const { theme } = useContext(ThemeContext);
  const windoWidth = Dimensions.get("window").width;
  const isDesktop = windoWidth > 940;
  const qty =
    product?.other_data?.user_data?.original_quantity_ordered ||
    product?.quantity ||
    0;
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);

  console.log("product====>", product);

  return (
    <View key={item._id}>
      <View
        style={{
          paddingHorizontal: theme.primaryPadding,
          paddingVertical: 10,
          overflow: "hidden",
        }}
      >
        <Card padding={-10}>
          <View
            style={{
              flexDirection: "column",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                padding: 10,

                marginBottom: -40,
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <View>
                  <PrestoText fontStyle="semibold" size={theme.h5FontSize}>
                    {product.name}
                  </PrestoText>
                  <PrestoText
                    fontStyle="normal"
                    size={theme.h5FontSize}
                    color={theme.darkText}
                  >
                    {product.other_data?.company_name}
                  </PrestoText>
                  <VerticalSpacing size={10} />
                </View>

                <View style={{}}>
                  <PrestoText size={12} color={theme.paragraph}>
                    {product.other_data?.molecule}
                  </PrestoText>
                </View>
              </View>

              <View
                style={{
                  width: 130,
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-around",
                }}
              >
                <PrestoText fontStyle="semibold" size={theme.h5FontSize}>
                  {utils.formattedPriceRoundOff.call(
                    {
                      currency: product.other_data.is_gift
                        ? ""
                        : I18n.t("screen_messages.common.currency"),
                    },
                    product.total_price + product.total_tax,
                    noRoundOff
                  )}
                </PrestoText>
                <VerticalSpacing size={20} />
              </View>
            </View>

            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                padding: 10,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <PrestoFramedButton
                  buttonStyle="primary"
                  size="normal"
                  width={100}
                  title={I18n.t("screen_messages.item_count_str", {
                    count: item.quantity,
                  })}
                  onPress={() => {}}
                  titleExtraStyle={{
                    textTransform: "uppercase",
                  }}
                  {...buttonProps}
                />
              </View>
            </View>
          </View>

          {isPartialdelivery &&
            product?.delivered_quantity != product?.quantity && (
              <>
                <PrestoIcon
                  icon={
                    <svgs.VerticalDashedLine
                      primaryColor={theme.subHeader}
                      width={!isDesktop ? windoWidth : 400}
                    />
                  }
                />
                <View style={{ padding: 10 }}>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <PrestoText
                      fontStyle="600.semibold"
                      color={theme.paragraph}
                      extraStyle={{ width: "50%" }}
                      size={12}
                    >
                      {I18n.t("screen_messages.item_delivered", {
                        count: Math.round(product?.delivered_quantity),
                      })}
                    </PrestoText>
                    <PrestoText
                      fontStyle="600.semibold"
                      color={theme.subHeader}
                      extraStyle={{ width: "50%", textAlign: "right" }}
                      size={12}
                    >
                      {product?.comment}
                    </PrestoText>
                  </View>

                  {product?.refund_request && (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 5,
                      }}
                    >
                      <PrestoText
                        fontStyle="600.semibold"
                        size={12}
                        extraStyle={{ textTransform: "uppercase" }}
                        color={
                          product?.refund_request?.status == "completed"
                            ? theme.primary
                            : theme.subHeader
                        }
                      >
                        {I18n.t("screen_messages.refund_status")}{" "}
                        {product?.refund_request?.status}
                      </PrestoText>
                      <PrestoText
                        fontStyle="600.semibold"
                        size={12}
                        extraStyle={{ textTransform: "uppercase" }}
                        color={
                          product?.refund_request?.status == "completed"
                            ? theme.primary
                            : theme.subHeader
                        }
                      >
                        {utils.formattedPriceRoundOff(
                          product?.refund_request?.refund_amount / 100.0
                        )}
                      </PrestoText>
                    </View>
                  )}
                </View>
              </>
            )}
        </Card>
      </View>
    </View>
  );
}
