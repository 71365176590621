import React from "react";
import I18n from "i18n-js";
import { SoldOutItemCards } from "presto-react-components";
import _ from "lodash";

export default function SoldOutItemCard(props) {
  let forwardProps = {
    ...props,
    buttonTitle: I18n.t("screen_messages.notify_me_str"),
  };

  if (!_.isFunction(props?.priceAccessorMethod)) {
    forwardProps.priceAccessorMethod = (product) => {
      return product.formatCurrency2;
    };
  }

  return <SoldOutItemCards.SoldOutItemCard1 {...forwardProps} />;
}
