import React from "react";
import { LoyaltyTierIndicatorCards } from "presto-react-components";
import _ from "lodash";
import UserModel from "@presto-component-models/UserModel";
import utils from "../../utils/index";

export default function LoyaltyTierIndicatorCard({
  user,
  loyaltyTiers,
  userLoyaltyTierProgress,
}) {
  const loyaltyTierProgressValue = userLoyaltyTierProgress.loyalty_tier_progress
    ? Number(
        Number(userLoyaltyTierProgress.loyalty_tier_progress / 100).toFixed("0")
      )
    : 0;

  const userModel = new UserModel({ user });
  userModel.setLoyaltyTiers(loyaltyTiers);
  let forwardedProps = {
    loyaltyInfo: {
      tierName: _.get(user, "loyalty_tier_name"),
      tierLimitValue: userModel.loyaltyTierLimitValue / 100,
      userSpendings: loyaltyTierProgressValue,
    },
    loyaltyTiers,
    userModel,
    formattedCurrencyToRoundOff: utils.formattedCurrencyToRoundOff,
  };
  
  return (
    <LoyaltyTierIndicatorCards.LoyaltyTierIndicatorCard1 {...forwardedProps} />
  );
}
