const fonts = {
  primaryFont: "Montserrat-Regular",
  primaryMediumFont: "Montserrat-Medium",
  primarySemiBoldFont: "Montserrat-SemiBold",
  primaryBoldFont: "Montserrat-Bold",
};

const themes = (svgs, images) => {
  return {
    ...fonts,
    name: "light",
    loyaltyTier: {
      noTier: {},
      gold: {
        titleColor: "#8C5F0A",
        dividerColor: "#8C5F0A",
        gradients: [
          {
            color: "#FFE19B",
            offset: "0%",
          },
          {
            color: "#D39F3A",
            offset: "45%",
          },
          {
            color: "#AB7818",
            offset: "100%",
          },
        ],
      },
      silver: {
        titleColor: "#444443",
        dividerColor: "#444443",
        gradients: [
          {
            color: "#C4C4C4",
            offset: "0%",
          },
          {
            color: "#656565",
            offset: "70%",
          },
          {
            color: "#C4C4C4",
            offset: "100%",
          },
        ],
      },
      bronze: {
        titleColor: "#88522A",
        dividerColor: "#844307",
        gradients: [
          {
            color: "#BA8758",
            offset: "0%",
          },
          {
            color: "#844307",
            offset: "60%",
          },
          {
            color: "#844307",
            offset: "100%",
          },
        ],
      },
    },

    get secondaryTextFitButtonVerticalPadding() {
      return 20;
    },
    get notifyMeButtonFontSize() {
      return 10;
    },
  };
};
export default themes;
