import React, { useContext, useState, useRef, useEffect } from "react";
import { View, Image, Platform, Keyboard } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import asyncify from "../../common/Asyncify";
import OperatorsManager from "@presto-services/features/operators/OperatorsManager";
import utils from "../../utils";
const AsyncOperatorManager = asyncify(OperatorsManager);
import LoadingContainer from "@presto-components/LoadingContainer";
import PrestoText from "@presto-components/PrestoText";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";
import ModalContext from "@presto-contexts/ModalContext";

export default function RegisterScreen({ navigation }) {
  const { images: Images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [padding, setPadding] = useState(0);
  const scrollRef = useRef();
  const { popRightModal } = useContext(ModalContext);
  const isMobileWeb = utils.isMobileWeb();
  const isNextJsApp = utils.isNextJsApp();

  const onPhoneNumberChange = ({ nativeEvent: { text } }) => {
    setPhoneNumber(text);
  };

  const onNameChange = ({ nativeEvent: { text } }) => {
    setName(text);
  };

  const onEmailChange = ({ nativeEvent: { text } }) => {
    setEmail(text);
  };

  const onLogin = () => {
    if(isMobileWeb && isNextJsApp){
      popRightModal();
    }else {

      navigation.goBack();
    }
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  const onSignup = () => {
    if (name.length == 0 || phoneNumber.length == 0 || email.length == 0) {
      alert(I18n.t("screen_messages.common.all_fields_mandatory"));
      return;
    }
    setLoading(true);
    async function signup() {
      const postData = {
        operator_lead: {
          name: name,
          phone_number: phoneNumber,
          email: email,
        },
      };
      const [error, response] = await AsyncOperatorManager.operatorLeads(
        postData
      );
      if (error) {
        setLoading(false);
        alert("Request Account", utils.errorMessage(error));
      } else {
        setLoading(false);
        setName("");
        setPhoneNumber("");
        setEmail("");
        alert(I18n.t("screen_messages.register.onregister_description"));
      }
    }
    signup();
  };
  return (
    <LoadingContainer
      bgColor={theme.primary}
      style={{ flex: 1 }}
      loading={loading}
    >
      <KeyboardAwareScrollView
        enableOnAndroid={true}
        style={{ backgroundColor: theme.primary, flex: 1 }}
        contentContainerStyle={{
          paddingTop: Platform.OS == "android" ? 30 : 100,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirextion: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: padding,
          }}
        >
          <Image
            source={Images.logo_transparent}
            style={{ width: 300, height: 216 }}
          />
          <FullWidthHorizontalPaddedBox>
            <PrestoTextInput
              theme={theme}
              placeholder={I18n.t("screen_messages.common.your_name")}
              value={name}
              onChange={onNameChange}
              backgroundColor={theme.white}
            ></PrestoTextInput>
            <VerticalSpacing size={10} />
            <PrestoTextInput
              theme={theme}
              placeholder={I18n.t(
                "screen_messages.login.enter_your_phone_number"
              )}
              value={phoneNumber}
              keyboardType={"phone-pad"}
              onChange={onPhoneNumberChange}
              backgroundColor={theme.white}
            ></PrestoTextInput>
            <VerticalSpacing size={10} />
            <PrestoTextInput
              theme={theme}
              placeholder={I18n.t("screen_messages.common.your_email")}
              value={email}
              onChange={onEmailChange}
              backgroundColor={theme.white}
            ></PrestoTextInput>
            <VerticalSpacing size={10} />
            <PrestoFramedButton
              size="normal"
              title={I18n.t("screen_messages.register.request_an_account")}
              onPress={onSignup}
              buttonStyle="secondary"
            />
            <VerticalSpacing size={20} />
            <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
              <PrestoText size={theme.h5FontSize} color={theme.white}>
                {I18n.t("screen_messages.login.sub_title")}
              </PrestoText>
            </FullWidthHorizontalPaddedBox>
            <VerticalSpacing size={20} />
            <PrestoFramedButton
              size="normal"
              title={I18n.t("screen_messages.login.login_btn_name")}
              onPress={onLogin}
              buttonStyle="secondary"
            />
          </FullWidthHorizontalPaddedBox>
        </View>
      </KeyboardAwareScrollView>
    </LoadingContainer>
  );
}
