import React, { useCallback, useReducer, useEffect, useContext } from "react";
import {
  View,
  ScrollView,
  Dimensions,
  FlatList,
  Pressable,
} from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import FavoritesManager from "@presto-services/features/favorites/FavoritesManager";
import I18n from "i18n-js";
import SearchableScreen from "../SearchableScreen/SearchableScreen";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import useFocusEffect from "@presto-common/useFocusEffect";
import _ from "lodash";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import utils from "../../utils";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useCatalog } from "@presto-stores/CatalogStore";
import GradientBackgroundForHeader from "@presto-screen-components/SearchableScreen/GradientBackgroundForHeader";
import UserContext from "@presto-contexts/UserContext";
import AnalyticsManager from "../../common/AnalyticsManager";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
    case "SET_CATEGORY_ITEMS":
      return { ...state, items: payload };
    case "SET_FILTERED_ITEMS":
      return { ...state, filteredItems: payload };
    case "SET_DISTRIBUTORS":
      return { ...state, distributors: payload };
    case "SET_SELECTED_DISTRIBUTORS":
      return { ...state, selectedDistributor: payload };
  }
  return state;
}

const windowHeight = Dimensions.get("window").height;

export default function FavoritesSccreen() {
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const catalog = useCatalog((state) => state.catalog);
  const { user } = useContext(UserContext);
  const [state, dispatch] = useReducer(reducer, {
    favorites: [],
    items: [],
    distributors: null,
    selectedDistributor: "",
    filteredItems: null,
  });

  const {
    favorites,
    items,
    distributors,
    selectedDistributor,
    filteredItems,
  } = state;

  const getItems = () => {
    const ids = _.map(state.favorites, (fav) => fav.item_id);
    if (!_.isEmpty(ids)) {
      SearchManager.fuzzySearchCategoryItem(
        { ids: ids, size: 100 },
        (response) => {
          const items = _.map(response.hits.hits, (item) => item._source);

          const filteredItems = _.filter(
            items,
            (item) =>
              item.other_data.is_gift != "true" || !item.other_data.is_gift
          );

          dispatch({
            type: "SET_CATEGORY_ITEMS",
            payload: _.keyBy(filteredItems, "id"),
          });
        },
        (error) => {
          alert(I18n.t("screen_messages.error_loading_favorites"));
        }
      );
    }
  };

  useEffect(() => {
    var result = null;
    if (selectedDistributor != "") {
      result = _.pickBy(
        items,
        (item) => item?.other_data?.company_name === selectedDistributor
      );
    }
    dispatch({
      type: "SET_FILTERED_ITEMS",
      payload: result,
    });
  }, [selectedDistributor]);

  useEffect(() => {
    if (state.favorites) {
      getItems();
    }
  }, [state.favorites]);

  useFocusEffect(
    useCallback(() => {
      FavoritesManager.getFavoriteProducts(
        { params: { records_per_page: 100, page: 1 } },
        (response) => {
          dispatch({
            type: "SET_FAVORITES",
            payload: response.data,
          });
        },
        (error) => {
          utils.errorMessage(error);
        }
      );
    }, [])
  );

  useEffect(() => {
    SearchManager.getZipHealthFilteredOtherDataAttributes(
      {},
      "company_name",
      (response) => {
        dispatch({
          type: "SET_DISTRIBUTORS",
          payload: response.aggregations.distinct_company_name.buckets,
        });
      },
      (error) => {}
    );
  }, []);

  const onPressDistributorsName = (key) => {
    AnalyticsManager.send("my_products_distributors");
    if (selectedDistributor === key) {
      dispatch({
        type: "SET_SELECTED_DISTRIBUTORS",
        payload: "",
      });
    } else {
      dispatch({
        type: "SET_SELECTED_DISTRIBUTORS",
        payload: key,
      });
    }
  };

  const renderDistributors = ({ item, index }) => {
    return (
      <Pressable
        onPress={() => onPressDistributorsName(item?.key)}
        key={index}
        style={{
          flex: 1,
          height: 35,
          maxWidth: 200,
          backgroundColor:
            item.key === selectedDistributor ? theme.primary : theme.white,
          borderColor: "#CBE5D3",
          borderWidth: 1,
          borderRadius: 8,
          marginRight: 10,
          padding: 10,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PrestoText
          fontStyle="500.medium"
          size={12}
          numberOfLines={1}
          color={
            item.key === selectedDistributor ? theme.white : theme.paragraph
          }
        >
          {item?.key}
        </PrestoText>
      </Pressable>
    );
  };

  const renderFavorite = (fav) => {
    var item = filteredItems ? filteredItems[fav.item_id] : items[fav.item_id];
    if (item) {
      return <ItemCard key={fav.id} item={item} />;
    } else {
      return null;
    }
  };

  const onSearchFocus = () => {
    AnalyticsManager.send("my_products_search");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: { searchParams: {}, focus: true, catalogId: catalog?.id },
      },
    });
  };
  
  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      showSoftInputOnFocus={false}
      placeholder={I18n.t(
        "screen_messages.common.search_by_placeholder_options.category"
      )}
      searchContainerStyle={{
        ...(utils.isDesktop() && {
          paddingTop: 0,
        }),
      }}
    >
      {utils.isDesktop() ? (
        <View style={{ minHeight: 50 }}>
          <GradientBackgroundForHeader user={user} />
        </View>
      ) : null}

      <ScrollView
        style={{ height: windowHeight - 100 }}
        contentContainerStyle={{ paddingBottom: 100 }}
      >
        {distributors ? (
          <View>
            <PrestoText
              fontStyle="600.semibold"
              color={theme.primary}
              extraStyle={{ paddingHorizontal: 20, paddingVertical: 15 }}
            >
              {I18n.t("screen_messages.filter_by_dist")}
            </PrestoText>
            <FlatList
              data={distributors}
              renderItem={renderDistributors}
              horizontal
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{
                paddingHorizontal: 20,
                paddingBottom: 15,
              }}
            />
          </View>
        ) : null}
        <View>
          {items?.length > 0 && favorites.length > 0 ? (
            <View
              style={{
                paddingLeft: 20,
              }}
            >
              <PrestoText size={theme.h6FontSize} color={theme.paragraph}>
                {I18n.t("screen_messages.products.your_products")}
              </PrestoText>
            </View>
          ) : null}
          {_.map(favorites, (fav) => {
            return renderFavorite(fav);
          })}
        </View>
      </ScrollView>
    </SearchableScreen>
  );
}
