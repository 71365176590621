import FeedbackResource from "./FeedbackResource";
import PrestoSdk from "../../global/PrestoSdk";
import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import $ from "../../global/util";

const FeedbackManager = function (
  FeedbackResource,
  PrestoSdk,
  Utils,
  ErrorConstants
) {
  function sendAppointmentFeedback(
    appointmentFeedback,
    successCallback,
    errorCallback
  ) {
    var validationResponse = appointmentFeedback.validateObject();
    if (validationResponse.success) {
      if (
        Utils.isEmpty(appointmentFeedback.afcust_id) &&
        Utils.isEmpty(appointmentFeedback.afop_id)
      ) {
        validationResponse = Utils.ErrorObject(
          ErrorConstants.ErrorCodes.APPOINTMENT_ID_EMPTY,
          false
        );
        validationResponse = Utils.updateErrorObject(validationResponse);
        if (errorCallback) {
          errorCallback(validationResponse);
        }
      } else {
        var params = {};
        params.app_id = PrestoSdk.getAppId();
        params.feedback = appointmentFeedback;
        FeedbackResource.sendAppointmentFeedback(params).then(
          function (feedbackResponse) {
            if (successCallback) {
              successCallback(feedbackResponse);
            }
          },
          function (error) {
            error = Utils.updateErrorObject(error);
            if (errorCallback) {
              errorCallback(error);
            }
          }
        );
      }
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      if (errorCallback) {
        errorCallback(validationResponse);
      }
    }
  }

  function sendOrderFeedback(params, successCallback, errorCallback) {
    params.app_id = PrestoSdk.getAppId();
    FeedbackResource.sendOrderFeedback(params).then(
      function (feedbackResponse) {
        if (successCallback) {
          successCallback(feedbackResponse);
        }
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        if (errorCallback) {
          errorCallback(error);
        }
      }
    );
  }

  function sendAppFeedback(params, successCallback, errorCallback) {
    params.app_id = PrestoSdk.getAppId();
    FeedbackResource.sendAppFeedback(params).then(
      function (feedbackResponse) {
        if (successCallback) {
          successCallback(feedbackResponse);
        }
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        if (errorCallback) {
          errorCallback(error);
        }
      }
    );
  }

  return {
    sendAppFeedback: sendAppFeedback,
    sendOrderFeedback: sendOrderFeedback,
    sendAppointmentFeedback: sendAppointmentFeedback,
  };
};

export default FeedbackManager(
  FeedbackResource,
  PrestoSdk,
  Utils,
  ErrorConstants
);
