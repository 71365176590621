import React, { useCallback, useReducer, useContext, useEffect } from "react";
import { View, Dimensions, FlatList, Pressable } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import I18n from "i18n-js";
import { useFocusEffect } from "@react-navigation/native";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import AnalyticsManager from "../../common/AnalyticsManager";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_REGIONS":
      return { ...state, region: payload };
  }
  return state;
}
let isDesktop = Dimensions.get("window").width > 940;

export default function RegionFilter({ navigate, catalog }) {
  const { Navigator } = useContext(NavigatorContext);
  const { theme } = useContext(ThemeContext);
  const [state, dispatch] = useReducer(reducer, {
    region: null,
  });

  const { region } = state;

  useEffect(() => {
    SearchManager.getZipHealthFilteredOtherDataAttributes(
      {},
      "region",
      (response) => {
        dispatch({
          type: "SET_REGIONS",
          payload: response.aggregations.distinct_region.buckets,
        });
      },
      (error) => {}
    );
  }, []);

  const onPressRegionName = (key) => {
    AnalyticsManager.send("home_region", { name: key });
    if (isDesktop) {
      navigate({ region: key });
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: {
              region: key,
              focus: false,
            },
            catalogId: catalog?.id,
          },
        },
      });
    }
  };

  const renderDistributors = ({ item, index }) => {
    return (
      <Pressable
        onPress={() => onPressRegionName(item?.key)}
        key={index}
        style={{
          flex: 1,
          height: 35,
          maxWidth: 200,
          borderColor: "#CBE5D3",
          borderWidth: 1,
          borderRadius: 8,
          marginRight: 10,
          padding: 10,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PrestoText
          fontStyle="500.medium"
          size={12}
          numberOfLines={1}
          color={theme.paragraph}
        >
          {I18n.t("screen_messages.region_and_count", {
            region: item?.key,
            count: item?.doc_count ?? "0",
          })}
        </PrestoText>
      </Pressable>
    );
  };

  return region?.length > 0 ? (
    <View>
      <PrestoText
        fontStyle="500.semibold"
        color={theme.primary}
        size={theme.h5FontSize}
        extraStyle={{
          paddingHorizontal: isDesktop ? 15 : 20,
          paddingVertical: 15,
        }}
      >
        {I18n.t("screen_messages.filter_by_region")}
      </PrestoText>
      <FlatList
        data={region}
        renderItem={renderDistributors}
        horizontal
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{ paddingHorizontal: 20, paddingBottom: 15 }}
      />
    </View>
  ) : null;
}
