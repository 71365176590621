import React, { useContext } from "react";
import { View } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";

export default function TitleHeaderWithBack({
  title,
  hideBack = false,
  titleProps = {},
  textFontStyle = "bold",
  ...props
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { leftArrowColor = theme.primary, titleColor = theme.darkText } = props;
  const onBack = () => {
    if (props?.onBack) {
      props.onBack();
    } else {
      Navigator.emit({ event: "goBack" });
    }
  };
  return (
    <View
      style={{
        width: "100%",
        height: 50,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {!hideBack ? (
        <TouchableWithoutFeedback onPress={onBack}>
          <View
            style={{
              width: 50,
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <PrestoIcon
              icon={<svgs.LeftArrow primaryColor={leftArrowColor} />}
              width={50}
              height={50}
            />
          </View>
        </TouchableWithoutFeedback>
      ) : (
        <View
          style={{
            paddingRight: theme.primaryPadding,
          }}
        ></View>
      )}

      <View
        style={{
          flex: 1,
          paddingRight: theme.primaryPadding,
        }}
      >
        <PrestoText
          fontStyle={textFontStyle}
          size={theme.h4FontSize}
          color={titleColor}
          {...titleProps}
        >
          {title}
        </PrestoText>
      </View>
    </View>
  );
}
