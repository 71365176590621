import React from "react";
import I18n from "i18n-js";
import { ItemCards } from "presto-react-components";
import utils from "../../utils/index";
import _ from "lodash";

export default function ItemCard(props) {
  let forwardProps = {
    ...props,
    offersCount: I18n.t("screen_messages.count_offers", {
      count: props?.product?.offersCount,
    }),
    is_sponsored: I18n.t("screen_messages.sponsored_text"),
  };

  if (!_.isFunction(props?.priceAccessorMethod)) {
    forwardProps.priceAccessorMethod = (product) => {
      return product.formatCurrency2;
    };
  }

  return <ItemCards.ItemCard3 {...forwardProps} />;
}
// TODO(refactor): move the below code to Presto-react-components
/*
export default function ItemCard({
  product,
  onPress,
  onAdd,
  onSubtract,
  updating,
  onUpdate,
  color = "#53C175",
}) {
  const renderOfferText = () => {
    const canShowOfferText = product.product.other_data?.offer_text;
    return !_.isEmpty(canShowOfferText) ? (
      <PrestoText
        fontStyle="400.normal"
        size={12}
        color={color}
        extraStyle={{ marginTop: 5 }}
      >
        {product.product.other_data.offer_text}
      </PrestoText>
    ) : null;
  };

  return (
    <View style={{ paddingVertical: 10 }} key={product.productId}>
      <FullWidthHorizontalPaddedBox>
        <Card padding={10}>
          <View style={{ flexDirection: "row", width: "100%" }}>
            {product.picURL ? (
              <View
                style={{
                  width: 55,
                  height: 55,
                  marginRight: 10,
                }}
              >
                <Pressable
                  onPress={() => onPress(product.product, product.variation)}
                >
                  <Image
                    source={{
                      uri: product.picURL,
                    }}
                    resizeMode="contain"
                    style={{
                      width: 55,
                      height: 55,
                      overflow: "hidden",
                    }}
                  />
                </Pressable>
              </View>
            ) : null}
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "space-between",
                width: "48%",
              }}
            >
              <View style={{ flex: 1 }}>
                <Pressable
                  onPress={() => onPress(product.product, product.variation)}
                >
                  <PrestoText fontStyle="semibold" size={14}>
                    {product.name}
                  </PrestoText>
                </Pressable>
                <Pressable
                  onPress={() => onPress(product.product, product.variation)}
                >
                  <PrestoText size={14}>{product.companyName}</PrestoText>
                </Pressable>
                <VerticalSpacing size={10} />
              </View>
              {renderOfferText()}
              <View style={{}}>
                <PrestoText fontStyle={{ color: "#666666" }} size={10}>
                  {product.molecule}
                </PrestoText>
              </View>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <PrestoText fontStyle="semibold" size={14}>
                {product.isGiftItem
                  ? product.priceWithoutCurrency
                  : product.price}
              </PrestoText>
              {updating ? (
                <View
                  style={{
                    alignItems: "center",
                    height: 40,
                  }}
                >
                  <DotIndicator
                    color={color}
                    maxScale={1.2}
                    minScale={0.2}
                    size={8}
                  />
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {product.hasOffers ? (
                    <View>
                      <VerticalSpacing size={10} />
                      <PrestoFramedButton
                        size="normal"
                        buttonStyle="primary"
                        width={100}
                        title={I18n.t("screen_messages.offers_count_message", {
                          count: product.offersCount,
                        })}
                        onPress={() =>
                          onPress(product.product, product.variation)
                        }
                      />
                    </View>
                  ) : product.count == 0 ? (
                    <Pressable
                      onPress={() => onAdd(product.product, product.variation)}
                    >
                      <View style={{ width: 40, height: 40 }}>
                        <PrestoIcon
                          icon={<svgs.PlusIcon primaryColor={color} />}
                        />
                      </View>
                    </Pressable>
                  ) : (
                    <>
                      <Pressable
                        onPress={() =>
                          onSubtract(product.product, product.variation)
                        }
                      >
                        <View
                          style={{
                            width: 40,
                            height: 40,
                          }}
                        >
                          <PrestoIcon
                            icon={<svgs.MinusIcon primaryColor={color} />}
                          />
                        </View>
                      </Pressable>
                      <AmountInput
                        size={40}
                        amount={product.count}
                        textSize={16}
                        onUpdate={(amount) =>
                          onUpdate(product.variation, amount)
                        }
                      />
                      <Pressable
                        onPress={() =>
                          onAdd(product.product, product.variation)
                        }
                      >
                        <View
                          style={{
                            width: 40,
                            height: 40,
                          }}
                        >
                          <PrestoIcon
                            icon={<svgs.PlusIcon primaryColor={color} />}
                          />
                        </View>
                      </Pressable>
                    </>
                  )}
                </View>
              )}
            </View>
          </View>
        </Card>
      </FullWidthHorizontalPaddedBox>
    </View>
  );
} */
