import React from "react";
import { View, StyleSheet } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";

export function SectionLinkTextComponent(props) {
  const {
    leftText1,
    leftText1FontStyle = "medium",
    leftText2,
    onPressButton,
    theme,
    linkTextColor,
    style,
    innerStyle = {},
    linkTextStyle = {},
    leftText1Style = {},
  } = props;
  const styles = getStyles(theme);
  return (
    <TouchableWithoutFeedback onPress={onPressButton}>
      <View
        style={{
          ...styles.default,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            ...innerStyle,
          }}
        >
          {leftText1 ? (
            <PrestoText
              color={styles.textColor || theme.title}
              fontStyle={leftText1FontStyle || style.fontFamily || "medium"}
              extraStyle={[[styles.linkText], leftText1Style]}
            >
              {leftText1}
            </PrestoText>
          ) : null}
          {leftText2 ? (
            <PrestoText
              fontStyle="semibold"
              color={linkTextColor || theme.primary}
              extraStyle={[styles.linkText, linkTextStyle]}
            >
              {leftText2}
            </PrestoText>
          ) : null}
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default SectionLinkTextComponent;

function getStyles(theme) {
  return {
    default: {
      paddingVertical: 15,
    },
    linkText: {
      fontSize: 14,
      // color: theme.linkTextColor,
      paddingTop: 2,
      cursor: "pointer",
    },
  };
}
