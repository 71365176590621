import React, { useContext } from "react";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { InCartItemCards } from "presto-react-components";
import _ from "lodash";

export default function ItemCard(props) {
  const { theme } = useContext(ThemeContext);
  let forwardProps = {
    ...props,
    offersCount: I18n.t("screen_messages.count_offers", {
      count: props?.product?.offersCount,
    }),
    is_sponsored: I18n.t("screen_messages.sponsored_text"),
    textInputStyles: {
      color: theme.black,
    },
  };

  if (!_.isFunction(props?.priceAccessorMethod)) {
    forwardProps.priceAccessorMethod = (product) => {
      return product.formatCurrency2;
    };
  }

  return <InCartItemCards.InCartItemCard2 {...forwardProps} />;
}

/*
import { DotIndicator } from "react-native-indicators";
import _ from "lodash";

export default function ItemCard({
  product,
  onPress,
  onAdd,
  onSubtract,
  updating,
  onUpdate,
  color = "#53C175",
}) {
  const renderOfferText = () => {
    const canShowOfferText = product.product.other_data?.offer_text;
    return !_.isEmpty(canShowOfferText) ? (
      <PrestoText
        fontStyle="400.normal"
        size={12}
        color={"#FFFFFF"}
        extraStyle={{ marginTop: 5 }}
      >
        {product.product.other_data.offer_text}
      </PrestoText>
    ) : null;
  };

  return (
    <View>
      <View style={{ paddingVertical: 10 }} key={product.productId}>
        <FullWidthHorizontalPaddedBox>
          <DropShadow
            style={{
              shadowColor: "rgb(0,0,0)",
              shadowOffset: {
                width: 2,
                height: 1,
              },
              shadowOpacity: 0.05,
              shadowRadius: 6,
              elevation: 5,
            }}
          >
            <View
              style={[
                {
                  padding: 10,
                  width: "100%",
                  backgroundColor: color,
                  borderRadius: 10,
                },
              ]}
            >
              <View style={{ flexDirection: "row" }}>
                {product.picURL ? (
                  <View
                    style={{
                      width: 50,
                      height: 50,
                      marginRight: 10,
                    }}
                  >
                    <TouchableWithoutFeedback
                      onPress={() =>
                        onPress(product.product, product.variation)
                      }
                    >
                      <Image
                        source={{
                          uri: product.picURL,
                        }}
                        resizeMode="contain"
                        style={{
                          width: 50,
                          height: 50,
                          overflow: "hidden",
                        }}
                      />
                    </TouchableWithoutFeedback>
                  </View>
                ) : null}
                <TouchableWithoutFeedback
                  onPress={() => onPress(product.product, product.variation)}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <PrestoText
                        fontStyle="semibold"
                        fontSize={14}
                        color={"#FFFFFF"}
                      >
                        {product.name}
                      </PrestoText>
                      <PrestoText fontSize={14} color={"white"}>
                        {product.companyName}
                      </PrestoText>
                      <VerticalSpacing size={10} />
                    </View>
                    {renderOfferText()}
                    <View style={{}}>
                      <PrestoText size={12} color={"white"}>
                        {product.molecule}
                      </PrestoText>
                    </View>
                  </View>
                </TouchableWithoutFeedback>
                <View
                  style={{
                    width: 150,
                    flexDirection: "column",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  <TouchableWithoutFeedback
                    onPress={() => onPress(product.product, product.variation)}
                  >
                    <PrestoText
                      fontStyle="semibold"
                      fontSize={14}
                      color={"#FFFFFF"}
                    >
                      {product.price}
                    </PrestoText>
                  </TouchableWithoutFeedback>
                  {updating ? (
                    <View
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                        height: 40,
                      }}
                    >
                      <DotIndicator
                        color={"white"}
                        maxScale={1.2}
                        minScale={0.2}
                        size={8}
                      />
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {product.hasOffers ? (
                        <View>
                          <VerticalSpacing size={10} />
                          <PrestoFramedButton
                            buttonStyle="primary"
                            size="normal"
                            width={100}
                            borderColor={"#FFFFFF"}
                            title={I18n.t(
                              "screen_messages.offers_count_message",
                              {
                                count: product.offersCount,
                              }
                            )}
                            titleColor={"#FFFFFF"}
                            onPress={() =>
                              onPress(product.product, product.variation)
                            }
                          />
                        </View>
                      ) : product.count == 0 ? (
                        <TouchableWithoutFeedback
                          onPress={() =>
                            onAdd(product.product, product.variation)
                          }
                        >
                          <View style={{ width: 40, height: 40 }}>
                            <PrestoIcon
                              icon={<svgs.PlusIcon primaryColor={"white"} />}
                            />
                          </View>
                        </TouchableWithoutFeedback>
                      ) : (
                        <>
                          <Pressable
                            onPress={() =>
                              onSubtract(product.product, product.variation)
                            }
                          >
                            <View
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            >
                              <PrestoIcon
                                icon={<svgs.MinusIcon primaryColor={"white"} />}
                              />
                            </View>
                          </Pressable>
                          <AmountInput
                            size={40}
                            amount={product.count}
                            textSize={16}
                            onUpdate={(amount) => {
                              onUpdate(product.variation, amount);
                            }}
                            textColor={"#FFFFFF"}
                            borderColor={"#FFFFFF"}
                            backgroundColor={"#FFFFFF"}
                          />
                          <Pressable
                            onPress={() =>
                              onAdd(product.product, product.variation)
                            }
                          >
                            <View
                              style={{
                                width: 40,
                                height: 40,
                              }}
                            >
                              <PrestoIcon
                                icon={<svgs.PlusIcon primaryColor={"white"} />}
                              />
                            </View>
                          </Pressable>
                        </>
                      )}
                    </View>
                  )}
                </View>
              </View>
            </View>
          </DropShadow>
        </FullWidthHorizontalPaddedBox>
      </View>
    </View>
  );
}
 */
