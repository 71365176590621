import Utils from "../../common/Utils";
import CategoryItemResource from "./CategoryItemResource";
import CategoryItemObject from "../../data_models/CategoryItemObject";
import ErrorConstants from "../../common/ErrorConstants";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const CategoryItemManager = function (
  Utils,
  CategoryItemResource,
  CategoryItemObject,
  ErrorConstants,
  PrestoSdk
) {
  function getCategoryItemDetails(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.productId)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ITEM_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CategoryItemResource.getCategoryItemDetails(params).then(
        function (categoryItemDetails) {
          var categoryItemObj = new CategoryItemObject();
          categoryItemObj.buildObject(categoryItemDetails.data);
          categoryItemDetails.data = categoryItemObj;
          successCallback(categoryItemDetails);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function notifyWhenAvailable(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.productId)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ITEM_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      if (!params.merchant_id) {
        params.merchant_id = PrestoSdk.getMerchantId();
      }
      CategoryItemResource.notifyWhenAvailable(params).then(
        function (notifyWhenAvailableResponse) {
          successCallback(notifyWhenAvailableResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }
  function getPromotionItems(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.merchant_id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ITEM_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CategoryItemResource.getPromotionItems(params).then(
        function (getPromotionItemsResponse) {
          successCallback(getPromotionItemsResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }
  function createProduct(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.category_id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.CATEGORY_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CategoryItemResource.createProduct(params).then(
        function (response) {
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }
  function updateCategoryItemDetails(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ITEM_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CategoryItemResource.updateCategoryItemDetails(params).then(
        function (response) {
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }
  function deleteCategoryItem(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.id)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.ITEM_ID_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      CategoryItemResource.deleteCategoryItem(params).then(
        function (response) {
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function employeeStockCountUpdate(params, successCallback, errorCallback) {
    CategoryItemResource.employeeStockCountUpdate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function employeeStockCountUpdateV1(params, successCallback, errorCallback) {
    CategoryItemResource.employeeStockCountUpdateV1(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getDynamicPriceForItems(params, successCallback, errorCallback) {
    CategoryItemResource.getDynamicPriceForItems(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getCategoryItemDetails: getCategoryItemDetails,
    notifyWhenAvailable: notifyWhenAvailable,
    getPromotionItems: getPromotionItems,
    createProduct: createProduct,
    updateCategoryItemDetails: updateCategoryItemDetails,
    deleteCategoryItem: deleteCategoryItem,
    employeeStockCountUpdate: employeeStockCountUpdate,
    employeeStockCountUpdateV1: employeeStockCountUpdateV1,
    getDynamicPriceForItems: getDynamicPriceForItems,
  };
};

export default CategoryItemManager(
  Utils,
  CategoryItemResource,
  CategoryItemObject,
  ErrorConstants,
  PrestoSdk
);
