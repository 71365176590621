import React, { useContext } from "react";
import { View, SafeAreaView } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import { Card, TransparentCard } from "@presto-components/Cards/Cards";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import _ from "lodash";
import PrestoText from "@presto-components/PrestoText";

export default function OffersCard({ coupon, onApply }) {
  const { theme } = useContext(ThemeContext);

  return (
    <LoadingContainer>
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ width: "100%" }}>
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
              paddingVertical: theme.containerPadding - 5,
            }}
          >
            <Card>
              <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <PrestoText fontStyle="bold">{coupon.couponCode}</PrestoText>
                  <VerticalSpacing size={5} />
                  <PrestoText fontStyle="bold" color={theme.primary} size={11}>
                    {coupon.description}
                  </PrestoText>
                </View>
                <PrestoText
                  fontStyle="medium"
                  color={theme.tertiaryTextColor}
                  size={10}
                >
                  {I18n.t("screen_messages.coupons.you_save")}
                  {coupon.value_type === "fixed_discount" ? (
                    <PrestoText
                      fontStyle="bold"
                      color={theme.tertiaryTextColor}
                      size={14}
                    >
                      {coupon.savingsFixedDiscountTitle}
                    </PrestoText>
                  ) : (
                    <PrestoText
                      fontStyle="bold"
                      color={theme.tertiaryTextColor}
                      size={14}
                    >
                      {coupon.savingsPercentageTitle}
                    </PrestoText>
                  )}
                </PrestoText>
              </View>
              <VerticalSpacing size={20} />
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <PrestoText
                  color="#b6b6b6"
                  size={12}
                  extraStyle={{
                    maxWidth: "65%",
                  }}
                >
                  {coupon.validityText}
                </PrestoText>
                <PrestoFramedButton
                  size="normal"
                  title={I18n.t("screen_messages.common.apply")}
                  width={100}
                  buttonStyle="primary"
                  bgColor={theme.linkTextColor}
                  onPress={onApply}
                />
              </View>
            </Card>
          </View>
        </View>
      </SafeAreaView>
    </LoadingContainer>
  );
}

export function OffersPrimaryCard({ coupon, onApply }) {
  const { theme } = useContext(ThemeContext);

  return (
    <LoadingContainer>
      <SafeAreaView style={{ flex: 1 }}>
        <View style={{ width: "100%" }}>
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
              paddingVertical: theme.primaryPadding,
            }}
          >
            <View
              style={{
                backgroundColor: theme.primary,
                borderRadius: theme.cardRadius,
              }}
            >
              <TransparentCard>
                <View
                  style={{ flexDirection: "row", alignItems: "flex-start" }}
                >
                  <View
                    style={{
                      flex: 1,
                    }}
                  >
                    <PrestoText fontStyle="bold" color={theme.white}>
                      {coupon.couponCode}
                    </PrestoText>
                    <VerticalSpacing size={5} />
                    <PrestoText fontStyle="bold" color={theme.white} size={11}>
                      {coupon.description}
                    </PrestoText>
                  </View>
                  <View
                    style={{
                      paddingLeft: theme.primaryPadding,
                      justifyContent: "center",
                      alignItems: "flex-end",
                      paddingHorizontal: 5,
                    }}
                  >
                    <PrestoText
                      fontStyle="medium"
                      color={theme.white}
                      size={10}
                    >
                      {I18n.t("screen_messages.coupons.you_save")}

                      {coupon.value_type === "fixed_discount" ? (
                        <PrestoText
                          fontStyle="bold"
                          color={theme.white}
                          size={14}
                        >
                          {coupon.savingsFixedDiscountTitle}
                        </PrestoText>
                      ) : (
                        <PrestoText
                          fontStyle="bold"
                          color={theme.white}
                          size={14}
                        >
                          {coupon.savingsPercentageTitle}
                        </PrestoText>
                      )}
                    </PrestoText>
                  </View>
                </View>
                <VerticalSpacing size={20} />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <PrestoText
                    color={theme.white}
                    size={12}
                    extraStyle={{
                      maxWidth: "65%",
                    }}
                  >
                    {coupon.validityTextWithDescription}
                  </PrestoText>
                  <PrestoFramedButton
                    size="normal"
                    title={I18n.t("screen_messages.common.remove")}
                    buttonStyle="primary"
                    width={100}
                    borderColor={theme.white}
                    titleColor={theme.white}
                    onPress={onApply}
                  />
                </View>
              </TransparentCard>
            </View>
          </View>
        </View>
      </SafeAreaView>
    </LoadingContainer>
  );
}
