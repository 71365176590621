import $ from "../../global/util";

export default {
  GET_APPOINTMENT: {
    PATH: "/status.json",
  },
  UPDATE_APPOINTMENT: {
    PATH: "/appointments",
  },
  CANCEL_APPOINTMENT: {
    PATH: "/appointments",
  },
  GET_ALL_APPOINTMENTS: {
    PATH: "/appointments.json",
  },
  BOOK_APPOINTMENT: {
    PATH: "/appointments.json",
  },
  CLEAR_APPOINTMENT: {
    PATH: "/users",
  },
};
