import React, { useState, useContext, useCallback, useEffect } from "react";
import { View, FlatList, Image, Dimensions } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import I18n from "i18n-js";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import * as Linking from "expo-linking";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import { useFocusEffect } from "@react-navigation/native";
import PrestoText from "@presto-components/PrestoText";
import { Surface } from "react-native-paper";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import AnalyticsManager from "../../common/AnalyticsManager";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import config from "@presto-common/config";
import SearchManager from "@presto-services/features/search/SearchManager";

export default function DistributorsGallery({
  name,
  imgStyle,
  hideTitle,
  onNavigate,
  sideMenuClicked,
  catalog,
}) {
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [gallery, setGallery] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const showViewAll = config.features?.show_view_all;
  let isDesktop = Dimensions.get("window").width > 940;

  const getCategoryDetails = (catId) => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: {
          searchParams: { category: { id: catId }, catalogId: catalog?.id },
        },
      },
    });
  };

  const onItemClicked = (item) => {
    AnalyticsManager.send("home_distributors");
    if (item?.other_data?.company_name) {
      if (isDesktop)
        onNavigate({ company_name: item?.other_data?.company_name });
      else {
        setPaneState(true);
        Navigator.emit({
          event: "push",
          params: {
            screenName: "SEARCH_RESULTS_SCREEN",
            screenParams: {
              searchParams: {
                distributor: item?.other_data?.company_name,
                focus: false,
              },
              catalogId: catalog?.id,
            },
          },
        });
      }
    } else {
      switch (item.target?.type) {
        case "category_item":
          setPaneState(true);
          Navigator.emit({
            event: "modal",
            params: {
              screenName: "DETAILED_ITEM",
              screenParams: {
                itemId: item.target.id_value,
                catalogId: catalog?.id,
              },
            },
          });
          return;
        case "url":
          Linking.openURL(item.id_value);
          return;
        case "category":
          getCategoryDetails(item.target.id_value);
          return;
        case "app_link":
          return;
      }
    }
  };
  const renderImage = ({ item, index }) => {
    return (
      <Pressable
        onPress={() => onItemClicked(item)}
        key={index}
        style={{
          marginLeft: theme.containerPadding,
          paddingBottom: theme.containerPadding,
          flexDirection: "column",
          alignItems: "flex-start",
          borderRadius: 150,
        }}
      >
        <Surface elevation={3} style={{ borderRadius: 100, marginTop: 10 }}>
          <Image
            source={{ uri: item?.file_url }}
            style={{
              resizeMode: "contain",
              height: imgStyle.height,
              width: imgStyle.width,
              borderRadius: 150,
              borderWidth: 0,
            }}
          />
        </Surface>
        <PrestoText
          fontStyle="500.medium"
          size={12}
          color={theme.darkText}
          extraStyle={{
            textAlign: "center",
            width: imgStyle.height,
            paddingTop: 10,
          }}
          numberOfLines={2}
        >
          {item?.name}
        </PrestoText>
      </Pressable>
    );
  };

  useEffect(() => {
    SearchManager.getZipHealthFilteredOtherDataAttributes(
      {},
      "distributor",
      (response) => {
        setDistributors(response.aggregations.distinct_distributor.buckets);
      },
      (error) => {}
    );
  }, []);

  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response.data?.media_items);
  };

  useFocusEffect(
    useCallback(() => {
      getGallery(name);
    }, [])
  );

  const flatListProps = {
    horizontal: isDesktop,
    ...(!isDesktop && { numColumns: 4 }),
  };

  const onPressViewAll = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "KEYWORD_BROWSE_SCREEN",
        screenParams: {
          key: "distributor",
          values: distributors,
          name: I18n.t("screen_messages.distributors_label"),
          catalogId: catalog?.id,
        },
      },
    });
  };

  return gallery.length > 0 ? (
    <View
      style={{
        paddingVertical: theme.containerPadding - 5,
        paddingHorizontal: isDesktop ? 10 : 0,
      }}
    >
      <View
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <PrestoText
          fontStyle="600.semibold"
          size={16}
          color={theme.primary}
          extraStyle={{
            paddingHorizontal: 20,
            paddingBottom: showViewAll ? 0 : 10,
          }}
        >
          {I18n.t("screen_messages.distributors_label")}
        </PrestoText>
        {showViewAll ? (
          <PrestoFramedButton
            buttonStyle="primary"
            size="small"
            title={I18n.t("screen_messages.common.view_all_uppercase")}
            titleColor={theme.primary}
            borderColor={theme.primary}
            width={100}
            extraStyle={{ marginRight: 20 }}
            borderRadius={6}
            onPress={() => onPressViewAll()}
            titleExtraStyle={{ textTransform: "uppercase" }}
          />
        ) : null}
      </View>

      <FlatList
        keyExtractor={(_, index) => "index-" + index}
        data={gallery}
        renderItem={renderImage}
        swipeThreshold={10}
        layout={"default"}
        contentContainerStyle={{
          paddingRight: theme.containerPadding,
        }}
        {...flatListProps}
      />
    </View>
  ) : null;
}
