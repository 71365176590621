import React, { useEffect, useContext, useReducer } from "react";
import { View } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import LoadingContainer from "@presto-components/LoadingContainer";
import { ScrollView } from "react-native-gesture-handler";
import { useSafeArea } from "react-native-safe-area-context";
import I18n from "i18n-js";
import RateManager from "@presto-services/features/rate/RateManager";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { VerticalSpacing } from "@presto-components/Spacing";
import TextInputLargeBox from "@presto-components/TextInputs/TextInputLargeBox";
import { FontAwesome } from "@expo/vector-icons";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import utils from "../../utils";
import { prestoAlert } from "../../common/Alert";
import {
  reviewServiceOptions,
  reviewOrderOptions,
  ratingsStar,
} from "../../constants/RatingConstants";
import PrestoText from "@presto-components/PrestoText";
import {
  PrestoSolidButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import { SafeAreaView } from "react-native-safe-area-context";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";

function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_LOADING":
      return { ...state, loading: payload };

    case "SET_RATING":
      return { ...state, selectedRating: payload };

    case "SET_REVIEW_MESSAGE":
      return { ...state, review_message: payload };

    case "SET_OPTIONS":
      return { ...state, selectedOption: payload };

    case "SET_AVAILABLE_RATINGS":
      return { ...state, availableRatings: payload };

    case "SET_RATING_ID":
      return { ...state, ratingid: payload };
  }
}

let destop = utils.isDesktop();

export default function ReviewDetails({ route, navigation }) {
  const params = route.params || {};
  const merchant = params?.merchant;
  const entityId = params?.entityId;
  const entityType = params?.entityType;
  const order = params?.order;
  const subEntityType = params?.subEntityType;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeArea();

  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    selectedRating: "",
    review_message: "",
    selectedOption: [],
    availableRatings: false,
    ratingid: "",
  });

  const {
    loading,
    selectedRating,
    review_message,
    selectedOption,
    availableRatings,
    ratingid,
  } = state;

  const setLoading = (value) => {
    dispatch({ type: "SET_LOADING", payload: value });
  };

  const setRating = (value) => {
    dispatch({ type: "SET_RATING", payload: value });
  };

  const setReviewMessage = (message) => {
    dispatch({ type: "SET_REVIEW_MESSAGE", payload: message });
  };

  const setOptions = (value) => {
    dispatch({ type: "SET_OPTIONS", payload: value });
  };

  const setAvailableRatings = (value) => {
    dispatch({ type: "SET_AVAILABLE_RATINGS", payload: value });
  };

  const setRatingId = (id) => {
    dispatch({ type: "SET_RATING_ID", payload: id });
  };

  const onBack = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  useEffect(() => {
    let params = {
      entity_id: entityId,
      entity_type: entityType,
    };
    RateManager.getRatingInfo(
      params,
      (response) => {
        let ratings = ratingsStar?.data;
        setReviewMessage(response?.data[0]?.review_message);
        ratings.forEach((ele) => {
          if (ele.value === response?.data[0]?.star_rating) {
            setRating(ele);
          }
        });
        let review_order_options = reviewOrderOptions.data;
        review_order_options.forEach((ele) => {
          if (ele.label === response?.data[0]?.review_title) {
            setOptions(ele);
          }
        });
        let review_service_options = reviewServiceOptions.data;
        review_service_options.forEach((ele) => {
          if (ele.label === response?.data[0]?.review_title) {
            setOptions(ele);
          }
        });
        setRatingId(response?.data[0]?.id);
        if (response?.data?.length > 0) {
          setAvailableRatings(true);
        } else {
          setAvailableRatings(false);
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const renderButtons = (item) => {
    let selected = false;
    if (selectedOption.id == item.id) {
      selected = true;
    }

    return (
      <PrestoFramedButton
        size="normal"
        borderColor={selected ? theme.secondary : theme.tertiaryHeadingColor}
        bgColor={selected ? theme.secondary : theme.backgroundColor}
        onPress={() => {
          onSelectOption(item);
        }}
        title={item.label}
        titleColor={
          selected ? theme.primaryButtonText : theme.tertiaryHeadingColor
        }
        buttonStyle="primary"
        width={120}
      />
    );
  };

  const onSelectOption = (option) => {
    setOptions(option);
  };

  const onAddReviewMessage = ({ nativeEvent: { text } }) => {
    setReviewMessage(text);
  };

  const renderRatingStar = () => {
    const ratingOptions = ratingsStar.data;
    let stars = _.map(ratingOptions, (option, index) => {
      return (
        <TouchableWithoutFeedback onPress={() => rateService(option)}>
          <View style={{ flexDirection: "row", paddingRight: 5 }}>
            <FontAwesome
              name="star"
              size={25}
              color={
                option.value <= selectedRating.value
                  ? "#FF9623"
                  : theme.paragraph
              }
            />
          </View>
        </TouchableWithoutFeedback>
      );
    });
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <View style={{ flexDirection: "row" }}>{stars}</View>
        <View style={{}}>
          {selectedRating.value == 3 ? (
            <PrestoText fontStyle="semibold" color={"#FF9623"}>
              {selectedRating?.label}
            </PrestoText>
          ) : (
            <PrestoText
              fontStyle="semibold"
              color={selectedRating.value < 3 ? theme.error : theme.primary}
            >
              {selectedRating?.label}
            </PrestoText>
          )}
        </View>
      </View>
    );
  };

  const rateService = (option) => {
    setRating(option);
  };

  const onSubmitRating = () => {
    if (selectedRating.value == null) {
      alert(
        "Information",
        I18n.t("screen_messages.review_screen.provide_rating_msg")
      );
      return;
    }
    setLoading(true);
    let params = {
      rating: {
        star_rating: selectedRating?.value,
        review_title: selectedRating?.label,
        review_message: review_message,
      },
      entity_id: entityId,
      entity_type: entityType,
    };
    RateManager.createRating(
      params,
      (response) => {
        setLoading(false);
        goBack();
        alert(
          I18n.t("screen_messages.review_screen.rating_provided_success_msg")
        );
      },
      (error) => {
        alert(
          I18n.t("screen_messages.common.sorry_text"),
          utils.errorMessage(error) || I18n.t("screen_messages.try_again_later")
        );
        setLoading(false);
      }
    );
  };

  const onUpdateRating = () => {
    setLoading(true);
    let params = {
      rating: {
        star_rating: selectedRating?.value,
        review_title: selectedRating?.label,
        review_message: review_message,
      },
    };
    params.id = ratingid;
    RateManager.updateRating(
      params,
      (response) => {
        setLoading(false);
        goBack();
        alert(
          I18n.t("screen_messages.review_screen.rating_provided_success_msg")
        );
      },
      (error) => {
        alert(
          I18n.t("screen_messages.common.sorry_text"),
          utils.errorMessage(error) || I18n.t("screen_messages.try_again_later")
        );
        setLoading(false);
      }
    );
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          onBack={onBack}
          title={
            order
              ? I18n.t("screen_messages.review_screen.review_order", {
                  order_id: order.friendly_id,
                })
              : ""
          }
        />
        <ScrollView
          style={{
            height: "100%",
            paddingBottom: 100,
            backgroundColor: theme.white,
          }}
        >
          <View
            style={{
              marginTop: theme.containerPadding,
            }}
          >
            <View style={{ paddingHorizontal: theme.primaryPadding }}>
              <PrestoText size={16} color={theme.defaultTextColor}>
                {I18n.t("screen_messages.review_screen.review_order_title")}
              </PrestoText>
            </View>

            <View
              style={{
                marginTop: 30,
                paddingHorizontal: theme.primaryPadding,
                paddingBottom: 15,
              }}
            >
              <PrestoText
                size={16}
                color={theme.primary}
                fontStyle={"600.semibold"}
              >
                {I18n.t("screen_messages.review_screen.rate_order_experience")}
              </PrestoText>
            </View>
            <View>
              <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />

              <VerticalSpacing size={20} />
              <View style={{ paddingHorizontal: theme.primaryPadding }}>
                {renderRatingStar()}
              </View>
              <VerticalSpacing size={20} />
            </View>
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            <View
              style={{ marginTop: 30, paddingHorizontal: theme.primaryPadding }}
            >
              <PrestoText
                size={16}
                color={theme.primary}
                fontStyle={"600.semibold"}
              >
                {I18n.t("screen_messages.review_screen.write_review")}
              </PrestoText>
            </View>
            <VerticalSpacing size={15} />
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            <View
              style={{ paddingHorizontal: theme.primaryPadding, marginTop: 10 }}
            >
              <TextInputLargeBox
                value={review_message}
                onChange={onAddReviewMessage}
                placeholder={I18n.t(
                  "screen_messages.review_screen.any_additional_comments"
                )}
                multiline={true}
                numberOfLines={10}
                autoCorrect={false}
                theme={theme}
                placeholderTextColor={theme.paragraph}
                textInputHeight={100}
                textInputBorderRadius={12}
                textInputfontSize={14}
                textContainerStyles={{
                  backgroundColor: theme.appBackgroundColor,
                  borderRadius: 12,
                }}
              />
            </View>
            <View
              style={{
                paddingTop: 40,
                paddingHorizontal: theme.primaryPadding,
              }}
            >
              {availableRatings ? (
                <PrestoSolidButton
                  size="large"
                  title={I18n.t("screen_messages.review_screen.update_title")}
                  titleColor={theme.primaryButtonText}
                  buttonStyle="primary"
                  titleExtraStyle={{
                    textTransform: "uppercase",
                  }}
                  onPress={onUpdateRating}
                />
              ) : (
                <PrestoSolidButton
                  size="large"
                  title={I18n.t("screen_messages.review_screen.submit_title")}
                  titleColor={theme.primaryButtonText}
                  buttonStyle="primary"
                  onPress={onSubmitRating}
                  titleExtraStyle={{
                    textTransform: "uppercase",
                  }}
                />
              )}
            </View>
          </View>
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
