import React, { useState, useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import Spring from "@presto-components/Spring/Spring";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import { useSafeArea } from "react-native-safe-area-context";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import { SafeAreaView } from "react-native-safe-area-context";
import UserContext from "@presto-contexts/UserContext";

const SORT_OPTIONS = [
  {
    key: "listing",
    label: "screen_messages.sort.relevance",
  },
  {
    key: "price_low",
    label: "screen_messages.sort.price_low_to_high",
  },
  {
    key: "price_high",
    label: "screen_messages.sort.price_high_to_low",
  },
  {
    key: "name_asc",
    label: "screen_messages.sort.sort_alphabetical",
  },
  {
    key: "name_desc",
    label: "screen_messages.sort.sort_reverse_alphabetical",
  },
];

export default function SortOptionsScreen({ route, navigation }) {
  const { onSortChanged, selectedSort } = route.params;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [close, setClose] = useState(false);
  const [sort, setSort] = useState(selectedSort);

  const onCheckboxClicked = (key) => {
    setSort(key);
    setTimeout(() => {
      onSortChanged(key);
      navigation.goBack();
    }, 0);
  };

  const insets = useSafeArea();
  const renderActionSheet = ({ margin }) => {
    return (
      <LoadingContainer bgColor={theme.white} loading={false}>
        <SafeAreaView
          style={{
            backgroundColor: theme.white,
            marginBottom: margin,
            flex: 1,
          }}
        >
          <TitleHeaderWithBackWithGradientBackground
            user={user}
            title={I18n.t("screen_messages.sort.sort")}
          />

          {_.map(SORT_OPTIONS, (option) => {
            return (
              <>
                <CheckboxRowButton
                  title={I18n.t(option.label)}
                  showDisclosure={true}
                  selected={option.key === sort}
                  onPress={() => onCheckboxClicked(option.key)}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            );
          })}
        </SafeAreaView>
      </LoadingContainer>
    );
  };

  const fromSpring = close ? { margin: 0 } : { margin: -600 };
  const toSpring = close ? { margin: -600 } : { margin: 0 };

  return (
    <Spring from={fromSpring} to={toSpring}>
      {(props) => renderActionSheet(props)}
    </Spring>
  );
}
