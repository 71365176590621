import Utils from "../../common/Utils";
import OutletResource from "./OutletResource";
import ErrorConstants from "../../common/ErrorConstants";
import OutletObject from "../../data_models/OutletObject";
import OutletConfigObject from "../../data_models/OutletConfigObject";
import $ from "../../global/util";

const OutletManager = function (
  Utils,
  OutletResource,
  ErrorConstants,
  OutletObject,
  OutletConfigObject
) {
  function getOutletsArray(rawOutlets) {
    var outlets = [];
    if (rawOutlets) {
      rawOutlets.forEach(function (rawOutlet, index, rawOutlets) {
        var outletObj = new OutletObject();
        outletObj.buildObject(rawOutlet);
        outlets.push(outletObj);
      });
    }
    return outlets;
  }

  function getOutlets(params, successCallback, errorCallback) {
    OutletResource.getOutlets(params).then(
      function (outlets) {
        outlets.data = getOutletsArray(outlets.data);
        successCallback(outlets);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOutletConfig(params, successCallback, errorCallback) {
    if (Utils.isEmpty(params.outletId)) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.OUTLET_ID_EMPTY,
        false
      );
      errorObject = Utils.updateErrorObject(errorObject);
      errorCallback(errorObject);
    } else {
      OutletResource.getOutletConfig(params).then(
        function (outletConfigResponse) {
          var outletConfigObj = new OutletConfigObject();
          outletConfigObj.buildObject(outletConfigResponse.data);
          outletConfigResponse.data = outletConfigObj;
          successCallback(outletConfigResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getServingOutlets(area, successCallback, errorCallback) {
    OutletResource.getServingOutlets(area).then(
      function (outlets) {
        outlets.data = getOutletsArray(outlets.data);
        successCallback(outlets);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOutletDetails(params, successCallback, errorCallback) {
    OutletResource.getOutletDetails(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOutlet(params, successCallback, errorCallback) {
    OutletResource.getOutlet(params).then(
      function (outlets) {
        successCallback(outlets);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getOutlets: getOutlets,
    getOutletConfig: getOutletConfig,
    getServingOutlets: getServingOutlets,
    getOutletDetails: getOutletDetails,
    getOutlet,
  };
};

export default OutletManager(
  Utils,
  OutletResource,
  ErrorConstants,
  OutletObject,
  OutletConfigObject
);
