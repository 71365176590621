import React, { useContext } from "react";
import { View, Pressable } from "react-native";
import SearchComponent from "@presto-screen-components/SearchComponent/SearchComponent";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";

export default function SearchHeaderWithBackAndOptions({
  onSearch,
  onSearchFocus,
  placeholder,
  onEndSearchFocusEnd,
  onBack,
  onFilter,
  onSort,
  sortEnabled,
  filterEnabled,
  query,
  inputref,
  showSoftInputOnFocus,
  onSearchChange,
  containerStyle,
  ...props
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  let {
    backIconColor = theme.primary,
    sortIconColor,
    filterIconColor,
    icon,
  } = props;

  if (!sortIconColor) {
    sortIconColor = sortEnabled ? theme.primary : theme.secondary;
  }

  if (!filterIconColor) {
    filterIconColor = filterEnabled ? theme.primary : theme.secondary;
  }

  return (
    <View
      style={{
        width: "100%",
        height: 50,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flex: 0,
        paddingRight: 10,
      }}
    >
      <Pressable onPress={onBack} style={{ width: "10%" }}>
        <View
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoIcon
            icon={<svgs.LeftArrow primaryColor={backIconColor} />}
            width={50}
            height={50}
          />
        </View>
      </Pressable>
      <View
        style={{ flex: 1, paddingRight: theme.primaryPadding, width: "70%" }}
      >
        <SearchComponent
          onSearchChange={onSearchChange}
          query={query}
          onSearch={onSearch}
          onSearchFocus={onSearchFocus}
          onEndSearchFocusEnd={onEndSearchFocusEnd}
          placeholder={placeholder}
          inputref={inputref}
          showSoftInputOnFocus={showSoftInputOnFocus}
          icon={icon}
          containerStyle={containerStyle}
        />
      </View>
      <Pressable onPress={onSort} style={{ width: "10%" }}>
        <View
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoIcon
            icon={<svgs.SortIcon color={sortIconColor} />}
            width={50}
            height={50}
          />
        </View>
      </Pressable>
      <Pressable onPress={onFilter} style={{ width: "10%" }}>
        <View
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PrestoIcon
            icon={<svgs.FilterIcon primaryColor={filterIconColor} />}
            width={50}
            height={50}
          />
        </View>
      </Pressable>
    </View>
  );
}
