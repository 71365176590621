import React, {
  useState,
  useContext,
  useEffect,
  memo,
  useRef,
  useCallback,
} from "react";
import { View, SectionList, ScrollView, Dimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { RowButton } from "@presto-components/PrestoButton";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import OrderManager from "@presto-services/features/orders/OrderManager";
import TrackOrderCard from "@presto-cards/TrackOrderCard/TrackOrderCard";
import _ from "lodash";
import ItemCard from "@presto-cards/ItemCards/ItemCardInOrderDetails.ziphealth";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import utils from "../../utils";
import PrestoText from "@presto-components/PrestoText";
import TrackOrderModel from "@presto-component-models/TrackOrderModel";
import KeyValueList from "@presto-components/KeyValueList/KeyValueList";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";
import AnalyticsManager from "../../common/AnalyticsManager";
import config from "@presto-common/config";

const MemoizedItemCard = memo(({ item, isPartialdelivery }) => (
  <ItemCard key={item.id} item={item} isPartialdelivery={isPartialdelivery} />
));

const MemoizedPartialItemCard = memo(({ item, isPartialdelivery }) => {
  const { theme } = useContext(ThemeContext);
  const color = item.isItemFullyUndelivered ? theme.error : theme.warning;
  return (
    <ItemCard
      key={item.id}
      item={item}
      isPartialdelivery={isPartialdelivery}
      buttonProps={{
        titleExtraStyle: {
          textTransform: "uppercase",
          color: color,
        },
        extraStyle: {
          borderColor: color,
        },
      }}
    />
  );
});

export default function OrderDetailsScreen({ route, navigation }) {
  const params = route.params || {};
  const { orderId, isInvoice = true } = params;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const windowHeight = Dimensions.get("window").height;
  const [order, setOrder] = useState(null);
  const extraOptions = useRef({
    isPartialdelivery: false,
  });
  const [partialItems, setPartialItems] = useState([]);
  const lusakaOrder = order?.address?.city == "Lusaka";
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);

  useEffect(() => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        const newOrder = response.data;
        const orderObject = new TrackOrderModel({ order: newOrder });
        extraOptions.current.isPartialdelivery = orderObject.isPartialDelivery;
        setOrder(newOrder);
        populatePartialOrderItems(newOrder);
        setLoading(false);
      },
      (error) => {
        console.log("OrderManager getorder error ", error);
        setLoading(false);
        alert(utils.errorMessage(error));
      }
    );
  }, [orderId]);

  const populatePartialOrderItems = (newOrder) => {
    const changedOrderItems = newOrder.items;
    const originalOrderItems = _.get(
      newOrder,
      "other_data.employee_inputs.original_ordered_items",
      []
    );

    let diffQtyOrderItems = [];
    if (!_.isEmpty(originalOrderItems)) {
      _.forEach(originalOrderItems, (item) => {
        const changedOrderItem = _.find(changedOrderItems, ["id", item.id]);
        let remainingQty = 0,
          totalPrice = 0,
          hasQtyDifference = false,
          isItemFullyUndelivered = false;

        if (
          changedOrderItem &&
          changedOrderItem.quantity > 0 &&
          changedOrderItem.quantity < item.quantity
        ) {
          hasQtyDifference = true;
          remainingQty = _.subtract(
            Number(item.quantity),
            Number(changedOrderItem.quantity)
          );
          totalPrice = changedOrderItem.total_price;
        } else if (!changedOrderItem) {
          hasQtyDifference = true;
          remainingQty = item.quantity;
          totalPrice = item.total_price;
          isItemFullyUndelivered = true;
        }

        if (hasQtyDifference) {
          diffQtyOrderItems = _.concat(diffQtyOrderItems, {
            ...item,
            quantity: remainingQty,
            total_price: totalPrice,
            isItemFullyUndelivered,
          });
        }
      });
    }
    setPartialItems(diffQtyOrderItems);
  };

  const onAction = () => {
    if (isInvoice) {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "ORDER_INVOICE_SCREEN",
          screenParams: { orderId: order.id },
        },
      });
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "REVIEW_SCREEN",
          screenParams: {
            entityId: order.id,
            entityType: "Order",
            order: order,
          },
        },
      });
    }
  };

  const onPressReport = () => {
    AnalyticsManager.send("report_problem");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "REPORT_A_PROBLEM",
        screenParams: { orderId: order.id },
      },
    });
  };

  const keyExtractor = (item) => {
    return item.key;
  };

  const renderLineItem = useCallback(
    ({ item }) => (
      <MemoizedItemCard
        key={item.id}
        item={item}
        isPartialdelivery={extraOptions.current.isPartialdelivery}
      />
    ),
    []
  );

  const onPressReview = () => {
    AnalyticsManager.send("order_review");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "REVIEW_SCREEN",
        screenParams: {
          entityId: order.id,
          entityType: "Order",
          order: order,
        },
      },
    });
  };

  const onBack = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  let items = order?.items;
  if (order?.line_items) {
    items = _.map(_.keys(order.line_items), (k) => order.line_items[k]);
  }
  const filteredItems = _.filter(
    items,
    (item) => item?.reference_id !== "DELIVERY_CHARGE"
  );
  const count = _.sumBy(filteredItems, (i) => i.quantity);
  const deliveryLineItem = _.filter(
    items,
    (item) => item?.reference_id == "DELIVERY_CHARGE"
  );
  let deliveryCharge = deliveryLineItem[0]?.total_price || 0;
  const strikeThrough = (key) => {
    return key === I18n.t("screen_messages.delivery_charges") && lusakaOrder;
  };

  let cartInfo = {
    [I18n.t("screen_messages.total_price_str")]: utils.formattedPriceRoundOff(
      order?.original_gross_amount - deliveryCharge || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
    [I18n.t("screen_messages.delivery_charges")]: utils.formattedPriceRoundOff(
      lusakaOrder ? 75 : deliveryCharge,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),

    [I18n.t("screen_messages.taxes_str")]: utils.formattedPriceRoundOff(
      order?.tax_amount || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
    [I18n.t("screen_messages.total_text")]: utils.formattedPriceRoundOff(
      order?.total_amount || 0,
      I18n.t("screen_messages.common.currency"),
      noRoundOff
    ),
  };

  if (order?.balance_amount && order?.total_amount - order?.balance_amount / 100 > 0) {
    cartInfo = {
      ...cartInfo,
      [I18n.t("screen_messages.payments.points_used")]: (order?.total_amount - order.balance_amount / 100).toFixed(2) || 0,
      [I18n.t("screen_messages.payments.amount_to_be_payed")]: utils.formattedPriceRoundOff(
        order?.balance_amount / 100 || 0,
        I18n.t("screen_messages.common.currency"),
        noRoundOff
      )
    }
  }

  const renderTrackOrder = ({ key }) => {
    return (
      <View key={key}>
        <View style={{ width: "100%" }}>
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
              paddingVertical: theme.containerPadding - 5,
            }}
          >
            <TrackOrderCard
              noButton={!_.includes(["DELIVERED", "CLOSED"], order.status)}
              order={new TrackOrderModel({ order: order, count: count })}
              onTrackOrderPressed={onAction}
              actionTitle={I18n.t("screen_messages.review_screen.review")}
            />
          </View>
        </View>
        <VerticalSpacing size={30} />
        <View
          style={{
            paddingHorizontal: theme.containerPadding,
          }}
        >
          <PrestoText
            color={theme.paragraph}
            size={theme.h6FontSize}
            extraStyle={{ marginBottom: 15, marginTop: -10 }}
          >
            {I18n.t("screen_messages.order_details_title")}
          </PrestoText>
          <KeyValueList dict={cartInfo} strikeThrough={strikeThrough} />
        </View>
        <VerticalSpacing size={30} />
        <RowButton
          onPress={onPressReport}
          title={I18n.t("screen_messages.orders.report_problem")}
          showDisclosure={true}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
        <VerticalSpacing size={30} />
        <FullWidthHorizontalPaddedBox>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PrestoText size={theme.h5FontSize} color={theme.tertiaryTextColor}>
              {I18n.t("screen_messages.items_in_order_str")}
            </PrestoText>
            {order?.sub_status === "PARTIAL_DELIVERY" ? (
              <PrestoText size={theme.h5FontSize} color={theme.error}>
                {I18n.t("screen_messages.partially_delivered")}
              </PrestoText>
            ) : null}
          </View>
        </FullWidthHorizontalPaddedBox>
      </View>
    );
  };

  const getOrderItemsForSectionList = () => {
    return _.map(filteredItems, (item) => {
      return { key: item.id, data: item };
    });
  };

  const getPartialOrderItemsForSectionList = useCallback(() => {
    return _.map(partialItems, (item) => {
      return { key: item.id, data: item };
    });
  }, [partialItems]);

  const renderPartialItemsHeader = ({ key }) => {
    return (
      <View key={key}>
        <VerticalSpacing size={30} />
        <FullWidthHorizontalPaddedBox>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <PrestoText size={theme.h5FontSize} color={theme.tertiaryTextColor}>
              {I18n.t("screen_messages.items_unfullfilled", {
                count: partialItems.length,
              })}
            </PrestoText>
          </View>
        </FullWidthHorizontalPaddedBox>
      </View>
    );
  };

  const renderItemForSectionList = ({ item, section }) => {
    if (item.render) {
      return item.render({ key: item.key });
    } else if (section.key === "ordered-items") {
      return renderLineItem({ item: item.data });
    } else if (section.key === "partial-delivered-order-items") {
      return (
        <MemoizedPartialItemCard
          key={item.data.id}
          item={item.data}
          isPartialdelivery={extraOptions.current.isPartialdelivery}
        />
      );
    }

    return null;
  };

  const sections = [
    {
      data: [
        {
          key: "top-body",
          render: renderTrackOrder,
        },
      ],
    },
    {
      key: "ordered-items",
      data: getOrderItemsForSectionList(),
    },
    {
      data: [
        {
          key: "partial-items-header",
          render: renderPartialItemsHeader,
        },
      ],
    },
    {
      key: "partial-delivered-order-items",
      data: getPartialOrderItemsForSectionList(),
    },
  ];

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          onBack={onBack}
          title={
            order
              ? I18n.t("screen_messages.orders.order_items_screen_title", {
                order_id: order.friendly_id,
              })
              : ""
          }
        />
        {order ? (
          <ScrollView
            style={{ height: windowHeight - 100 }}
            contentContainerStyle={{
              width: "100%",
              paddingBottom: 100,
              paddingTop: theme.containerPadding,
            }}
          >
            <SectionList
              sections={sections}
              keyExtractor={keyExtractor}
              renderItem={renderItemForSectionList}
            />
          </ScrollView>
        ) : null}
      </SafeAreaView>
    </LoadingContainer>
  );
}
