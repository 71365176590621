import React, { useState, useContext, useCallback, useEffect } from "react";
import { View, FlatList, ScrollView, Dimensions } from "react-native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import SearchableScreen from "../SearchableScreen/SearchableScreen";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import * as Linking from "expo-linking";
import { usePagination } from "../../hooks/pagination";
import SearchManager from "../../services/features/search/SearchManager";
import ItemCard from "@presto-screen-components/ItemCard/ItemCard";
import PrestoText from "@presto-components/PrestoText";

export default function ViewAllDeals({ navigation, route }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const windowHeight = Dimensions.get("window").height;
  const { catalogId } = route.params;

  const onBack = () => {
    navigation.goBack();
  };
  const searchPage = useCallback((pageNumber, success, failure) => {
    const params = {
      on_variation_offer: true,
      catalog_id: catalogId,
      page: pageNumber,
    };
    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        success(response.hits.hits);
      },
      (error) => {
        failure(error);
      }
    );
  }, []);
  const [
    results,
    loadNextPage,
    loadFirstPage,
    loading,
    pageLoaded,
  ] = usePagination(searchPage);
  useEffect(() => {
    loadFirstPage();
  }, []);

  const renderItem = ({ item, index }) => {
    return <ItemCard key={item._source.id} item={item._source}></ItemCard>;
  };
  useFocusEffect(
    useCallback(() => {
      getGallery("SPONSORED_PRODUCTS");
    }, [])
  );
  const [gallery, setGallery] = useState([]);
  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response.data?.media_items);
  };
  const getCategoryDetails = (catId) => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: {
          searchParams: { category: { id: catId } },
          catalogId: catalogId,
        },
      },
    });
  };

  const onItemClicked = (item) => {
    switch (item.target?.type) {
      case "category_item":
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "DETAILED_ITEM",
            screenParams: { itemId: item.target.id_value },
          },
        });

        return;
      case "url":
        Linking.openURL(item.id_value);
        return;
      case "category":
        getCategoryDetails(item.target.id_value);
        return;
      case "app_link":
        return;
    }
  };
  const onSearchFocus = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: { searchParams: {}, focus: true, catalogId: catalogId },
      },
    });
  };

  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      canGoback={true}
      onBack={onBack}
      showSoftInputOnFocus={false}
    >
      <ScrollView
        contentContainerStyle={{}}
        style={{ height: windowHeight - 150 }}
      >
        <View
          style={{
            paddingVertical: theme.primaryPadding,
          }}
        >
          <View
            style={{
              paddingHorizontal: theme.containerPadding,
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              paddingBottom: 0,
            }}
          >
            <PrestoText
              fontStyle={"semibold"}
              size={theme.h5FontSize}
              color={theme.primary}
            >
              {I18n.t("screen_messages.deals.deals_text")}
            </PrestoText>
          </View>
          <FlatList
            data={results}
            renderItem={renderItem}
            keyExtractor={(item) => item._source.id}
            style={{ paddingTop: 5 }}
            ListFooterComponent={() => {
              return <VerticalSpacing size={100} />;
            }}
            onEndReached={
              results.length > 0
                ? () => {
                    console.log("onEndREached");
                    loadNextPage();
                  }
                : undefined
            }
            onEndReachedThreshold={0.25}
          />
        </View>
      </ScrollView>
    </SearchableScreen>
  );
}
