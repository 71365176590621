import React, { useState, useContext, useEffect } from "react";
import { View, SafeAreaView, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { RowButton } from "@presto-components/PrestoButton";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import OrderManager from "@presto-services/features/orders/OrderManager";
import TrackOrderCard from "@presto-cards/TrackOrderCard/TrackOrderCard";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import utils from "../../utils";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";

export default function OrderInvoiceScreen({ route }) {
  const params = route.params || {};
  const { orderId } = params;
  const { theme } = useContext(ThemeContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const { Navigator } = useContext(NavigatorContext);

  useEffect(() => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        setOrder(response.data);
        setLoading(false);
      },
      (error) => {
        alert(utils.errorMessage(error));
      }
    );
  }, [orderId]);

  const onAction = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "ORDER_DETAILS_SCREEN",
        screenParams: {
          orderId: order.id,
        },
      },
    });
  };

  const downloadFile = (header) => {
    const url =
      PrestoSdk.getHostName() + `/user/v0/orders/${orderId}/download_invoice`;
    console.log("url - ", url);
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
      credentials: true,
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `FileName.pdf`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const downloadInvoice = () => {
    if (Platform.OS === "ios") {
      shareInvoice();
    } else if (Platform.OS === "web") {
      const url =
        PrestoSdk.getHostName() + `/user/v0/orders/${orderId}/download_invoice`;
      console.log("URL == ", url);
      window.open(url, "_blank");
    }
  };

  const shareInvoice = () => {
    //const url = "http://www.africau.edu/images/default/sample.pdf";
    setLoading(true);
    const url =
      PrestoSdk.getHostName() + `/user/v0/orders/${orderId}/download_invoice`;
    console.log(" url ", url);
    fetch(url)
      .then((response) => Promise.all([response.status, response.text()]))
      .then(([status, text]) => {
        console.log("Response from PDF : ", text);
      });
    let filePath = null;
    const configOptions = { fileCache: true, trusty: true };
  };

  const onPressReport = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "REPORT_A_PROBLEM",
        screenParams: { orderId: order.id },
      },
    });
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBack
          title={
            order
              ? I18n.t("screen_messages.orders.order_invoice_screen_title", {
                  order_id: order.friendly_id,
                })
              : ""
          }
        />
        {order ? (
          <>
            <View style={{ width: "100%" }}>
              <View
                style={{
                  paddingHorizontal: theme.containerPadding,
                  paddingVertical: theme.containerPadding - 5,
                }}
              >
                <TrackOrderCard
                  order={order}
                  onTrackOrder={onAction}
                  actionTitle={I18n.t("screen_messages.orders.view_items")}
                />
              </View>
            </View>
            <VerticalSpacing size={30} />
            <RowButton
              onPress={onPressReport}
              title={I18n.t("screen_messages.orders.report_problem")}
              showDisclosure={true}
            />
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            <VerticalSpacing size={30} />
            <FullWidthHorizontalPaddedBox>
              <PrestoText
                size={theme.h6FontSize}
                color={theme.tertiaryTextColor}
              >
                {I18n.t("screen_messages.invoices.order_invoice")}
              </PrestoText>
            </FullWidthHorizontalPaddedBox>
            <VerticalSpacing size={10} />
            <RowButton
              onPress={downloadInvoice}
              title={I18n.t("screen_messages.common.download")}
              showDisclosure={true}
            />
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          </>
        ) : null}
      </SafeAreaView>
    </LoadingContainer>
  );
}
