import SessionResource from "./SessionResource";
import ErrorConstants from "../../common/ErrorConstants";
import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import UserObject from "../../data_models/UserObject";
import $ from "../../global/util";

const SessionManager = function (
  SessionResource,
  ErrorConstants,
  Utils,
  PrestoSdk,
  UserObject
) {
  /**
   * @ngdoc
   * @name Session.SessionManager#login
   * @methodOf Session.SessionManager
   * @description Method to do user login via email/phonenumber and password.
   * @param {string} errorCode - code defined in ErrorConstants
   * @param {boolean} success - true/false
   * @returns {ErrorObject} - error object containing success/fail status, error code and error message
   */
  function login(loginObject, successCallback, errorCallback) {
    var validationResponse = loginObject.validateObject();
    console.log("validationResponse=");
    console.log(validationResponse);
    if (validationResponse.success) {
      SessionResource.login(loginObject).then(
        function (loginResponse) {
          loginResponse.message = Utils.getSuccessMessage("on_login");
          var user = new UserObject();
          user.buildObject(loginResponse.data);
          loginResponse.data = user;
          successCallback(loginResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }
  /**
   * @ngdoc
   * @name Session.SessionManager#socialLogin
   * @methodOf Session.SessionManager
   * @description Method to do user login via social network credentials.
   * @param {DataModel.SocialLogin} socialLoginObject - social login object containing user credentials
   * @param {callback} successCallback - success callback
   * @param {callback} errorCallback - failure callback
   * @returns {ErrorObject} - error object containing success/fail status, error code and error message
   */
  function socialLogin(socialLoginObject, successCallback, errorCallback) {
    var validationResponse = socialLoginObject.validateObject();
    console.log("validationResponse=");
    console.log(validationResponse);
    if (validationResponse.success) {
      var params = {};
      params.user = socialLoginObject;
      SessionResource.socialLogin(params).then(
        function (loginResponse) {
          loginResponse.message = Utils.getSuccessMessage("on_login");
          var user = new UserObject();
          user.buildObject(loginResponse.data);
          loginResponse.data = user;
          successCallback(loginResponse);
        },
        function (errorResponse) {
          errorResponse = Utils.updateErrorObject(errorResponse);
          //TODO:Convert network error to presto defined error
          errorCallback(errorResponse);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }
  /**
   * @ngdoc
   * @name Session.SessionManager#logout
   * @methodOf Session.SessionManager
   * @description Method to do user login via email/phonenumber and password.
   * @param {string} errorCode - code defined in ErrorConstants
   * @param {boolean} success - true/false
   * @returns {ErrorObject} - error object containing success/fail status, error code and error message
   */
  function logout(successCallback, errorCallback) {
    SessionResource.logout().then(
      function (logoutResponse) {
        logoutResponse.message = Utils.getSuccessMessage("on_logout");
        successCallback(logoutResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  /**
   * @ngdoc
   * @name Session.SessionManager#signup
   * @methodOf Session.SessionManager
   * @description Method to do user signup
   * @param {DataModel.User} userObject - user details needed for signup
   * @param {callbackFunction} successCallback - callback method to be called on successful signup
   * @returns {callbackFunction} errorCallback- callback method to be called on signup fail
   */
  function signup(user, successCallback, errorCallback) {
    var validationResponse = user.validateObject();
    var appConfig = PrestoSdk.getAppConfig();
    console.log("validationResponse=");
    console.log(validationResponse);
    if (validationResponse.success) {
      validationResponse = user.validatePassword();
      if (!validationResponse.success) {
        validationResponse = Utils.updateErrorObject(validationResponse);
        errorCallback(validationResponse);
        return;
      } else if (appConfig.merchant_config.features.repeat_password_enabled) {
        if (Utils.isEmpty(user.password_confirmation)) {
          validationResponse = Utils.ErrorObject(
            ErrorConstants.ErrorCodes.REPEAT_PASSWORD_EMPTY,
            false
          );
          errorCallback(validationResponse);
          return;
        }
        if (user.password_confirmation != user.password) {
          validationResponse = Utils.ErrorObject(
            ErrorConstants.ErrorCodes.REPEAT_PASSWORD_MISMATCH,
            false
          );
          errorCallback(validationResponse);
          return;
        }
      }
      user.merchant_id = PrestoSdk.getMerchantId();
      var signupData = {
        user: user,
      };
      console.log("signupData=");
      console.log(signupData);
      SessionResource.signup(signupData).then(
        function (signupResponse) {
          signupResponse.message = Utils.getSuccessMessage("on_signup");
          var user = new UserObject();
          user.buildObject(signupResponse.data);
          signupResponse.data = user;
          successCallback(signupResponse);
        },
        function (errorResponse) {
          errorResponse = errorResponse.data;
          errorResponse = Utils.updateErrorObject(errorResponse);
          //TODO:Convert network error to presto defined error
          errorCallback(errorResponse);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }
  /**
   * @ngdoc
   * @name Session.SessionManager#forgotPassword
   * @methodOf Session.SessionManager
   * @description Method to do get token needed to set a new password.
   * @param {method} successCallback - callback method to be invoked on success
   * @param {method} errorCallback - callback method to be invoked on failure
   * @returns {ErrorObject} - error object containing success/fail status, error code and error message
   */
  function forgotPassword(
    forgotPasswordObject,
    successCallback,
    errorCallback
  ) {
    var validationResponse = forgotPasswordObject.validateObject();
    if (validationResponse.success) {
      SessionResource.forgotPassword(forgotPasswordObject).then(
        function (forgotPasswordResponse) {
          forgotPasswordResponse.message = Utils.getSuccessMessage(
            "on_token_sent"
          );
          successCallback(forgotPasswordResponse);
        },
        function (errorResponse) {
          errorResponse = Utils.updateErrorObject(errorResponse);
          errorCallback(errorResponse);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }
  /**
   * @ngdoc
   * @name Session.SessionManager#resetPassword
   * @methodOf Session.SessionManager
   * @description Method to do user login via email/phonenumber and password.
   * @param {string} errorCode - code defined in ErrorConstants
   * @param {boolean} success - true/false
   * @returns {ErrorObject} - error object containing success/fail status, error code and error message
   */
  function resetPassword(resetPasswordObject, successCallback, errorCallback) {
    var validationResponse = resetPasswordObject.validateObject();
    console.log("validationResponse=");
    console.log(validationResponse);
    if (validationResponse.success) {
      SessionResource.resetPassword(resetPasswordObject).then(
        function (resetPasswordResponse) {
          resetPasswordResponse.message = Utils.getSuccessMessage(
            "on_password_reset"
          );
          successCallback(resetPasswordResponse);
        },
        function (errorResponse) {
          errorResponse = Utils.updateErrorObject(errorResponse);
          errorCallback(errorResponse);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function verifyOtp(params, successCallback, errorCallback) {
    if (!params || Utils.isEmpty(params.otp)) {
      var error = Utils.ErrorObject(ErrorConstants.ErrorCodes.OTP_EMPTY, false);
      errorCallback(error);
    } else {
      SessionResource.verifyOtp(params).then(
        function (verifyOtpResponse) {
          verifyOtpResponse.message =
            ErrorConstants.SuccessMessages["on_otp_verified"];
          successCallback(verifyOtpResponse);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getOtp(successCallback, errorCallback) {
    SessionResource.getOtp().then(
      function (getOtpResponse) {
        getOtpResponse.message =
          ErrorConstants.SuccessMessages["on_otp_requested"];
        successCallback(getOtpResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  /**
   * @ngdoc
   * @name Session.SessionManager#isLoggedInUser
   * @methodOf Session.SessionManager
   * @description Method to check whether user is logged in or not.
   */
  function isLoggedInUser(successCallback, errorCallback) {
    SessionResource.isLoggedInUser().then(
      function (user) {
        var userObj = new UserObject();
        userObj.buildObject(user.data);
        user.data = userObj;
        successCallback(user);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function linkAccount(params, successCallback, errorCallback) {
    SessionResource.linkAccount(params).then(
      function (linkAccountResponse) {
        successCallback(linkAccountResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function verifyAccount(params, successCallback, errorCallback) {
    SessionResource.verifyAccount(params).then(
      function (verifyAccountResponse) {
        successCallback(verifyAccountResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function startOtpLogin(params, successCallback, errorCallback) {
    SessionResource.startOtpLogin(params).then(
      function (startOtpLoginResponse) {
        console.log("success callback");
        successCallback(startOtpLoginResponse);
      },
      function (errorResponse) {
        console.log("errorResponse callback");
        errorResponse = Utils.updateErrorObject(errorResponse);

        errorCallback(errorResponse);
      }
    );
  }

  function completeOtpLogin(params, successCallback, errorCallback) {
    SessionResource.completeOtpLogin(params).then(
      function (completeOtpLoginResponse) {
        successCallback(completeOtpLoginResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function switchAccount(params, successCallback, errorCallback) {
    SessionResource.switchAccount(params).then(
      function (switchAccountResponse) {
        successCallback(switchAccountResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function removeAccount(params, successCallback, errorCallback) {
    SessionResource.removeAccount(params).then(
      function (removeAccountResponse) {
        successCallback(removeAccountResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function operatorLogin(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    SessionResource.operatorLogin(params).then(
      function (operatorLoginResponse) {
        successCallback(operatorLoginResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function operatorLogout(successCallback, errorCallback) {
    SessionResource.operatorLogout().then(
      function (operatorLogoutResponse) {
        successCallback(operatorLogoutResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function agentLogin(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    SessionResource.agentLogin(params).then(
      function (agentLoginResponse) {
        successCallback(agentLoginResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function agentLogout(successCallback, errorCallback) {
    SessionResource.agentLogout().then(
      function (agentLogoutResponse) {
        successCallback(agentLogoutResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }
  function adminLogin(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    SessionResource.adminLogin(params).then(
      function (adminLoginResponse) {
        successCallback(adminLoginResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function adminLogout(successCallback, errorCallback) {
    SessionResource.adminLogout().then(
      function (adminLogoutResponse) {
        successCallback(adminLogoutResponse);
      },
      function (errorResponse) {
        errorResponse = Utils.updateErrorObject(errorResponse);
        errorCallback(errorResponse);
      }
    );
  }

  function isMerchantLoggedIn(successCallback, errorCallback) {
    SessionResource.isMerchantLoggedIn().then(
      function (getEmplyoeeInfoResponse) {
        successCallback(getEmplyoeeInfoResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getEmployee(successCallback, errorCallback) {
    SessionResource.getEmployee().then(
      function (getEmplyoeeInfoResponse) {
        
        successCallback(getEmplyoeeInfoResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeAvailabilityStatus(
    params,
    successCallback,
    errorCallback
  ) {
    SessionResource.updateEmployeeAvailabilityStatus(params).then(
      function (updateEmployeeAvailabilityStatusResponse) {
        successCallback(updateEmployeeAvailabilityStatusResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeCitizenId(params, successCallback, errorCallback) {
    SessionResource.updateEmployeeCitizenId(params).then(
      function (updateEmployeeResponse) {
        successCallback(updateEmployeeResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeLocation(params, successCallback, errorCallback) {
    SessionResource.updateEmployeeLocation(params).then(
      function (updateEmployeeResponse) {
        successCallback(updateEmployeeResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getEmployeeStatus(successCallback, errorCallback) {
    SessionResource.getEmployeeStatus().then(
      function (getEmployeeStatusResponse) {
        successCallback(getEmployeeStatusResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function sendEmployeeLocation(params, successCallback, errorCallback) {
    SessionResource.sendEmployeeLocation(params).then(
      function (sendEmployeeLocationResponse) {
        successCallback(sendEmployeeLocationResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function setEmployeeStatus(params, successCallback, errorCallback) {
    SessionResource.setEmployeeStatus(params).then(
      function (setEmployeeStatusResponse) {
        successCallback(setEmployeeStatusResponse);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getReferralCode(params, successCallback, errorCallback) {
    SessionResource.getReferralCode(params).then(
      function (refResponse) {
        successCallback(refResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function getUsersByIds(params, successCallback, errorCallback) {
    SessionResource.getUsersByIds(params).then(
      function (refResponse) {
        successCallback(refResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }
  function isOperatorLoggedIn(successCallback, errorCallback) {
    SessionResource.isOperatorLoggedIn().then(
      function (refResponse) {
        successCallback(refResponse);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function employeeOtpLogin(params, successCallback, errorCallback) {
    SessionResource.employeeOtpLogin(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function employeeVerifyOtpLogin(params, successCallback, errorCallback) {
    SessionResource.employeeVerifyOtpLogin(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function operatorOtpLogin(params, successCallback, errorCallback) {
    SessionResource.operatorOtpLogin(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  function operatorVerifyOtpLogin(params, successCallback, errorCallback) {
    SessionResource.operatorVerifyOtpLogin(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        errorCallback(error);
      }
    );
  }

  // return public model API
  return {
    login: login,
    socialLogin: socialLogin,
    signup: signup,
    logout: logout,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
    isLoggedInUser: isLoggedInUser,
    verifyOtp: verifyOtp,
    getOtp: getOtp,
    linkAccount: linkAccount,
    verifyAccount: verifyAccount,
    startOtpLogin: startOtpLogin,
    completeOtpLogin: completeOtpLogin,
    switchAccount: switchAccount,
    removeAccount: removeAccount,
    agentLogin: agentLogin,
    agentLogout: agentLogout,
    adminLogin: adminLogin,
    adminLogout: adminLogout,
    isMerchantLoggedIn: isMerchantLoggedIn,
    getEmployee: getEmployee,
    updateEmployeeAvailabilityStatus: updateEmployeeAvailabilityStatus,
    updateEmployeeLocation: updateEmployeeLocation,
    getEmployeeStatus: getEmployeeStatus,
    sendEmployeeLocation: sendEmployeeLocation,
    setEmployeeStatus: setEmployeeStatus,
    updateEmployeeCitizenId: updateEmployeeCitizenId,
    getReferralCode: getReferralCode,
    getUsersByIds: getUsersByIds,
    operatorLogin: operatorLogin,
    operatorLogout: operatorLogout,
    isOperatorLoggedIn: isOperatorLoggedIn,
    employeeOtpLogin,
    employeeVerifyOtpLogin,
    operatorOtpLogin,
    operatorVerifyOtpLogin,
  };
};

export default SessionManager(
  SessionResource,
  ErrorConstants,
  Utils,
  PrestoSdk,
  UserObject
);
