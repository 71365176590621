import LoyaltyTierResource from "./LoyaltyTierResource";
import Utils from "../../common/Utils";

const LoyaltyTierManager = function (LoyaltyTierResource, Utils) {
  function listTiers(params, successCallback, errorCallback) {
    LoyaltyTierResource.listTiers(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function currentUserProgress(params, successCallback, errorCallback) {
    LoyaltyTierResource.currentUserProgress(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    listTiers,
    currentUserProgress,
  };
};

export default LoyaltyTierManager(LoyaltyTierResource, Utils);
