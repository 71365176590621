import config from "@presto-common/config";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import LoadingContainer from "@presto-components/LoadingContainer";
import {
  PrestoTextButton,
  PrestoFramedButton,
} from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import PasswordInput from "@presto-components/TextInputs/PasswordInput";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import LoginObject from "@presto-services/data_models/LoginObject";
import SessionManager from "@presto-services/features/session/SessionManager";
import * as Linking from "expo-linking";
import I18n, { reset } from "i18n-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Dimensions,
  Image,
  Keyboard,
  Platform,
  ScrollView,
  View,
  Text,
} from "react-native";
import { useMediaQuery } from "react-responsive";
import asyncify from "../../common/Asyncify";
import utils from "../../utils/index";
const AsyncSessionManager = asyncify(SessionManager);
import Utils from "../../services/common/Utils";
import PrestoSdk from "@presto-services/global/PrestoSdk";
import { useAssets } from "presto-react-components";
import { prestoAlert } from "../../common/Alert";
import AnalyticsManager from "../../common/AnalyticsManager";
import SectionLinkTextComponent from "@presto-components/SectionLinks/SectionLinkTextComponent";

export default function LoginWithOtpScreen() {
  const windowHeight = Dimensions.get("window").height;
  const { images: Images, svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { setUser, refreshUser } = useContext(UserContext);
  const { setPaneState } = useContext(RightPaneContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [shown, setShown] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [password, setPassword] = useState("");
  const [hide, setHide] = useState(true);
  const [padding, setPadding] = useState(0);
  const scrollRef = useRef();

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onPhoneNumberChange = ({ nativeEvent: { text } }) => {
    setPhoneNumber(text);
  };

  const onPasswordChange = ({ nativeEvent: { text } }) => {
    setPassword(text);
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollToEnd({ animated: true });
  }, [padding]);
  const isDesktop = useMediaQuery({ minWidth: 941 });

  const onLogin = () => {
    AnalyticsManager.send("login_with_otp_login");
    if (password && password.length >= 6) {
      setLoading(true);
      let params = {
        merchant_id: PrestoSdk.getMerchantId(),
        user: {
          phone_number: phoneNumber,
          password: password,
        },
      };
      SessionManager.completeOtpLogin(
        params,
        (response) => {
          refreshUser();
          setLoading(false);
          if (isDesktop) {
            setPaneState(false);
            WebNavigator.emit({
              event: "replace",
              params: {
                screenName: "HOME",
                screenParams: {
                  is_new: "true",
                },
              },
            });
          } else {
            if (Platform.OS === "web") {
              Linking.openURL("/home");
            }
          }
        },
        (error) => {
          setLoading(false);
          alert(utils.errorMessage(error) || "Please try again later");
          console.log("Error", error);
        }
      );
    } else {
      alert(
        I18n.t("screen_messages.alert"),
        I18n.t("screen_messages.invalid_otp")
      );
    }
  };
  const onPressReadMore = () => {
    Linking.openURL(config.constants.read_more_about_product);
  };

  const goBack = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  const onPressSignUp = () => {
    if (isDesktop) {
      WebNavigator.emit({
        event: "modal",
        params: {
          screenName: "HUB_SPOT_FORM",
          title: "Sign Up",
          shouldGoBack: true,
          goBack: goBack,
        },
      });
    } else {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "HUB_SPOT_FORM",
          screenParams: {
            title: "Sign Up",
            shouldGoBack: true,
            goBack: goBack,
          },
        },
      });
    }
  };

  const eyeOnPress = () => {
    setHide(!hide);
  };

  const sendOtp = () => {
    if (isOtpSent) {
      AnalyticsManager.send("resend_otp");
    } else {
      AnalyticsManager.send("login_with_otp_proceed");
    }
    if (Utils.isValidPhoneNumber(phoneNumber)) {
      setLoading(true);

      let params = {
        phone_number: phoneNumber,
        merchant_id: PrestoSdk.getMerchantId(),
      };
      SessionManager.startOtpLogin(
        params,
        (response) => {
          console.log("start otp response = ", JSON.stringify(response));
          setLoading(false);
          setIsOtpSent(true);
          alert(
            I18n.t("screen_messages.alert"),
            I18n.t("screen_messages.OTP_sent", { phone: phoneNumber })
          );
        },
        (error) => {
          setLoading(false);
          if (error.code == 1009010) {
            alert("ERROR : Please signup to continue");
          } else {
            alert(error.message);
            console.log("startOtpLogin failed = ", JSON.stringify(error));
          }
        }
      );
    } else {
      alert(
        I18n.t("screen_messages.alert"),
        I18n.t("screen_messages.invalid_number")
      );
    }
  };
  const resetPhone = () => {
    AnalyticsManager.send("login_with_otp_edit");
    setIsOtpSent(false);
    setPhoneNumber("");
    setPassword("");
  };

  return (
    <LoadingContainer
      bgColor={theme.primary}
      style={{ flex: 1 }}
      loading={loading}
    >
      <ScrollView
        ref={scrollRef}
        enableOnAndroid={true}
        scrollEnabled={true}
        style={{ backgroundColor: theme.primary, flex: 1 }}
        contentContainerStyle={{
          paddingTop: Platform.OS == "android" ? 40 : 100,
          minHeight: windowHeight,
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirextion: "column",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: padding,
          }}
        >
          <FullWidthHorizontalPaddedBox>
            <Image
              source={Images.logo_transparent}
              style={{ width: 300, height: 216, alignSelf: "center" }}
            />
            <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
              <PrestoText size={theme.h5FontSize} color={theme.white}>
                {I18n.t("screen_messages.login.title")}
              </PrestoText>
              <VerticalSpacing size={15} />
            </FullWidthHorizontalPaddedBox>
            <VerticalSpacing size={15} />
            <PrestoTextInput
              theme={theme}
              placeholder={I18n.t(
                "screen_messages.login.enter_your_phone_number"
              )}
              value={phoneNumber}
              keyboardType={"phone-pad"}
              onChange={onPhoneNumberChange}
              backgroundColor={theme.white}
              textContentType={"username"}
              autoCompleteType={"username"}
              importantForAutofill={"yes"}
              editable={!isOtpSent}
              style={{ textInputHeight: 40 }}
            />
            {isOtpSent ? (
              <>
                <VerticalSpacing size={10} />
                <PasswordInput
                  theme={theme}
                  placeholder={I18n.t("screen_messages.login.please_enter_otp")}
                  value={password}
                  onChange={onPasswordChange}
                  backgroundColor={theme.white}
                  textContentType={"password"}
                  autoCompleteType={"password"}
                  importantForAutofill={"yes"}
                  secureTextEntry={hide}
                  icon={Images.eye}
                  onPress={() => eyeOnPress()}
                />
              </>
            ) : null}
            <VerticalSpacing size={15} />
            <PrestoFramedButton
              buttonStyle="primary"
              size="large"
              title={
                !isOtpSent
                  ? I18n.t("screen_messages.common.proceed")
                  : I18n.t("screen_messages.login.login_btn_name")
              }
              borderRadius={20}
              titleColor={theme.white}
              borderColor={theme.white}
              titleExtraStyle={{ textTransform: "uppercase" }}
              onPress={!isOtpSent ? sendOtp : onLogin}
            />
            {isOtpSent ? (
              <>
                <VerticalSpacing size={80} />
                <PrestoFramedButton
                  buttonStyle="primary"
                  size="large"
                  titleColor={theme.white}
                  borderColor={theme.white}
                  title={I18n.t("screen_messages.resend")}
                  borderRadius={20}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  onPress={() => sendOtp()}
                />
              </>
            ) : null}

            <VerticalSpacing size={20} />
            {!isOtpSent ? (
              <>
                <VerticalSpacing size={20} />
                <FullWidthHorizontalPaddedBox paddingHorizontal={10}>
                  <PrestoText
                    size={theme.h5FontSize}
                    color={theme.white}
                    extraStyle={{ marginTop: 40, marginBottom: -10 }}
                  >
                    {I18n.t("screen_messages.users.new_with_question_mark")}
                  </PrestoText>
                </FullWidthHorizontalPaddedBox>
                <VerticalSpacing size={20} />
                <PrestoFramedButton
                  buttonStyle="primary"
                  size="large"
                  titleColor={theme.white}
                  borderColor={theme.white}
                  title={I18n.t("screen_messages.register.register_btn")}
                  borderRadius={20}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  onPress={() => onPressSignUp()}
                />
              </>
            ) : (
              <PrestoFramedButton
                buttonStyle="primary"
                size="large"
                titleColor={theme.white}
                borderColor={theme.white}
                title={I18n.t("screen_messages.edit_str")}
                borderRadius={20}
                titleExtraStyle={{ textTransform: "uppercase" }}
                onPress={() => resetPhone()}
              />
            )}
            {/* <Text style={{ textTransform: "uppercase" }}></Text> */}
          </FullWidthHorizontalPaddedBox>

          <View
            style={{
              marginVertical: theme.containerPadding,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "center",
              }}
            >
              <SectionLinkTextComponent
                theme={theme}
                leftText1={I18n.t(
                  "screen_messages.register.already_have_account"
                )}
                linkTextColor={theme.white}
                leftText2={` ${I18n.t("screen_messages.login.login")}`}
                onPressButton={goBack}
                leftText1Style={{ color: theme.white }}
              />
            </View>

            <View style={{ paddingLeft: 5 }}>
              <PrestoTextButton
                buttonStyle="secondary"
                size="normal"
                title={I18n.t("screen_messages.login.read_more_about")}
                rightIcon={<svgs.RightArrow primaryColor={theme.white} />}
                titleColor={theme.white}
                onPress={() => goBack()}
              />
            </View>
          </View>
        </View>
      </ScrollView>
    </LoadingContainer>
  );
}
