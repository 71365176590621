import utils from "../utils";
import I18n from "i18n-js";
import { Dimensions } from "react-native";
import _ from "lodash";
import config from "@presto-common/config";
import VariantCategoryItem from "@presto-component-models/VariantCategoryItem";

export default class ItemCardModal {
  constructor({ product, variation, count, lineItem, itemPromotion, cart }) {
    this.product = product;
    this.variation = variation;
    this.count = count;
    this.lineItem = lineItem;
    this.itemPromotion = itemPromotion;
    this.cart = cart;
  }

  get hasOffers() {
    return this.product.variations && this.product.variations.length;
  }
  get productId() {
    return this.product.id;
  }

  get picURL() {
    if (this.cart) {
      let item = _.find(this.cart?.items, ["item_id", this.productId]);
      if (item) {
        if (item.variation && !_.isEmpty(item.variation)) {
          return item.variation.pic_url;
        } else {
          return this.product.pic_url;
        }
      }
    } else {
      return this.product.pic_url;
    }
  }

  get picture() {
    if (!_.isEmpty(this.product.documents)) {
      return this.product.documents[0]?.url;
    } else {
      return this.product.pic_url;
    }
  }

  get variations() {
    return this.product.variations;
  }

  get name() {
    return this.lineItem ? this.lineItem.name : this.product.name;
  }

  get companyName() {
    return this.product.other_data?.company_name;
  }

  get molecule() {
    return this.product.other_data?.molecule;
  }

  get ramAndStorage() {
    const result = [];
    if (this.ram) {
      result.push(this.ram);
    }
    if (this.storage) {
      result.push(this.storage);
    }
    return _.join(result, "/");
  }

  get ram() {
    return this.firstVariantItem.other_data?.ram;
  }

  get storage() {
    return this.firstVariantItem.other_data?.storage;
  }

  get stockCount() {
    if (this.product.inventory) {
      return this.product.inventory.stock_count;
    } else {
      return this.product.stock_count;
    }
  }

  get inventory() {
    if (this.product.inventory) {
      return this.product.inventory.stock_count;
    } else return undefined;
  }

  get priceInfo() {
    let info = {
      price: 0,
      displayName: null,
    };

    let totalPrice = this.lineItem
      ? this.lineItem.rate
      : this.product.price?.total_price;

    if (this.displayName) {
      let division_number = 1;
      let itemPrice = 0;
      if (this.displayName == "Kg") {
        division_number = this.displayQuantity;
      }
      if (this.displayQuantity > 1) {
        let text1 = `${this.displayQuantity + " " + this.displayName}`;
        if (this.displayName == "Kg") {
          text1 = `${this.displayName}`;
        }
        info["price"] = this.product.price?.total_price / division_number;
        info["displayName"] = text1;
      } else {
        info["price"] = totalPrice / division_number;
        info["displayName"] = this.displayName;
      }
    } else {
      info["price"] = this.product.price?.total_price;
    }
    return info;
  }

  get price() {
    const info = this.priceInfo;
    let itemPrice = null;
    if (info.displayName) {
      itemPrice = `${utils.formattedPrice(info.price)}/${info.displayName}`;
    } else {
      itemPrice = utils.formattedPrice(info.price);
    }
    return itemPrice;
  }

  get price2() {
    const priceInfo = this.priceInfo;
    let itemPrice = null;
    if (priceInfo.displayName) {
      itemPrice = `${utils.formattedPriceRoundOff(priceInfo.price)}/${
        priceInfo.displayName
      }`;
    } else {
      itemPrice = utils.formattedPriceRoundOff(priceInfo.price);
    }
    return itemPrice;
  }

  get itemPrice() {
    return utils.formattedPrice(this.product.price?.total_price);
  }

  get cartItemPrice() {
    if (this.cart) {
      let item = _.find(this.cart?.items, ["item_id", this.productId]);
      if (item) {
        if (item.variation && !_.isEmpty(item?.variation)) {
          return utils.formattedPrice(item.variation.price?.total_price);
        } else {
          return utils.formattedPrice(this.product.price?.total_price);
        }
      }
    }
  }

  get vartiationPrice() {
    if (this.variation) {
      return utils.formattedCurrencySeparator(
        this.variation.price?.base_price,
        false
      );
    } else {
      return utils.formattedCurrencySeparator(
        this.product.price?.base_price,
        false
      );
    }
  }

  get priceWithoutCurrency() {
    let totalPrice = this.lineItem
      ? this.lineItem.rate
      : this.product.price?.total_price;

    if (this.displayName) {
      let division_number = 1;
      let itemPrice = 0;
      if (this.displayName == "Kg") {
        division_number = this.displayQuantity;
      }
      if (this.displayQuantity > 1) {
        itemPrice = `${utils.formattedPriceWithoutCurrency(
          this.product.price?.total_price / division_number
        )}/${this.displayQuantity + " " + this.displayName}`;
      } else {
        itemPrice = `${utils.formattedPriceWithoutCurrency(
          totalPrice / division_number
        )}/${this.displayName}`;

        return itemPrice;
      }
    } else {
      // return utils.formattedPriceRoundOff(this.product.price?.total_price);
      return utils.formattedPriceWithoutCurrency(
        this.product.price?.total_price + this.product.price?.tax
      );
    }
  }

  get totalPrice() {
    let price = this.lineItem
      ? this.lineItem.rate
      : this.product.price?.total_price;
    let calculatedPrice = price * (this.count || 1);
    return `${utils.formattedPrice(
      !isNaN(calculatedPrice) ? calculatedPrice : 0
    )}`;
  }

  get totalPriceWithoutCurrency() {
    let price = this.lineItem
      ? this.lineItem.rate
      : this.product.price?.total_price;

    let calculatedPrice = price * (this.count || 1);
    return !isNaN(calculatedPrice) ? calculatedPrice : 0;
  }

  get offerCountTitle() {
    return I18n.t("screen_messages.offers.count_offers", {
      count: this.offersCount,
    });
  }

  get offersCount() {
    return this.product.variations?.length || 0;
  }

  get priceWithCurrency() {
    return `${I18n.t("screen_messages.common.currency")} ${
      this.product?.price?.total_price || 0
    }`;
  }

  get computedWidth() {
    const windowWidth = Dimensions.get("window").width;
    let computedWidth = (windowWidth * 0.8 - 300) / 4;
    if (computedWidth < 190) {
      computedWidth = (windowWidth * 0.8 - 250) / 3;
    }
    return computedWidth;
  }

  get formattedPrice() {
    const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
    let isGift = this.product?.other_data?.is_gift;
    let price = this.priceWithoutCurrency;
    if (isGift) {
      return utils.formattedGiftPrice(Number(price));
    } else {
      return utils.formattedPriceRoundOff(
        this.variation
          ? this.variation.price?.total_price
          : this.product.price?.total_price,
        noRoundOff
      );
    }
  }

  get savePrice() {
    const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
    let price = this.variation
      ? this.variation.other_data?.old_price - this.variation.price.base_price
      : this.product.other_data?.old_price - this.product.price.base_price;

    if (noRoundOff) {
      price = price * this.count;
    } else {
      price = Math.round(price) * this.count;
    }

    price = Number(price.toFixed(noRoundOff ? 2 : 0));

    let text = "";
    if (price) {
      text = I18n.t("screen_messages.discount_save_message", {
        amount: utils.formattedPriceRoundOff(price, noRoundOff),
      });
    }

    return text;
  }

  get offerVariationValue() {
    return parseInt(this.variation?.other_data?.variation_quantity ?? "0");
  }

  get offerfreeItem() {
    return parseInt(this.variation?.value ?? "0");
  }

  get isFreeItem() {
    return (
      this.offerfreeItem > 1 ||
      (this.offerVariationValue > 1 &&
        (this.offerfreeItem + 1) * this.count !== 0) ||
      0
    );
  }
  get freeItemCount() {
    return (
      (parseInt(this.variation?.other_data?.variation_quantity) +
        parseInt(this.variation?.other_data?.variation_free_quantity)) *
        this.count || 0
    );
  }

  get issues() {
    return this.product.issues || null;
  }

  get oldPrice() {
    if (this.lineItem) {
      if (this.lineItem.variation) {
        return this.lineItem.variation.other_data?.old_price;
      } else {
        return this.lineItem.other_data?.old_price;
      }
    } else {
      return this.product.other_data?.old_price;
    }
  }

  get originalPrice() {
    if (this.variation && this.variation?.other_data?.old_price) {
      return `${utils.formattedPrice(
        parseFloat(this.variation?.other_data?.old_price)
      )} `;
    } else {
      return this.oldPrice !== "0" && this.oldPrice
        ? `${utils.formattedPrice(parseFloat(this.oldPrice))} `
        : "";
    }
  }

  get comment() {
    return this.lineItem?.comment ?? this.product.comment;
  }

  get oldCartPrice() {
    if (this.product?.issues?.old_price) {
      return `${utils.formattedPrice(this.product.issues?.old_price / 100.0)}`;
    } else {
      return 0;
    }
  }

  get offer() {
    if (this.oldPrice !== "0" && this.oldPrice) {
      const priceDiff = this.oldPrice - this.product.price.base_price;
      const percentage = ((priceDiff / this.oldPrice) * 100).toFixed(1);

      return `${percentage}%`;
    } else {
      return null;
    }
  }

  get variationOffer() {
    if (this.variation) {
      let vOldPrice = this.variation?.other_data?.old_price;
      let vBasePrice = this.variation?.price?.base_price;
      const priceDiff = vOldPrice - vBasePrice;
      const percentage = ((priceDiff / vOldPrice) * 100).toFixed(1);
      return `${percentage}%`;
    } else {
      const priceDiff = this.oldPrice - this.product.price.base_price;
      const percentage = ((priceDiff / this.oldPrice) * 100).toFixed(1);
      return `${percentage}%`;
    }
  }

  get savings() {
    const priceDiff = this.oldPrice - this.product.price.base_price;
    if (priceDiff > 0) {
      return I18n.t("screen_messages.billing.discount_save_message", {
        amount: utils.formattedPrice(priceDiff),
      });
    } else {
      return 0;
    }
  }

  get quantity() {
    if (this.displayQuantity) {
      let quantity;
      if (this.count > 0) {
        quantity = `${(
          (this.count * this.displayQuantity) /
          this.priceMultiplier
        ).toFixed(2)} ${this.displayName}`;
      } else {
        quantity = `${this.displayQuantity} ${this.displayName}`;
      }
      return quantity;
    } else {
      return `${this.count || 1}`;
    }
  }

  get priceMultiplier() {
    return (
      this.lineItem?.other_data?.display_price_multiplier ??
      this.product.other_data?.display_price_multiplier
    );
  }

  get deliveredQuantity() {
    let returnedQty = Number(_.get(this.lineItem, "returned_quantity", 0));
    if (isNaN(returnedQty)) {
      returnedQty = 0;
    }
    let deliveredQuantity = 1;
    if (this.displayQuantity != null) {
      if (this.lineItem?.delivered_quantity > 0) {
        let substitutedQty =
          (this.lineItem?.delivered_quantity - returnedQty) *
          this.lineItem.other_data?.display_quantity;

        deliveredQuantity = `${(
          substitutedQty / this.lineItem.other_data?.display_price_multiplier
        ).toFixed(2)} ${this.lineItem.other_data?.display_name}`;
      } else {
        deliveredQuantity = `${this.lineItem?.delivered_quantity} ${this.lineItem.other_data?.display_name}`;
      }
    } else {
      let substitutedQty = this.lineItem?.delivered_quantity - returnedQty;
      deliveredQuantity = `${substitutedQty || 1}`;
    }
    return deliveredQuantity;
  }

  get lineItemDeliveredQuantity() {
    return this.lineItem?.delivered_quantity;
  }

  get displayName() {
    return this.lineItem
      ? this.lineItem.other_data?.display_name
      : this.product.other_data?.display_name ?? null;
  }

  get displayQuantity() {
    return this.lineItem
      ? this.lineItem.other_data?.display_quantity
      : this.product.other_data?.display_quantity;
  }

  get orderSubStatus() {
    return this.lineItem
      ? this.lineItem.order_sub_status
      : this.product.order_sub_status;
  }

  get lineItemRefundAmount() {
    let refundAmount = _.get(this.lineItem, "refund_request.refund_amount", 0);
    refundAmount = this.lineItem?.returned_amount;
    if (refundAmount) {
      return utils.formattedPrice(refundAmount / 100);
    } else {
      return 0;
    }
  }
  get refundStatus() {
    if (this.lineItem?.refund_request?.status) {
      return this.lineItem?.refund_request?.status.toUpperCase();
    } else {
      return null;
    }
  }

  get returnStatus() {
    if (this.lineItem?.return_status) {
      return this.lineItem?.return_status?.toUpperCase();
    } else {
      return null;
    }
  }
  get returnStatus() {
    return this.lineItem?.return_status;
  }

  get refundRequestAvailable() {
    return !!this.lineItem?.refund_request;
  }

  get isGiftItem() {
    if (this.product?.other_data?.is_gift == "true") {
      return true;
    } else {
      return false;
    }
  }

  get productGrade() {
    if (this.product?.other_data?.grade) {
      return this.product?.other_data?.grade;
    } else return "";
  }

  get isLot() {
    if (this.product?.other_data?.is_lot === "TRUE") {
      return true;
    } else return false;
  }

  get qcVerified() {
    if (this.product?.other_data?.qc === "TRUE") {
      return true;
    } else return false;
  }

  get gradeObj() {
    if (this.product?.other_data?.grades) {
      let array = JSON.parse(this.product?.other_data?.grades);
      let obj;
      obj = array?.reduce((maxObj, currentObj) => {
        const maxValue = Object.values(maxObj)[0];
        const currentValue = Object.values(currentObj)[0];
        if (parseFloat(currentValue) > parseFloat(maxValue)) {
          return currentObj;
        } else {
          return maxObj;
        }
      });
      return obj;
    } else if (this.product?.other_data?.grade) {
      return {
        ["Grade " + this.product.other_data.grade]: this.product.other_data
          .grade,
      };
    } else {
      return "";
    }
  }

  get countOfLot() {
    if (this.product?.other_data?.brands) {
      let array = JSON.parse(this.product?.other_data?.brands);
      let sum = _.sum(_.map(array, (obj) => Object.values(obj)[0]));
      return sum;
    } else {
      return 0;
    }
  }

  get brandObj() {
    if (this.product?.other_data?.brands) {
      let array = JSON.parse(this.product?.other_data?.brands);
      let obj;
      obj = array?.reduce((maxObj, currentObj) => {
        const maxValue = Object.values(maxObj)[0];
        const currentValue = Object.values(currentObj)[0];
        if (parseFloat(currentValue) > parseFloat(maxValue)) {
          return currentObj;
        } else {
          return maxObj;
        }
      });
      return obj;
    } else {
      return "";
    }
  }

  get warranty() {
    if (this.product.other_data?.warranty) {
      return this.product.other_data?.warranty;
    } else {
      return "";
    }
  }

  get returnedQuantity() {
    if (this.lineItem.other_data?.display_quantity) {
      if (
        this.lineItem.returned_quantity *
          this.lineItem.other_data?.display_quantity >
        0
      ) {
        return `${(
          this.lineItem.returned_quantity *
          this.lineItem.other_data?.display_quantity
        ).toFixed(2)} ${this.lineItem.other_data?.display_name}`;
      }
    }else{
      return `${this.lineItem.returned_quantity}`;
    }
  }

  get itemPromotionAvailableText() {
    let itemPromotionObject = undefined;
    let msg = "";

    let addedItem = _.find(this.cart?.items, {
      item_id: this.product.id,
    });

    if (addedItem && this.itemPromotion) {
      itemPromotionObject = this.itemPromotion[addedItem?.id];
    }

    if (itemPromotionObject && itemPromotionObject?.promotions_available) {
      if (itemPromotionObject.prerequisite_item_count_to_be_added != 0) {
        if (itemPromotionObject.entitlement_type == "amount_discount") {
          msg = I18n.t("screen_messages.common.buy_more_to_avail_discount", {
            count: itemPromotionObject.prerequisite_item_count_to_be_added,
            amount:
              itemPromotionObject.value_type == "fixed"
                ? itemPromotionObject.value / 100
                : itemPromotionObject.value + "%",
          });
        } else {
          msg = I18n.t("screen_messages.common.buy_more_to_get_free_item", {
            count: itemPromotionObject.prerequisite_item_count_to_be_added,
            value:
              itemPromotionObject.prerequisite_to_entitlement_quantity_ratio
                .entitled_item_quantity,
          });
        }
      } else {
        if (addedItem) {
          if (addedItem.promotion_discount_amount != 0) {
            msg = I18n.t("screen_messages.common.your_savings_text", {
              amount: addedItem.promotion_discount_amount / 100,
            });
          } else {
            msg = I18n.t("screen_messages.common.congrats_on_discount");
          }
        } else {
          _.forEach(this.cart.items, (item) => {
            if (item.promotion_discount_amount != 0) {
              msg = I18n.t("screen_messages.common.your_savings_text", {
                amount: item.promotion_discount_amount / 100,
              });
            }
          });
        }
      }
    } else {
      msg = "";
    }

    return msg;
  }

  get entitlementType() {
    let itemPromotionObject = undefined;
    let addedItem = _.find(this.cart?.items, {
      item_id: this.product.id,
    });

    if (addedItem && this.itemPromotion) {
      itemPromotionObject = this.itemPromotion[addedItem?.id];
      return itemPromotionObject.entitlement_type;
    }
  }

  get allowChooseDiscountedItem() {
    let itemPromotionObject = undefined;
    let flag = false;

    let addedItem = _.find(this.cart?.items, {
      item_id: this.product.id,
    });

    if (addedItem && this.itemPromotion) {
      itemPromotionObject = this.itemPromotion[addedItem?.id];
    }

    if (
      itemPromotionObject &&
      itemPromotionObject?.promotions_available &&
      itemPromotionObject?.prerequisite_item_count_to_be_added == 0
    ) {
      if (itemPromotionObject.entitlement_type == "item_discount") {
        flag = true;
      } else {
        flag = false;
      }
    }
    return flag;
  }

  get showFreeItemOnPromotion() {
    let flag = false;

    let freeItem = _.find(this.cart?.items, {
      item_id: this.product.id,
    });
    if (freeItem && freeItem.promotion_discount_amount == freeItem.rate) {
      flag = true;
    }
    return flag;
  }

  get isPartialDelivery() {
    let quantity = Number(_.get(this.lineItem, "quantity", 0));
    let deliveredQuantity = Number(
      _.get(this.lineItem, "delivered_quantity", 0)
    );
    return quantity > deliveredQuantity;
  }

  get formatCurrency1() {
    let price = this.priceWithoutCurrency;
    return utils.formattedPriceRoundOff.call({ currency: "" }, price, true);
  }

  get formatCurrency2() {
    let price = this.priceWithoutCurrency;
    return utils.formattedPriceRoundOff(price, true);
  }

  get formatCurrency3() {
    let price = this.priceWithoutCurrency;
    return utils.formattedPriceRoundOff(price, false);
  }

  get firstVariantItem() {
    const variantCategoryItem = new VariantCategoryItem(
      this.product,
      this.variation
    );

    const sizeVariant = _.first(
      variantCategoryItem.getSizeVariants(
        variantCategoryItem.colorVariant?.id
      ) || []
    );

    const nonSoldOutSizeVariant = _.find(
      variantCategoryItem.getSizeVariants(
        variantCategoryItem.colorVariant?.id
      ) || [],
      (variation) => {
        return !variation.sold_out && variation.stock_count > 0;
      }
    );
    const selectedVariantCategoryItem = sizeVariant
      ? new VariantCategoryItem(
          variantCategoryItem.product,
          nonSoldOutSizeVariant || sizeVariant
        )
      : variantCategoryItem;

    return selectedVariantCategoryItem;
  }

  get variantItemOldPrice() {
    return this.firstVariantItem?.old_price;
  }

  get variantItemPrice() {
    return this.firstVariantItem.base_price;
  }

  static fake() {
    return new ItemCardModal({
      product: {
        name: "Product name",
        other_data: {
          companyName: "Company",
          molecule: "this is molecule",
        },
        price: {
          total_price: "$200",
        },
      },
    });
  }
}
