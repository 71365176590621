import React, { createRef, useContext } from "react";
import { View, TextInput, Pressable, Platform } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PrestoIcon from "../PrestoIcon";
import ThemeContext from "@presto-contexts/ThemeContext";
import { useAssets } from "presto-react-components";

export default function PrestoSearchInput(props) {
  const { theme } = useContext(ThemeContext);

  const {
    onSearch,
    onChange,
    onSearchFocus,
    onEndSearchFocusEnd,
    showSoftInputOnFocus,
    iconStyle,
    rightIcon,
    textInputStyle = {},
    inputref,
    showSearchIcon = true,
    maxWidth,
    autoFocus,
    minWidth = 250,
    containerStyle = {},
  } = props || {};

  const styles = getStyles(theme, icon, maxWidth, minWidth);
  const { svgs } = useAssets();

  const icon = props.icon || (
    <svgs.SearchIconOutlined
      primaryColor={theme.placeHolderColor}
      width={iconStyle ? iconStyle.width : 40}
      height={iconStyle ? iconStyle.height : 40}
    />
  );

  const closeIcon = props.closeIcon || (
    <svgs.CloseIcon2 width={31} height={31} />
  );

  const focus = () => {
    inputRef.current.focus();
  };
  const onQueryChange = ({ nativeEvent: { text } }) => {
    onChange(text);
  };

  const onClearSearch = () => {
    onChange("");
  };

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.textinputContainer,
          { ...props.containerStyle },
          {
            marginRight:
              icon || rightIcon
                ? props.marginRight
                : theme.textInputBorderRadius,
          },
        ]}
      >
        <TextInput
          ref={inputref}
          nativeID="search-id"
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          value={props.value}
          placeholder={props.placeholder}
          placeholderTextColor={
            props.textInputPlaceholderColor || styles.placeHolder.textColor
          }
          showSoftInputOnFocus={showSoftInputOnFocus || true}
          returnKeyType={props.returnKeyType || "search"}
          onSubmitEditing={() => onSearch && onSearch(props.value)}
          onKeyPress={(e) => {
            if (
              Platform.OS === "web" &&
              e?.keyCode == 13 &&
              _.isFunction(onSearch)
            ) {
              onSearch(props.value);
            }
          }}
          onChange={onQueryChange}
          style={{
            ...styles.textInputStyle,
            ...textInputStyle,
          }}
          numberOfLines={props.numberOfLines || 1}
          onFocus={onSearchFocus}
          onBlur={onEndSearchFocusEnd}
          autoFocus={autoFocus || false}
        />
        {showSearchIcon ? (
          <Pressable
            style={{ height: containerStyle?.height }}
            onPress={() => {
              if (props.value?.length > 0) {
                onClearSearch();
              } else {
                onSearch ? onSearch(props.value) : null;
                onSearchFocus(props.value);
              }
            }}
          >
            <View
              style={{
                paddingTop:
                  props.value?.length > 0
                    ? containerStyle?.height
                      ? containerStyle?.height * 0.15
                      : 0
                    : containerStyle?.height
                    ? containerStyle?.height * 0.15
                    : 10,
                paddingRight:
                  props.value?.length > 0
                    ? containerStyle?.height
                      ? containerStyle?.height * 0.15
                      : 10
                    : containerStyle?.height
                    ? containerStyle?.height * 0.15
                    : 0,
              }}
            >
              <PrestoIcon
                icon={props.value?.length > 0 ? closeIcon : rightIcon ?? icon}
              />
            </View>
          </Pressable>
        ) : null}
      </View>
    </View>
  );
}

const getStyles = (theme, icon, maxWidth, minWidth) => {
  return {
    container: {
      width: "100%",
      position: "relative",
    },
    textInputStyle: {
      flex: 1,
      height: theme.textInputHeight - 4 || 50,
      borderWidth: 0,
      fontSize: theme.textInputFontSize || 16,
      fontFamily: theme.primaryFont,
      color: theme.textInputColor,
      borderRadius: theme.textInputBorderRadius || 25,
      padding: 0,
      margin: 0,
      maxWidth: maxWidth || "85%",
      outlineStyle: "none",
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      // flex: 1,
      height: theme.textInputHeight,
      backgroundColor: theme.textInputBgColor,
      borderWidth: theme.textInputBorderWidth ?? 2,
      borderRadius: theme.textInputBorderRadius || 25,
      borderColor: theme.textInputBorderColor || "#CCCCCC",
      minWidth: minWidth || 250,
      justifyContent: "space-between",
    },
    placeHolder: {
      textColor: theme.placeHolderColor,
    },
  };
};
