import React, { useContext } from "react";
import { Platform, Alert, View } from "react-native";
import I18n from "i18n-js";
import config from "@presto-common/config";
import { showAlert, closeAlert } from "react-native-customisable-alert";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";

function CustomAlert({ title = "Alert", message, onPressClose = closeAlert }) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        maxWidth: 480,
        width: "90%",
        backgroundColor: "white",
        padding: 16,
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      <PrestoText
        fontStyle="400.medium"
        size={18}
        extraStyle={{ color: "black", marginBottom: 12 }}
      >
        {title}
      </PrestoText>
      <PrestoText
        fontStyle="400.normal"
        size={16}
        extraStyle={{ color: "black" }}
      >
        {message}
      </PrestoText>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 16,
        }}
      >
        <PrestoSolidButton
          buttonStyle="primary"
          size="normal"
          title={"Ok"}
          titleFontStyle="400.medium"
          titleSize={18}
          bgColor={theme.primary}
          extraStyle={{
            marginTop: 20,
            minWidth: 80,
            paddingHorizontal: 8,
          }}
          borderWidth={1}
          titleColor={"white"}
          onPress={() => onPressClose()}
        />
      </View>
    </View>
  );
}

function CustomConfirm({ title = "Alert", message, buttons, cancellable }) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        maxWidth: 480,
        width: "90%",
        backgroundColor: "white",
        padding: 16,
        borderRadius: 8,
        overflow: "hidden",
      }}
    >
      {title && (
        <PrestoText
          fontStyle="400.medium"
          size={18}
          extraStyle={{ color: "black", marginBottom: 12 }}
        >
          {title}
        </PrestoText>
      )}
      <PrestoText
        fontStyle="400.normal"
        size={16}
        extraStyle={{ color: "black", marginBottom: 12 }}
      >
        {message}
      </PrestoText>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: 16,
        }}
      >
        {_.map(buttons, (button) => {
          return (
            <PrestoSolidButton
              buttonStyle="primary"
              size="normal"
              title={button.text}
              titleFontStyle="400.medium"
              titleSize={16}
              bgColor={theme.primary}
              extraStyle={{
                marginTop: 20,
                minWidth: 80,
                paddingHorizontal: 8,
              }}
              borderWidth={1}
              titleColor={"white"}
              onPress={() => {
                button.onPress && button.onPress();
                closeAlert();
              }}
            />
          );
        })}
      </View>
    </View>
  );
}

export const prestoAlert = (
  title,
  message = "",
  buttons = [
    {
      text: "ok",
      style: "cancel",
    },
  ],
  isCancelable
) => {
  if (Platform.OS === "web" && config.features.show_custom_alert) {
    showAlert({
      alertType: "custom",
      customAlert: (
        <CustomAlert title={title} message={message} buttons={buttons} />
      ),
    });
  } else if (Platform.OS != "web") {
    Alert.alert(title, message, [...buttons], { cancelable: isCancelable });
  } else {
    alert(`${title} \n${message}`);
  }
};
export const prestoConfirm = (
  title,
  message,
  buttons,
  isCancelable,
  callBack
) => {
  if (Platform.OS === "web" && config.features.show_custom_alert) {
    showAlert({
      alertType: "custom",
      customAlert: (
        <CustomConfirm
          title={title}
          message={message}
          buttons={buttons}
          cancellable={isCancelable}
        />
      ),
    });
  } else if (Platform.OS != "web") {
    Alert.alert(title, message, buttons, { cancelable: isCancelable });
  } else {
    callBack(confirm(`${title} \n${message}`));
  }
};

export const AsyncAlert = async (
  title,
  message,
  successTitle,
  cancelTitle,
  isCancelable
) =>
  new Promise((resolve) => {
    Alert.alert(
      title,
      message,
      [
        {
          text: successTitle,
          onPress: () => {
            resolve("yes");
          },
        },
        {
          text: cancelTitle,
          onPress: () => resolve("cancel"),
          style: "cancel",
        },
      ],
      { cancelable: isCancelable }
    );
  });

export const alertBox = (title, message = "") =>
  prestoAlert(
    title,
    message,
    [
      {
        text: I18n.t("screen_messages.common.button_ok"),
        style: "cancel",
      },
    ],
    false
  );
