import React, { useContext } from "react";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import GradientBackgroundForHeader from "@presto-screen-components/SearchableScreen/GradientBackgroundForHeader";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserModel from "@presto-component-models/UserModel";

export default function TitleHeaderWithBackWithGradientBackground(props) {
  const { theme } = useContext(ThemeContext);
  let titleColor = theme.primary,
    leftArrowColor = theme.primary,
    textFontStyle = "600.semibold";
  const userModel = new UserModel({ user: props.user });
  if (userModel.isLoyaltyTierValid) {
    titleColor = theme.white;
    leftArrowColor = theme.white;
  }

  return (
    <>
      <GradientBackgroundForHeader user={props.user} />
      <TitleHeaderWithBack
        titleProps={{
          color: titleColor,
        }}
        leftArrowColor={leftArrowColor}
        textFontStyle={textFontStyle}
        {...props}
      />
    </>
  );
}
