import React, { createContext, useState } from "react";
import SessionManager from "@presto-services/features/session/SessionManager";
import SessionHelper from "@presto-helpers/SessionHelper";
import AnalyticsManager from "@presto-common/AnalyticsManager";
import config from "@presto-common/config";

const UserContext = createContext();

export const AuthState = {
  unknown: "unknown",
  userLoggedIn: "userLoggedIn",
  userLoggedOut: "userLoggedOut",
};

export const EmpAuthState = {
  unknown: "unknown",
  empLoggedIn: "empLoggedIn",
  empLoggedOut: "empLoggedOut",
};

export function UserContextProvider(props) {
  const [userState, setUserState] = useState({
    user: undefined,
    authState: AuthState.unknown,
  });
  const [address, setAddress] = useState(null);
  const [currentLocation, setLocation] = useState(null);

  const setUser = (user) => {
    if (user) {
      if (config?.features?.setupEnhancedConversion) {
        AnalyticsManager.setupEnhancedConversion(user);
      }
      setUserState({ user, authState: AuthState.userLoggedIn });
    } else {
      setUserState({ user: undefined, authState: AuthState.userLoggedOut });
    }
  };

  const setCurrentAddress = (address) => {
    if (address) {
      setAddress(address);
    } else {
      setAddress(null);
    }
  };
  const setCurrentLocation = (address) => {
    if (address) {
      setLocation(address);
    } else {
      setLocation(null);
    }
  };

  const isUserLoggedIn = () => {
    if (userState.authState === AuthState.userLoggedIn) {
      return true;
    } else {
      return false;
    }
  };

  const refreshUser = () => {
    SessionHelper.isLoggedInUser(
      (response) => {
        setUser(response.data);
      },
      () => {
        setUser(undefined);
      }
    );
  };

  return (
    <UserContext.Provider
      value={{
        authState: userState.authState,
        user: userState.user || null,
        setUser: setUser,
        isUserLoggedIn,
        currentAddress: address,
        setCurrentAddress: setCurrentAddress,
        currentLocation: currentLocation,
        setCurrentLocation: setCurrentLocation,
        refreshUser: refreshUser,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}

export default UserContext;
