import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";

export function Card({ children, padding, backgroundColor }) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        ...theme.darkShadow,
        borderRadius: theme.cardRadius,
        overflow: "hidden",
      }}
    >
      <View
        style={[
          {
            padding: padding || theme.primaryPadding,
            overflow: "hidden",
            backgroundColor: backgroundColor || theme.white,
          },
        ]}
      >
        {children}
      </View>
    </View>
  );
}

export function TransparentCard({ children, padding }) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={[
        {
          padding: padding || theme.primaryPadding,
          width: "100%",
          backgroundColor: "transparent",
        },
      ]}
    >
      {children}
    </View>
  );
}
