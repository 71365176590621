import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import LoadingContainer from "@presto-components/LoadingContainer";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoTextInput from "@presto-components/TextInputs/TextInput";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Keyboard, ScrollView, View } from "react-native";
import { prestoAlert } from "../../common/Alert";
import DefaultModal from "../../components/Modals/Modal.bookmychef";
import AsyncStorage from "@react-native-community/async-storage";

export default function GetStoreId() {
  const { theme } = useContext(ThemeContext);
  const { setUser, refreshUser } = useContext(UserContext);
  const { setPaneState } = useContext(RightPaneContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [storeId, setStoreId] = useState("");
  const [padding, setPadding] = useState(0);
  const scrollRef = useRef();

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const onStoreIdChange = ({ nativeEvent: { text } }) => {
    setStoreId(text);
  };

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setPadding(200);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  useEffect(() => {
    scrollRef.current?.scrollToEnd({ animated: true });
  }, [padding]);

  const goBack = () => {
    Navigator.emit({
      event: "goBack",
    });
  };

  const saveStoreId = () => {
    if (storeId.length > 0) {
      AsyncStorage.setItem("zhc_store_id", storeId);
      goBack();
    } else {
      alert(
        I18n.t("screen_messages.alert"),
        I18n.t("screen_messages.invalid_store_id")
      );
    }
  };

  return (
    <LoadingContainer bgColor="#FFFFFF9D" loading={loading}>
      <DefaultModal goBack={() => {}} height="60%">
        <ScrollView
          ref={scrollRef}
          enableOnAndroid={true}
          scrollEnabled={true}
          style={{
            backgroundColor: theme.primary,
            borderTopRightRadius: 30,
            borderTopLeftRadius: 30,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirextion: "column",
              alignItems: "center",
              paddingBottom: padding,
              paddingTop: 40,
            }}
          >
            <FullWidthHorizontalPaddedBox>
              <PrestoText
                fontStyle="600.semibold"
                size={16}
                color={theme.white}
              >
                {I18n.t("screen_messages.store_id_required")}
              </PrestoText>
              <VerticalSpacing size={10} />
              <VerticalSpacing size={15} />
              <PrestoTextInput
                theme={theme}
                placeholder={I18n.t("screen_messages.store_id")}
                value={storeId}
                onChange={onStoreIdChange}
                backgroundColor={theme.white}
                textContentType={"username"}
                autoCompleteType={"username"}
                importantForAutofill={"yes"}
                style={{ textInputHeight: 40 }}
              />
              <VerticalSpacing size={25} />
              <PrestoFramedButton
                buttonStyle="primary"
                size="large"
                title={I18n.t("screen_messages.save")}
                borderRadius={20}
                titleColor={theme.white}
                borderColor={theme.white}
                titleExtraStyle={{ textTransform: "uppercase" }}
                onPress={saveStoreId}
              />
            </FullWidthHorizontalPaddedBox>
          </View>
        </ScrollView>
      </DefaultModal>
    </LoadingContainer>
  );
}
