import React, { useEffect, useState } from "react";
import { View, FlatList, Platform } from "react-native";
import { RadioButton } from "react-native-paper";
import RadioButtonWithText from "./RadioButtonWithText.bookmychef";

export default function RadioButtonGroup(props) {
  const { theme, onPress, resetRadioButton } = props;
  const { data } = props || [];
  const [radioData, setRadioData] = useState(data);
  const [value, setValue] = React.useState(props.value);
  const [selectedItem, setSelectedItem] = React.useState([]);

  useEffect(() => {
    if (props.selectedItem) {
      setSelectedItem(props.selectedItem);
    } else {
      setSelectedItem([]);
    }
  }, [props.selectedItem]);

  const setOption = (newValue) => {
    setValue(newValue);
    onPress(newValue);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const onPressItem = (item) => {
    if (props.multipleValue) {
      if (selectedItem.findIndex((i) => i.name === item.name) >= 0) {
        setSelectedItem(selectedItem.filter((i) => i.name != item.name));
      } else {
        setSelectedItem([...selectedItem, item]);
      }
    } else {
      setSelectedItem([item]);
    }

    if (props.onPressItem) {
      setSelectedItem([item]);
      props.onPressItem(item);
    }
  };
  const renderItem = ({ item }) => {
    return (
      <View
        key={item.name}
        style={{
          borderBottomWidth: 1,
          justifyContent: "center",
          borderBottomColor: props?.style?.borderBottomColor
            ? props?.style?.borderBottomColor
            : theme.lightGrey,
          paddingTop: 10,
        }}
      >
        <RadioButtonWithText
          value={item.name}
          theme={theme}
          paddingHorizontal={props?.paddingHorizontal ?? theme.containerPadding}
          text={item.name}
          description={item?.description}
          onPress={() => onPressItem(item)}
          status={
            selectedItem.findIndex((i) => i.name === item.name) >= 0
              ? true
              : false
          }
          iconStyle={{
            width: 30,
            height: 30,
            borderColor: theme.darkGrey,
            paddingTop: Platform.OS === "ios" ? 0 : 0.1,
          }}
          icon={item.icon}
          size={28}
          descriptionTextStyles={props?.descriptionTextStyles}
        />
      </View>
    );
  };
  return (
    <View>
      <FlatList
        data={data}
        keyExtractor={(_, index) => `${index}`}
        renderItem={renderItem}
      />
    </View>
  );
}
