import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import FavoritesResource from "./FavoritesResource";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const FavoritesManager = function (
  Utils,
  ErrorConstants,
  FavoritesResource,
  PrestoSdk
) {
  function getFavoriteProducts(params, successCallback, errorCallback) {
    if (!params.merchant_id) {
      params.merchant_id = PrestoSdk.getMerchantId();
    }
    FavoritesResource.getFavoriteProducts(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getFavoriteProducts: getFavoriteProducts,
  };
};

export default FavoritesManager(
  Utils,
  ErrorConstants,
  FavoritesResource,
  PrestoSdk
);
