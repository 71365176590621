import React from "react";
import { CartItemCards } from "presto-react-components";
import _ from "lodash";

export default function CartItemCard(props) {
  let forwardProps = { ...props };
  if (!_.isFunction(forwardProps?.priceAccessorMethod)) {
    forwardProps.priceAccessorMethod = (product) => {
      return product.formatCurrency2;
    };
  }

  return <CartItemCards.CartItemCard1 {...forwardProps} />;
}
