import React, { useState, useContext, useCallback } from "react";
import {
  View,
  Platform,
  Dimensions,
  FlatList,
  ImageBackground,
  TouchableWithoutFeedback,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import svgs from "@presto-common-assets/svgs";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { useSafeArea } from "react-native-safe-area-context";
import { ModalWithIcon } from "@presto-components/Modals/Modal";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import { useFocusEffect } from "@react-navigation/native";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import config from "@presto-common/config";
import * as Linking from "expo-linking";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function OfferGalleryComponent({ route, navigation }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { catalog, gallery } = route.params;

  const loading = false;
  const [state, setState] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const imageWidth = 600 / 2;

  const insets = useSafeArea();

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const renderItem = ({ item, index }) => {
    const len = Number(gallery?.length || 0);
    return (
      <TouchableWithoutFeedback onPress={() => onItemClicked(item)}>
        <ImageBackground
          style={{
            width: imageWidth,
            height: imageWidth * 2,
            borderRadius: 10,
            overflow: "hidden",
            marginRight: len === 1 ? 0 : 20,
          }}
          source={{ uri: item.file_url }}
          resizeMode="cover"
        />
      </TouchableWithoutFeedback>
    );
  };

  const onItemClicked = (item) => {
    AnalyticsManager.send("home_offer");
    if (item.target?.type) {
      switch (item.target?.type) {
        case "category_item":
          Navigator.emit({
            event: "modal",
            params: {
              screenName: "DETAILED_ITEM",
              screenParams: {
                itemId: item.target.id_value,
                catalogId: catalog?.id,
                item: item,
              },
            },
          });
          return;
        case "url":
          Linking.openURL(item.target.id_value);
          return;
        case "category":
          getCategoryDetails(item.target.id_value);
          return;
        case "app_link":
          goToPromotedItems(item.target.id_value);
      }
    } else {
      goBack();
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: {
              company_name: item?.other_data?.company_name,
              focus: false,
            },
            catalogId: catalog?.id,
          },
        },
      });
    }
  };

  const goToPromotedItems = (deepLink) => {
    var search_query = {};

    var parts = _.split(deepLink, "/");
    if (parts.length == 2) {
      search_query[parts[0]] = parts[1];
    } else {
      search_query.query = deepLink;
    }
    goBack();
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: {
          searchParams: search_query,
          catalogId: catalog?.id,
        },
      },
    });
  };

  const getCategoryDetails = (catId) => {
    goBack();
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: {
          searchParams: { category: { id: catId }, catalogId: catalog?.id },
        },
      },
    });
  };

  const renderActionSheet = ({ margin }) => {
    return (
      <ModalWithIcon goBack={goBack} keyboardAvoidView={false}>
        <View
          style={{
            alignItems: "center",
            backgroundColor: "white",
            marginBottom: margin,
            flex: 1,
            paddingTop:
              Platform.OS === "web" ? insets.top + 30 : insets.top - 20,
            alignItems: "center",
          }}
        >
          <LoadingContainer bgColor={"transparent"} loading={loading}>
            <View style={{ paddingHorizontal: theme.containerPadding }}>
              <FlatList
                horizontal
                keyExtractor={(_, index) => "index-" + index}
                data={gallery}
                renderItem={renderItem}
                swipeThreshold={10}
                layout={"default"}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{}}
              />
            </View>
          </LoadingContainer>
        </View>
      </ModalWithIcon>
    );
  };

  const fromSpring = state.close ? { margin: 0 } : { margin: -600 };
  const toSpring = state.close ? { margin: -600 } : { margin: 0 };

  return renderActionSheet(toSpring.margin);
}
