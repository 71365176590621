import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import OrderManager from "@presto-services/features/orders/OrderManager";
import FeedbackManager from "@presto-services/features/feedback/FeedbackManager";
import _ from "lodash";
import CheckboxRowButton from "@presto-components/CheckBoxes/CheckboxRowButton";
import TextInputComponent from "@presto-screen-components/TextInputComponent/TextInputComponent";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import UserContext from "@presto-contexts/UserContext";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function ReportAProblem({ route, navigation }) {
  const params = route.params || {};
  const { orderId } = params;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [selectedProblem, setSelectedProblem] = useState(problemData[0]);
  const [reason, setReason] = useState("");
  const windowHeight = Dimensions.get("window").height;

  useEffect(() => {
    OrderManager.getOrder(
      { id: orderId },
      (response) => {
        console.log("response,, ", response);
        setOrder(response.data);
        setLoading(false);
      },
      (error) => {
        console.log("Error : ", error);
        alert(I18n.t("error_messages.error_loading_order"));
      }
    );
  }, [orderId]);

  const onBack = () => {
    navigation.goBack();
  };
  const onCheckboxClicked = (option) => {
    console.log(option);
    setSelectedProblem(option);
  };
  const onChangeReason = ({ nativeEvent: { text } }) => {
    setReason(text);
  };
  const sendRequest = () => {
    AnalyticsManager.send("report_submit");
    setLoading(true);
    const params = {
      rating: -1,
      message: `Type: ${
        selectedProblem ? selectedProblem.key : "Others"
      } , Reason: ${
        selectedProblem.key != "Others" ? I18n.t(selectedProblem.label) : reason
      } ${reason ? ", Message : " + reason : null}`,
      afcust_id: orderId,
      afop_id: "",
    };
    FeedbackManager.sendOrderFeedback(
      params,
      (response) => {
        if (response.data?.success) {
          setLoading(false);
          alert(
            I18n.t("screen_messages.report_problem.onsuccessfull_feedback_msg")
          );
          setTimeout(() => {
            onBack();
          }, 1000);
        }
      },
      (error) => {
        console.log("sendRequest error", error);
        setLoading(false);
        alert(utils.errorMessage(error));
      }
    );
  };
  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          onBack={onBack}
        />
        <ScrollView
          contentContainerStyle={{ paddingBottom: 150 }}
          style={{ height: windowHeight - 150 }}
        >
          <View
            style={{
              paddingHorizontal: 15,
              paddingVertical: theme.primaryPadding,
            }}
          >
            <PrestoText
              fontStyle={"semibold"}
              size={theme.h1FontSize}
              color={theme.primaryTextColor}
            >
              {I18n.t("screen_messages.report_problem.title")}
            </PrestoText>
            <PrestoText
              fontStyle={"medium"}
              extraStyle={{
                marginTop: 20,
              }}
            >
              {I18n.t("screen_messages.report_problem.description")}
            </PrestoText>
            <PrestoText
              fontStyle={"semibold"}
              color={theme.primary}
              size={16}
              extraStyle={{
                marginTop: 30,
              }}
            >
              {I18n.t("screen_messages.report_problem.select_problem")}
            </PrestoText>
          </View>
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          {_.map(problemData, (option) => {
            return (
              <>
                <CheckboxRowButton
                  title={I18n.t(option.label)}
                  showDisclosure={true}
                  selected={option === selectedProblem}
                  onPress={() => onCheckboxClicked(option)}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </>
            );
          })}
          <View style={{ paddingHorizontal: 15, marginTop: 15 }}>
            <TextInputComponent
              theme={theme}
              value={reason}
              placeholder={I18n.t(
                "screen_messages.report_problem.problem_placeholder"
              )}
              onChange={onChangeReason}
              autoCapitalize="words"
              autoCorrect={false}
              multiline={true}
              numberOfLines={4}
            />
            <VerticalSpacing size={20} />
            <PrestoSolidButton
              size="large"
              title={I18n.t("screen_messages.common.send")}
              titleColor={theme.white}
              buttonStyle="primary"
              onPress={sendRequest}
              titleExtraStyle={{ textTransform: "uppercase" }}
            />
            <VerticalSpacing size={30} />
          </View>
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}

const problemData = [
  {
    key: "late",
    label: "screen_messages.report_problem.option_1",
  },
  {
    key: "missing",
    label: "screen_messages.report_problem.option_2",
  },
  {
    key: "expired",
    label: "screen_messages.report_problem.option_3",
  },
  {
    key: "damaged",
    label: "screen_messages.report_problem.option_4",
  },
  {
    key: "wrong_delivered",
    label: "screen_messages.report_problem.option_5",
  },
  {
    key: "Others",
    label: "screen_messages.report_problem.option_6",
  },
];
