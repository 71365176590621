import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import SearchResults from "@presto-screen-components/SearchResults/SearchResults";
import SideNavigation from "@presto-screen-components/SideNavigation/SideNavigation";
import WebHeader from "@presto-components/Headers/WebHeader";
import PromotedGallery from "@presto-screen-components/PromotedGallery/PromotedGallery";
import DistributorsGallery from "@presto-screen-components/PromotedGallery/DistributorsGallery.js";
import NavigationManager from "../../common/NavigationManager";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import { useCatalog } from "@presto-stores/CatalogStore";
import LoyaltyTierManager from "@presto-services/features/loyalty_tier/LoyaltyTierManager";
import _ from "lodash";
import config from "@presto-common/config";
import Manufacturers from "@presto-screen-components/ManufacturersGallery/Manufacturers";
import PopularBrands from "@presto-screen-components/ManufacturersGallery/PopularBrands";

var windowWidth = Dimensions.get("window").width;

export default function WebHome({ route, navigation }) {
  const catalog = useCatalog((state) => state.catalog);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { authState } = useContext(UserContext);
  const [active, setActive] = useState("HOME");
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const [loading, setLoading] = useState(true);
  const [searchQry, setSearchQuery] = useState(
    route.params || { bestsellers: true }
  );
  const [sideMenuClicked, setSideMenuClicked] = useState(
    route?.params?.sideMenuClicked || false
  );
  const [loyaltyTiers, setLoyaltyTiers] = useState([]);
  const showManufacturers = config.merchant_config?.show_manufacturers;
  const hideDisctributors = config.merchant_config?.hide_distributors;

  useEffect(() => {
    if (route.params?.searchParams) {
      setSearchQuery(route.params);
      navigation.setParams(route.params);
    }
  }, [route.params]);

  const listTiers = () => {
    function onSuccess(response) {
      setLoyaltyTiers(response.data);
    }
    function onError(error) {
      console.log("Home -> listTiers -> error", error);
    }
    LoyaltyTierManager.listTiers({}, onSuccess, onError);
  };

  useEffect(() => {
    listTiers();
  }, []);

  const onNavigate = (path) => {
    if (typeof path === "object") {
      setSearchQuery(path);
      setSideMenuClicked(true);
    }
    path = _.toUpper(path);
    switch (path) {
      case "FAVOURITES":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: "MY_PRODUCTS",
            pop: true,
          },
        });
        break;
      case "ACCOUNT":
      case "CART":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: path,
            pop: true,
          },
        });
        break;
      case "DEALS":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "push",
          params: { screenName: "VIEW_ALL_DEALS" },
        });
        break;
      case "HOME":
        setPaneState(false);
        break;
      case "PAYMENT_SUCCESS":
        alert("Payment is successful");
        setPaneState(false);
    }
  };

  const onSideNavigate = (params) => {
    setSearchQuery(params);
    setSideMenuClicked(true);
    navigation.setParams({
      query: undefined,
      category_id: undefined,
      is_new: undefined,
      bestsellers: undefined,
      company_name: undefined,
      molecule: undefined,
      therapy_area_simple: undefined,
      brand_name: undefined,
      region: undefined,
      distributor: undefined,
      ...params,
    });
  };

  const orderNowClicked = (value) => {
    setActive(value);
  };

  return (
    <>
      <WebHeader
        onNavigate={onNavigate}
        active={active}
        orderNowClicked={orderNowClicked}
      />
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          zIndex: -1,
        }}
      >
        <View
          style={{
            width: "20%",
            height: "100%",
            minWidth: 200,
            borderWidth: 0.1,
            borderColor: theme.imageBorderColor,
          }}
        >
          <SideNavigation
            onNavigate={onSideNavigate}
            loyaltyTiers={loyaltyTiers}
            setParentLoading={setLoading}
            orderNowClicked={orderNowClicked}
          />
        </View>
        <ScrollView style={{ height: "100%", zIndex: -1 }}>
          <View
            style={{
              flex: 1,
              zIndex: -1,
              paddingBottom: 100,
            }}
          >
            {!sideMenuClicked && (
              <>
                <PromotedGallery
                  title=""
                  name="PROMOTED_PRODUCTS_WEB"
                  height={(windowWidth * 20) / 100}
                  width={(windowWidth * 40) / 100}
                  onNavigate={onNavigate}
                />
                {hideDisctributors ? (
                  <PopularBrands
                    catalog={catalog}
                    galleryName="RECENT_BRANDS"
                    onNavigate={onSideNavigate}
                  />
                ) : (
                  <DistributorsGallery
                    name="DISTRIBUTORS_GALLERY"
                    imgStyle={{ height: 80, width: 80 }}
                    onNavigate={onSideNavigate}
                  />
                )}
                {showManufacturers ? (
                  <Manufacturers
                    onNavigate={onSideNavigate}
                    galleryName="RECENT_MANUFACTURERS"
                  />
                ) : null}
                <DealsComponent />
              </>
            )}
            <SearchResults
              showDeals={false}
              searchObj={searchQry}
              catalogId={catalog?.id}
            />
          </View>
        </ScrollView>
        <TouchableWithoutFeedback onPress={() => setPaneState(false)}>
          <View
            style={{
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              backgroundColor: "#00000050",
              position: "absolute",
              display: rightPaneOpen ? "flex" : "none",
            }}
          >
            <TouchableWithoutFeedback onPress={() => {}}>
              <View
                style={{
                  width: 400,
                  alignSelf: "flex-start",
                  position: "absolute",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  borderColor: theme.primary,
                  backgroundColor: "green",
                  borderWidth: 1,
                  overflow: "hidden",
                  right: 0,
                  top: 0,
                  height: "90%",
                  zIndex: 300,
                  display: rightPaneOpen ? "flex" : "none",
                }}
              >
                {NavigationManager.renderTree(
                  authState === AuthState.userLoggedIn,
                  true
                )}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </>
  );
}
