import React, { Component } from "react";
import Slider from "react-slick";
import { View, Image, Dimensions, Pressable } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
var windowWidth = Dimensions.get("window").width;
export default class BannerSlide extends Component {
  _renderItem = (item) => {
    return (
      <Pressable onPress={() => this.props.onClickBanner(item)}>
        <View
          style={{
            borderRadius: this.props.borderRadius || 0,
            height: this.props.height || 200,
            overflow: "hidden",
            width: this.props.width || "100%",
          }}
        >
          <Image
            source={{ uri: item.file_url }}
            resizeMode="cover"
            style={{
              borderRadius: this.props.borderRadius || 0,
              height: this.props.height || 200,
            }}
          />
        </View>
      </Pressable>
    );
  };

  render() {
    const settings = {
      dots: this.props.dots || false,
      infinite: true,
      speed: 1000,
      slidesToShow: this.props.slidesToShow || 1,
      slidesToScroll: this.props.slidesToShow || 1,
      arrows: this.props.arrows || false,
      autoplay: true,
      centerPadding: (windowWidth * 18.33) / 100 + "px",
      centerMode: true,
      adaptiveHeight: true,
    };
    const renderItems = this.props.banners.map((item) => {
      return this._renderItem(item);
    });
    return (
      <View style={{ marginBottom: 20, height: this.props.height || 200 }}>
        <Slider {...settings}>{renderItems}</Slider>
      </View>
    );
  }
}
