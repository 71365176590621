import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import EmployeeConstants from "./EmployeeConstants";
import $ from "../../global/util";

const EmployeeResource = function ($http, PrestoSdk, EmployeeConstants) {
  function getAllEmployees(params) {
    var url =
      PrestoSdk.getHostName() + EmployeeConstants.GET_ALL_EMPLOYEES.PATH;
    return $http.get(url);
  }

  function getAdminEmployees() {
    var url = PrestoSdk.getHostName() + "/admin/v0/employees.json";
    return $http.get(url);
  }

  function createAdminManager(params) {
    var url = PrestoSdk.getHostName() + "/employees.json";
    return $http.post(url, params);
  }

  function updateAdminManager(params) {
    var url = PrestoSdk.getHostName() + "/employees/" + params.id + ".json";
    return $http.put(url, params);
  }

  function createEmployee(params) {
    var url =
      PrestoSdk.getHostName() + EmployeeConstants.GET_ALL_EMPLOYEES.PATH;
    return $http.post(url, params);
  }

  function createEmployeeAddress(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v0/employees/${employee_id}/address`;

    return $http.post(url, params);
  }

  function updateEmployee(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${params.employee_id}.json`;

    return $http.put(url, params);
  }

  function showEmployee(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${params.employee_id}.json`;

    return $http.put(url, params);
  }

  function showEmployeeAddress(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${employee_id}/address.json`;

    return $http.get(url, params);
  }

  function updateEmployeeAddress(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${employee_id}/address.json`;

    // console.log("updateEmployeeAddress url, params:", url, params);
    return $http.put(url, params);
  }

  function removeEmployee(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v0/employees/${employee_id}.json`;

    // console.log("removeEmployee url, params:", url, params);
    return $http.delete(url, params);
  }

  function updateEmployeeAvailabilityStatus(params) {
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/employees/${employee_id}/available.json`;

    return $http.post(url, params);
  }

  function updateEmployeeCitizenUserId(params) {
    // let employee_id = params.employee_id;
    // var url =
    //   PrestoSdk.getHostName() +
    //   `/employee/v0/employees/update_citizen_user_id.json`;
    // console.log("updateEmployeeCitizenUserId url, params:", url, params);
    // return $http.post(url, params);
  }

  function updateEmployeeLocation(params) {
    // where should I add employee id
    let employee_id = params.employee_id;
    var url =
      PrestoSdk.getHostName() + `/employee/v0/employees/update_location.json`;

    console.log("updateEmployeeCitizenUserId url, params:", url, params);
    return $http.put(url, params);
  }

  function operatorsOccupancyByDate(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/bookings/occupancy.json?start_time=${params.start_time}&end_time=${params.end_time}&operator_ids=${params.operator_ids}`;

    return $http.get(url);
  }

  function showOperationalTiming(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/operational_timing.json?entity_name=${params.entity_name}&entity_id=${params.entity_id}`;
    return $http.get(url, params);
  }

  function createOperationalTiming(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/operational_timing.json`;
    return $http.post(url, params);
  }

  function updateOperationalTiming(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/operational_timing.json`;
    return $http.put(url, params);
  }

  function operatorLeaves({
    entity_id,
    start_time,
    page = 1,
    records_per_page = 10,
  }) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/leave_list.json?entity_id=${entity_id}&start_time=${start_time}&page=${page}&records_per_page=${records_per_page}`;
    return $http.get(url, {});
  }

  function showNonWorkingSlots({
    start_time,
    page = 1,
    records_per_page = 10,
  }) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/holiday_list.json?start_time=${start_time}&page=${page}&records_per_page=${records_per_page}`;

    return $http.get(url, {});
  }

  function createNonWorkingSlots(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/non_working_slots.json`;
    return $http.post(url, params);
  }

  function updateNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/${params.id}.json`;
    return $http.put(url, params);
  }

  function removeNonWorkingSlots(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/non_working_slots/${params.id}.json`;
    return $http.delete(url, params);
  }

  function getOrders(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/orders.json`;
    if (params.oldest_time) {
      url += "?oldest_time=" + params.oldest_time;
    }
    return $http.get(url);
  }

  function applyCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.orderId}/apply_coupon.json`;
    return $http.put(url, params);
  }

  function removeCoupon(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.orderId}/remove_coupon.json`;
    return $http.put(url, params);
  }

  function generatorLoginTokenForOperator(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/operators/" +
      params.id +
      "/generate_login_token.json";
    return $http.post(url, params);
  }

  function loginEmployeeWithToken(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/login_with_token.json";
    console.log(`loginEmployeeWithToken url`, url);
    return $http.post(url, params);
  }

  function getOrderReturnReasons(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/order_return_reasons.json?category_id=" +
      params.categoryId;
    console.log("url===", url);
    return $http.get(url);
  }

  function createOrderItemReturn(params) {
    var url = PrestoSdk.getHostName() + "/employee/v0/order_returns.json";
    return $http.post(url, params);
  }

  function getOrderItemReturn(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/order_returns/" +
      params.order_return_id +
      ".json";
    return $http.get(url, params);
  }

  function associateDocumentToLineItem(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/order_returns/${params.return_id}/add_document`;
    return $http.post(url, params);
  }

  function getCoupons(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/coupons.json";
    if (params.exclude_expired_coupon) {
      url += `?exclude_expired_coupon=${"true"}`;
    }
    if (params.page) {
      url += `&page=${params.page}`;
    }
    return $http.get(url, params);
  }

  function deleteEmployee(params) {
    var url = PrestoSdk.getHostName() + `/employees/${params.manager_id}.json`;
    return $http.delete(url);
  }

  function getItemPromotions(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/item_promotions.json`;
    return $http.get(url);
  }

  function getCCAppointments(params) {
    let newDate = new Date();
    var url =
      PrestoSdk.getHostName() +
      `/cc/employees/${params.employee_id}/appointments.json?status=${
        params.orderStatus
      }&_prid=${newDate.toISOString()}`;
    return $http.get(url);
  }

  function getAppointmentDetails(params) {
    var url =
      PrestoSdk.getHostName() +
      `/cc/${params.merchant_id}/appointments/${params.appointment_id}.json`;
    return $http.get(url);
  }

  function updateDeliveredQuantity(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.order_id}/update_delivered_quantity.json`;
    return $http.post(url, params);
  }

  function updateEmployeeOtherdata(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.order_id}/update_employee_other_data.json`;
    return $http.put(url, params);
  }

  function updateItem(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/orders/${params.order_id}/update_item.json`;
    return $http.put(url, params);
  }

  function getPriceRule(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/price_rules/${params.id}.json`;
    return $http.get(url);
  }

  function getTransactionHistory(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v0/wallets/${params.userId}/transactions.json`;
    return $http.get(url);
  }

  function userWalletInfo(params) {
    var url = PrestoSdk.getHostName() + `/employee/v0/wallets.json`;
    if (Array.isArray(params.userIds)) {
      url += `?user_ids=${params.userIds.join(",")}`;
    }
    return $http.get(url);
  }

  function updateAppointmentUserInstructions(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/appointments/${params.id}.json`;
    return $http.put(url, params);
  }

  function createServiceProduct(params) {
    var url = PrestoSdk.getHostName() + "/employee/v1/products.json";
    return $http.post(url, params);
  }

  function getServiceProduct(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/products/${params.id}.json`;
    return $http.get(url);
  }

  function updateServiceProduct(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/products/${params.id}.json`;
    return $http.put(url, params);
  }

  function deleteServiceProduct(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/products/${params.id}.json`;
    return $http.delete(url);
  }

  function getAllServiceProducts() {
    var url = PrestoSdk.getHostName() + `/employee/v1/products.json`;
    return $http.get(url);
  }

  function createInventory(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/inventories.json`;
    return $http.post(url, params);
  }

  function updateInventory(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/inventories/${params.id}.json`;
    return $http.put(url, params);
  }

  function deleteInventory(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/inventories/${params.id}.json`;
    return $http.delete(url);
  }

  function createInventoryLog(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/inventory_logs.json`;
    return $http.post(url, params);
  }

  function getAllInvetoryLogs() {
    var url = PrestoSdk.getHostName() + "/employee/v1/inventory_logs.json";
    if (params.operator_id) {
      url += `?operator_id=${params.operator_id}`;
    }
    return $http.get(url);
  }

  function getInvetoryLog(params) {
    var url =
      PrestoSdk.getHostName() + `/employee/v1/inventory_logs/${params.id}.json`;
    return $http.get(url);
  }

  function createUsageForService(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/usages.json`;
    return $http.post(url, params);
  }

  function fetchUsagesOfService(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/usages/for_service.json`;
    return $http.get(url, params);
  }

  function updateUsageForService(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/usages/${params.id}.json`;
    return $http.put(url, params);
  }

  function deleteUsageForService(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/usages/${params.id}.json`;
    return $http.delete(url);
  }

  function addDocumentToProduct() {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/products/${params.id}/add_document.json`;
    return $http.post(url);
  }

  function markOrderAsDeliveredV1(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/orders/" +
      params.order_id +
      "/mark_as_delivered.json";
    return $http.put(url, params);
  }

  function markOrderAsPickerUpV1(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/orders/" +
      params.order_id +
      "/mark_as_picked_up.json";
    return $http.put(url, params);
  }

  function getAllDashboard() {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/metabase_embedded_dashboards.json";
    return $http.get(url);
  }

  function embedUrl(params) {
    var url =
      PrestoSdk.getHostName() +
      `/employee/v1/metabase_embedded_dashboards/${params.id}/embed_url.json`;
    return $http.get(url);
  }

  function operatorAttendances(params) {
    var url = PrestoSdk.getHostName() + `/employee/v1/operator_attendances.json`;
    return $http.post(url, params);
  }

  return {
    updateEmployeeOtherdata: updateEmployeeOtherdata,
    addDocumentToProduct: addDocumentToProduct,
    createUsageForService: createUsageForService,
    fetchUsagesOfService: fetchUsagesOfService,
    updateUsageForService: updateUsageForService,
    deleteUsageForService: deleteUsageForService,
    createInventoryLog: createInventoryLog,
    getAllInvetoryLogs: getAllInvetoryLogs,
    getInvetoryLog: getInvetoryLog,
    createInventory: createInventory,
    updateInventory: updateInventory,
    deleteInventory: deleteInventory,
    createServiceProduct: createServiceProduct,
    getServiceProduct: getServiceProduct,
    updateServiceProduct: updateServiceProduct,
    deleteServiceProduct: deleteServiceProduct,
    getAllServiceProducts: getAllServiceProducts,
    getAllEmployees: getAllEmployees,
    createEmployee: createEmployee,
    createEmployeeAddress: createEmployeeAddress,
    updateEmployee: updateEmployee,
    showEmployee: showEmployee,
    showEmployeeAddress: showEmployeeAddress,
    updateEmployeeAddress: updateEmployeeAddress,
    removeEmployee: removeEmployee,
    updateEmployeeAvailabilityStatus: updateEmployeeAvailabilityStatus,
    operatorsOccupancyByDate: operatorsOccupancyByDate,
    showOperationalTiming: showOperationalTiming,
    createOperationalTiming: createOperationalTiming,
    updateOperationalTiming: updateOperationalTiming,
    showNonWorkingSlots: showNonWorkingSlots,
    createNonWorkingSlots: createNonWorkingSlots,
    updateNonWorkingSlots: updateNonWorkingSlots,
    removeNonWorkingSlots: removeNonWorkingSlots,
    getAdminEmployees: getAdminEmployees,
    createAdminManager: createAdminManager,
    updateAdminManager: updateAdminManager,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    getCoupons: getCoupons,
    generatorLoginTokenForOperator: generatorLoginTokenForOperator,
    loginEmployeeWithToken: loginEmployeeWithToken,
    operatorLeaves: operatorLeaves,
    getOrders: getOrders,
    deleteEmployee: deleteEmployee,
    getOrderReturnReasons: getOrderReturnReasons,
    createOrderItemReturn: createOrderItemReturn,
    associateDocumentToLineItem: associateDocumentToLineItem,
    getOrderItemReturn: getOrderItemReturn,
    getItemPromotions: getItemPromotions,
    getCCAppointments: getCCAppointments,
    getAppointmentDetails: getAppointmentDetails,
    updateDeliveredQuantity: updateDeliveredQuantity,
    getPriceRule: getPriceRule,
    updateItem: updateItem,
    getTransactionHistory: getTransactionHistory,
    userWalletInfo: userWalletInfo,
    updateAppointmentUserInstructions: updateAppointmentUserInstructions,
    operatorAttendances: operatorAttendances,
    markOrderAsDeliveredV1,
    markOrderAsPickerUpV1,
    getAllDashboard,
    embedUrl,
  };
};

export default EmployeeResource($http, PrestoSdk, EmployeeConstants);
