import React, { useContext, useState, useEffect } from "react";
import {
  View,
  StyleSheet,
  Share,
  ScrollView,
  Platform,
  Clipboard,
  Pressable,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import UserContext from "@presto-contexts/UserContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoText from "@presto-components/PrestoText";
import { SafeAreaView } from "react-native-safe-area-context";
import PrestoTextInput from "@presto-components/TextInputs/PrimaryTextInput";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import config from "@presto-common/config";
import { useToast } from "native-base";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";

export default function Refer(props) {
  const toast = useToast();
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  const { user } = useContext(UserContext);
  const { svgs } = useAssets();
  const referralCode = user?.ref_code;
  const shareableLink = config?.hubspotUrl + "?referral_code=" + referralCode;

  const [visibleToast, setvisibleToast] = useState(false);
  useEffect(() => setvisibleToast(false), [visibleToast]);

  const onPressCopyCode = () => {
    toast.show({
      description: ` ${referralCode} ${I18n.t(
        "screen_messages.Copied_to_clipboard"
      )}`,
    });
  };

  const onShareReferralLink = async () => {
    const referral_link = shareableLink;
    if (Platform.OS != "web") {
      const result = await Share.share({
        message:
          I18n.t("screen_messages.refer_earn.description") +
          " " +
          shareableLink,
      });
    } else {
      Clipboard.setString(referral_link);
      alert(I18n.t("screen_messages.Copied_to_clipboard"));
    }
  };

  const copyToClipBoard = () => {
    onPressCopyCode();
    Clipboard.setString(referralCode);
  };

  const DashLine = () => {
    return (
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <View
          style={{
            borderStyle: "dashed",
            borderWidth: 1,
            height: 36,
            borderRadius: 2,
            borderColor: theme.primary,
          }}
        />
      </View>
    );
  };

  const NumberCircle = ({ number }) => {
    return (
      <View
        style={{
          backgroundColor: theme.primary,
          height: 50,
          width: 50,
          borderRadius: 100,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PrestoText
          fontStyle="600.semibold"
          color={theme.white}
          size={16}
          extraStyle={styles.textRoundContainer}
        >
          {number}
        </PrestoText>
      </View>
    );
  };

  return (
    <SafeAreaView
      style={{
        backgroundColor: theme.white,
        height: "100%",
      }}
    >
      <TitleHeaderWithBackWithGradientBackground
        user={user}
        title={I18n.t("screen_messages.refer_earn.title")}
      />
      <ScrollView
        scrollEnabled={true}
        style={{
          height: "100%",
        }}
      >
        <VerticalSpacing size={18} />
        <View style={{ paddingHorizontal: 20 }}>
          <View>
            <PrestoText
              fontStyle="600.semibold"
              size={16}
              color={theme.primary}
            >
              {I18n.t("screen_messages.refer_earn.how_this_works")}
            </PrestoText>
          </View>
          <VerticalSpacing size={25} />
          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <View
              style={{ flexDirection: "column", justifyContent: "flex-start" }}
            >
              <NumberCircle number={1} />
              <DashLine />
              <NumberCircle number={2} />
              <DashLine />
              <NumberCircle number={3} />
            </View>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "flex-start",
                marginLeft: 10,
                marginTop: 5,
                marginRight: 20,
                width: "85%",
              }}
            >
              <View
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  color={theme.darkText}
                  numberOfLines={1}
                >
                  {I18n.t("screen_messages.refer_earn.msg_label_1")}
                </PrestoText>
                <PrestoText
                  fontStyle="400.normal"
                  color="#999999"
                  extraStyle={{ marginTop: 7 }}
                  numberOfLines={1}
                >
                  {I18n.t("screen_messages.refer_earn.msg_text_1")}
                </PrestoText>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                  marginTop: 45,
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  color={theme.darkText}
                  numberOfLines={1}
                >
                  {I18n.t("screen_messages.refer_earn.msg_label_2")}
                </PrestoText>
                <PrestoText
                  color="#999999"
                  fontStyle="400.normal"
                  extraStyle={{ marginTop: 7 }}
                  numberOfLines={1}
                >
                  {I18n.t("screen_messages.refer_earn.msg_text_2")}
                </PrestoText>
              </View>
              <View
                style={{
                  flexDirection: "column",
                  alignContent: "center",
                  marginTop: 45,
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  color={theme.darkText}
                  numberOfLines={1}
                >
                  {I18n.t("screen_messages.refer_earn.msg_label_3")}
                </PrestoText>
                <PrestoText
                  fontStyle="400.normal"
                  color="#999999"
                  extraStyle={{ marginTop: 7 }}
                  numberOfLines={1}
                >
                  {I18n.t("screen_messages.refer_earn.msg_text_3")}
                </PrestoText>
              </View>
            </View>
          </View>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            width: "100%",
            height: 60,
            marginTop: 50,
            marginHorizontal: 20,
          }}
        >
          <View style={{ flex: 0.9, paddingRight: 20 }}>
            <PrestoTextInput
              theme={theme}
              value={shareableLink}
              backgroundColor={theme.darkGrey}
              autoCorrect={false}
              editable={false}
            />
          </View>
          <Pressable
            onPress={onShareReferralLink}
            style={{
              height: 40,
              justifyContent: "center",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <PrestoText fontStyle={"medium"} color={theme.primary} size={16}>
                {Platform.OS === "web"
                  ? I18n.t("screen_messages.copy_text")
                  : I18n.t("screen_messages.share_text")}
              </PrestoText>
              <View style={{ alignContent: "flex-end" }}>
                <PrestoIcon
                  icon={<svgs.RightArrow primaryColor={theme.primary} />}
                />
              </View>
            </View>
          </Pressable>
        </View>
        <View
          style={{
            paddingTop: Platform.OS === "web" ? 20 : 0,
            paddingHorizontal: 20,
            paddingRight: 12,
            width: "100%",
            height: 100,
          }}
        >
          <PrestoText fontStyle="600.semibold" size={16} color={theme.primary}>
            {I18n.t("screen_messages.refer_earn.refer_code")}
          </PrestoText>
          <View style={{ flex: 1, flexDirection: "row", marginTop: 10 }}>
            <View style={{ flex: 1, paddingRight: 20 }}>
              <PrestoTextInput
                theme={theme}
                value={referralCode}
                backgroundColor={theme.darkGrey}
                autoCorrect={false}
                editable={false}
              />
            </View>
            <Pressable
              onPress={copyToClipBoard}
              style={{
                height: 40,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <PrestoText
                  fontStyle={"medium"}
                  color={theme.primary}
                  size={16}
                >
                  {I18n.t("screen_messages.copy_text")}
                </PrestoText>
                <PrestoIcon
                  icon={<svgs.RightArrow primaryColor={theme.primary} />}
                />
              </View>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
}

function getStyles(theme) {
  return {
    textRoundContainer: {},
  };
}
