import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const FavoritesResource = function ($http, PrestoSdk) {
  function getFavoriteProducts(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/favorites.json";
    console.log("URLLLL==>", url);
    return $http.get(url, params);
  }
  return {
    getFavoriteProducts: getFavoriteProducts,
  };
};

export default FavoritesResource($http, PrestoSdk);
