import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import FeedbackConstants from "./FeedbackConstants";
import $ from "../../global/util";

const FeedbackResource = function ($http, PrestoSdk, FeedbackConstants) {
  function sendAppointmentFeedback(appointmentFeedback) {
    var url = PrestoSdk.getHostName() + FeedbackConstants.SEND_FEEDBACK.PATH;
    return $http.post(url, appointmentFeedback);
  }

  function sendOrderFeedback(orderFeedback) {
    var url = PrestoSdk.getHostName() + FeedbackConstants.SEND_FEEDBACK.PATH;
    return $http.post(url, orderFeedback);
  }

  function sendAppFeedback(appFeedback) {
    var url = PrestoSdk.getHostName() + FeedbackConstants.SEND_FEEDBACK.PATH;
    return $http.post(url, appFeedback);
  }

  return {
    sendAppFeedback: sendAppFeedback,
    sendOrderFeedback: sendOrderFeedback,
    sendAppointmentFeedback: sendAppointmentFeedback,
  };
};

export default FeedbackResource($http, PrestoSdk, FeedbackConstants);
