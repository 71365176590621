import React, { useContext, useState, useEffect } from "react";
import {
  View,
  ScrollView,
  KeyboardAvoidingView,
  Dimensions,
} from "react-native";
import I18n from "i18n-js";
import SearchableScreen from "../SearchableScreen/SearchableScreen";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import PromotedGallery from "@presto-screen-components/PromotedGallery/PromotedGallery";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import { VerticalSpacing } from "@presto-components/Spacing";
import DistributorsGallery from "@presto-screen-components/PromotedGallery/DistributorsGallery";
import ProfileHighlight from "@presto-screen-components/ProfileHighlight/ProfileHighlight";
import RegionFilter from "@presto-screen-components/Region/Region";
import { useCatalog } from "@presto-stores/CatalogStore";
import config from "@presto-common/config";
import LoyaltyTierManager from "@presto-services/features/loyalty_tier/LoyaltyTierManager";
import Manufacturers from "@presto-screen-components/ManufacturersGallery/Manufacturers";
import PopularBrands from "@presto-screen-components/ManufacturersGallery/PopularBrands";

export function reducer(state, { type, payload }) {
  switch (type) {
    case "SET_SEARCH_QUERY":
      return {
        ...state,
        ...{ query: payload },
      };
    case "SET_SEARCH_FILTER":
      return {
        ...state,
      };
    case "SET_SORT_TYPE":
      return {
        ...state,
        ...{ sort_type: payload },
      };
    case "SET_SEARCH_RESULTS":
      return { ...state, search_results: payload };
  }
  return state;
}

export default function Home({ navigation }) {
  const { Navigator } = useContext(NavigatorContext);
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const silderWidth = (windowWidth * 90) / 100;
  const silderHeight = (windowWidth * 60) / 100;
  const [loading, setLoading] = useState(true);
  const catalog = useCatalog((state) => state.catalog);
  const [loyaltyTiers, setLoyaltyTiers] = useState([]);
  let isDesktop = Dimensions.get("window").width > 940;
  const showManufacturers = config.merchant_config?.show_manufacturers;
  const hideDisctributors = config.merchant_config?.hide_distributors;

  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const onSearchFocus = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: { searchParams: {}, focus: true },
      },
    });
  };

  const onNavigate = (path) => {
    path = _.toUpper(path);
    switch (path) {
      case "FAVOURITES":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: "MY_PRODUCTS",
            pop: true,
          },
        });
        break;
      case "ACCOUNT":
      case "CART":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: path,
            pop: true,
          },
        });
        break;
      case "DEALS":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "push",
          params: { screenName: "VIEW_ALL_DEALS" },
        });
        break;
      case "HOME":
        setPaneState(false);
    }
  };

  const listTiers = () => {
    function onSuccess(response) {
      setLoyaltyTiers(response.data);
    }
    function onError(error) {
      console.log("Home -> listTiers -> error", error);
    }
    LoyaltyTierManager.listTiers({}, onSuccess, onError);
  };

  useEffect(() => {
    listTiers();
  }, []);

  const distributorPicSize = ((silderWidth - 60) * 25) / 100;

  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      showSoftInputOnFocus={false}
      placeholder={I18n.t(
        "screen_messages.common.search_by_placeholder_options.home"
      )}
    >
      <KeyboardAvoidingView behavior="padding" enabled={false}>
        <View>
          <ScrollView
            style={{ height: windowHeight - 100 }}
            contentContainerStyle={{ paddingBottom: 100 }}
          >
            <View>
              <ProfileHighlight
                isDesktop={isDesktop}
                loyaltyTiers={loyaltyTiers}
                setParentLoading={setLoading}
              />
              <View style={{ marginBottom: -10 }} />
              <PromotedGallery
                hideTitle
                imgStyle={{ height: 180, width: 290 }}
                onNavigate={onNavigate}
              />
              <View style={{ marginTop: -20 }} />
              {hideDisctributors ? (
                <PopularBrands catalog={catalog} galleryName="RECENT_BRANDS" />
              ) : (
                <DistributorsGallery
                  name="DISTRIBUTORS_GALLERY"
                  imgStyle={{
                    height: distributorPicSize,
                    width: distributorPicSize,
                  }}
                />
              )}

              {hideDisctributors ? (
                <Manufacturers
                  catalog={catalog}
                  galleryName="RECENT_MANUFACTURERS"
                />
              ) : (
                <>
                  <View style={{ marginTop: -20 }} />
                  <RegionFilter />
                </>
              )}

              <DealsComponent />
              <VerticalSpacing size={40} />
            </View>
          </ScrollView>
        </View>
      </KeyboardAvoidingView>
    </SearchableScreen>
  );
}
