import React, { useContext, useReducer } from "react";
import { View, FlatList, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import SearchableScreen from "../SearchableScreen/SearchableScreen";
import DealsComponent from "@presto-screen-components/DealsComponent/DealsComponent";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import _ from "lodash";
import I18n from "i18n-js";
import { VerticalSpacing } from "../../components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import utils from "../../../src/utils";
import RightPaneContext from "@presto-contexts/RightPaneContext";

const windowHeight = Dimensions.get("window").height;

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_TOP_CATEGORIES":
      return { ...state, top_level_categories: payload };

    case "SET_CATEGORY_COUNT":
      return {
        ...state,
        category_count: {
          ...state.category_count,
          ...{ [payload.cat_id]: payload.count },
        },
      };
  }
  return state;
}

export default function KeywordBrowse({ navigation, route }) {
  const { theme } = useContext(ThemeContext);
  const [state, dispatch] = useReducer(reducer, {
    category_count: {},
  });
  const { Navigator } = useContext(NavigatorContext);
  const { key, values, name, catalogId, onPressRegionName } = route.params;
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const isDesktop = utils.isDesktop();

  const onItemPress = (value) => {
    if (isDesktop) {
      setPaneState(false);
      onPressRegionName(value.key);
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: { [key]: value.key },
            catalogId: catalogId,
          },
        },
      });
    }
  };

  const onBack = () => {
    navigation.goBack();
  };

  const renderItem = ({ item: value }) => {
    return (
      <View key={value.key}>
        <RowButton
          title={value.key}
          rightTitle={value.doc_count}
          onPress={() => {
            onItemPress(value);
          }}
          showDisclosure={true}
          numberOfLines={2}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const onSearchFocus = () => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: {
          searchParams: {},
          focus: true,
          catalogId: catalogId,
        },
      },
    });
  };

  return (
    <SearchableScreen
      onFocus={onSearchFocus}
      canGoback={true}
      onBack={onBack}
      showSoftInputOnFocus={false}
      placeholder={I18n.t("screen_messages.common.search_with_query", {
        query: name || null,
      })}
    >
      <FlatList
        style={{ height: windowHeight - 100 }}
        contentContainerStyle={{ paddingBottom: 50 }}
        data={values}
        renderItem={renderItem}
        keyExtractor={(item) => item.key}
        ListFooterComponent={() => (
          <>
            <VerticalSpacing size={40} />
            <DealsComponent />
            <VerticalSpacing size={40} />
          </>
        )}
      />
    </SearchableScreen>
  );
}
