import React from "react";
import { View, StyleSheet, TextInput } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PrestoText from "@presto-components/PrestoText";
export default function TextInputComponent(props) {
  const { theme, fontSize } = props;
  const styles = getTheme(theme, props, fontSize);
  const showSoftInputOnFocus = props.showSoftInputOnFocus ?? true;

  return (
    <View style={[styles.container, props.style]}>
      {props?.labelText ? (
        <View style={{ ...styles.labelContainer }}>
          <PrestoText extraStyle={styles.label}>
            {props?.labelText || ""}
          </PrestoText>
        </View>
      ) : null}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          ...props?.textContainerStyles,
        }}
      >
        <TextInput
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          spellCheck={false}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          value={props.value}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          multiline={props.multiline}
          numberOfLines={props.numberOfLines}
          placeholderTextColor={
            props.placeholderTextColor || styles.placeHolder.color
          }
          secureTextEntry={props.secureTextEntry}
          {...props}
          style={{
            paddingRight: props.icon && 40,
            ...styles.textInputStyle,
            height: props?.textInputHeight,
            paddingTop: 15,
            paddingBottom: 15,
            borderRadius: props?.borderRadius,
          }}
          autoFocus={props.autoFocus || false}
          showSoftInputOnFocus={showSoftInputOnFocus}
        />
        {props.icon && (
          <TouchableOpacity
            onPress={props.onEyePress}
            style={{ position: "absolute", right: 5 }}
          >
            {props.icon}
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

function getTheme(theme, props, fontSize) {
  return {
    container: {
      width: "100%",
    },
    labelContainer: {
      width: "100%",
      flexDirection: "row",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    label: {
      fontSize: 12,
      width: "100%",
      color: theme.textInputLabelColor,
    },
    textInputStyle: {
      width: "100%",
      borderWidth: props?.borderWidth ? props?.borderWidth : 1,
      fontSize: props?.textInputfontSize || fontSize ? 14 : 16,
      fontWeight: props?.textInputFontWeight || "normal",
      paddingHorizontal: props?.textInputPaddingHorizontal || 10,
      color: theme.textInputColor,
      fontFamily: theme.primaryMediumFont,
      textAlignVertical: "top",
      height: 70,
      borderRadius: props?.textInputBorderRadius || 4,
      backgroundColor: props?.backgroundColor
        ? props?.backgroundColor
        : theme.textInputBgColor,
      borderColor: props?.borderColor || theme.textInputBgColor,
    },
    placeHolder: {
      color: "#999999",
    },
  };
}
