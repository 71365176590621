import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";

const LoyaltyTierResource = function ($http, PrestoSdk) {
  function listTiers(params = {}) {
    var url = `${PrestoSdk.getHostName()}/user/v1/loyalty_tiers.json`;
    return $http.get(url);
  }

  function currentUserProgress(params = {}) {
    var url = `${PrestoSdk.getHostName()}/user/v1/loyalty_tiers/my_progress.json`;
    return $http.get(url);
  }

  return {
    listTiers,
    currentUserProgress,
  };
};

export default LoyaltyTierResource($http, PrestoSdk);
