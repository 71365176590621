import React, { useState, useContext, useEffect } from "react";
import {
  View,
  SafeAreaView,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import { Card } from "@presto-components/Cards/Cards";
import SearchManager from "@presto-services/features/search/SearchManager";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import _ from "lodash";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import utils from "../../utils/index";
import { VerticalSpacing } from "@presto-components/Spacing";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
    case "SET_CATEGORY_ITEMS":
      return { ...state, items: payload };
  }
  return state;
}

function ItemCard({ item, variation, maxCount, currentCount, onUpdateCount }) {
  const { svgs } = useAssets();
  const product = item;
  const { theme } = useContext(ThemeContext);
  const [count, setCount] = useState(currentCount);

  const onAdd = () => {
    if (count >= maxCount) return;
    setCount(count + 1);
    onUpdateCount(item.id, variation?.id, count + 1);
  };

  const onSubtract = () => {
    if (count <= 0) return;
    setCount(count - 1);
    onUpdateCount(item.id, variation?.id, count - 1);
  };
  return (
    <TouchableWithoutFeedback>
      <View style={{ paddingVertical: 10 }} key={item._id}>
        <FullWidthHorizontalPaddedBox>
          <Card padding={10}>
            <View style={{ flexDirection: "row" }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ flex: 1 }}>
                  <PrestoText
                    fontStyle={"semibold"}
                    color={theme.darkText}
                    size={theme.h5FontSize}
                  >
                    {product.name}
                  </PrestoText>
                  <PrestoText color={theme.darkText} size={theme.h5FontSize}>
                    {product.other_data?.company_name}
                  </PrestoText>
                  <VerticalSpacing size={10} />
                </View>
                <View style={{}}>
                  <PrestoText color={theme.tertiaryTextColor} size={10}>
                    {product.other_data?.molecule}
                  </PrestoText>
                </View>
              </View>
              <View
                style={{
                  width: 150,
                  flexDirection: "column",
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                }}
              >
                <PrestoText
                  fontStyle={"semibold"}
                  color={theme.darkText}
                  size={theme.h5FontSize}
                >
                  {variation
                    ? `${utils.formattedPrice(product.price.total_price)}/${
                        variation.value
                      }`
                    : utils.formattedPrice(product.price.total_price)}
                </PrestoText>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  {count == 0 ? (
                    <TouchableWithoutFeedback onPress={() => onAdd(item)}>
                      <View style={{ width: 40, height: 40 }}>
                        <PrestoIcon
                          icon={<svgs.PlusIcon primaryColor={theme.primary} />}
                        />
                      </View>
                    </TouchableWithoutFeedback>
                  ) : (
                    <>
                      <TouchableWithoutFeedback
                        onPress={() => onSubtract(item)}
                      >
                        <View
                          style={{
                            width: 40,
                            height: 40,
                          }}
                        >
                          <PrestoIcon
                            icon={<svgs.MinusIcon color={theme.primary} />}
                          />
                        </View>
                      </TouchableWithoutFeedback>
                      <View
                        style={{
                          width: 40,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <PrestoText
                          fontStyle={"semibold"}
                          color={theme.primary}
                          size={16}
                        >
                          {count}
                        </PrestoText>
                      </View>
                      <TouchableWithoutFeedback onPress={() => onAdd(item)}>
                        <View
                          style={{
                            width: 40,
                            height: 40,
                          }}
                        >
                          <PrestoIcon
                            icon={
                              <svgs.PlusIcon primaryColor={theme.primary} />
                            }
                          />
                        </View>
                      </TouchableWithoutFeedback>
                    </>
                  )}
                </View>
              </View>
            </View>
          </Card>
        </FullWidthHorizontalPaddedBox>
      </View>
    </TouchableWithoutFeedback>
  );
}

export default function Home({ navigation }) {
  const { theme } = useContext(ThemeContext);
  const { cart, getCountOfItemInCart, updateItemInCart } = useContext(
    CartContext
  );
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [itemCounts, setItemCounts] = useState([]);

  let categoryItemIds = [];
  let variationIds = [];
  if (cart.discount_response?.discountable_type === "category_item") {
    categoryItemIds = cart.discount_response?.discountable_item_ids || [];
  }
  if (cart.discount_response?.discountable_type === "variation") {
    variationIds = cart.discount_response?.discountable_item_ids || [];
  }

  const itemIds = cart.discount_response?.discountable_item_ids || [];

  const getItems = () => {
    SearchManager.searchCategoryItem(
      { ids: categoryItemIds, variation_ids: variationIds },
      (response) => {
        const items = _.map(response.hits.hits, (item) => item._source);
        setItems(items);
        setLoading(false);
      },
      (error) => {
        alert(I18n.t("screen_messages.error_messages.error_loading_free_str"));
      }
    );
  };

  useEffect(() => {
    getItems();
  }, [cart]);

  const findItemForId = (itemId) => {
    if (cart.discount_response?.discountable_type === "category_item") {
      const item = _.find(items, (item) => item.id == itemId);
      return [item, null];
    }
    if (cart.discount_response?.discountable_type === "variation") {
      for (const item of items) {
        const variation = _.find(
          item.variations || [],
          (variation) => variation.id === itemId
        );
        if (variation) {
          return [item, variation];
        }
      }
    }
  };
  const onUpdateCount = (itemId, variationId, count) => {
    const remaining = _.filter(
      itemCounts,
      (ic) => !(ic.itemId == itemId && ic.variationId == variationId)
    );
    setItemCounts([...remaining, { itemId, variationId, count }]);
  };

  const renderItem = (itemId) => {
    const [item, variation] = findItemForId(itemId);
    if (!item) return null;
    if (item) {
      return (
        <ItemCard
          key={item._id}
          item={item}
          variation={variation}
          currentCount={0}
          maxCount={cart.discount_response?.discountable_quantity || 0}
          onUpdateCount={onUpdateCount}
        ></ItemCard>
      );
    } else {
      return null;
    }
  };

  const onSaveToCart = async () => {
    setLoading(true);

    for (const item of itemCounts) {
      const count = getCountOfItemInCart(item.itemId, item.variationId);
      await updateItemInCart(item.itemId, item.variationId, count + item.count);
    }
    setLoading(true);
    navigation.goBack();
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBack
          title={I18n.t("screen_messages.payments.select_payment_method")}
        />
        <ScrollView>
          {loading ? null : (
            <>
              <VerticalSpacing size={20} />
              <FullWidthHorizontalPaddedBox>
                <PrestoSolidButton
                  size="normal"
                  title={I18n.t("screen_messages.cart.claim_free_items")}
                  onPress={() => onSaveToCart()}
                  buttonStyle="primary"
                />
                <VerticalSpacing size={20} />
                <PrestoText
                  size={theme.h6FontSize}
                  color={theme.tertiaryTextColor}
                >
                  {I18n.t("screen_messages.cart.free_items_remaining", {
                    count: cart.discount_response?.discountable_quantity || 0,
                  })}
                </PrestoText>
              </FullWidthHorizontalPaddedBox>
              {_.map(itemIds, (itemId) => {
                return renderItem(itemId);
              })}
            </>
          )}
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}
