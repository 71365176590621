import React, { useContext, useState, useEffect } from "react";
import {
  View,
  Image,
  Pressable,
  FlatList,
  TouchableOpacity,
  ScrollView,
  StyleSheet,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import I18n from "i18n-js";
import SearchInput from "@presto-components/TextInputs/SearchInput";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import SearchManager from "@presto-services/features/search/SearchManager";
import PrestoIcon from "@presto-components/PrestoIcon";
import { toUpper } from "lodash";
import _ from "lodash";
import AddressCard from "@presto-cards/AddressCard/AddressCard";
import SelectedCheckbox from "@presto-components/SelectedCheckBox";
import UnSelectedCheckbox from "@presto-components/UnSelectedCheckBox";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import AddressCardModel from "@presto-component-models/AddressCardModel";
import { useAssets } from "presto-react-components";
import { useCatalog } from "@presto-stores/CatalogStore";
import asyncify from "@presto-common/Asyncify";

const AsyncSearchManager = asyncify(SearchManager);

export default function WebHeader({
  onNavigate,
  catalogId,
  active,
  orderNowClicked,
}) {
  const { images, svgs } = useAssets();
  const catalog = useCatalog((state) => state.catalog);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [autoCompleteResults, setAutoCompleteResults] = useState(null);
  const { theme } = useContext(ThemeContext);
  const { setPaneState } = useContext(RightPaneContext);
  const [activeTab, setActiveTab] = useState("HOME");
  const [showAddress, setShowAddress] = useState(false);

  const { user, currentAddress, setCurrentAddress } = useContext(UserContext);
  const { cart } = useContext(CartContext);

  const styles = getStyle(theme);
  const onSearchWithQuery = () => {
    onSearch(searchQuery);
  };

  const onSearch = (searchProps) => {
    setPaneState(false);
    WebNavigator.emit({
      event: "replace",
      params: {
        screenName: "SEARCH_RESULTS_HOME",
        screenParams: { ...{ query: searchProps || searchQuery } },
      },
    });
  };

  useEffect(() => {
    setActiveTab(active);
  }, [active]);

  function _onSearchQueryChange(text) {
    setPaneState(false);
    setSearchQuery(text);
    loadAutoComplete2(text);
  }

  const loadAutoComplete = (text) => {
    const params = {
      search_string: text,
      page: 1,
      fuzziness: "AUTO",
      catalog_id: catalog?.id,
    };

    SearchManager.fuzzySearchCategoryItem(
      params,
      (response) => {
        setAutoCompleteResults(_.slice(response.hits.hits, 0, 7));
      },
      () => {}
    );
  };

  const loadAutoComplete2 = (text) => {
    const params = {
      name: text,
      catalog_id: catalogId || catalog?.id,
      is_available: true,
      brand_name: text,
      molecule: text,
    };
    return AsyncSearchManager.strictSearch(params)
      .then(([error, response]) => {
        let items = _.get(response, "hits.hits", []);
        return items;
      })
      .then((items) => {
        setAutoCompleteResults(_.slice(items, 0, 7));
      });
  };

  const RenderItem = ({
    icon,
    title,
    onPress,
    floatingCount,
    counts,
    activeStyles,
  }) => {

    return (
      <Pressable onPress={onPress}>
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            marginHorizontal: 5,
            width: 80,
          }}
        >
          {floatingCount && counts > 0 && (
            <View
              style={{
                position: "absolute",
                top: 0.5,
                right: 10,
                backgroundColor: theme.primary,
                borderWidth: 1,
                borderColor: theme.white,
                height: 23,
                width: 23,
                borderRadius: 25,
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
              }}
            >
              <PrestoText
                fontStyle="bold"
                color={theme.white}
                size={10}
                extraStyle={{
                  padding: 5,
                }}
              >
                {counts}
              </PrestoText>
            </View>
          )}
          <PrestoIcon
            icon={icon}
            style={{
              width: 40,
              height: 40,
              alignItems: "center",
            }}
          />
          <PrestoText
            fontStyle="bold"
            color={activeStyles.color ?? theme.white}
            size={10}
            extraStyle={{
              textAlign: "center",
              backgroundColor: activeStyles.backgroundColor ?? null,
              borderRadius: activeStyles.borderRadius ?? null,
              paddingHorizontal: activeStyles.paddingHorizontal ?? null,
            }}
          >
            {title}
          </PrestoText>
        </View>
      </Pressable>
    );
  };

  const options = {
    FAVOURITES: "FAVOURITES",
    CART: "CART",
    HOME: "HOME",
    ACCOUNT: "ACCOUNT",
  };

  const onPressItem = (option) => {
    setActiveTab(option);
    if (orderNowClicked) {
      orderNowClicked(option);
    }
    onNavigate(options[toUpper(option)]);
  };

  const activeTabStyle = {
    backgroundColor: theme.white,
    color: theme.primary,
    paddingHorizontal: 5,
    borderRadius: 10,
  };

  useEffect(() => {
    if (user) {
      const address = _.first(user.addresses);
      setCurrentAddress(address);
    }
  }, [user]);
  const remainingAddresses = currentAddress
    ? _.filter(user.addresses || [], (add) => add.id !== currentAddress?.id)
    : [];

  console.log("catalog===>", catalog);

  const RenderSelectAddress = () => {
    const AddressDropDown = () => {
      return (
        <View style={styles.addressDropdownContainer}>
          <View style={styles.AddressContainer}>
            <ScrollView>
              {remainingAddresses.map((add) => (
                <TouchableOpacity
                  activeOpacity={0.5}
                  key={add.id}
                  onPress={() => {
                    setShowAddress(!showAddress);
                    setCurrentAddress(add);
                  }}
                  style={styles.AddressButtons}
                >
                  <View style={{ flex: 0.95 }}>
                    <AddressCard
                      address={new AddressCardModel({ address: add })}
                    />
                  </View>
                  {add.id == currentAddress.id ? (
                    <SelectedCheckbox />
                  ) : (
                    <UnSelectedCheckbox />
                  )}
                </TouchableOpacity>
              ))}
            </ScrollView>
          </View>
        </View>
      );
    };

    return (
      <TouchableOpacity
        activeOpacity={0.5}
        onPress={() => setShowAddress(!showAddress)}
      >
        <View
          style={{ width: 200, flexDirection: "row", alignItems: "center" }}
        >
          <View style={{ width: "85%" }}>
            <PrestoText
              fontStyle="semibold"
              color={theme.white}
              size={12}
              extraStyle={{ marginBottom: 3 }}
            >
              Delivering to Outlet
            </PrestoText>
            <PrestoText color={theme.white} size={12}>
              {currentAddress && currentAddress.line1}{" "}
              {currentAddress && currentAddress.line2}
            </PrestoText>
          </View>
          {remainingAddresses.length > 0 ? (
            <View>
              <PrestoIcon
                icon={
                  <svgs.DownArrow color={theme.white} width="18" height="18" />
                }
              />
            </View>
          ) : null}
        </View>
        {showAddress && remainingAddresses.length > 0 && AddressDropDown()}
      </TouchableOpacity>
    );
  };
  const onSearchItemPress = (text) => {
    console.log("text==", text);
    onSearch(text);
  };
  const renderAutoCompleteItem = ({ item, index }) => {
    return (
      <View key={`index-${index}-query`}>
        <RowButton
          title={item._source.name}
          rightTitle={""}
          onPress={() => {
            onSearchItemPress(item._source.name);
          }}
          showDisclosure={false}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </View>
    );
  };

  const renderAutoComplete = () => {
    return (
      <View style={styles.autoComplete}>
        <View style={styles.autoCompleteContainer}>
          <FlatList
            keyExtractor={(_, index) => "index-" + index}
            data={autoCompleteResults}
            renderItem={renderAutoCompleteItem}
            layout={"default"}
            showsHorizontalScrollIndicator={false}
          />
        </View>
      </View>
    );
  };
  const onPressLogo = () => {
    setPaneState(false);
    WebNavigator.emit({
      event: "replace",
      params: {
        screenName: "HOME",
        screenParams: {
          is_new: "true",
        },
      },
    });
  };
  
  return (
    <View
      style={{
        backgroundColor: theme.primary,
        paddingHorizontal: 20,
        flexDirection: "row",
        minHeight: 80,
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Pressable onPress={() => onPressLogo()}>
        <View style={{ width: 60 }}>
          <Image source={images.Icon} style={{ width: 60, height: 60 }} />
        </View>
      </Pressable>
      <View
        style={{
          width: "80%",
          flexDirection: "row-reverse",
          alignItems: "center",
        }}
      >
        <View
          style={{
            alignItems: "center",
            marginLeft: 20,
            flexDirection: "row",
          }}
        >
          {user && user.addresses.length > 0 ? (
            <View>
              <RenderSelectAddress />
            </View>
          ) : null}
          <View
            style={{
              height: 70,
              backgroundColor: "white",
              width: 1,
              marginHorizontal: 8,
            }}
          ></View>
          <RenderItem
            icon={<svgs.HomeIconV2 primaryColor={theme.white} />}
            title={I18n.t("screen_messages.tab_text.tab1")}
            onPress={() => onPressItem("HOME")}
            activeStyles={activeTab === "HOME" ? activeTabStyle : {}}
          />
          <RenderItem
            icon={<svgs.BagIcon primaryColor={theme.white} />}
            title={I18n.t("screen_messages.tab_text.tab4")}
            onPress={() => onPressItem("FAVOURITES")}
            activeStyles={activeTab === "FAVOURITES" ? activeTabStyle : {}}
          />
          <RenderItem
            icon={<svgs.CartIcon color={theme.white} height={45} width={45} />}
            title={I18n.t("screen_messages.tab_text.tab3")}
            onPress={() => onPressItem("CART")}
            activeStyles={activeTab === "CART" ? activeTabStyle : {}}
            floatingCount
            counts={cart !== null && cart.item_count}
          />

          <RenderItem
            icon={<svgs.ProfileIconOutlined primaryColor={theme.white} />}
            title={I18n.t("screen_messages.tab_text.tab5")}
            onPress={() => onPressItem("ACCOUNT")}
            activeStyles={activeTab === "ACCOUNT" ? activeTabStyle : {}}
          />
        </View>
        <View style={{ width: "50%", maxWidth: 500 }}>
          <SearchInput
            onSearch={onSearch}
            placeholder={I18n.t(
              "screen_messages.common.search_by_placeholder_options.home"
            )}
            value={searchQuery}
            onChange={_onSearchQueryChange}
            containerStyle={{
              backgroundColor: theme.white,
            }}
            onSearchFocus={(value) => {
              if (searchQuery.length > 0) {
                onSearch();
              }
            }}
            icon={
              <svgs.SearchIconOutlined
                color={theme.placeHolderColor}
                width={40}
                height={40}
                key={"placeholder"}
                opacity={1}
              />
            }
            textInputStyle={{
              paddingLeft: theme.primaryPadding,
            }}
          />
          {autoCompleteResults?.length && searchQuery && searchQuery.length > 0
            ? renderAutoComplete()
            : null}
        </View>
      </View>
    </View>
  );
}

function getStyle(theme) {
  return {
    AddressButtons: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      borderBottomWidth: 1,
      borderBottomColor: "#DADADA",
    },
    addressDropdownContainer: {
      backgroundColor: "transparent",
      position: "absolute",
      height: 350,
      top: 80,
      left: 0,
      width: "100%",
      minWidth: 350,
      flexDirection: "column",
      alignItems: "center",
      zIndex: 1000,
    },
    AddressContainer: {
      backgroundColor: "white",
      position: "absolute",
      height: "100%",
      width: "90%",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "lightgray",
      overflow: "hidden",
    },
    autoComplete: {
      backgroundColor: "transparent",
      position: "absolute",
      height: 300,
      top: 50,
      left: 0,
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 1000,
    },
    autoCompleteContainer: {
      backgroundColor: "white",
      position: "absolute",
      height: "100%",
      width: "95%",
      borderRadius: 10,
      borderWidth: 1,
      borderColor: "lightgray",
    },
  };
}
