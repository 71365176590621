import React, { useState, useContext } from "react";
import { View, Platform } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import SearchHeader from "@presto-screen-components/SearchableScreen/SearchHeader";
import SearchHeaderWithBack from "@presto-components/Headers/SearchHeaderWithBack";
import SearchHeaderWithBackAndOptions from "@presto-components/Headers/SearchHeaderWithBackAndOptions";
import { useSafeArea } from "react-native-safe-area-context";
import { useMediaQuery } from "react-responsive";
import UserContext from "@presto-contexts/UserContext";
import GradientBackgroundForHeader from "@presto-screen-components/SearchableScreen/GradientBackgroundForHeader";
import { useAssets } from "presto-react-components";
import UserModel from "@presto-component-models/UserModel";
import utils from "../../utils";

export default function SearchableScreen({
  children,
  placeholder,
  canGoback,
  showOptions,
  onBack,
  onSearch,
  query,
  filterEnabled,
  sortEnabled,
  onSort,
  onFilter,
  onFocus,
  inputref,
  showSoftInputOnFocus,
  onSearchChange,
  searchContainerStyle = {},
  searchMobileContainerStyle = {},
}) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const userModel = new UserModel({ user });
  const [searchFocus, setSearchFocus] = useState(false);
  const insets = useSafeArea();
  const isLoyaltyTierValid = userModel.isLoyaltyTierValid;

  const onBeginSearch = () => {
    onFocus && onFocus();
    setSearchFocus(true);
  };

  const onEndSearchFocusEnd = () => {
    setSearchFocus(false);
  };

  if (Platform.OS === "web" && useMediaQuery({ minWidth: 941 })) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: theme.modalBackgroundColor,
          paddingTop: Platform.OS === "web" ? insets.top + 30 : insets.top,
          paddingBottom: 0,
          ...searchContainerStyle,
        }}
      >
        {children}
      </View>
    );
  }

  const Icon = (
    <svgs.SearchIconOutlined
      color={theme.placeHolderColor}
      width={40}
      height={40}
      key={"placeholder"}
      opacity={1}
    />
  );

  const containerStyle = {
    backgroundColor: isLoyaltyTierValid ? theme.white : theme.textInputBgColor,
    paddingLeft: theme.primaryPadding,
  };

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "transparent",
        paddingTop: Platform.OS === "web" ? insets.top + 30 : insets.top,
        paddingBottom: 100,
        ...(utils.isMobileWeb() && {
          backgroundColor: theme.modalBackgroundColor,
        }),
        ...searchMobileContainerStyle,
      }}
    >
      <GradientBackgroundForHeader user={user} />
      <View>
        {showOptions ? (
          <SearchHeaderWithBackAndOptions
            onSearch={onSearch}
            onSearchFocus={onBeginSearch}
            onEndSearchFocusEnd={onEndSearchFocusEnd}
            placeholder={placeholder}
            sortEnabled={sortEnabled}
            filterEnabled={filterEnabled}
            onBack={onBack}
            onSort={onSort}
            onFilter={onFilter}
            query={query}
            inputref={inputref}
            showSoftInputOnFocus={showSoftInputOnFocus}
            onSearchChange={onSearchChange}
            backIconColor={isLoyaltyTierValid ? theme.white : theme.primary}
            filterIconColor={isLoyaltyTierValid ? theme.white : null}
            sortIconColor={isLoyaltyTierValid ? theme.white : null}
            icon={Icon}
            containerStyle={containerStyle}
          />
        ) : canGoback ? (
          <SearchHeaderWithBack
            onSearch={onSearch}
            onSearchFocus={onBeginSearch}
            onEndSearchFocusEnd={onEndSearchFocusEnd}
            placeholder={placeholder}
            onBack={onBack}
            query={query}
            inputref={inputref}
            showSoftInputOnFocus={showSoftInputOnFocus}
            onSearchChange={onSearchChange}
            backIconColor={isLoyaltyTierValid ? theme.white : theme.primary}
            icon={Icon}
            containerStyle={containerStyle}
          />
        ) : (
          <SearchHeader
            onSearch={onSearch}
            onSearchFocus={onBeginSearch}
            onEndSearchFocusEnd={onEndSearchFocusEnd}
            placeholder={placeholder}
            query={query}
            inputref={inputref}
            showSoftInputOnFocus={showSoftInputOnFocus}
            onSearchChange={onSearchChange}
            icon={Icon}
            containerStyle={containerStyle}
          />
        )}
      </View>
      <View style={{ backgroundColor: theme.white, height: "100%" }}>
        {children}
        {false && (
          <View
            style={{
              width: "100%",
              position: "absolute",
              backgroundColor: "white",
              height: 200,
              top: insets.top + 45,
            }}
          ></View>
        )}
      </View>
    </View>
  );
}
