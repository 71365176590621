import React from "react";
import { WalletCards } from "presto-react-components";
import I18n from "i18n-js";
import config from "@presto-common/config";
import utils from "../../utils/index";
import _ from "lodash";

export default function WalletCard(props) {
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
  let points = props.lastCredit?.amount / 100;
  let forwardProps = {
    ...props,
    your_points_title: I18n.t("screen_messages.wallet.your_points_label"),
    last_transaction_prefix: I18n.t(
      "screen_messages.wallet.last_transaction_prefix"
    ),
    last_transaction_bold: I18n.t(
      "screen_messages.wallet.last_transaction_bold",
      {
        points: noRoundOff
          ? utils.formattedPriceRoundOff.call(
              {
                currency: "",
              },
              points.toFixed(2),
              noRoundOff
            )
          : Math.round(points),
      }
    ),
    last_transaction_suffix: I18n.t(
      "screen_messages.wallet.last_transaction_suffix"
    ),
    actionTitle:
      props.actionTitle ??
      I18n.t("screen_messages.transactions.view_transactions"),
  };

  return <WalletCards.WalletCard1 {...forwardProps} />;
}
