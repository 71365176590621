import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Platform } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import { RowButton } from "@presto-components/PrestoButton";
import { VerticalSpacing } from "@presto-components/Spacing";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import TitleHeaderWithBack from "@presto-components/Headers/TitleHeaderWithBack";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import AddressCard from "@presto-cards/AddressCard/AddressCard";
import _ from "lodash";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import SelectedCheckbox from "@presto-components/SelectedCheckBox";
import UnSelectedCheckbox from "@presto-components/UnSelectedCheckBox";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartContext from "@presto-contexts/CartContext";
import * as Linking from "expo-linking";
import config from "@presto-common/config";
import PrestoTextInput from "@presto-components/TextInputs/PrimaryTextInput";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import AddressCardModel from "@presto-component-models/AddressCardModel";
import { useAssets } from "presto-react-components";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import utils from "../../utils/index";
import AnalyticsManager from "../../common/AnalyticsManager";

function AddressButton({ address, selected, onPress }) {
  const { theme } = useContext(ThemeContext);

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          backgroundColor: "transparent",
        }}
      >
        <View style={{ flex: 1 }}>
          <AddressCard address={new AddressCardModel({ address })} />
        </View>
        {selected ? (
          <View
            style={{
              alignContent: "flex-end",
              width: 50,
            }}
          >
            <SelectedCheckbox />
          </View>
        ) : (
          <View
            style={{
              alignContent: "flex-end",
              width: 50,
            }}
          >
            <UnSelectedCheckbox />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}

export default function CartCheckoutScreen() {
  const { svgs } = useAssets();
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(false);
  const [showCoupons, setShowCoupons] = useState(false);

  const {
    cart,
    applyCouponToCart,
    removeCouponFromCart,
    checkoutCart,
    placeOrder,
    clearCart,
  } = useContext(CartContext);
  const [couponCode, setCouponCode] = useState(cart?.coupon_code || "");

  const onCouponApply = async () => {
    AnalyticsManager.send("coupon_apply");
    if (!couponCode || couponCode == "") {
      return;
    }
    setLoading(true);
    const [error, response] = await applyCouponToCart(couponCode);
    if (error) {
      alert(utils.errorMessage(error));
      removeCouponFromCart(couponCode);
    } else {
      if (response.data && !response.data.discount_response) {
        alert(I18n.t("screen_messages.coupons.coupon_not_applied"));
        removeCouponFromCart(couponCode);
      } else {
        alert(I18n.t("screen_messages.coupons.coupon_applied"));
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    setCouponCode(cart?.coupon_code);
  }, [cart?.coupon_code]);

  useEffect(() => {
    showCouponSection();
  }, [cart]);

  const showCouponSection = () => {
    _.map(cart?.items, (cartItem) => {
      if (cartItem.other_data.is_gift) {
        setShowCoupons(false);
      } else {
        setShowCoupons(true);
      }
    });
  };

  const lastUsedAddress = undefined;
  const remainingAddresses = _.filter(
    user.addresses,
    (add) => add.id !== lastUsedAddress?.id
  );
  const [selectedAddress, setSelectedAddress] = useState(
    lastUsedAddress || remainingAddresses[0]
  );

  const gotoPayment = async () => {
    if (selectedAddress) {
      Navigator.emit({
        event: "replace",
        params: {
          screenName: "CART_PAYMENT_SCREEN",
          screenParams: { address: selectedAddress },
        },
      });
    } else {
      alert(I18n.t("screen_messages.location.no_address_selected"));
    }
  };

  const onCartCheckout = async (payment = "WALLET") => {
    AnalyticsManager.send("cart_checkout");
    setLoading(true);
    const [error, response] = await checkoutCart({
      address: selectedAddress,
      instructions: "",
      payment_type: payment != "COD" ? "ONLINE" : payment,
      delivery_type: "Delivery",
      other_data: {},
      source: "WEBSITE",
      instructions: "",
    });

    if (error) {
      console.log(
        "Error in checking out ",
        error,
        JSON.stringify({
          address: selectedAddress,
          instructions: "",
          payment_type: payment === "WALLET" ? "ONLINE" : payment,
          delivery_type: "Delivery",
          other_data: {},
          source: "WEBSITE",
          instructions: "",
        })
      );
      setLoading(false);
      alert("Error in checking out");
    } else {
      setLoading(false);
      onPlaceOrder(payment, response.data);
    }
  };

  const onPlaceOrder = async (payment, order) => {
    const [error, response] = await placeOrder({});

    setLoading(true);
    if (error) {
      console.log("Error in place order : ", error);
      alert("Error in placing order");
      setLoading(false);
    } else {
      if (payment === "WALLET") {
        WalletManager.makePayment(
          { id: response.data.id, id_type: "order_id" },
          (response) => {
            setLoading(false);
            clearCart();
            Navigator.emit({
              event: "replace",
              params: {
                screenName: "ORDER_SUCCESSFUL_SCREEN",
              },
            });
            setLoading(false);
          },
          (error) => {
            setLoading(false);
            alert(utils.errorMessage(error));
            clearCart();
            setTimeout(() => {
              onGoToHome();
            }, 2000);
          }
        );
      }
    }
  };

  const onGoToHome = () => {
    setPaneState(false);
    Navigator.emit({
      event: "push",
      params: {
        screenName: "HOME",
      },
    });
  };

  const onCouponCodeChange = ({ nativeEvent: { text } }) => {
    setCouponCode(text);
  };

  const onRequestANewOutlet = () => {
    AnalyticsManager.send("add_new_address");
    Linking.openURL(config.constants.request_outlet_url);
  };

  const showCouponsScreen = () => {
    AnalyticsManager.send("browse_coupons");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "OFFERS_SCREEN",
      },
    });
  };

  const onClaimFreeItems = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "FREE_ITEMS_SCREEN",
      },
    });
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.select_coupon_and_outlet_title")}
        />
      </SafeAreaView>

      <ScrollView
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
        }}
      >
        {showCoupons ? (
          <>
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <PrestoText
                fontStyle={"semibold"}
                color={theme.primary}
                size={16}
                extraStyle={{
                  paddingTop:
                    Platform.OS === "android" ? theme.primaryPadding : 0,
                }}
              >
                {I18n.t("screen_messages.coupons_str")}
              </PrestoText>

              <View style={{ flex: 1, flexDirection: "row", width: "100%" }}>
                <View
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    paddingTop: 20,
                  }}
                >
                  <PrestoTextInput
                    theme={theme}
                    placeholder={I18n.t("screen_messages.coupons.code_title")}
                    value={couponCode}
                    onChange={onCouponCodeChange}
                    backgroundColor={"transparent"}
                  />
                </View>
                <View
                  style={{
                    paddingHorizontal: theme.primaryPadding,
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <VerticalSpacing size={20} />

                  <View
                    style={{ flex: 1, flexDirection: "row", width: "100%" }}
                  >
                    <View
                      style={{
                        height: 40,
                        justifyContent: "center",
                      }}
                    >
                      <TouchableWithoutFeedback
                        onPress={() => {
                          if (
                            !(
                              cart.coupon_code &&
                              cart.coupon_code === couponCode
                            )
                          )
                            onCouponApply();
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <PrestoText
                            fontStyle={"medium"}
                            color={theme.primary}
                            size={16}
                            extraStyle={{
                              cursor: "pointer",
                            }}
                          >
                            {cart.coupon_code && cart.coupon_code === couponCode
                              ? I18n.t("screen_messages.common.applied")
                              : I18n.t("screen_messages.common.apply")}
                          </PrestoText>
                          <View style={{ alignContent: "flex-end" }}>
                            <PrestoIcon
                              icon={
                                <svgs.RightArrow primaryColor={theme.primary} />
                              }
                            />
                          </View>
                        </View>
                      </TouchableWithoutFeedback>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <RowButton
              title={I18n.t("screen_messages.coupons.browse_coupons")}
              showDisclosure={true}
              onPress={() => showCouponsScreen()}
            />
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />

            {(cart.discount_response?.discountable_item_ids?.length || 0) >
            0 ? (
              <>
                <VerticalSpacing size={10} />
                <RowButton
                  title={I18n.t("screen_messages.cart.claim_free_items")}
                  showDisclosure={true}
                  onPress={() => showCouponsScreen()}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />

                {(cart.discount_response?.discountable_item_ids?.length || 0) >
                0 ? (
                  <>
                    <VerticalSpacing size={10} />
                    <RowButton
                      title={I18n.t("screen_messages.claim_free_items")}
                      showDisclosure={true}
                      onPress={onClaimFreeItems}
                    />
                    <VerticalSpacing size={10} />
                    <LineDivider
                      dividerColor="#DADADA"
                      width="100%"
                      theme={theme}
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
        {lastUsedAddress ? (
          <>
            <View
              style={{
                paddingHorizontal: theme.primaryPadding,
                height: 50,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <PrestoText
                fontStyle={"semibold"}
                color={theme.primary}
                size={16}
              >
                {I18n.t("screen_messages.outlets.last_used_outlet")}
              </PrestoText>
            </View>
            <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
            <AddressButton
              address={lastUsedAddress}
              selected={selectedAddress?.id === lastUsedAddress.id}
              onPress={() => {
                AnalyticsManager.send("address_button");
                setSelectedAddress(lastUsedAddress);
              }}
            />
          </>
        ) : null}
        <>
          <View
            style={{
              paddingHorizontal: theme.primaryPadding,
              height: 50,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <PrestoText fontStyle={"semibold"} color={theme.primary} size={16}>
              {lastUsedAddress
                ? I18n.t("screen_messages.outlets.other_outlets")
                : I18n.t("screen_messages.outlets")}
            </PrestoText>
          </View>
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          {_.map(remainingAddresses, (add) => {
            return (
              <View key={add.id}>
                <AddressButton
                  address={add}
                  selected={selectedAddress?.id === add.id}
                  onPress={() => {
                    AnalyticsManager.send("address_button");
                    setSelectedAddress(add);
                  }}
                />
                <LineDivider
                  dividerColor="#DADADA"
                  width="100%"
                  theme={theme}
                />
              </View>
            );
          })}
        </>
        <VerticalSpacing size={10} />
        <RowButton
          title={I18n.t("screen_messages.add_new")}
          showDisclosure={true}
          onPress={onRequestANewOutlet}
        />
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
        <VerticalSpacing size={100} />
      </ScrollView>
      <View
        style={{
          width: "100%",
          height: 60,
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      >
        <FullWidthHorizontalPaddedBox>
          <PrestoSolidButton
            size="large"
            title={I18n.t("screen_messages.cart.checkout")}
            buttonStyle="primary"
            titleExtraStyle={{ textTransform: "uppercase" }}
            onPress={showCoupons ? gotoPayment : onCartCheckout}
            titleColor={theme.white}
          />
        </FullWidthHorizontalPaddedBox>
      </View>
    </LoadingContainer>
  );
}
