import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Platform,
  Dimensions,
  TouchableOpacity,
  Pressable,
  ScrollView,
} from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import I18n from "i18n-js";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import LoadingContainer from "@presto-components/LoadingContainer";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import moment from "moment";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import SearchManager from "@presto-services/features/search/SearchManager";
import config from "@presto-common/config";
import asyncify from "../../common/Asyncify";
const AsyncOfferManager = asyncify(OffersManager);
import { useAssets } from "presto-react-components";
import OffersManager from "@presto-services/features/offers/OffersManager";
import LoyaltyTierDetailCard2 from "@presto-cards/LoyaltyTierDetailCard/LoyaltyTierDetailCard2";
import LoyaltyTierIndicatorCard from "@presto-cards/LoyaltyTierIndicatorCard/LoyaltyTierIndicatorCard";
import BenefitsSection from "@presto-screen-components/LoyaltyPointsScreen/BenefitsSection";
import FAQSection from "@presto-screen-components/LoyaltyPointsScreen/FAQSection";
import LoyaltyTierManager from "@presto-services/features/loyalty_tier/LoyaltyTierManager";
import LoyaltyInfoStrip from "@presto-screen-components/LoyaltyPointsScreen/LoyaltyInfoStrip.ziphealth";
import { Components } from "presto-react-components";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import GiftRedemption from "@presto-screen-components/LoyaltyPointsScreen/GiftRedemption";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function LoyaltyPointsScreen(props) {
  const { Accordion1 } = Components.Accordions;

  const { typeOfTransaction = null } = props.route.params;
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [loading, setLoading] = useState(true);
  const [wallet, setWallet] = useState(null);
  const [loyaltyTiers, setLoyaltyTiers] = useState([]);
  const [userLoyaltyTierProgress, setUserLoyaltyTierProgress] = useState({});
  const [transactionType, setTransactionType] = useState(
    typeOfTransaction ?? "ALL"
  );
  const windowHeight = Dimensions.get("window").height;
  const windowWidth = Dimensions.get("window").width;
  const styles = getStyles(theme, windowWidth);
  const merchantId = config.merchant_id;
  const [giftItems, setGiftItems] = useState([]);
  const [redeemText, setRedeemText] = useState("");
  const { svgs } = useAssets();

  useEffect(() => {
    if (user) {
      setLoading(true);
      WalletManager.getWalletInfo(
        { walletId: user.wallet_id },
        (response) => {
          setLoading(false);
          setWallet(response.data);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [user]);

  useEffect(() => {
    getCatalog();
    getOffersText();
    listTiers();
    currentUserProgress();
  }, []);

  const listTiers = () => {
    function onSuccess(response) {
      setLoyaltyTiers(response.data);
      setLoading(false);
    }
    function onError(error) {
      console.log("LoyaltyTierManager -> listTiers -> error", error);
      setLoading(false);
    }
    setLoading(true);
    LoyaltyTierManager.listTiers({}, onSuccess, onError);
  };

  const currentUserProgress = () => {
    function onSuccess(response) {
      setUserLoyaltyTierProgress(response.data);
      setLoading(false);
    }
    function onError(error) {
      console.log("currentUserProgress -> listTiers -> error", error);
      setLoading(false);
    }
    setLoading(true);
    LoyaltyTierManager.currentUserProgress({}, onSuccess, onError);
  };

  const getCatalog = () => {
    var params = {
      merchant_id: merchantId,
      filter: "gifts",
    };

    CatalogManager.getFilterBasedCatalog(
      params,
      (response) => {
        if (response.data.id) {
          SearchManager.searchCategoryItem(
            {
              catalog_id: response.data.id,
              merchant_id: merchantId,
            },
            (response) => {
              setGiftItems(response.hits.hits);
            },
            () => {}
          );
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  const getOffersText = async () => {
    const [error, response] = await AsyncOfferManager.getHomePageOffers();
    if (response) {
      setRedeemText(response.data.offer_text);
    }
  };

  const walletInfo = wallet
    ? {
        [I18n.t("screen_messages.total_cashback_earned")]: (
          wallet.expiry_balance / 100
        ).toFixed(0),
        [I18n.t("screen_messages.total_cashback_spent")]: (
          wallet.total_cashback_received / 100
        ).toFixed(0),
      }
    : {};

  const showOrder = (orderId) => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "ORDER_DETAILS_SCREEN",
        screenParams: { orderId: orderId },
      },
    });
  };

  const _onSetActive = (str) => {
    setTransactionType(str);
  };

  const _isActive = (str) => {
    return str == transactionType;
  };

  const eventCallback = (key) => {
    if (key === "Benefits") {
      AnalyticsManager.send("loyalty_benefits");
    }
    if (key === "Gift redemption") {
      AnalyticsManager.send("loyalty_gifts");
    }
    if (key === "FAQs") {
      AnalyticsManager.send("loyalty_faqs");
    }
  };

  const onRedeem = () => {
    AnalyticsManager.send("redeem_points");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "REDEEM_POINTS_SCREEN",
        screenParams: {
          category_items: giftItems,
          wallet: wallet,
        },
      },
    });
  };

  const isAllDataForUserLoyaltyTierAvailable =
    user &&
    wallet &&
    !_.isEmpty(loyaltyTiers) &&
    !_.isEmpty(userLoyaltyTierProgress);

  const renderTransactionTypes = () => {
    return (
      <View
        style={[
          {
            overflow: "hidden",
            marginTop: 10,
            marginBottom: 10,
            maxWidth: 400,
          },
          styles.container,
        ]}
      >
        <TouchableOpacity
          onPress={() => _onSetActive("ALL")}
          style={{
            ...styles.buttonContainer,
            backgroundColor: _isActive("ALL") ? theme.primary : "#EDEDED",
            borderTopLeftRadius: _isActive("ALL") ? 30 : 0,
            borderBottomLeftRadius: _isActive("ALL") ? 30 : 0,
            borderTopRightRadius: _isActive("ALL") ? 30 : 0,
            borderBottomRightRadius: _isActive("ALL") ? 30 : 0,
          }}
        >
          <PrestoText
            fontStyle="medium"
            color={_isActive("ALL") ? theme.white : theme.defaultTextColor}
          >
            {I18n.t("screen_messages.all")}
          </PrestoText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => _onSetActive("CREDIT")}
          style={{
            ...styles.buttonContainer,
            backgroundColor: _isActive("CREDIT") ? theme.primary : "#EDEDED",
            borderTopLeftRadius: _isActive("CREDIT") ? 30 : 0,
            borderBottomLeftRadius: _isActive("CREDIT") ? 30 : 0,
          }}
        >
          <PrestoText
            fontStyle="medium"
            color={_isActive("CREDIT") ? theme.white : theme.defaultTextColor}
          >
            {I18n.t("screen_messages.earned")}
          </PrestoText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => _onSetActive("DEBIT")}
          style={{
            ...styles.buttonContainer,
            backgroundColor: _isActive("DEBIT") ? theme.primary : "#EDEDED",
            borderTopLeftRadius: _isActive("DEBIT") ? 30 : 0,
            borderBottomLeftRadius: _isActive("DEBIT") ? 30 : 0,
          }}
        >
          <PrestoText
            fontStyle="medium"
            color={_isActive("DEBIT") ? theme.white : theme.defaultTextColor}
          >
            {I18n.t("screen_messages.spent")}
          </PrestoText>
        </TouchableOpacity>
      </View>
    );
  };

  const RowButton = (props) => {
    const { title, item, rightTitle, rightColor, onPress, showDisclosure } =
      props || {};
    return (
      <Pressable onPress={onPress} style={{ width: "100%" }}>
        <View
          style={{
            paddingHorizontal: 20,
            padding: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "column" }}>
            <PrestoText
              fontStyle={"600.semibold"}
              size={14}
              extraStyle={{ textTransform: "capitalize" }}
            >
              {title}
            </PrestoText>
            <PrestoText
              fontStyle={"400.normal"}
              size={12}
              color={theme.paragraph}
              extraStyle={{ marginTop: 5 }}
            >
              {item?.expiry_date != "" && item?.expiry_date != null
                ? I18n.t("screen_messages.Expires_on", {
                    date: moment(item?.expiry_date).format("llll"),
                  })
                : moment(item?.time).format("llll")}
            </PrestoText>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {rightTitle ? (
              <View style={{ alignContent: "flex-end" }}>
                <PrestoText
                  size={16}
                  fontStyle="medium"
                  color={rightColor || theme.primary}
                  extraStyle={{
                    textTransform: "uppercase",
                    marginRight: 10,
                  }}
                >
                  {rightTitle}
                </PrestoText>
              </View>
            ) : null}
            {showDisclosure ? (
              <View style={{ alignContent: "flex-end", width: 30 }}>
                <PrestoIcon
                  icon={<svgs.RightArrow primaryColor={theme.primary} />}
                />
              </View>
            ) : (
              <View style={{ width: 30, height: 40 }} />
            )}
          </View>
        </View>
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </Pressable>
    );
  };

  const getTitle = (item) => {
    if (item?.target?.type === "Order" && item.type === "CREDIT") {
      return I18n.t("screen_messages.cashback_title2");
    } else if (item?.target?.type === "LineItem" && item.type === "CREDIT") {
      return I18n.t("screen_messages.cashback_title2");
    } else if (item.type === "DEBIT" && item?.target?.type === "Order") {
      return I18n.t("screen_messages.order_payment");
    } else if (item.type === "DEBIT") {
      return I18n.t("screen_messages.debit_text");
    } else {
      return I18n.t("screen_messages.cashback_title2");
    }
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.zip_club_points")}
          onBack={() => {
            Navigator.emit({
              event: "goBack",
            });
          }}
        />

        <ScrollView
          style={{ height: windowHeight - 100 }}
          contentContainerStyle={{
            width: "100%",
            paddingBottom: 100,
            paddingTop: theme.containerPadding,
          }}
        >
          {isAllDataForUserLoyaltyTierAvailable ? (
            <>
              <View style={{ paddingHorizontal: theme.containerPadding }}>
                <LoyaltyTierDetailCard2
                  user={user}
                  wallet={wallet}
                  loyaltyTiers={loyaltyTiers}
                  userLoyaltyTierProgress={userLoyaltyTierProgress}
                />

                <View style={{ paddingTop: theme.containerPadding }}>
                  <LoyaltyTierIndicatorCard
                    user={user}
                    wallet={wallet}
                    loyaltyTiers={loyaltyTiers}
                    userLoyaltyTierProgress={userLoyaltyTierProgress}
                  />
                </View>
              </View>

              <View style={{ paddingTop: theme.containerPadding }}>
                <LoyaltyInfoStrip
                  user={user}
                  wallet={wallet}
                  loyaltyTiers={loyaltyTiers}
                  userLoyaltyTierProgress={userLoyaltyTierProgress}
                />
              </View>

              <View
                style={{
                  paddingTop: theme.containerPadding,
                  paddingHorizontal: theme.containerPadding,
                }}
              >
                <PrestoSolidButton
                  buttonStyle="primary"
                  size="extra-large"
                  title={I18n.t(
                    "screen_messages.redeem_points_screen.redeempoints"
                  )}
                  titleColor={theme.white}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  onPress={() => onRedeem()}
                />
              </View>
              <View style={{ paddingTop: theme.containerPadding }}>
                <Accordion1
                  eventCallback={eventCallback}
                  initialOpened={[I18n.t("screen_messages.benefits.title")]}
                  rows={[
                    {
                      title: I18n.t("screen_messages.benefits.title"),
                      renderComponent: () => {
                        return (
                          <View
                            style={{
                              paddingHorizontal: theme.primaryPadding,
                              borderBottomWidth: 1,
                              borderBottomColor: theme.lightdark,
                            }}
                          >
                            <BenefitsSection user={user} />
                          </View>
                        );
                      },
                    },
                    {
                      title: I18n.t(
                        "screen_messages.gifts.gift_redemption_and_cashback"
                      ),
                      renderComponent: () => {
                        return (
                          <>
                            <View
                              style={{
                                paddingHorizontal: theme.primaryPadding,
                                paddingBottom: theme.primaryPadding,
                                borderBottomWidth: 1,
                                borderBottomColor: theme.lightdark,
                              }}
                            >
                              <GiftRedemption />
                            </View>
                          </>
                        );
                      },
                    },
                    {
                      title: I18n.t("screen_messages.faq.title"),
                      renderComponent: () => {
                        return (
                          <>
                            {isAllDataForUserLoyaltyTierAvailable && (
                              <View
                                style={{
                                  borderBottomWidth: 1,
                                  borderBottomColor: theme.lightdark,
                                }}
                              >
                                <FAQSection onRedeem={onRedeem} />
                              </View>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              </View>
            </>
          ) : null}
        </ScrollView>
      </SafeAreaView>
    </LoadingContainer>
  );
}

function getStyles(theme, windowWidth) {
  let minusWidth = theme.primaryPadding / 2;
  return {
    noItems: {
      fontSize: 14,
      fontWeight: "400",
      paddingTop: 15,
      paddingHorizontal: theme.containerPadding,
      color: theme.secondary,
    },
    container: {
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "#EDEDED",
      borderRadius: 30,
    },
    buttonContainer: {
      paddingVertical: 10,
      height: 40,
      width: windowWidth / 3,
      maxWidth: 400 / 3,
      alignItems: "center",
      borderRadius: 30,
    },
  };
}
