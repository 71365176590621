import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Image, Platform, Dimensions } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import { VerticalSpacing } from "@presto-components/Spacing";
import LoadingContainer from "@presto-components/LoadingContainer";
import { Components } from "presto-react-components";
const AmountInput = Components.AmountInput;
import _ from "lodash";
import CategoryItemManager from "@presto-services/features/category_item/CategoryItemManager";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import SearchManager from "@presto-services/features/search/SearchManager";
import utils from "../../utils/index";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useSafeArea } from "react-native-safe-area-context";
import { RowButton } from "@presto-components/PrestoButton";
import { ModalWithIcon } from "@presto-components/Modals/Modal";
import { DotIndicator } from "react-native-indicators";
import PrestoText from "@presto-components/PrestoText";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import { useAssets } from "presto-react-components";
import { prestoAlert, prestoConfirm } from "../../common/Alert";
import config from "@presto-common/config";

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_FAVORITES":
      return { ...state, favorites: payload };
    case "SET_CATEGORY_ITEM":
      return { ...state, items: { ...state.items, [payload.id]: payload } };
  }
  return state;
}

export default function DetailedItemScreen({ route, navigation }) {
  const { svgs } = useAssets();
  const { itemId, item, wallet, catalogId } = route.params;
  const { user } = useContext(UserContext);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [updating, setUpdating] = useState(undefined);
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
  const {
    updateItemInCart,
    getCountOfItemInCart,
    cart,
    clearCart,
  } = useContext(CartContext);
  const [product, setProduct] = useState(item);
  const [productLoaded, setProductLoaded] = useState(true);

  const loading = !product;
  const [screenLoading, setScreenLoading] = useState(false);
  const [state, setState] = useState(false);
  const windowHeight = Dimensions.get("window").height;
  const isDesktop = utils.isDesktop();

  const onVerifyAndAddItem = async (variationId) => {
    if (updating || !productLoaded) {
      return true;
    }

    let isGift = _.some(cart?.items, (ele) => ele.other_data.is_gift == "true");
    const giftString = isGift.toString();

    const totalItemPrice = Number(product.price.total_price);
    const newCartValue = Number(cart?.total_price) / 100 + totalItemPrice;

    if (
      product?.other_data?.is_gift &&
      isGift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      return true;
    }

    if (cart?.items.length) {
      if (giftString === _.get(item, "other_data.is_gift", "false")) {
        onAdd(variationId);
      } else {
        alertOnClearCart((cartCleared) => {
          if (cartCleared) {
            onAdd(variationId);
          }
        });
        return true;
      }
    } else {
      if (
        product?.other_data?.is_gift &&
        newCartValue > wallet?.current_balance / 100
      ) {
        alert(
          I18n.t("screen_messages.redeem_points_screen.information_title"),
          I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
        );
        return true;
      }
      onAdd(variationId);
    }
  };

  const onVerifyAndUpdateItem = async (variation, quantity) => {
    if (!productLoaded) {
      return;
    }
    setUpdating(variation?.id || product.id);
    let isGift = _.some(cart?.items, (ele) => ele.other_data.is_gift == "true");
    const giftString = isGift.toString();

    const oldItemTotalPrice =
      _.find(cart?.items, ["item_id", variation?.id || item?.id])
        ?.total_price || 0;
    const totalItemPrice = Number(item?.price?.total_price);

    const newCartValue =
      Number(cart?.total_price) / 100 -
      oldItemTotalPrice / 100 +
      totalItemPrice * quantity;

    if (cart?.items.length) {
      if (giftString === _.get(item, "other_data.is_gift", "false")) {
        if (
          item?.other_data?.is_gift &&
          isGift &&
          newCartValue > wallet?.current_balance / 100
        ) {
          alert(
            I18n.t("screen_messages.redeem_points_screen.information_title"),
            I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
          );
          setUpdating(undefined);
        } else {
          onAmountEdit(variation, quantity);
        }
      } else {
        alertOnClearCart((cartCleared) => {
          if (cartCleared) {
            if (
              item?.other_data?.is_gift &&
              isGift &&
              newCartValue > wallet?.current_balance / 100
            ) {
              alert(
                I18n.t(
                  "screen_messages.redeem_points_screen.information_title"
                ),
                I18n.t(
                  "screen_messages.redeem_points_screen.no_sufficient_points"
                )
              );
              setUpdating(undefined);
            } else {
              onAmountEdit(variation, quantity);
            }
          }
        });
        setUpdating(undefined);
      }
    } else {
      if (
        item?.other_data?.is_gift &&
        newCartValue > wallet?.current_balance / 100
      ) {
        alert(
          I18n.t("screen_messages.redeem_points_screen.information_title"),
          I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
        );
        setUpdating(undefined);
      } else {
        onAmountEdit(variation, quantity);
      }
    }
  };

  const onAdd = async (variationId) => {
    const count = getCountOfItemInCart(product.id, variationId);
    setUpdating(variationId || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variationId,
      count + 1
    );
    if (!status) {
      console.log("Error in Add", error, status);
      alert(utils.errorMessage(error));
    }
    setUpdating(undefined);
  };

  const onSubtract = async (variationId) => {
    if (updating) {
      return true;
    }
    const count = getCountOfItemInCart(product.id, variationId);
    if (count - 1 < 0) {
      return;
    }

    setUpdating(variationId || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variationId,
      count - 1 > 0 ? count - 1 : 0
    );
    if (!status) {
      alert(I18n.t("error_messages.error_updating_cart"));
    }
    setUpdating(undefined);
  };

  useEffect(() => {
    if (itemId) {
      setScreenLoading(true);
      SearchManager.searchCategoryItem(
        { id: itemId },
        (response) => {
          setScreenLoading(false);
          if (response.hits.hits.length > 0) {
            setProductLoaded(true);
            setProduct(response.hits.hits[0]._source);
          } else {
            setProductLoaded(false);
            alert(I18n.t("error_messages.error_loading_product_str"));
          }
        },
        (error) => {
          setProductLoaded(false);
          setScreenLoading(false);
          alert(utils.errorMessage(error));
        }
      );
    }
  }, [itemId]);

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const alertOnClearCart = (successCallback) => {
    prestoConfirm(
      I18n.t("screen_messages.clear_gift_cart.confirm_text"),
      I18n.t("screen_messages.clear_gift_cart.cleart_cart_warning"),
      [
        {
          text: I18n.t("screen_messages.clear_gift_cart.yes_text"),
          onPress: () => {
            clearCart();
            successCallback(true);
          },
        },
        {
          text: I18n.t("screen_messages.clear_gift_cart.no_text"),
          onPress: () => {
            successCallback(false);
          },
        },
      ],
      false,
      (userChoice) => {
        if (userChoice) {
          clearCart();
          successCallback(true);
        } else {
          successCallback(false);
        }
      }
    );
  };

  const renderItem = () => {
    const count = getCountOfItemInCart(product.id);
    const isSoldOut = product.sold_out;
    let saveMessage = "";
    if (product.other_data?.old_price) {
      saveMessage = I18n.t("screen_messages.billing.discount_save_message", {
        amount: utils.formattedPriceRoundOff(
          product.other_data?.old_price - product.price.base_price
        ),
      });
    }
    if (isSoldOut) {
      return (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottomWidth: 1,
            borderBottomColor: "#DADADA",
            paddingHorizontal: 20,
            paddingVertical: 10,
          }}
        >
          <View style={{ width: "45%" }}>
            <PrestoText fontStyle={"medium"} color={theme.darkText}>
              {product.name}
            </PrestoText>
            <PrestoText fontStyle={"medium"} color={theme.primary}>
              {saveMessage}
            </PrestoText>
            {renderOfferText()}
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <PrestoText
              fontStyle={"medium"}
              color={theme.darkText}
              extraStyle={{ paddingRight: 10 }}
            >
              {utils.formattedPriceRoundOff(product.price.total_price)}
            </PrestoText>
            <View>
              <PrestoFramedButton
                size="normal"
                onPress={() => {
                  onNotifyMe();
                }}
                width={110}
                title={I18n.t("screen_messages.notifications.notify_me")}
                buttonStyle="primary"
                titleExtraStyle={{ textTransform: "uppercase" }}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottomWidth: 1,
            borderBottomColor: "#DADADA",
            paddingHorizontal: 20,
            paddingVertical: 10,
          }}
        >
          <View style={{ width: "40%" }}>
            <PrestoText fontStyle={"medium"} color={theme.darkText}>
              {product.name}
            </PrestoText>
            <PrestoText fontStyle={"medium"} color={theme.primary}>
              {saveMessage}
            </PrestoText>
            {renderOfferText()}
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <PrestoText
              fontStyle={"medium"}
              color={theme.darkText}
              extraStyle={{ paddingRight: 10 }}
            >
              {product && product.price
                ? utils.formattedPriceRoundOff(
                  product.price.total_price,
                  noRoundOff
                )
                : null}
            </PrestoText>
            {updating ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  height: 40,
                  width: 100,
                }}
              >
                <DotIndicator
                  color={theme.primary}
                  maxScale={1.2}
                  minScale={0.2}
                  size={8}
                />
              </View>
            ) : (
              <>
                <Pressable onPress={() => onSubtract()}>
                  <View
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <PrestoIcon
                      icon={<svgs.MinusIcon color={theme.primary} />}
                    />
                  </View>
                </Pressable>
                <AmountInput
                  size={40}
                  amount={count}
                  textSize={16}
                  onUpdate={(amount) => onVerifyAndUpdateItem(null, amount)}
                />
                <Pressable
                  onPress={() => {
                    onVerifyAndAddItem();
                  }}
                >
                  <View
                    style={{
                      width: 40,
                      height: 40,
                    }}
                  >
                    <PrestoIcon
                      icon={<svgs.PlusIcon primaryColor={theme.primary} />}
                    />
                  </View>
                </Pressable>
              </>
            )}
          </View>
        </View>
      );
    }
  };

  const onNotifyMe = (variation) => {
    setScreenLoading(true);
    let params = {
      productId: product.id,
      itemId: product.id,
      requestor: {
        name: user.name,
        phone: user.phone_number,
      },
    };
    if (variation) {
      params.variation_id = variation.id;
    }
    CategoryItemManager.notifyWhenAvailable(
      params,
      () => {
        setScreenLoading(false);
        alert(I18n.t("screen_messages.notifications.notify_thanks_messages"));
      },
      (error) => {
        setScreenLoading(false);
        alert(error.message);
      }
    );
  };

  const onAmountEdit = async (variation, quantity) => {
    setUpdating(variation?.id || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variation?.id,
      quantity
    );
    if (!status) {
      console.log("Error in onAmountEdit", error, status);
      alert(utils.errorMessage(error));
    }
    setUpdating(undefined);
  };

  const renderVariation = (variation) => {
    const count = getCountOfItemInCart(product.id, variation.id);
    const isSoldOut = variation.sold_out;
    let saveMessage = variation.description;
    var offerVariationValue = parseInt(
      variation?.other_data?.variation_quantity ?? "0"
    );
    var offerfreeItem = parseInt(variation?.value ?? "0");
    if (variation.other_data?.old_price) {
      saveMessage = I18n.t("screen_messages.billing.discount_save_message", {
        amount: utils.formattedPriceRoundOff(
          variation.other_data?.old_price - variation.price.base_price
        ),
      });
    }
    if (isSoldOut) {
      return (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottomWidth: 1,
            borderBottomColor: "#DADADA",
            paddingHorizontal: 20,
            paddingVertical: 10,
          }}
        >
          <View style={{ width: "45%" }}>
            <PrestoText fontStyle={"medium"} color={theme.darkText}>
              {variation.name}
            </PrestoText>
            <PrestoText fontStyle={"medium"} color={theme.primary}>
              {saveMessage}
            </PrestoText>
            {renderOfferText(variation)}
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <PrestoText
              fontStyle={"medium"}
              color={theme.darkText}
              extraStyle={{ paddingRight: 10 }}
            >
              {utils.formattedPriceRoundOff(variation.price.total_price)}
            </PrestoText>
            <View>
              <PrestoFramedButton
                size="normal"
                width={110}
                onPress={() => {
                  onNotifyMe(variation);
                }}
                title={I18n.t("screen_messages.notifications.notify_me")}
                buttonStyle="primary"
                titleExtraStyle={{ textTransform: "uppercase" }}
              />
            </View>
          </View>
        </View>
      );
    } else {
      return (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottomWidth: 1,
            borderBottomColor: "#DADADA",
            paddingHorizontal: 20,
            paddingVertical: 10,
          }}
        >
          <View style={{ width: "40%" }}>
            <PrestoText fontStyle={"medium"} color={theme.darkText}>
              {variation.name}
            </PrestoText>
            <PrestoText fontStyle={"medium"} color={theme.primary}>
              {saveMessage}
            </PrestoText>
            {renderOfferText(variation)}
          </View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              flex: 1,
            }}
          >
            <PrestoText
              fontStyle={"medium"}
              color={theme.darkText}
              extraStyle={{ paddingRight: 10 }}
            >
              {utils.formattedPriceRoundOff(variation.price.total_price)}
            </PrestoText>
            {updating === variation.id ? (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  height: 40,
                  width: 100,
                }}
              >
                <DotIndicator
                  color={theme.primary}
                  maxScale={1.2}
                  minScale={0.2}
                  size={8}
                />
              </View>
            ) : (
              <View>
                <View
                  style={{ flexDirection: "row", justifyContent: "flex-end" }}
                >
                  <Pressable
                    onPress={() => {
                      onSubtract(variation.id);
                    }}
                  >
                    <View
                      style={{
                        width: 40,
                        height: 40,
                      }}
                    >
                      <PrestoIcon
                        icon={<svgs.MinusIcon color={theme.primary} />}
                      />
                    </View>
                  </Pressable>
                  {offerfreeItem > 1 ||
                    (offerVariationValue > 1 &&
                      (offerfreeItem + 1) * count !== 0) ? (
                    <AmountInput
                      size={40}
                      amount={
                        (parseInt(variation.other_data?.variation_quantity) +
                          parseInt(
                            variation.other_data?.variation_free_quantity
                          )) *
                        count
                      }
                      textSize={16}
                      onUpdate={(amount) => { }}
                      disableOnTap={true}
                    />
                  ) : (
                    <AmountInput
                      size={40}
                      amount={count}
                      textSize={16}
                      onUpdate={(amount) =>
                        onVerifyAndUpdateItem(variation, amount)
                      }
                    />
                  )}
                  <Pressable
                    onPress={() => {
                      onVerifyAndAddItem(variation.id);
                    }}
                  >
                    <View
                      style={{
                        width: 40,
                        height: 40,
                      }}
                    >
                      <PrestoIcon
                        icon={<svgs.PlusIcon primaryColor={theme.primary} />}
                      />
                    </View>
                  </Pressable>
                </View>
              </View>
            )}
          </View>
        </View>
      );
    }
  };

  const renderOfferText = (productItem = null) => {
    productItem = !productItem ? product : productItem;
    const canShowOfferText = productItem.other_data?.offer_text;
    return !_.isEmpty(canShowOfferText) ? (
      <PrestoText
        fontStyle="400.normal"
        size={12}
        color={theme.primary}
        extraStyle={{ marginTop: 5 }}
      >
        {productItem.other_data.offer_text}
      </PrestoText>
    ) : null;
  };

  const onShowSimilarProducts = () => {
    if (!isDesktop) {
      Navigator.emit({
        event: "goBack",
      });
    }
    console.log("Product similar : ", product.other_data?.molecule);
    setTimeout(() => {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: {
              query: null,
              molecule: product.other_data?.molecule,
              company_name: null,
              category_id: null,
              distributor: null,
              brand_name: null,
              region: null,
              therapy_area_simple: null,
              on_variation_offer: null,
              is_new: false,
              bestsellers: null,
              price_range: null,
              catalog_id: catalogId,
            },
            catalogId: catalogId,
          },
        },
      });
    }, 200);
  };

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const insets = useSafeArea();
  const renderActionSheet = ({ margin }) => {
    return (
      <ModalWithIcon goBack={goBack} keyboardAvoidView={false}>
        <View
          style={{
            backgroundColor: "white",
            marginBottom: margin,
            flex: 1,
            paddingTop:
              Platform.OS === "web" ? insets.top + 30 : insets.top - 20,
            minHeight: windowHeight,
          }}
        >
          <LoadingContainer
            bgColor={"transparent"}
            loading={loading || screenLoading}
          >
            {product ? (
              <>
                <ScrollView
                  style={{
                    // flex: 1,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <View style={{ paddingHorizontal: theme.primaryPadding + 5 }}>
                    <PrestoText
                      fontStyle={"semibold"}
                      size={theme.h1FontSize}
                      color={theme.darkText}
                    >
                      {product.name}
                    </PrestoText>
                    <PrestoText fontStyle={"medium"} color={theme.darkText}>
                      {product.other_data?.molecule}
                    </PrestoText>
                    <VerticalSpacing size={20} />
                    {product.other_data?.company_name ? (
                      <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                        <PrestoText fontStyle={"medium"} color={theme.darkText}>
                          {I18n.t("screen_messages.manufactured_by")}
                        </PrestoText>
                        <PrestoText
                          fontStyle={"bold"}
                          color={theme.darkText}
                          extraStyle={{
                            paddingLeft: 5,
                          }}
                        >
                          {product.other_data.company_name}
                        </PrestoText>
                      </View>
                    ) : null}
                    {product.other_data.is_gift ? null : (
                      <>
                        <VerticalSpacing size={20} />
                        <PrestoText fontStyle={"medium"} color={theme.darkText}>
                          {I18n.t("screen_messages.available_following_packs")}
                        </PrestoText>
                        <VerticalSpacing size={20} />
                      </>
                    )}
                  </View>
                  {product.variations && (product.variations?.length ?? 0) > 0
                    ? _.map(product.variations, (variation) => {
                      return renderVariation(variation);
                    })
                    : renderItem()}
                  <VerticalSpacing size={30} />
                  <View style={{ paddingHorizontal: 20 }}>
                    {product.pic_url ? (
                      <View style={{ alignItems: "center" }}>
                        <Image
                          source={{ uri: product.pic_url }}
                          style={{
                            width: 200,
                            height: 200,
                            resizeMode: "contain",
                          }}
                        />
                        <VerticalSpacing size={10} />
                      </View>
                    ) : null}

                    {product.description ? (
                      <>
                        <PrestoText fontStyle={"bold"} color={theme.darkText}>
                          {I18n.t("screen_messages.details_str")}
                        </PrestoText>
                        <PrestoText
                          fontStyle="medium"
                          color={theme.paragraph}
                          extraStyle={{ paddingTop: 10 }}
                        >
                          {product.description}
                        </PrestoText>
                        <VerticalSpacing size={20} />
                      </>
                    ) : null}
                  </View>

                  {product.other_data.is_gift ? null : (
                    <>
                      <VerticalSpacing size={40} />
                      <RowButton
                        title={I18n.t("screen_messages.similar_products_str")}
                        showDisclosure={true}
                        onPress={onShowSimilarProducts}
                      />
                      <LineDivider
                        dividerColor="#DADADA"
                        width="100%"
                        theme={theme}
                      />
                    </>
                  )}

                  <VerticalSpacing size={50} />
                </ScrollView>
              </>
            ) : null}
          </LoadingContainer>
        </View>
      </ModalWithIcon>
    );
  };

  const fromSpring = state.close ? { margin: 0 } : { margin: -600 };
  const toSpring = state.close ? { margin: -600 } : { margin: 0 };

  return renderActionSheet(toSpring.margin);
}
