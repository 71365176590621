import React, { useState, useEffect } from "react";
import { Platform, View, Keyboard } from "react-native";
import { WebView } from "react-native-webview";
import AppConfig from "@presto-common/config";
import TopHeaderWithTitle from "@presto-components/Headers/TopHeaderWithTitle";

export default function RegisterWebView(props) {
  const { title, shouldGoBack, goBack } = props.route.params || {};
  const [padding, setPadding] = useState(0);

  useEffect(() => {
    const showSubscription = Keyboard.addListener("keyboardDidShow", () => {
      setPadding(250);
    });
    const hideSubscription = Keyboard.addListener("keyboardDidHide", () => {
      setPadding(0);
    });

    return () => {
      showSubscription.remove();
      hideSubscription.remove();
    };
  }, []);

  return (
    <View
      style={{
        flex: 1,
        minHeight: "100%",
        minWidth: "100%",
        backgroundColor: "#fefefe",
        paddingBottom: padding,
      }}
    >
      <TopHeaderWithTitle
        title={title}
        shouldGoBack={shouldGoBack}
        onPressLeftButton={goBack}
      />
      {Platform.OS === "web" ? (
        <iframe src={AppConfig.hubspotUrl} width="100%" height="100%"></iframe>
      ) : (
        <WebView source={{ uri: AppConfig.hubspotUrl }} />
      )}
    </View>
  );
}
