import _ from "lodash";
import StylistManager from "@presto-services/features/pos_stylist/StylistManager";
import AdminManager from "@presto-services/features/admin/AdminManager";
import LoginHelper from "@presto-helpers/LoginHelper";
import { getGlobalKeys, setGlobalKeys } from "@presto-helpers/OutletHelper";

function stylistsBasedOnUserType(
  params = {},
  onSuccessCallback,
  onErrorCallback
) {
  const isAgent = LoginHelper.isUserRoleEmployee();
  const isAdmin = LoginHelper.isUserRoleAdmin();

  if (isAgent) {
    StylistManager.getAllStylists(
      { outlet_id: getGlobalKeys("OUTLETID") },
      onSuccessCallback,
      onErrorCallback
    );
  }

  if (isAdmin) {
    AdminManager.getAllStylists(
      { outlet_id: getGlobalKeys("OUTLET_ID") },
      onSuccessCallback,
      onErrorCallback
    );
  }
}

export default {
  stylistsBasedOnUserType,
};
