import Utils from "../../common/Utils";
import ErrorConstants from "../../common/ErrorConstants";
import EmployeeResource from "./EmployeeResource";
import PrestoSdk from "../../global/PrestoSdk";
import AppointmentObject from "../../data_models/AppointmentObject";
import EmployeeObject from "../../data_models/EmployeeObject";
import AddressObject from "../../data_models/AddressObject";
import $ from "../../global/util";

const EmployeeManager = function (
  Utils,
  ErrorConstants,
  EmployeeResource,
  PrestoSdk,
  EmployeeObject
) {
  function getAllEmployees(params, successCallback, errorCallback) {
    EmployeeResource.getAllEmployees(params).then(
      function (response) {
        // console.log("allEmployeesResponse json: ", response);
        let list = [];
        if (response.data.length) {
          for (let index in response.data) {
            // var employeeObj = new EmployeeObject();
            // employeeObj.buildObject(response.data[index]);
            list.push(response.data[index]);
          }
          response.data = list;
        }
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAdminEmployees(params, successCallback, errorCallback) {
    EmployeeResource.getAdminEmployees(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createAdminManager(params, successCallback, errorCallback) {
    EmployeeResource.createAdminManager(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateAdminManager(params, successCallback, errorCallback) {
    EmployeeResource.updateAdminManager(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createEmployee(employee, successCallback, errorCallback) {
    if (!(employee instanceof EmployeeObject)) {
      var employeeObj = new EmployeeObject();
      employeeObj.buildObject(employee);
      employee = employeeObj;
    }
    var validationResponse = employee.validateObject();
    // console.log("employee: ", employee);
    if (validationResponse.success) {
      var params = {};
      params.merchant_id = PrestoSdk.getMerchantId();
      params.app_id = PrestoSdk.getAppId();
      params.employee = employee;
      EmployeeResource.createEmployee(params).then(
        function (response) {
          response.message = Utils.getSuccessMessage("employee_created");
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function createEmployeeAddress(params, successCallback, errorCallback) {
    let address = null;
    if (!(params?.address instanceof AddressObject)) {
      var addressObj = new AddressObject();
      addressObj.buildObject(params.address);
      address = addressObj;
    }

    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    params.address = address;
    EmployeeResource.createEmployeeAddress(params).then(
      function (response) {
        // response.message = Utils.getSuccessMessage("employee_created");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployee(params, successCallback, errorCallback) {
    let employee = null;
    if (!(params.employee instanceof EmployeeObject)) {
      var employeeObj = new EmployeeObject();
      employeeObj.buildObject(params.employee);
      employee = employeeObj;
    }
    var validationResponse = employee.validateObject();
    if (validationResponse.success) {
      params.merchant_id = PrestoSdk.getMerchantId();
      params.app_id = PrestoSdk.getAppId();
      params.employee = employee;
      EmployeeResource.updateEmployee(params).then(
        function (response) {
          response.message = Utils.getSuccessMessage("employee_updated");
          successCallback(response);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    } else {
      validationResponse = Utils.updateErrorObject(validationResponse);
      errorCallback(validationResponse);
    }
  }

  function showEmployee(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    EmployeeResource.showEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showEmployeeAddress(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    EmployeeResource.showEmployeeAddress(params).then(
      function (response) {
        // response.message = Utils.getSuccessMessage("employee_created");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeAddress(params, successCallback, errorCallback) {
    let address = null;
    if (!(params?.address instanceof AddressObject)) {
      var addressObj = new AddressObject();
      addressObj.buildObject(params.address);
      address = addressObj;
    }

    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    params.address = address;
    EmployeeResource.updateEmployeeAddress(params).then(
      function (response) {
        response.message = Utils.getSuccessMessage("employee_address_updated");
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeEmployee(params, successCallback, errorCallback) {
    params.merchant_id = PrestoSdk.getMerchantId();
    params.app_id = PrestoSdk.getAppId();
    EmployeeResource.removeEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeAvailabilityStatus(
    params,
    successCallback,
    errorCallback
  ) {
    let query = {
      merchant_id: PrestoSdk.getMerchantId(),
      app_id: PrestoSdk.getAppId(),
      employee_id: params.employee_id, // values : true or false only
      active: params.active, // values : true or false only
    };

    EmployeeResource.updateEmployeeAvailabilityStatus(query).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function operatorsOccupancyByDate(params, successCallback, errorCallback) {
    EmployeeResource.operatorsOccupancyByDate(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showOperationalTiming(params, successCallback, errorCallback) {
    EmployeeResource.showOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createOperationalTiming(params, successCallback, errorCallback) {
    EmployeeResource.createOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateOperationalTiming(params, successCallback, errorCallback) {
    EmployeeResource.updateOperationalTiming(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function showNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.showNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.createNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.updateNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeNonWorkingSlots(params, successCallback, errorCallback) {
    EmployeeResource.removeNonWorkingSlots(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function applyCoupon(params, successCallback, errorCallback) {
    EmployeeResource.applyCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function removeCoupon(params, successCallback, errorCallback) {
    EmployeeResource.removeCoupon(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function generatorLoginTokenForOperator(
    params,
    successCallback,
    errorCallback
  ) {
    EmployeeResource.generatorLoginTokenForOperator(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function loginEmployeeWithToken(params, successCallback, errorCallback) {
    EmployeeResource.loginEmployeeWithToken(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function operatorLeaves(params, successCallback, errorCallback) {
    EmployeeResource.operatorLeaves(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrders(params, successCallback, errorCallback) {
    EmployeeResource.getOrders(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrderReturnReasons(params, successCallback, errorCallback) {
    const reasonMockData = [
      {
        id: "65804717179fb021cfa59ec1",
        reason: "Item not received",
      },
      {
        id: "65804721179fb021cfa59ec2",
        reason: "Quality issues",
      },
      {
        id: "6580472e179fb021cfa59ec3",
        reason: "Less quantity received",
      },
      {
        id: "6580473d179fb021cfa59ec4",
        reason: "More quantity received",
      },
      {
        id: "65804816179fb021c7a59ebe",
        reason: "Item returned",
      },
      {
        id: "65804822179fb021cfa59ec5",
        reason: "Incorrectly ordered/ Do not require anymore",
      },
      {
        id: "6580482d179fb021cfa59ec6",
        reason: "Wrong item",
      },
    ];

    EmployeeResource.getOrderReturnReasons(params).then(
      function (response) {
        if (response.data.length === 0) {
          response.data = reasonMockData;
        }
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
        successCallback({
          data: reasonMockData,
        });
      }
    );
  }

  function createOrderItemReturn(params, successCallback, errorCallback) {
    EmployeeResource.createOrderItemReturn(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getOrderItemReturn(params, successCallback, errorCallback) {
    EmployeeResource.getOrderItemReturn(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function associateDocumentToLineItem(params, successCallback, errorCallback) {
    EmployeeResource.associateDocumentToLineItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getCoupons(params, successCallback, errorCallback) {
    EmployeeResource.getCoupons(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteEmployee(params, successCallback, errorCallback) {
    EmployeeResource.deleteEmployee(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getItemPromotions(params, successCallback, errorCallback) {
    EmployeeResource.getItemPromotions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getCCAppointments(params, successCallback, errorCallback) {
    EmployeeResource.getCCAppointments(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAppointmentDetails(params, successCallback, errorCallback) {
    EmployeeResource.getAppointmentDetails(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateDeliveredQuantity(params, successCallback, errorCallback) {
    EmployeeResource.updateDeliveredQuantity(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateItem(params, successCallback, errorCallback) {
    EmployeeResource.updateItem(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getPriceRule(params, successCallback, errorCallback) {
    EmployeeResource.getPriceRule(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getTransactionHistory(params, successCallback, errorCallback) {
    EmployeeResource.getTransactionHistory(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function userWalletInfo(params, successCallback, errorCallback) {
    EmployeeResource.userWalletInfo(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateAppointmentUserInstructions(
    params,
    successCallback,
    errorCallback
  ) {
    EmployeeResource.updateAppointmentUserInstructions(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function createServiceProduct(params, successCallback, errorCallback) {
    EmployeeResource.createServiceProduct(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getServiceProduct(params, successCallback, errorCallback) {
    EmployeeResource.getServiceProduct(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateServiceProduct(params, successCallback, errorCallback) {
    EmployeeResource.updateServiceProduct(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteServiceProduct(params, successCallback, errorCallback) {
    EmployeeResource.deleteServiceProduct(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllServiceProducts(params, successCallback, errorCallback) {
    EmployeeResource.getAllServiceProducts(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createInventory(params, successCallback, errorCallback) {
    EmployeeResource.createInventory(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateInventory(params, successCallback, errorCallback) {
    EmployeeResource.updateInventory(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteInventory(params, successCallback, errorCallback) {
    EmployeeResource.deleteInventory(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createInventoryLog(params, successCallback, errorCallback) {
    EmployeeResource.createInventoryLog(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllInvetoryLogs(params, successCallback, errorCallback) {
    EmployeeResource.getAllInvetoryLogs(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getInvetoryLog(params, successCallback, errorCallback) {
    EmployeeResource.getInvetoryLog(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createUsageForService(params, successCallback, errorCallback) {
    EmployeeResource.createUsageForService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fetchUsagesOfService(params, successCallback, errorCallback) {
    EmployeeResource.fetchUsagesOfService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateUsageForService(params, successCallback, errorCallback) {
    EmployeeResource.updateUsageForService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function deleteUsageForService(params, successCallback, errorCallback) {
    EmployeeResource.deleteUsageForService(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function markOrderAsDeliveredV1(params, successCallback, errorCallback) {
    EmployeeResource.markOrderAsDeliveredV1(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function markOrderAsPickerUpV1(params, successCallback, errorCallback) {
    EmployeeResource.markOrderAsPickerUpV1(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllDashboards(successCallback, errorCallback) {
    EmployeeResource.getAllDashboard().then(
      function (response) {
        successCallback(response?.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function embedUrl(params, successCallback) {
    return EmployeeResource.embedUrl(params).then(
      function (response) {
        successCallback(response?.data, params?.id);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
      }
    );
  }

  function operatorAttendances(params, successCallback, errorCallback) {
    EmployeeResource.operatorAttendances(params).then(
      function (response) {
        successCallback(response);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateEmployeeOtherdata(params, successCallback) {
    return EmployeeResource.updateEmployeeOtherdata(params).then(
      function (response) {
        successCallback(response?.data, params?.id);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
      }
    );
  }

  return {
    updateEmployeeOtherdata: updateEmployeeOtherdata,
    createUsageForService: createUsageForService,
    fetchUsagesOfService: fetchUsagesOfService,
    updateUsageForService: updateUsageForService,
    deleteUsageForService: deleteUsageForService,
    createInventoryLog: createInventoryLog,
    getAllInvetoryLogs: getAllInvetoryLogs,
    getInvetoryLog: getInvetoryLog,
    createInventory: createInventory,
    updateInventory: updateInventory,
    deleteInventory: deleteInventory,
    createServiceProduct: createServiceProduct,
    getServiceProduct: getServiceProduct,
    updateServiceProduct: updateServiceProduct,
    deleteServiceProduct: deleteServiceProduct,
    getAllServiceProducts: getAllServiceProducts,
    getAllEmployees: getAllEmployees,
    createEmployee: createEmployee,
    createEmployeeAddress: createEmployeeAddress,
    updateEmployee: updateEmployee,
    showEmployee: showEmployee,
    showEmployeeAddress: showEmployeeAddress,
    updateEmployeeAddress: updateEmployeeAddress,
    removeEmployee: removeEmployee,
    updateEmployeeAvailabilityStatus: updateEmployeeAvailabilityStatus,
    operatorsOccupancyByDate: operatorsOccupancyByDate,
    showOperationalTiming: showOperationalTiming,
    createOperationalTiming: createOperationalTiming,
    updateOperationalTiming: updateOperationalTiming,
    showNonWorkingSlots: showNonWorkingSlots,
    createNonWorkingSlots: createNonWorkingSlots,
    updateNonWorkingSlots: updateNonWorkingSlots,
    removeNonWorkingSlots: removeNonWorkingSlots,
    getAdminEmployees: getAdminEmployees,
    createAdminManager: createAdminManager,
    updateAdminManager: updateAdminManager,
    applyCoupon: applyCoupon,
    removeCoupon: removeCoupon,
    getCoupons: getCoupons,
    generatorLoginTokenForOperator: generatorLoginTokenForOperator,
    loginEmployeeWithToken: loginEmployeeWithToken,
    operatorLeaves: operatorLeaves,
    getOrders: getOrders,
    getOrderReturnReasons: getOrderReturnReasons,
    createOrderItemReturn: createOrderItemReturn,
    getOrderItemReturn: getOrderItemReturn,
    associateDocumentToLineItem: associateDocumentToLineItem,
    deleteEmployee: deleteEmployee,
    getItemPromotions: getItemPromotions,
    getCCAppointments: getCCAppointments,
    getAppointmentDetails: getAppointmentDetails,
    updateDeliveredQuantity: updateDeliveredQuantity,
    getPriceRule: getPriceRule,
    updateItem: updateItem,
    getTransactionHistory: getTransactionHistory,
    userWalletInfo: userWalletInfo,
    updateAppointmentUserInstructions: updateAppointmentUserInstructions,
    operatorAttendances: operatorAttendances,
    markOrderAsDeliveredV1,
    markOrderAsPickerUpV1,
    getAllDashboards,
    embedUrl,
  };
};

export default EmployeeManager(
  Utils,
  ErrorConstants,
  EmployeeResource,
  PrestoSdk,
  EmployeeObject
);
