import React, { useState, useContext, useEffect, useRef } from "react";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import utils from "../../utils/index";
import CategoryItemManager from "@presto-services/features/category_item/CategoryItemManager";
import _ from "lodash";
import I18n from "i18n-js";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import ItemCard from "@presto-cards/ItemCards/ItemCard";
import InCartItemCard from "@presto-cards/ItemCards/InCartItemCard";
import SoldOutItemCard from "@presto-cards/ItemCards/SoldOutItemCard";
import ItemCardModel from "../../component-models/ItemCardModel";
import { prestoAlert, prestoConfirm } from "../../common/Alert";
import ServerCartManager from "@presto-services/features/server_cart/ServerCartManager";
import config from "@presto-common/config";

export default function ItemCardss({
  item,
  wallet,
  priceAccessorMethod,
  setParentLoading,
  isGift
}) {
  const product = item;
  const {
    getCountOfItemInCart,
    updateItemInCart,
    cart,
    clearCart,
    fetchCart,
  } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [updating, setUpdating] = useState(false);
  const isItemPromotionFeatureEnabled = config.features.item_promotions;

  const hasOffers = product.variations && product.variations.length;
  const offersCount = product.variations?.length || 0;
  let unsoldVariations = _.filter(
    product.variations || [],
    (varitation) => !varitation.sold_out
  );

  const variation = _.first(unsoldVariations);
  const [itemPromotion, setItemPromotion] = useState(undefined);
  const [itemPromotionText, setItemPromotionText] = useState("");
  const extraOptions = useRef({
    mounted: false,
  });
  useEffect(() => {
    if (!extraOptions.current.mounted && cart?.items.length > 0) {
      extraOptions.current.mounted = true;
      checkCartItemPromtion();
    }
  }, [cart]);
  const onVerifyAndAddItem = async () => {
    if (updating) {
      return true;
    }
    let isGift = !_.isEmpty(cart?.items)
      ? _.some(cart?.items, (ele) => ele.other_data.is_gift == "true")
      : true;
    const giftString = isGift.toString();
    const totalItemPrice = Number(item.price.total_price);
    const newCartValue = Number(cart?.total_price || 0) / 100 + totalItemPrice;

    if (
      item?.other_data?.is_gift &&
      isGift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      return true;
    }

    if (cart?.items.length) {
      if (giftString === _.get(item, "other_data.is_gift", "false")) {
        onAdd();
      } else {
        alertOnClearCart((cartCleared) => {
          if (cartCleared) {
            if (
              item?.other_data?.is_gift &&
              totalItemPrice > wallet?.current_balance / 100
            ) {
              alert(
                I18n.t(
                  "screen_messages.redeem_points_screen.information_title"
                ),
                I18n.t(
                  "screen_messages.redeem_points_screen.no_sufficient_points"
                )
              );
              return true;
            } else {
              onAdd();
            }
          }
        });
        return true;
      }
    } else {
      if (
        item?.other_data?.is_gift &&
        newCartValue > wallet?.current_balance / 100
      ) {
        alert(
          I18n.t("screen_messages.redeem_points_screen.information_title"),
          I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
        );
        return true;
      }
      onAdd();
    }
  };

  const onAdd = async () => {
    let variationId = null;
    if (unsoldVariations && unsoldVariations.length > 0) {
      variationId = variation.id;
    }
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count + 1
    );
    console.log("Error in Add", error, status);
    if (!status) {
      alert(utils.errorMessage(error));
    } else {
      checkCartItemPromtion();
    }
    setUpdating(false);
  };

  const checkCartItemPromtion = () => {
    if (!isItemPromotionFeatureEnabled) {
      return false;
    }

    const cartItem = _.find(cart?.items, ["item_id", product.id]);
    if (!cartItem) {
      return;
    }

    setUpdating(true);
    ServerCartManager.getProbableItemPromotions(
      {},
      (response) => {
        setItemPromotion(response.data);
        if (response.data) {
          itemPromotionAvailableText(response.data);
        }

        setUpdating(false);
      },
      (error) => {
        //alertBox(error.message);
        console.log("promotion error ", error);
        setUpdating(false);
      }
    );
  };

  const count = getCountOfItemInCart(item.id, variation?.id);
  const onSubtract = async () => {
    if (updating) {
      return true;
    }
    let variationId = null;
    if (unsoldVariations && unsoldVariations.length > 0) {
      variationId = variation.id;
    }
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count - 1 > 0 ? count - 1 : 0
    );
    if (!status) {
      alert(I18n.t("error_messages.error_updating_cart"));
    } else {
      checkCartItemPromtion();
    }
    setUpdating(false);
  };
  const onPressItem = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "DETAILED_ITEM",
        screenParams: { item: item, wallet, wallet },
      },
    });
  };

  const onNotifyMe = (product) => {
    if (!user) {
      Navigator.emit({
        event: "modal",
        params: {
          screenName: "LOGIN",
        },
      });
      return;
    }
    if (_.isFunction(setParentLoading)) {
      setParentLoading(true);
    }
    CategoryItemManager.notifyWhenAvailable(
      {
        productId: product.id,
        itemId: product.id,
        requestor: {
          name: user.name,
          phone: user.phone_number,
        },
      },
      () => {
        alert(I18n.t("screen_messages.notify_thanks_messages"));
        if (_.isFunction(setParentLoading)) {
          setParentLoading(false);
        }
      },
      (error) => {
        alert(error.message);
        if (_.isFunction(setParentLoading)) {
          setParentLoading(false);
        }
      }
    );
  };

  const onVerifyAndUpdateItem = async (variation, quantity) => {
    setUpdating(variation?.id || product.id);
    let isGift = _.some(cart?.items, (ele) => ele.other_data.is_gift == "true");

    const oldItemTotalPrice =
      _.find(cart?.items, ["item_id", variation?.id || item.id])?.total_price ||
      0;
    const totalItemPrice = Number(item.price.total_price);

    const newCartValue =
      Number(cart?.total_price) / 100 -
      oldItemTotalPrice / 100 +
      totalItemPrice * quantity;

    if (
      item?.other_data?.is_gift &&
      isGift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      setUpdating(undefined);
    } else {
      onUpdate(variation, quantity);
    }
  };
  const onUpdate = async (variation, quantity) => {
    setUpdating(variation?.id || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variation?.id,
      quantity
    );
    if (!status) {
      console.log("Error in onAmountEdit", error, status);
      alert(utils.errorMessage(error));
    } else {
      checkCartItemPromtion();
    }
    setUpdating(undefined);
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const alertOnClearCart = (successCallback) => {
    prestoConfirm(
      I18n.t("screen_messages.clear_gift_cart.confirm_text"),
      I18n.t("screen_messages.clear_gift_cart.cleart_cart_warning"),
      [
        {
          text: I18n.t("screen_messages.clear_gift_cart.yes_text"),
          onPress: () => {
            clearCart();
            successCallback(true);
          },
        },
        {
          text: I18n.t("screen_messages.clear_gift_cart.no_text"),
          onPress: () => {
            successCallback(false);
          },
        },
      ],
      false,
      (userChoice) => {
        if (userChoice) {
          clearCart();
          successCallback(true);
        } else {
          successCallback(false);
        }
      }
    );
  };

  const itemPromotionAvailableText = async (data) => {
    const [error, response] = await fetchCart();

    let addedItem = response.items
      ? _.find(response.items, { item_id: item.id })
      : null;
    if (addedItem) {
      let itemPromotionObject = data[addedItem?.id];

      if (itemPromotionObject && itemPromotionObject?.promotions_available) {
        if (itemPromotionObject.prerequisite_item_count_to_be_added != 0) {
          if (itemPromotionObject.entitlement_type == "amount_discount") {
            setItemPromotionText(
              I18n.t("screen_messages.common.buy_more_to_avail_discount", {
                count: itemPromotionObject.prerequisite_item_count_to_be_added,
                amount:
                  itemPromotionObject.value_type == "fixed"
                    ? itemPromotionObject.value / 100
                    : itemPromotionObject.value + "%",
              })
            );
          } else {
            setItemPromotionText(
              I18n.t("screen_messages.common.buy_more_to_get_free_item", {
                count: itemPromotionObject.prerequisite_item_count_to_be_added,
                value:
                  itemPromotionObject.prerequisite_to_entitlement_quantity_ratio
                    .entitled_item_quantity,
              })
            );
          }
        } else {
          if (addedItem) {
            if (addedItem.promotion_discount_amount != 0) {
              setItemPromotionText(
                I18n.t("screen_messages.common.your_savings_text", {
                  amount: addedItem.promotion_discount_amount / 100,
                })
              );
            } else {
              setItemPromotionText(
                I18n.t("screen_messages.common.claim_your_item")
              );
            }
          } else {
            _.forEach(cart.items, (item) => {
              if (item.promotion_discount_amount != 0) {
                setItemPromotionText(
                  I18n.t("screen_messages.common.your_savings_text", {
                    amount: item.promotion_discount_amount / 100,
                  })
                );
              }
            });
          }
        }
      } else {
        setItemPromotionText("");
      }
    }
  };

  let isSoldOut;

  if (config?.features?.show_all) {
    isSoldOut =
      (!hasOffers && !product.is_available) || (hasOffers && !variation);
  } else {
    isSoldOut = (!hasOffers && product.sold_out) || (hasOffers && !variation);
  }

  if (isSoldOut) {
    return (
      <SoldOutItemCard
        product={new ItemCardModel({ product, variation, count })}
        onPress={onPressItem}
        onNotifyMe={onNotifyMe}
        soldTitle={I18n.t("screen_messages.sold_out_str")}
        isGift={isGift}
        priceAccessorMethod={priceAccessorMethod}
      />
    );
  }

  if (count > 0) {
    return (
      <InCartItemCard
        product={
          new ItemCardModel({ product, variation, count, itemPromotion, cart })
        }
        onPress={onPressItem}
        onAdd={onVerifyAndAddItem}
        onSubtract={onSubtract}
        onUpdate={onVerifyAndUpdateItem}
        updating={updating}
        itemPromotionAvailableText={itemPromotionText}
        priceAccessorMethod={priceAccessorMethod}
        isGift={isGift}
      />
    );
  } else {
    return (
      <ItemCard
        product={
          new ItemCardModel({ product, variation, count, itemPromotion })
        }
        onPress={onPressItem}
        onAdd={onVerifyAndAddItem}
        onSubtract={onSubtract}
        onUpdate={onVerifyAndUpdateItem}
        updating={updating}
        priceAccessorMethod={priceAccessorMethod}
        isGift={isGift}
      />
    );
  }
}
