import React from "react";
import { StyleSheet, View } from "react-native";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import FlexRowSpaceAligned from "@presto-components/FlexRowSpaceAligned";
import SelectedCheckbox from "@presto-components/SelectedCheckBox";
import UnSelectedCheckbox from "@presto-components/UnSelectedCheckBox";
import Pressable from "@presto-components/Pressable/Pressable";
import _ from "lodash";

export default function RadioButtonWithText(props) {
  const {
    text,
    theme,
    onPress,
    description,
    icon,
    status,
    defaultBorderColor = theme.radioButtonBorderColor,
    textStyle = {},
    reverse = false,
    descriptionTextStyles,
  } = props;

  const textGroup = (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "flex-start",
        alignContent: "center",
        alignItems: "center",
        width: "90%",
        marginBottom: description ? 15 : 0,
      }}
    >
      {icon ? (
        <View style={{ paddingRight: 10, paddingBottom: 5 }}>
          <PrestoIcon icon={icon} />
        </View>
      ) : null}
      <View style={{ width: "100%" }}>
        <PrestoText
          fontStyle="400.normal"
          color="#000000"
          extraStyle={[styles.textStyle, textStyle]}
        >
          {text}
        </PrestoText>
        {description ? (
          <PrestoText
            fontStyle={
              descriptionTextStyles?.fontStyle
                ? descriptionTextStyles?.fontStyle
                : "400.normal"
            }
            color={
              descriptionTextStyles?.color
                ? descriptionTextStyles?.color
                : "#999999"
            }
            extraStyle={styles.descriptionStyle}
          >
            {description}
          </PrestoText>
        ) : null}
      </View>
    </View>
  );

  const radioGroup = (
    <View
      style={{
        ...styles.iconStyles,
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        paddingRight: reverse ? theme.primaryPadding : 0,
      }}
    >
      {props.rightText ? (
        <PrestoText fontStyle="bold" color={theme.primary}>
          {props.rightText}
        </PrestoText>
      ) : null}
      {status ? (
        <SelectedCheckbox
          borderWidth={1.5}
          size={props.size}
          iconColor={theme.primary}
          style={props.iconStyle}
          borderColor={defaultBorderColor}
        />
      ) : (
        <UnSelectedCheckbox
          borderWidth={1.5}
          borderColor={defaultBorderColor}
          style={props.iconStyle}
          size={props.size}
        />
      )}
    </View>
  );

  let components = [textGroup, radioGroup];

  if (reverse) {
    components.reverse();
  }

  return (
    <Pressable
      onPress={() => {
        if (onPress) {
          onPress();
        }
      }}
    >
      <FlexRowSpaceAligned
        styleSet={{
          paddingHorizontal: props.paddingHorizontal || 0,
        }}
      >
        {_.map(components, (componentItem) => componentItem)}
      </FlexRowSpaceAligned>
    </Pressable>
  );
}

const styles = {
  textStyle: {
    fontSize: 14,
    letterSpacing: 0.4,
    paddingBottom: 5,
    paddingTop: 3,
    minWidth: "50%",
  },
  descriptionStyle: {
    letterSpacing: 0.4,
  },
  iconStyles: {
    paddingVertical: 10,
  },
};
