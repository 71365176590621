import React, { useContext } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import PrestoText from "@presto-components/PrestoText";
import { TransparentCard } from "@presto-components/Cards/Cards";
import I18n from "i18n-js";

export default function AddressCard({ address, width }) {
  const { theme } = useContext(ThemeContext);
  return (
    <TransparentCard>
      <View
        style={{
          width: width || "100%",
        }}
      >
        {address.line1 ? (
          <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
            {address.line1}
          </PrestoText>
        ) : null}
        {address.line2 ? (
          <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
            {address.line2}
          </PrestoText>
        ) : null}
        {address.area ? (
          <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
            {address.area}
          </PrestoText>
        ) : null}
        {address.landmark ? (
          <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
            {address.landmark}
          </PrestoText>
        ) : null}
        {address.state || address.city ? (
          <>
            <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
              {address?.state ? address?.state + " , " : ""}{" "}
              {address?.city ?? ""}
            </PrestoText>
          </>
        ) : null}
        {address.pincode && (
          <PrestoText size={theme.primaryTextSize} color={theme.darkText}>
            {I18n.t("screen_messages.pin")}
            {address.pincode}
          </PrestoText>
        )}
      </View>
    </TransparentCard>
  );
}
