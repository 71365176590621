import React from "react";
import { LastOrderCards } from "presto-react-components";

export default function LastOrderCard(props) {
  return <LastOrderCards.LastOrderCard3 {...props} />;
}
// TODO(refactor): move the below code to Presto-react-components
/* 
export default function LastOrderCard({
  order,
  onReOrderPressed,
  onViewOrderPressed,
  onTrackOrderPressed,
  active,
  color,
  bgColor = "#FFFFFF",
  padding,
  onPayPressed,
}) {
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  const [showCard, SetShowCard] = useState(isDesktop ? true : false);

  let items = order.items;
  const count = _.sumBy(items, (i) => i.quantity);

  const onPress = () => {
    SetShowCard(false);
  };

  if (!isDesktop) {
    return (
      <DropShadow
        style={{
          borderRadius: theme.cardRadius,
          ...theme.darkShadow,
        }}
      >
        <View
          style={{
            padding: padding || theme.primaryPadding,
            width: "100%",
            backgroundColor: bgColor || "#FFFFFF",
            borderRadius: theme.cardRadius,
          }}
        >
          <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <View style={{ flex: 1 }}>
              <PrestoText
                fontStyle="400.semibold"
                size={theme.h5FontSize}
                color={theme.paragraph}
              >
                {order.repeatOrderTitleWithAmount}
              </PrestoText>
              <PrestoText
                fontStyle="400.normal"
                size={theme.h6FontSize}
                color={theme.paragraph}
              >
                {order.repeatOrderMessageWithDateAndCount}
              </PrestoText>
            </View>
            <View
              style={{
                width: 100,
                paddingLeft: 15,
                justifyContent: "center",
                alignItems: "flex-end",
                paddingHorizontal: 5,
              }}
            >
              <PrestoIcon icon={<svgs.LogoIcon />} />
            </View>
          </View>
          <VerticalSpacing size={20} />
          <View style={{ flexDirection: "row-reverse", flexWrap: "wrap" }}>
            {order.isGiftItem ? null : (
              <View style={{ paddingBottom: 10, paddingLeft: 10 }}>
                <PrestoSolidButton
                  buttonStyle="primary"
                  size="normal"
                  width={110}
                  title={order.reOrderButton}
                  titleColor={theme.white}
                  onPress={onReOrderPressed}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                  numberOfLines={1}
                />
              </View>
            )}
            <View style={{ paddingBottom: 10 }}>
              {order.orderStatus === "DISPATCHED" &&
              order.paymentSatus !== "SUCCESS" ? (
                <PrestoFramedButton
                  buttonStyle="primary"
                  size="normal"
                  width={110}
                  title={order.payOrderButton}
                  onPress={onPayPressed}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                />
              ) : (
                <PrestoFramedButton
                  buttonStyle="primary"
                  size="normal"
                  width={110}
                  title={order.viewOrderButton}
                  onPress={onTrackOrderPressed}
                  titleExtraStyle={{ textTransform: "uppercase" }}
                />
              )}
            </View>
          </View>
        </View>
      </DropShadow>
    );
  } else if (showCard && isDesktop) {
    return (
      <Card bgColor={active ? color || "#53C175" : bgColor}>
        {active ? (
          <Pressable onPress={() => onPress()}>
            <View
              style={{
                borderWidth: 1,
                position: "absolute",
                right: -10,
                top: -12,
                borderRadius: 35,
                backgroundColor: "#FFFFFF",
                borderColor: color || "#53C175",
              }}
            >
              <PrestoIcon
                icon={
                  <Image
                    source={images.close}
                    style={{
                      width: 25,
                      height: 25,
                      resizeMode: "contain",
                      tintColor: color || "#53C175",
                    }}
                  />
                }
              />
            </View>
          </Pressable>
        ) : null}
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <PrestoText
              fontStyle="semibold"
              size={14}
              color={active ? "#FFFFFF" : null}
            >
              {order.repeatOrderTitleWithAmount}
            </PrestoText>
            <PrestoText
              size={theme.h6FontSize}
              color={active ? theme.white : theme.paragraph}
            >
              {order.repeatOrderMessageWithDateAndCount}
            </PrestoText>
          </View>
          <View
            style={{
              width: 100,
              paddingLeft: 15,
              justifyContent: "center",
              alignItems: "flex-end",
              paddingHorizontal: 5,
            }}
          >
            <PrestoIcon
              icon={<svgs.LogoIcon color={active ? "#FFFFFF" : null} />}
            />
          </View>
        </View>
        <VerticalSpacing size={20} />
        <View style={{ flexDirection: "row-reverse", flexWrap: "wrap" }}>
          <View style={{ paddingBottom: 10, paddingLeft: 10 }}>
            {active ? (
              <PrestoFramedButton
                size="normal"
                buttonStyle="primary"
                width={120}
                borderRadius={10}
                borderColor={theme.white}
                title={order.reOrderButton}
                titleColor="#fff"
                onPress={onReOrderPressed}
                numberOfLines={1}
                titleExtraStyle={{ textTransform: "uppercase" }}
              />
            ) : (
              <>
                {order?.isGiftItem ? null : (
                  <PrestoFramedButton
                    size="normal"
                    buttonStyle="primary"
                    width={120}
                    borderRadius={10}
                    borderColor={theme.primary}
                    title={order.reOrderButton}
                    titleColor={theme.primary}
                    onPress={onReOrderPressed}
                    numberOfLines={1}
                    titleExtraStyle={{ textTransform: "uppercase" }}
                  />
                )}
              </>
            )}
          </View>
          <View style={{ paddingBottom: 10 }}>
            {order.orderStatus === "DISPATCHED" &&
            order.paymentSatus !== "SUCCESS" ? (
              <PrestoFramedButton
                size="normal"
                buttonStyle="primary"
                width={120}
                borderRadius={10}
                borderColor={theme.white}
                title={order.payOrderButton}
                titleColor={theme.white}
                onPress={onPayPressed}
                numberOfLines={1}
                titleExtraStyle={{ textTransform: "uppercase" }}
              />
            ) : (
              <PrestoFramedButton
                size="normal"
                buttonStyle="primary"
                width={120}
                borderRadius={10}
                borderColor={theme.primary}
                title={order.viewOrderButton}
                titleColor={theme.primary}
                onPress={onTrackOrderPressed}
                titleExtraStyle={{ textTransform: "uppercase" }}
              />
            )}
          </View>
        </View>
      </Card>
    );
  } else {
    return null;
  }
}
 */
