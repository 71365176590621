import React, { useState, useMemo } from "react";
import { View, TextInput, Image } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PropTypes from "prop-types";

export default function PrestoPasswordInput(props) {
  const {
    theme,
    backgroundColor,
    style,
    iconStyle,
    containerStyle = {},
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const styles = useMemo(() => {
    return getStyles(theme, backgroundColor, style, iconStyle, containerStyle);
  }, [theme, style]);

  const textContainerStylesOnFocus = props?.textContainerStylesOnFocus
    ? props.textContainerStylesOnFocus
    : {};

  const initialTextContainerStyles = [
    styles.textinputContainer,
    props?.textContainerStyles,
  ];

  const [textContainerStyles, setTextContainerStyles] = useState(
    initialTextContainerStyles
  );

  const onFocus = () => {
    if (props?.onFocus) {
      props.onFocus();
    }

    if (textContainerStylesOnFocus) {
      let _styles = [...initialTextContainerStyles, textContainerStylesOnFocus];
      setTextContainerStyles([..._styles]);
    }
  };

  const onBlur = () => {
    if (textContainerStylesOnFocus) {
      setTextContainerStyles([
        styles.textinputContainer,
        props?.textContainerStyles,
      ]);
    }
  };

  return (
    <View style={styles.container}>
      <View style={textContainerStyles}>
        <TextInput
          onFocus={onFocus}
          onBlur={onBlur}
          underlineColorAndroid="transparent"
          autoCapitalize={props.autoCapitalize || "none"}
          autoCompleteType={props.autoCompleteType || "off"}
          autoCorrect={props.autoCorrect || false}
          keyboardType={props.keyboardType || "default"}
          secureTextEntry={!isVisible}
          value={props.value}
          placeholder={props.placeholder}
          placeholderTextColor={
            props.textInputPlaceholderColor || styles.placeHolder.textColor
          }
          {...props}
          style={{
            ...styles.textInputStyle,
          }}
          textContentType={props.textContentType || "password"}
          importantForAutofill={props.importantForAutofill || "yes"}
          secureTextEntry={props.secureTextEntry}
        ></TextInput>
      </View>
      {props.icon && (
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={props.onPress}
          // onPress={() => eyeOnPress()}
          style={styles.IconStyle}
        >
          <Image source={props.icon} style={styles.IconStyle} />
        </TouchableOpacity>
      )}
    </View>
  );
}

PrestoPasswordInput.propTypes = {
  theme: PropTypes.shape({
    textInputLabelColor: PropTypes.string.isRequired,
    textInputLabelFont: PropTypes.string.isRequired,
    textInputFont: PropTypes.string.isRequired,
    textInputLabelFontSize: PropTypes.number.isRequired,
    textInputFontSize: PropTypes.number.isRequired,
    textInputTextColor: PropTypes.string.isRequired,
    textInputPlaceholderColor: PropTypes.string.isRequired,
    textInputBorderRadius: PropTypes.number.isRequired,
  }),
  labelStyle: PropTypes.object,
  textInputStyle: PropTypes.object,
};

const getStyles = (
  theme,
  backgroundColor,
  style,
  iconStyle,
  containerStyle
) => {
  return {
    container: {
      flex: 1,
      alignItems: "center",
      flexDirection: "row",
      backgroundColor: "#fff",
      borderRadius: theme.textInputBorderRadius || 25,
      width: "100%",
      ...containerStyle,
    },
    textInputStyle: {
      width: "100%",
      height: theme.textInputHeight || 50,
      overflow: "hidden",
      borderWidth: style?.borderWidth || 0,
      borderRadius: theme.textInputBorderRadius || 25,
      fontSize: 16,
      fontFamily: theme.primaryMediumFont,
      paddingHorizontal: theme.textInputBorderRadius || 25,
      color: theme.textInputColor,
      backgroundColor: backgroundColor || theme.textInputBgColor,
      borderColor:
        style?.borderColor || theme.textInputBorderColor || "#CCCCCC",
    },
    textinputContainer: {
      overflow: "hidden",
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: style?.textInputHeight
        ? style?.textInputHeight
        : theme.textInputHeight,
      // borderWidth: 1,
      borderRadius: theme.textInputBorderRadius || 25,
      borderColor: theme.textInputBorderColor || "#CCCCCC",
    },
    placeHolder: {
      textColor: theme.placeHolderColor,
    },
    IconStyle: {
      width: 33,
      height: 33,
      alignItems: "center",
      marginRight: 5,
      justifyContent: "center",
      position: iconStyle?.position || "relative",
      right: iconStyle?.right || 0,
    },
  };
};
