import React, { useContext } from "react";
import { View, Dimensions } from "react-native";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import { Appbar } from "react-native-paper";
import { useSafeArea } from "react-native-safe-area-context";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import { useAssets } from "presto-react-components";
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";

function TopHeaderWithTitle(props) {
  const { onPressLeftButton, title, shouldGoBack } = props;
  const insets = useSafeArea();
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const windowWidth = Dimensions.get("window").width;
  let isDesktop = windowWidth > 940 ? true : false;
  return (
    <Appbar
      style={{
        height: 50 + insets.top,
        width: "100%",
        alignItems: "flex-end",
        backgroundColor: theme.appBackgroundColor,
        borderBottomColor: "lightgray",
        borderBottomWidth: isDesktop && !shouldGoBack ? 0 : 1,
        shadowOpacity: 0,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          height: 44,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            height: "100%",
            justifyContent:
              isDesktop && !shouldGoBack ? "flex-start" : "center",
            alignItems: "center",
            marginLeft: isDesktop && !shouldGoBack ? theme.primaryPadding : 0,
          }}
        >
          <PrestoText fontStyle="500.medium" color={theme.darkText} size={16}>
            {I18n.t(title, { defaultValue: title })}
          </PrestoText>
        </View>
        {shouldGoBack ? (
          <View
            style={{
              position: "absolute",
              height: 50,
              width: 50,
              justifyContent: "center",
              alignItems: "center",
              bottom: 0,
            }}
          >
            <TouchableWithoutFeedback onPress={onPressLeftButton}>
              <View>
                <PrestoIcon icon={<svgs.LeftArrow width={40} height={30} />} />
              </View>
            </TouchableWithoutFeedback>
          </View>
        ) : null}
      </View>
    </Appbar>
  );
}

export default TopHeaderWithTitle;
