import config from "@presto-common/config";
import React from "react";
import LoginWithPassword from "@presto-screens/LoginWithPassword/LoginWithPassword";
import LoginWithOtpScreen from "@presto-screens/LoginWithOtp/LoginWithOtp";

export default function LoginScreen() {
  const { merchant_config } = config;
  if (merchant_config?.features?.otp_login) {
    return <LoginWithOtpScreen />;
  } else {
    return <LoginWithPassword />;
  }
}
