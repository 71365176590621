const fonts = {
  primaryFont: "Montserrat-Regular",
  primaryMediumFont: "Montserrat-Medium",
  primarySemiBoldFont: "Montserrat-SemiBold",
  primaryBoldFont: "Montserrat-Bold",
};

const fontSizes = {
  h1FontSize: 24,
  h2FontSize: 20,
  h3FontSize: 18,
  h4FontSize: 16,
  h5FontSize: 14,
  h6FontSize: 12,
};

const buttonVariations = {
  "extra-large": {
    height: 50,
    fontSize: 18,
    borderRadius: 8,
    borderWidth: 1,
  },
  large: {
    height: 40,
    fontSize: 16,
    borderRadius: 8,
    borderWidth: 1,
  },
  normal: {
    height: 30,
    fontSize: 14,
    borderRadius: 6,
    borderWidth: 1,
  },
  small: {
    height: 25,
    fontSize: 12,
    borderRadius: 4,
    borderWidth: 1,
  },
  "extra-small": {
    height: 20,
    fontSize: 10,
    borderRadius: 4,
    borderWidth: 1,
  },
};

const fontVariations = {
  normal: {
    fontFamily: fonts.primaryFont,
    fontWeight: "normal",
  },
  medium: {
    fontFamily: fonts.primaryMediumFont,
    fontWeight: "normal",
  },
  semibold: {
    fontFamily: fonts.primarySemiBoldFont,
    fontWeight: "normal",
  },
  bold: {
    fontFamily: fonts.primaryBoldFont,
    fontWeight: "normal",
  },
  "100": {
    lite: {
      fontFamily: fonts.primaryLiteFont,
      fontWeight: "100",
    },
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "100",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "100",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "100",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "100",
    },
  },
  "400": {
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "400",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "400",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "400",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "400",
    },
  },
  "500": {
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "500",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "500",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "500",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "500",
    },
  },
  "600": {
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "600",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "600",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "600",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "600",
    },
  },
  "700": {
    normal: {
      fontFamily: fonts.primaryFont,
      fontWeight: "700",
    },
    medium: {
      fontFamily: fonts.primaryMediumFont,
      fontWeight: "700",
    },
    semibold: {
      fontFamily: fonts.primarySemiBoldFont,
      fontWeight: "700",
    },
    bold: {
      fontFamily: fonts.primaryBoldFont,
      fontWeight: "700",
    },
  },
};

const colors = {
  //theme specific text, button, bg, border colors
  primary: "#53C175",
  secondary: "#666666",
  tertiary: "#FFFFFF",
  //common text or background colors
  black: "#000",
  white: "#fff",
  //both background and border colors
  light: "#fefefe",
  //both background and border colors
  dark: "#333333",
  //success popup or inline success msgs
  success: "green",
  //error popup or inline errors msgs
  error: "red",
  warning: "orange",
  //only for specific components
  marketing: "blue",
};

const textColor = {
  title: "#000000",
  header: "#000000",
  subHeader: "#B6B6B6",
  paragraph: "#666666",
  lightText: "#CCCCCC",
  darkText: "#333333",
  lightdark: "#E3E3E3",
  // buttons Color
  primaryButtonText: "#FFFFFF",
  secondaryButtonText: "#FFFFFF",
  tertiaryButtonText: "#FFFFFF",
};

const backgroundColor = {
  //only for model background
  modalBackgroundColor: "#FFFFFF",
  //only for APP background
  appBackgroundColor: "rgba(236, 236, 236, 1)",
  //only for card background
  cardBackgroundColor: "#FFFFFF",
};

const textInputStyles = {
  placeHolderColor: "#999999",
  textInputBorderColor: "transparent",
  textInputBorderWidth: 0,
  textInputBorderRadius: 19,
  textInputBgColor: "#D8D8D8",
  textInputSize: 14,
  textInputHeight: 38,
  textInputColor: "#666666",
  textInputLabelColor: "#333333",
};

const constantStyles = {
  cardRadius: 10,
  modalBorderRadius: 4,
  borderWidth: 1,
  containerPadding: 20,
  primaryPadding: 15,
};

const buttonStyles = {
  "solid-button-styles": {
    primary: {
      color: colors.primaryButtonText,
      backgroundColor: colors.primary,
      fontStyle: "600.normal",
    },
    secondary: {
      color: colors.secondaryButtonText,
      backgroundColor: colors.secondary,
      fontStyle: "600.normal",
    },
    "white-primary": {
      color: colors.primary,
      backgroundColor: "#fff",
      fontStyle: "600.normal",
    },
    white: {
      color: "#000",
      backgroundColor: "#fff",
      fontStyle: "600.normal",
    },
    black: {
      color: "#fff",
      backgroundColor: "#000",
      fontStyle: "600.normal",
    },
    "black-primary": {
      color: colors.primary,
      backgroundColor: "#000",
      fontStyle: "600.normal",
    },
  },
  "framed-button-styles": {
    primary: {
      color: colors.primary,
      borderColor: colors.primary,
      fontStyle: "600.semibold",
    },
  },
  "dotted-button-styles": {
    primary: {
      color: colors.primary,
      borderColor: colors.primary,
      fontStyle: "600.normal",
    },
  },
};

const shadowStyles = {
  //shadow for button, icon, modal and card
  lightShadow: {
    shadowColor: "rgb(0,0,0)",
    shadowOffset: {
      width: 2,
      height: 1,
    },
    shadowOpacity: 0.05,
    shadowRadius: 6,
    elevation: 5,
  },
  darkShadow: {
    shadowColor: "rgb(0,0,0)",
    shadowOffset: {
      width: 2,
      height: 1,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
  },
};

const allThemes = {
  light: (svgs, images) => {
    return {
      ...fonts,
      ...fontSizes,
      ...fontVariations,
      ...buttonVariations,
      ...buttonStyles,
      ...colors,
      ...textColor,
      ...backgroundColor,
      ...textInputStyles,
      ...shadowStyles,
      ...constantStyles,
    };
  },
  dark: (svgs, images) => {
    return {
      ...fonts,
      ...fontSizes,
    };
  },
};

export default allThemes;
