import React, { useContext } from "react";
import { LoyaltyTierDetailCards } from "presto-react-components";
import _ from "lodash";
import utils from "../../utils/index";
import moment from "moment";
import UserModel from "@presto-component-models/UserModel";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import AnalyticsManager from "../../common/AnalyticsManager";
import config from "@presto-common/config";

export default function LoyaltyTierDetailCard({
  user,
  wallet,
  loyaltyTiers,
  userLoyaltyTierProgress,
}) {
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", true);
  const { Navigator } = useContext(NavigatorContext);
  const userModel = new UserModel({ user: user });
  userModel.setLoyaltyTiers(loyaltyTiers);
  const tierName = userModel.isLoyaltyTierValid
    ? _.toUpper(userModel.loyaltyTierName)
    : "noTier";

  const loyaltyTierProgressValue = userLoyaltyTierProgress.loyalty_tier_progress
    ? Number(userLoyaltyTierProgress.loyalty_tier_progress)
    : 0;

  const walletObject = () => {
    const totalSpendings = () =>
      Number(Number(loyaltyTierProgressValue).toFixed(noRoundOff ? 2 : 0));
    return {
      zipPoints: () => {
        return wallet.current_balance
          ? (Number(wallet.current_balance) / 100).toFixed(noRoundOff ? 2 : 0)
          : 0;
      },
      totalSavings: () =>
        wallet.total_cashback_received
          ? Number(wallet.total_cashback_received / 100).toFixed(
              noRoundOff ? 2 : 0
            )
          : 0,
      totalSpendings,
    };
  };

  const walletData = walletObject();
  const quarter = moment().quarter() * 3;
  const endOfMonth = moment()
    .month(quarter - 1)
    .endOf("month");

  let forwardedProps = {
    userModel,
    loyaltyInfo: {
      tierName,
      zipPoints: utils.formattedPriceRoundOff.call(
        { currency: "" },
        walletData.zipPoints(),
        false
      ),
      totalSpendings: utils.formattedPriceRoundOff.call(
        { currency: "" },
        walletData.totalSpendings(),
        false
      ),
      totalSavings: utils.formattedPriceRoundOff.call(
        { currency: "" },
        walletData.totalSavings(),
        false
      ),
      validTill: endOfMonth.format("DD/MM/YY"),
    },
    onPressViewMyTransactions: () => {
      AnalyticsManager.send("view_transactions");
      Navigator.emit({
        event: "push",
        params: {
          screenName: "TRANSACTIONS",
        },
      });
    },
  };

  return <LoyaltyTierDetailCards.LoyaltyTierDetailCard2 {...forwardedProps} />;
}
