import { getAnalytics, logEvent } from "firebase/analytics";
import AnalyticsConfig from "@presto-app/analytics";
import CommonAnalytics from "../data/analytics";
import config from "@presto-common/config";
import _ from "lodash";

class AnalyticsManager {
  initialize = () => {
    this.trackedEvents = _.merge(
      CommonAnalytics.tracked_events,
      AnalyticsConfig.tracked_events
    );
  };

  setupEnhancedConversion = (user) => {
    if (window.gtag) {
      console.log("gtag conversion==", window?.gtag, user.email);
      gtag("set", "user_data", {
        email: user?.email,
        phone_number: user?.phone_number,
      });
    }
  };

  send = (eventName, eventAttr) => {
    if (!this.trackedEvents) {
      return;
    }
    const event = this.trackedEvents[eventName];
    if (event) {
      const query = { ...event, ...eventAttr };
      console.log(
        "Sending Event Web: ",
        eventName,
        event,
        query,
        config?.web?.firebase,
        config?.analytics?.firebase
      );
      eventName = query.overrideEventName ? query.overrideEventName : eventName;
      if (config?.web?.firebase && !config?.web?.firebase_analytics_disabled) {
        const analytics = getAnalytics();
        logEvent(analytics, eventName, query);
      }

      if (this.isFBEventsEnabled()) {
        console.log("sending fb event", eventName);
        this.sendFBEvents(eventName, query);
      }

      if (_.isFunction(window?.gtag)) {
        this.sendGA4Events(eventName, query);
      }

      if (this.isConversionEvent(event)) {
        if (_.isFunction(window?.gtag)) {
          this.sendConversionEvents(eventName, query);
        }
      }

      if (this.isCleverTapEventsEnabled()) {
        this.sendCleverTapEvents(eventName, query);
      }
    }
  };

  sendFBEvents = (eventName, eventAttr) => {
    if (eventName === "purchase") {
      window.fbq("track", "Purchase", { currency: "INR", ...eventAttr });
    } else {
      window.fbq("trackCustom", eventName, eventAttr);
    }
  };

  sendGA4Events = (eventName, eventAttr) => {
    console.log("sending ga4 event", [eventName, eventAttr]);
    window.gtag("event", eventName, eventAttr);
  };

  sendConversionEvents = (eventName, eventAttr) => {
    console.log("sending google conversion event", [eventName, eventAttr]);
    window.gtag("event", "conversion", eventAttr);
  };

  isFBEventsEnabled = () => {
    return !_.isEmpty(_.get(config, "analytics.facebook.pixel"));
  };

  reportScreenOpened = (screenName) => {
    console.log("Reporting Screen Opened : ", screenName);
  };

  isConversionEvent = (event) => {
    return !_.isEmpty(_.get(event, "send_to"));
  };

  isCleverTapEventsEnabled = () => {
    return _.get(config, "analytics.clevertap.enabled");
  };

  sendCleverTapEvents = (eventName, eventAttr) => {
    clevertap.event.push(eventName, eventAttr);
  };
}

export default new AnalyticsManager();
