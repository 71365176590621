import React, { useState, useContext, useCallback } from "react";
import { View, FlatList, Image, Dimensions, Platform } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import { VerticalSpacing } from "@presto-components/Spacing";
import I18n from "i18n-js";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import * as Linking from "expo-linking";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import PrestoText from "@presto-components/PrestoText";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import CarouselGallery from "@presto-screen-components/CarouselGallery/CarouselGallery";
import AnalyticsManager from "@presto-common/AnalyticsManager";

export default function PromotionsGallery({
  title,
  imgStyle,
  hideTitle,
  width,
  height,
  name,
  onNavigate,
}) {
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const [gallery, setGallery] = useState([]);

  const { WebNavigator } = useContext(WebNavigatorContext);

  let isDesktop = Dimensions.get("window").width > 940;

  const goToPromotedItems = (deepLink) => {
    if (deepLink == "LOYALTY_POINT_TRANSACTIONS_SCREEN") {
      AnalyticsManager.send("home_zip_points");
      setPaneState(true);
      Navigator.emit({
        event: "jumpTab",
        params: {
          tabName: "ACCOUNT",
          screenName: "LOYALTY_POINT_TRANSACTIONS_SCREEN",
        },
      });
      return;
    }

    var search_query = {};

    var parts = _.split(deepLink, "/");

    if (parts.length == 2) {
      search_query[parts[0]] = parts[1];
    } else {
      search_query = deepLink;
    }

    if (isDesktop || Platform.OS === "web") {
      onNavigate(search_query);
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: search_query,
          },
        },
      });
    }
  };

  const getCategoryDetails = (catId) => {
    onNavigate({ category_id: catId });
  };

  const onItemClicked = (item) => {
    switch (item.target?.type) {
      case "category_item":
        setPaneState(true);
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "DETAILED_ITEM",
            screenParams: { itemId: item.target.id_value },
          },
        });
        return;
      case "url":
        if (Platform.OS == "web") {
          window.open(item.target.id_value, "_blank");
        } else {
          Linking.openURL(item.target.id_value);
        }
        return;
      case "category":
        getCategoryDetails(item.target.id_value);
        return;
      case "app_link":
        goToPromotedItems(item.target.id_value);
        return;
    }
  };

  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response?.data?.media_items);
  };

  useFocusEffect(
    useCallback(() => {
      getGallery(name);
    }, [])
  );

  return (
    <View
      style={{
        paddingVertical: theme.containerPadding - 5,
        paddingHorizontal: isDesktop ? 10 : 0,
      }}
    >
      <CarouselGallery
        title=""
        name={name || "PROMOTED_PRODUCTS"}
        height={height}
        width={width}
        onItemClicked={(e) => onItemClicked(e)}
        borderRadius={8}
      />
    </View>
  );
}
