import Utils from "../common/Utils";
import ErrorConstants from "../common/ErrorConstants";
import $ from "../global/util";

const OutletObject = function (Utils, ErrorConstants) {
  function OutletObject() {
    this.name = "";
    this.description = "";
    this.reference_id = "";
    this.listing_order = 0;
    this.phone_numbers = [];
    this.pic_url = "";
    this.is_closed = false;
    this.email = "";
    this.other_data = {};
    this.delivery_radius = 0.0;
    this.address = {};
    this.id = "";
    this.pictures = [];
    this.latitude = -1;
    this.longitude = -1;
    this.description = "";
    this.full_address = "";
    this.accepting_orders = "";
  }

  OutletObject.prototype = {
    validateObject: function () {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SUCCESS,
        true
      );
      return errorObject;
    },
    buildObject: function (rawOutletObject) {
      this.name = rawOutletObject.name;
      this.description = rawOutletObject.description;
      this.reference_id = rawOutletObject.reference_id;
      this.listing_order = rawOutletObject.listing_order;
      this.phone_numbers = rawOutletObject.phone_numbers;
      this.pic_url = rawOutletObject.pic_url;
      this.is_closed = rawOutletObject.is_closed;
      this.email = rawOutletObject.email;
      this.other_data = rawOutletObject.other_data;
      this.delivery_radius = rawOutletObject.delivery_radius;
      this.address = rawOutletObject.address;
      this.id = rawOutletObject.id;
      this.pictures = rawOutletObject.pictures;
      this.latitude = rawOutletObject.latitude;
      this.longitude = rawOutletObject.longitude;
      this.description = rawOutletObject.description;
      this.full_address = rawOutletObject.full_address;
      this.accepting_orders = rawOutletObject.accepting_orders;
    },
  };

  return OutletObject;
};

export default OutletObject(Utils, ErrorConstants);
