import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Platform } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import I18n from "i18n-js";
import LoadingContainer from "@presto-components/LoadingContainer";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import OffersCard, {
  OffersPrimaryCard,
} from "@presto-screen-components/OffersComponent/OffersCard";
import CouponManager from "@presto-services/features/coupons/CouponManager";
import { prestoAlert } from "../../common/Alert";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import OffersCardModel from "@presto-component-models/OffersCardModel";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";
import AnalyticsManager from "../../common/AnalyticsManager";

export default function OffersScreen() {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { cart, applyCouponToCart, removeCouponFromCart } = useContext(
    CartContext
  );
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);

  const onCouponApply = async (coupon) => {
    const [error, response] = await applyCouponToCart(coupon.code);

    if (error) {
      prestoAlert(utils.errorMessage(error));
    } else {
      if (response.data && !response.data.discount_response) {
        prestoAlert(I18n.t("screen_messages.coupons.coupon_not_applied"));
      } else {
        prestoAlert(
          "",
          I18n.t("screen_messages.coupons.coupon_applied_success_msg")
        );
      }
    }
  };

  const onCouponRemove = async (coupon) => {
    const [error, response] = await removeCouponFromCart(coupon.code);
    if (error) {
      prestoAlert(utils.errorMessage(error));
    }
  };

  useEffect(() => {
    CouponManager.getMyCoupons(
      (response) => {
        setCoupons(response.data);
      },
      (error) => {
        console.log("Coupons response : ", JSON.stringify(error, null, 2));
      }
    );
  }, []);

  const gotoCheckout = () => {
    AnalyticsManager.send("coupons_continue");
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "CART_CHECKOUT_SCREEN",
      },
    });
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.coupons.select_coupon_to_apply")}
        />
        <ScrollView>
          {_.map(coupons, (coupon, index) => {
            return coupon.code === cart.coupon_code ? (
              <OffersPrimaryCard
                key={index}
                coupon={new OffersCardModel({ coupon: coupon })}
                onApply={() => onCouponRemove(coupon)}
              />
            ) : (
              <OffersCard
                coupon={new OffersCardModel({ coupon: coupon })}
                key={index}
                onApply={() => onCouponApply(coupon)}
              />
            );
          })}
        </ScrollView>
        <View
          style={{
            paddingHorizontal: theme.containerPadding,
            paddingVertical: theme.containerPadding - 5,
          }}
        >
          <PrestoSolidButton
            size="large"
            buttonStyle="primary"
            title={I18n.t("screen_messages.common.continue")}
            onPress={gotoCheckout}
            titleColor={theme.white}
          />
        </View>
      </SafeAreaView>
    </LoadingContainer>
  );
}
