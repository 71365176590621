import React, { useContext } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserModel from "@presto-component-models/UserModel";
import moment from "moment";
import utils from "../../utils/index";

export default function LoyaltyInfoStrip({
  user,
  wallet,
  loyaltyTiers,
  userLoyaltyTierProgress,
}) {
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);

  const userModel = new UserModel({ user: user, userLoyaltyTierProgress });
  userModel.setLoyaltyTiers(loyaltyTiers);
  const tierName = userModel.isLoyaltyTierValid
    ? userModel.loyaltyTierName
    : "notier";

  let tierLimitValue = userModel.loyaltyTierBronzeLimitValue / 100;
  const loyalty_tier_progress = Number(
    userLoyaltyTierProgress.loyalty_tier_progress
  );

  const currentTierAsperSpendings = userModel.currentTierAsperSpendings;
  let currentTierAsperSpendingsLimitValue =
    Number(_.get(currentTierAsperSpendings, "limit_value")) / 100;
  if (isNaN(currentTierAsperSpendingsLimitValue)) {
    currentTierAsperSpendingsLimitValue = 0;
  }

  const loyaltyTierProgressValue = loyalty_tier_progress
    ? Number((loyalty_tier_progress / 100).toFixed(0))
    : 0;

  let text = I18n.t("screen_messages.loyalty_tier.update_to_bronze");
  let tier = _.lowerCase(tierName);

  const totalSpendings = () =>
    Number(Number(loyaltyTierProgressValue).toFixed("0"));

  const remainingSpendings = () => {
    let val =
      tierLimitValue >= totalSpendings()
        ? _.subtract(tierLimitValue, totalSpendings())
        : 0;
    return val;
  };

  if (tier === "bronze") {
    let bronzeTierLimitValue = userModel.loyaltyTierBronzeLimitValue / 100;
    tierLimitValue = userModel.loyaltyTierSilverLimitValue / 100;
    if (loyaltyTierProgressValue >= tierLimitValue) {
      text = I18n.t("screen_messages.loyalty_tier.update_to_silver");
    } else if (loyaltyTierProgressValue >= bronzeTierLimitValue) {
      tierLimitValue = userModel.loyaltyTierSilverLimitValue / 100;
      text = I18n.t("screen_messages.loyalty_tier.update_to_silver");
    } else {
      tierLimitValue = userModel.loyaltyTierBronzeLimitValue / 100;
      text = I18n.t(
        "screen_messages.loyalty_tier.maintain_your_zip_tier_status",
        {
          tier: I18n.t("screen_messages.loyalty_tier.title_choices.bronze"),
        }
      );
    }
  } else if (tier === "silver") {
    let silverTierLimitValue = userModel.loyaltyTierSilverLimitValue / 100;
    tierLimitValue = userModel.loyaltyTierGoldLimitValue / 100;
    if (loyaltyTierProgressValue >= tierLimitValue) {
      text = I18n.t("screen_messages.loyalty_tier.update_to_gold");
    } else if (loyaltyTierProgressValue >= silverTierLimitValue) {
      tierLimitValue = userModel.loyaltyTierGoldLimitValue / 100;
      text = I18n.t("screen_messages.loyalty_tier.update_to_gold");
    } else {
      tierLimitValue = userModel.loyaltyTierSilverLimitValue / 100;
      text = I18n.t(
        "screen_messages.loyalty_tier.maintain_your_zip_tier_status",
        {
          tier: I18n.t("screen_messages.loyalty_tier.title_choices.silver"),
        }
      );
    }
  } else if (tier === "gold") {
    tierLimitValue = userModel.loyaltyTierGoldLimitValue / 100;
    if (loyaltyTierProgressValue >= tierLimitValue) {
      text = I18n.t("screen_messages.loyalty_tier.congrats_for_retaining_tier");
    } else {
      text = I18n.t(
        "screen_messages.loyalty_tier.maintain_your_zip_tier_status",
        {
          tier: I18n.t("screen_messages.loyalty_tier.title_choices.gold"),
        }
      );
    }
  }

  const quarter = moment().quarter() * 3;
  const endDateOfCurrentQuater = moment()
    .month(quarter - 1)
    .endOf("month");
  const endDateOfCurrentQuaterFormatted = endDateOfCurrentQuater.format(
    "DD/MM/YY"
  );

  const renderText = () => {
    return (
      <PrestoText
        fontStyle="700.bold"
        size={14}
        extraStyle={styles.heading}
        color={theme.dark}
      >
        {text}
      </PrestoText>
    );
  };

  return (
    <View style={styles.container}>
      <View>{renderText()}</View>
      <View style={{ paddingTop: 5 }}>
        <PrestoText
          fontStyle="400.normal"
          size={14}
          color="#757474"
          extraStyle={styles.heading}
        >
          {I18n.t("screen_messages.loyalty_tier.remaining_amount_to_spend", {
            amount: utils.formattedPriceRoundOff.call(
              { currency: "" },
              remainingSpendings(),
              true
            ),
            date: endDateOfCurrentQuaterFormatted,
          })}
        </PrestoText>
      </View>
    </View>
  );
}
function getStyles(theme) {
  return StyleSheet.create({
    container: {
      backgroundColor: "#F0F0F0",
      paddingVertical: theme.primaryPadding,
    },
    row: {
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "space-between",
    },
    heading: {
      textAlign: "center",
    },
  });
}
