import React, { useContext } from "react";
import { View } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import PrestoText from "@presto-components/PrestoText";
import I18n from "i18n-js";

function LeftRightRow(props) {
  if (props.children.length !== 2) {
    throw new Error(I18n.t("screen_messages.common.total_number_of_children"));
  }
  if (props.leftWidth && props.rightWidth) {
    throw new Error(I18n.t("screen_messages.common.dont_specify_left_right"));
  }

  const leftStyle = props.rightWidth
    ? { width: props.leftWidth, flex: 1 }
    : { width: props.leftWidth };
  const rightStyle = props.leftWidth
    ? { width: props.rightWidth, flex: 1 }
    : { width: props.rightWidth };

  return (
    <View
      style={{
        flexDirection: "row",
        backgroundColor: "transparent",
        justifyContent: "space-between",
        alignItems: "center",
        height: props.height,
      }}
    >
      <View style={leftStyle}>{props.children[0]}</View>
      <View style={rightStyle}>{props.children[1]}</View>
    </View>
  );
}

export default function KeyValue({
  dict,
  onRowClick,
  rightTextColor,
  showDivider = true,
  strikeThrough,
  noLastDivider = false, //for this to work show divider should be false
  rowDetailNumber,
  rowDis
}) {
  const { theme } = useContext(ThemeContext);
  const components = _.map(_.keys(dict), (key, index) => {
    console.log(index !== dict.length - 1, index, Object.keys(dict))
    return (
      <React.Fragment key={`${index}`}>
        <TouchableOpacity
          onPress={() => {
            onRowClick && onRowClick(key, dict[key]);
          }}
          style={{
            height: rowDetailNumber == index ? 40 : 30,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <LeftRightRow>
            <View>
              <PrestoText
                color={theme.paragraph}
                size={13}
                wieght="600"
                extraStyle={{ marginTop: 2 }}
              >
                {key}
              </PrestoText>
              {rowDetailNumber == index ? <PrestoText size={10}>{rowDis}</PrestoText> : <></>}
            </View>
            <PrestoText
              color={rightTextColor || theme.paragraph}
              size={13}
              fontStyle="400.bold"
              extraStyle={{
                marginTop: 2,
                textDecorationLine:
                  strikeThrough && strikeThrough(key) ? "line-through" : "",
              }}
            >
              {dict[key]}
            </PrestoText>
          </LeftRightRow>
        </TouchableOpacity>

        {showDivider ? (
          <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
        ) : null}
        {noLastDivider && (Object.keys(dict).length - 1 !== index) ? (
          <LineDivider lineHeight={1} width="100%" dividerColor="#DADADA" />
        ) : null}
      </React.Fragment>
    );
  });
  return <View>{components}</View>;
}
