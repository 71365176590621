import React, { useContext, useEffect } from "react";
import * as SplashScreen from "expo-splash-screen";
import MainScreens from "@presto-screens/Main/Main";
import asyncify from "../../common/Asyncify";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import SessionHelper from "@presto-helpers/SessionHelper";
import { useMerchantStore } from "@presto-stores/MerchantStore";
import config from "@presto-common/config";
const AsyncSessionHelper = asyncify(SessionHelper);
import AsyncStorage from "@react-native-community/async-storage";
import {
  useGlobalStateStore,
  GlobalStoreKeys,
} from "@presto-stores/GlobalStateStore";

export default function LoadingScreen() {
  const { authState, setUser } = useContext(UserContext);
  const globalStateStore = useGlobalStateStore();
  const merchantStore = useMerchantStore();

  const hideSplashScreen = async () => {
    const [error, response] = await AsyncSessionHelper.isLoggedInUser();
    await SplashScreen.hideAsync().catch(console.warn);
    if (error) {
      setUser(undefined);
    } else {
      setUser(response.data);
      if (response.data?.outlet_id) {
        globalStateStore.setKey(
          GlobalStoreKeys.outletId,
          response.data.outlet_id
        );
      }
      merchantStore.setMerchantId(response.data.merchant_id);
      merchantStore.refreshMerchant();
    }
  };

  const checkAndHideSplashScreen = async () => {
    const previouslyOpened = await AsyncStorage.getItem("PREVIOUSLY_OPENED");
    if (!config.splash_screen_delay || previouslyOpened === "true") {
      hideSplashScreen();
    } else {
      AsyncStorage.setItem("PREVIOUSLY_OPENED", "true");
      setTimeout(() => {
        hideSplashScreen();
      }, config.splash_screen_delay);
    }
  };

  useEffect(() => {
    checkAndHideSplashScreen();
  }, [authState]);

  if (authState === AuthState.unknown) {
    return null;
  } else {
    return <MainScreens />;
  }
}
