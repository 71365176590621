import React, { useState, useContext, useCallback } from "react";
import { View, FlatList, ImageBackground, Dimensions } from "react-native";
import Pressable from "@presto-components/Pressable/Pressable";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import useFocusEffect from "@presto-common/useFocusEffect";
import { VerticalSpacing } from "@presto-components/Spacing";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import * as Linking from "expo-linking";
import I18n from "i18n-js";
import utils from "../../utils";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import PrestoText from "@presto-components/PrestoText";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import { useCatalog } from "@presto-stores/CatalogStore";
import AnalyticsManager from "@presto-common/AnalyticsManager";
let isDesktop = utils.isDesktop();

export default function DealsComponent({ navigation, showImage, title }) {
  const { theme } = useContext(ThemeContext);
  const colors = [
    { backgroundColor: "#53C175", textColor: theme.white },
    { backgroundColor: "#E7D13D", textColor: theme.black },
    { backgroundColor: "#4FD2DD", textColor: theme.black },
  ];

  const { Navigator } = useContext(NavigatorContext);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const catalog = useCatalog((state) => state.catalog);
  const [gallery, setGallery] = useState([]);

  const getGallery = async (name) => {
    const [error, response] = await AsyncGalleryManager.getGallery({ name });
    setGallery(response.data?.media_items);
  };

  const getCategoryDetails = (catId) => {
    setPaneState(true);
    Navigator.emit({
      event: "push",
      params: {
        screenName: "SEARCH_RESULTS_SCREEN",
        screenParams: {
          searchParams: { category: { id: catId }, catalogId: catalog?.id },
        },
      },
    });
  };

  const onItemClicked = (item) => {
    AnalyticsManager.send("deals");
    switch (item.target?.type) {
      case "category_item":
        setPaneState(true);
        Navigator.emit({
          event: "modal",
          params: {
            screenName: "DETAILED_ITEM",
            screenParams: {
              itemId: item.target.id_value,
              catalogId: catalog?.id,
              item: item,
            },
          },
        });
        return;
      case "url":
        Linking.openURL(item.target.id_value);
        return;
      case "category":
        getCategoryDetails(item.target.id_value);
        return;
      case "app_link":
        initAppLink(item.target.id_value);
    }
  };

  const initAppLink = (deepLink) => {
    if (deepLink == "deals") {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "VIEW_ALL_DEALS",
          screenParams: { catalogId: catalog?.id },
        },
      });
    } else {
      var search_query = {};

      var parts = _.split(deepLink, "/");
      if (parts.length == 2) {
        search_query[parts[0]] = parts[1];
      } else {
        search_query.query = deepLink;
      }
      setPaneState(true);
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: search_query,
            catalogId: catalog?.id,
          },
        },
      });
    }
  };

  useFocusEffect(
    useCallback(() => {
      getGallery("SPONSORED_PRODUCTS");
    }, [])
  );

  const renderItem = ({ item, index }) => {
    const randInt = index % colors.length;
    const randomColor = index === 0 ? colors[0] : colors[randInt];

    if (item?.file_url.search(".transparent.png") < 0) {
      return (
        <Pressable onPress={() => onItemClicked(item)}>
          <ImageBackground
            style={{
              width: 250,
              height: 115,
              borderRadius: 15,
              overflow: "hidden",
              marginHorizontal: theme.primaryPadding,
            }}
            source={{ uri: item.file_url }}
            resizeMode="cover"
          />
        </Pressable>
      );
    }
    return (
      <Pressable onPress={() => onItemClicked(item)}>
        <View
          key={index + ""}
          style={{
            marginHorizontal: theme.primaryPadding,
            width: 250,
            height: 115,
            backgroundColor:
              item?.other_data?.background_color || randomColor.backgroundColor,
            borderRadius: 15,
            overflow: "hidden",
            padding: 10,
          }}
        >
          <PrestoText
            size={24}
            color={item.other_data?.text_color || randomColor.textColor}
            fontStyle="bold"
          >
            {item.name}
          </PrestoText>
          <PrestoText
            fontStyle="normal"
            size={14}
            color={item.other_data?.text_color || randomColor.textColor}
          >
            {item.description}
          </PrestoText>
        </View>
      </Pressable>
    );
  };
  const onPressViewAll = () => {
    setPaneState(true);

    Navigator.emit({
      event: "push",
      params: { screenName: "VIEW_ALL_DEALS" },
    });
  };
  return gallery.length > 0 ? (
    <>
      <View
        style={{
          paddingVertical: theme.containerPadding - 5,
          paddingHorizontal: isDesktop ? 10 : 0,
        }}
      >
        <View
          style={{
            paddingHorizontal: theme.containerPadding,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            paddingBottom: 15,
          }}
        >
          <PrestoText fontStyle="semibold" size={16} color={theme.primary}>
            {title || I18n.t("screen_messages.deals.deals_text")}
          </PrestoText>

          <PrestoFramedButton
            buttonStyle="primary"
            size="small"
            title={I18n.t("screen_messages.common.view_all_uppercase")}
            titleColor={theme.primary}
            borderColor={theme.primary}
            width={100}
            borderRadius={6}
            extraStyle={{ marginRight: -10 }}
            onPress={() => onPressViewAll()}
            titleExtraStyle={{ textTransform: "uppercase" }}
          />
        </View>
        <VerticalSpacing size={5} />
        <FlatList
          horizontal
          keyExtractor={(_, index) => "index-" + index}
          data={gallery}
          renderItem={renderItem}
          swipeThreshold={10}
          layout={"default"}
          showsHorizontalScrollIndicator={false}
        />
      </View>
    </>
  ) : null;
}
