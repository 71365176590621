import React, { useState, useContext, useEffect } from "react";
import { View, ScrollView, Dimensions } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext, { AuthState } from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import SearchResults from "@presto-screen-components/SearchResults/SearchResults";
import SideNavigation from "@presto-screen-components/SideNavigation/SideNavigation";
import WebHeader from "@presto-components/Headers/WebHeader";
import WebHomeOrdersList from "@presto-screen-components/WebHomeOrdersList/WebHomeOrdersList";
import NavigationManager from "../../common/NavigationManager";
import TouchableWithoutFeedback from "@presto-components/TouchableWithoutFeedback/TouchableWithoutFeedback";
import LoyaltyTierManager from "@presto-services/features/loyalty_tier/LoyaltyTierManager";

export default function WebHome({ route, navigation }) {
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const { authState } = useContext(UserContext);
  const showTrackOrderFloatingCard = false;
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const [loading, setLoading] = useState(false);
  const [loyaltyTiers, setLoyaltyTiers] = useState([]);
  const [searchQry, setSearchQuery] = useState(
    route.params || { bestsellers: true }
  );
  useEffect(() => {
    if (route.params?.searchParams) {
      setSearchQuery(route.params);
      navigation.setParams(route.params);
    }
  }, [route.params]);

  const listTiers = () => {
    function onSuccess(response) {
      setLoyaltyTiers(response.data);
    }
    function onError(error) {
      console.log("Home -> listTiers -> error", error);
    }
    LoyaltyTierManager.listTiers({}, onSuccess, onError);
  };

  useEffect(() => {
    listTiers();
  }, []);

  const onNavigate = (path) => {
    switch (path) {
      case "FAVOURITES":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: "MY_PRODUCTS",
            pop: true,
          },
        });
        break;
      case "ACCOUNT":
      case "CART":
        setPaneState(true);
        Navigator.emit({
          event: "popToTop",
        });
        Navigator.emit({
          event: "switchTab",
          params: {
            tabName: path,
            pop: true,
          },
        });
        break;
      case "HOME":
        setPaneState(false);
    }
  };

  const onSideNavigate = (params) => {
    setSearchQuery(params);

    navigation.setParams({
      query: undefined,
      category_id: undefined,
      is_new: undefined,
      bestsellers: undefined,
      company_name: undefined,
      molecule: undefined,
      therapy_area_simple: undefined,
      brand_name: undefined,
      region: undefined,
      ...params,
    });
  };
  return (
    <>
      <WebHeader onNavigate={onNavigate} />
      <View
        style={{
          flexDirection: "row",
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          zIndex: -1,
        }}
      >
        <View
          style={{
            width: "20%",
            height: "100%",
            minWidth: 200,
            borderWidth: 0.1,
            borderColor: theme.imageBorderColor,
          }}
        >
          <SideNavigation
            onNavigate={onSideNavigate}
            loyaltyTiers={loyaltyTiers}
            setParentLoading={setLoading}
          />
        </View>
        <ScrollView style={{ height: "100%", zIndex: -1 }}>
          <View
            style={{
              flex: 1,
              zIndex: -1,
              paddingBottom: 100,
            }}
          >
            <SearchResults
              showDeals={false}
              searchObj={searchQry}
              showNoResultText={true}
              useMultiSearch={true}
            />
          </View>
        </ScrollView>
        {showTrackOrderFloatingCard ? <WebHomeOrdersList /> : null}
        <TouchableWithoutFeedback onPress={() => setPaneState(false)}>
          <View
            style={{
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              backgroundColor: "#00000050",
              position: "absolute",
              display: rightPaneOpen ? "flex" : "none",
            }}
          >
            <TouchableWithoutFeedback onPress={() => {}}>
              <View
                style={{
                  width: 400,
                  alignSelf: "flex-start",
                  position: "absolute",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  borderColor: theme.primary,
                  backgroundColor: "green",
                  borderWidth: 1,
                  overflow: "hidden",
                  right: 0,
                  top: 0,
                  height: "90%",
                  zIndex: 300,
                  display: rightPaneOpen ? "flex" : "none",
                }}
              >
                {NavigationManager.renderTree(
                  authState === AuthState.userLoggedIn,
                  true
                )}
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </>
  );
}
