import _ from "lodash";
import moment from "moment";
import create from "zustand";
import { devtools } from "zustand/middleware";
import StylistHelper from "@presto-helpers/StylistHelper";
import SearchManager from "@presto-services/features/search/SearchManager";
import CategoryObject from "@presto-services/data_models/CategoryObject";
import config from "@presto-common/config";

let createStylists = (set, get) => ({
  ready: false,
  stylists: {},
  stylistCategoryHash: {},
  loading: false,
  lastTryTime: moment().subtract(1, "day"),
  init: () => {
    set({ ready: true, lastTryTime: moment().subtract(1, "day") });
  },
  fetch: async (force = false) => {
    const { loading: isLoading, lastTryTime } = get();
    if (!force && moment() - moment(lastTryTime) < 60000) {
      return;
    }
    if (!isLoading || force) {
      set({ loading: true, lastTryTime: new Date() });
      await getStylists(set);
    }
  },
  setStylistsDirectly: (userDetails) => {
    set({
      stylists: userDetails.stylists,
      loaded: true,
      ready: true,
    });
  },
  reset: () => {
    set({
      stylists: {},
      lastTryTime: moment().subtract(1, "day"),
      loading: false,
      ready: false,
    });
  },
  refresh: () => {
    get().fetch(true);
  },
  get: () => {
    const stylists = get().stylists;
    if (stylists) {
      return stylists;
    } else {
      // get().fetch();
      return undefined;
    }
  },
  getStylist: (id) => {
    let operator = get().stylists?.find((ele) => {
      if (ele.id === id) return ele;
    });
    return operator;
  },
  getStylistByReferenceId: (id) => {
    let operator = get().stylists?.find((ele) => {
      if (ele.reference_id === id) return ele;
    });
    return operator;
  },
  getStylistBgColor: (stylist) => {
    let selectedStylist = _.find(
      get().stylistCategoryHash,
      (val) => stylist?.refId === val.ref_id
    );
    return selectedStylist ? selectedStylist.bg_color : stylist.color;
  },
});

const fetchServiceCategories = async (set, stylists) => {
  let promises = _.map(stylists, (stylist) => {
    return new Promise((resolve, reject) => {
      SearchManager.getReferenceIdBasedServices(
        { service_ids: stylist.service_ids },
        (response) => {
          let services = createServiceItemObjects(response.hits.hits);
          let categoryIds = _.map(services, (service) => {
            return service.category_id;
          });

          SearchManager.searchCategory(
            {
              category_ids: _.uniq(categoryIds),
              merchant_id: stylist.merchant_id,
            },
            (response) => {
              let categories = createCategoriesObject(response.hits.hits);
              resolve({
                categories: categories,
                stylist: stylist,
              });
            },
            (error) => {
              resolve({});
              console.log("searchCategory Error :>> ", error);
            }
          );
        },
        (error) => {
          resolve({});
          console.log("getReferenceIdBasedServices Error :>> ", error);
        }
      );
    });
  });

  Promise.all(promises)
    .then((results) => {
      let stylistCategory = _.map(results, (res) => {
        let categories = _.get(res, "categories", []);
        let category =
          categories.length > 1
            ? _.find(
              categories,
              (categoryItem) =>
                !_.isEmpty(categoryItem?.other_data?.color_code)
            )
            : _.first(categories);

        let colorCode = category?.other_data?.color_code;

        let defaultColor = config?.constants?.default_category_color_code;

        let bgColor =
          categories.length > 1
            ? defaultColor
            : !_.isEmpty(colorCode)
              ? colorCode
              : defaultColor;

        return {
          ref_id: res?.stylist?.reference_id,
          bg_color: bgColor,
        };
      });
      set({ stylistCategoryHash: stylistCategory });
    })
    .catch((error) => {
      console.log("promise error = > ");
      console.log(error);
    });
};

const createCategoriesObject = (rawcategories) => {
  return _.map(rawcategories, (rawCategory) => {
    let a = new CategoryObject();
    a.buildObject(rawCategory._source);
    return a;
  });
};

const createServiceItemObjects = (rawServiceItems) => {
  return _.map(rawServiceItems, (rawServiceItem) => {
    return rawServiceItem._source;
  });
};

const getStylists = async (set) => {
  StylistHelper.stylistsBasedOnUserType(
    {},
    (response) => {
      set({ stylists: response.data, loaded: true });
      fetchServiceCategories(set, response.data);
    },
    (error) => {
      console.log("getStylists Error :>> ", error);
      set(null);
    }
  );
};

export const useStylists = create(devtools(createStylists));
