import React from "react";
import { Image, View } from "react-native";
import { Card } from "@presto-components/Cards/WebCards";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import PrestoIcon from "@presto-components/PrestoIcon";
import PrestoText from "@presto-components/PrestoText";
import { VerticalSpacing } from "@presto-components/Spacing";
import { Components } from "presto-react-components";
const AmountInput = Components.AmountInput;
import I18n from "i18n-js";
import { DotIndicator } from "react-native-indicators";
import Pressable from "@presto-components/Pressable/Pressable";
import { useAssets } from "presto-react-components";

export default function WebItemCard({
  product,
  onPress,
  onAdd,
  onSubtract,
  updating,
  onUpdate,
  color = "#53C175",
  priceAccessorMethod,
}) {
  const { svgs } = useAssets();

  const priceHandler = () => {
    return _.isFunction(priceAccessorMethod)
      ? priceAccessorMethod(product)
      : product.price;
  };
  return (
    <Card padding={10} key={product.productId}>
      <View
        style={{
          display: "flex",
          width: product.computedWidth || 230,
          height: 180,
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              width: product.picURL ? "70%" : "100%",
            }}
          >
            <View style={{ flex: 1 }}>
              <Pressable
                onPress={() => onPress(product.product, product.variation)}
              >
                <PrestoText fontStyle="semibold" size={14} numberOfLines={2}>
                  {product.name}
                </PrestoText>
              </Pressable>
              <Pressable
                onPress={() => onPress(product.product, product.variation)}
              >
                <PrestoText size={14} numberOfLines={2}>
                  {product.companyName}
                </PrestoText>
              </Pressable>
              {product.product.other_data.sponsored && product.product.other_data.sponsored=="yes" &&
              <>
                <VerticalSpacing size={5} />
                <View style={{}}>
                  <PrestoText fontStyle="semibold" size={14} color={color}>
                    {I18n.t("screen_messages.sponsored_text")}
                  </PrestoText>
                </View>
                <VerticalSpacing size={5} />
              </>}

              {/* <VerticalSpacing size={20} /> */}
              <View style={{}}>
                <PrestoText
                  fontStyle={{
                    fontSize: 10,
                    color: "#666666",
                  }}
                  numberOfLines={2}
                >
                  {product.molecule}
                </PrestoText>
              </View>
              <VerticalSpacing size={20} />
              <PrestoText fontStyle="semibold" size={14} color={"#666666"}>
                {priceHandler()}
              </PrestoText>
            </View>
          </View>

          {product.picURL ? (
            <View style={{ width: 55, height: 55, marginRight: 10 }}>
              <Pressable
                onPress={() => onPress(product.product, product.variation)}
              >
                <Image
                  source={{ uri: product.picURL }}
                  resizeMode="cover"
                  style={{
                    width: 55,
                    height: 55,
                    borderRadius: 10,
                    overflow: "hidden",
                    borderWidth: 0.1,
                    borderColor: "#E3E3E3",
                  }}
                />
              </Pressable>
            </View>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "space-between",
            }}
          >
            {updating ? (
              <View
                style={{
                  alignItems: "center",
                  height: 40,
                }}
              >
                <DotIndicator
                  color={color || "#53C175"}
                  maxScale={1.2}
                  minScale={0.2}
                  size={8}
                />
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {product.hasOffers ? (
                  <PrestoFramedButton
                    buttonStyle="primary"
                    size="normal"
                    width={120}
                    title={I18n.t("screen_messages.count_offers", {
                      count: product.offersCount,
                    })}
                    onPress={() => onPress(product.product, product.variation)}
                  />
                ) : product.count == 0 ? (
                  <Pressable
                    onPress={() => onAdd(product.product, product.variation)}
                  >
                    <View style={{ width: 35, height: 35 }}>
                      <PrestoIcon
                        icon={
                          <svgs.PlusIcon primaryColor={color || "#53C175"} />
                        }
                      />
                    </View>
                  </Pressable>
                ) : (
                  <>
                    <Pressable
                      onPress={() =>
                        onSubtract(product.product, product.variation)
                      }
                    >
                      <View
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      >
                        <PrestoIcon
                          icon={<svgs.MinusIcon color={color || "#53C175"} />}
                        />
                      </View>
                    </Pressable>
                    <AmountInput
                      size={40}
                      amount={product.count}
                      textSize={16}
                      onUpdate={(amount) => onUpdate(product.variation, amount)}
                    />
                    <Pressable
                      onPress={() => onAdd(product.product, variation)}
                    >
                      <View
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      >
                        <PrestoIcon
                          icon={
                            <svgs.PlusIcon primaryColor={color || "#53C175"} />
                          }
                        />
                      </View>
                    </Pressable>
                  </>
                )}
              </View>
            )}
          </View>
        </View>
      </View>
    </Card>
  );
}
