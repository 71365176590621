import Utils from "../../common/Utils";
import SearchResource from "./SearchResource";
import ErrorConstants from "../../common/ErrorConstants";
import $ from "../../global/util";

const SearchManager = function (Utils, SearchResource, ErrorConstants) {
  function searchCategoryItem(params, successCallback, errorCallback) {
    SearchResource.searchCategoryItem(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function fuzzySearchCategoryItem(params, successCallback, errorCallback) {
    SearchResource.fuzzySearchCategoryItem(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        console.log("errrrr",error)
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function searchSellerItem(params, successCallback, errorCallback) {
    SearchResource.searchSellerItem(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function searchCategory(params, successCallback, errorCallback) {
    SearchResource.searchCategory(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function searchBrand(params, successCallback, errorCallback) {
    if (
      !params ||
      Utils.isEmpty(params.brand || Utils.isEmpty(params.category_id))
    ) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.searchBrand(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getFilteredBrands(params, successCallback, errorCallback) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.getFilteredBrands(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getFilteredOtherDataAttributes(
    params,
    attributeName,
    successCallback,
    errorCallback
  ) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.getFilteredOtherDataAttributes(params, attributeName).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getZipHealthFilteredOtherDataAttributes(
    params,
    attributeName,
    successCallback,
    errorCallback
  ) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.getZipHealthFilteredOtherDataAttributes(
        params,
        attributeName
      ).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function searchService(params, successCallback, errorCallback) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.searchService(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function searchSellers(params, successCallback, errorCallback) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.searchSellers(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function searchLocationBasedSellerItems(
    params,
    successCallback,
    errorCallback
  ) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.searchLocationBasedSellerItems(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function searchLocationBasedSellerServices(
    params,
    successCallback,
    errorCallback
  ) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.searchLocationBasedSellerServices(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function searchLocationBasedMerchants(
    params,
    successCallback,
    errorCallback
  ) {
    SearchResource.searchLocationBasedMerchants(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function searchMerchantTickets(params, successCallback, errorCallback) {
    SearchResource.searchMerchantTickets(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }
  function getFilteredMerchants(params, successCallback, errorCallback) {
    if (!params) {
      var errorObject = Utils.ErrorObject(
        ErrorConstants.ErrorCodes.SEARCH_STRING_EMPTY,
        false
      );
      errorCallback(errorObject);
    } else {
      SearchResource.getFilteredMerchants(params).then(
        function (searchResponse) {
          successCallback(searchResponse.data);
        },
        function (error) {
          error = Utils.updateErrorObject(error);
          errorCallback(error);
        }
      );
    }
  }

  function getAllServices(params, successCallback, errorCallback) {
    SearchResource.getAllServices(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);

        errorCallback(error);
      }
    );
  }

  function getAllServicesBasedOnSearchString(
    params,
    successCallback,
    errorCallback
  ) {
    SearchResource.getAllServicesBasedOnSearchString(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);

        errorCallback(error);
      }
    );
  }

  function getReferenceIdBasedServices(params, successCallback, errorCallback) {
    SearchResource.getReferenceIdBasedServices(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getReferenceIdBasedProducts(params, successCallback, errorCallback) {
    SearchResource.getReferenceIdBasedProducts(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getServiceVariation(params, successCallback, errorCallback) {
    SearchResource.getServiceVariation(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getSponsoredSlot(params, successCallback, errorCallback) {
    SearchResource.getSponsoredSlot(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getServicesBasedOnTags(params, successCallback, errorCallback) {
    SearchResource.getServicesBasedOnTags(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);

        errorCallback(error);
      }
    );
  }

  function getCategoryItemsBasedOnQueries(
    params,
    successCallback,
    errorCallback
  ) {
    SearchResource.getCategoryItemsBasedOnQueries(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);

        errorCallback(error);
      }
    );
  }

  function getMerchantInfo(params, successCallback, errorCallback) {
    SearchResource.getMerchantInfo(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function strictSearch(params, successCallback, errorCallback) {
    SearchResource.strictSearch(params).then(
      function (searchResponse) {
        successCallback(searchResponse.data);
      },
      function (error) {
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getAllServicesBasedOnSearchString: getAllServicesBasedOnSearchString,
    searchCategoryItem: searchCategoryItem,
    fuzzySearchCategoryItem: fuzzySearchCategoryItem,
    searchBrand: searchBrand,
    getFilteredBrands: getFilteredBrands,
    searchCategory: searchCategory,
    searchSellerItem: searchSellerItem,
    searchService: searchService,
    searchSellers: searchSellers,
    searchLocationBasedSellerItems: searchLocationBasedSellerItems,
    searchLocationBasedSellerServices: searchLocationBasedSellerServices,
    searchLocationBasedMerchants: searchLocationBasedMerchants,
    searchMerchantTickets: searchMerchantTickets,
    getFilteredMerchants: getFilteredMerchants,
    getFilteredOtherDataAttributes: getFilteredOtherDataAttributes,
    getAllServices: getAllServices,
    getReferenceIdBasedServices: getReferenceIdBasedServices,
    getReferenceIdBasedProducts: getReferenceIdBasedProducts,
    getServiceVariation: getServiceVariation,
    getServicesBasedOnTags: getServicesBasedOnTags,
    getCategoryItemsBasedOnQueries: getCategoryItemsBasedOnQueries,
    getZipHealthFilteredOtherDataAttributes: getZipHealthFilteredOtherDataAttributes,
    getMerchantInfo: getMerchantInfo,
    getSponsoredSlot: getSponsoredSlot,
    strictSearch,
  };
};

export default SearchManager(Utils, SearchResource, ErrorConstants);
