import _ from "lodash";
import { Platform } from "react-native";

const DEFAULT_DOT_RADIUS = 11;

export default function styles(theme, images) {
  const currentStyles = {
    primaryButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 18,
        fontWeight: "500",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 50,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.primaryButtonColor,
      },
    },
    specialButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 14,
        fontWeight: "500",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 38,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.primaryButtonColor,
      },
    },
    smallPrimaryButton: {
      title: {
        color: theme.primaryButtonTextColor,
        textTransform: "capitalize",
        fontFamily: theme.primaryMediumFont,
      },
      container: {
        minWidth: 75,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 5,
        paddingVertical: 2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.primaryButtonColor,
        flex: 1,
        ...Platform.select({
          ios: {
            height: 25,
          },
          android: {
            height: 25,
          },
        }),
        justifyContent: "space-evenly",
      },
      iconStyle: {
        color: theme.primaryButtonTextColor,
        fontSize: 20,
        fontWeight: "600",
        height: 20,
        lineHeight: 18,
      },
    },
    smallSecondaryButton: {
      title: {
        color: theme.primaryButtonTextColor,
        textTransform: "capitalize",
        fontFamily: theme.primaryMediumFont,
      },
      container: {
        minWidth: 75,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 5,
        paddingVertical: 2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.secondaryColor,
        flex: 1,
        ...Platform.select({
          ios: {
            height: 25,
          },
          android: {
            height: 25,
          },
        }),
        justifyContent: "space-evenly",
      },
      iconStyle: {
        color: theme.primaryButtonTextColor,
        fontSize: 20,
        fontWeight: "600",
        height: 20,
        lineHeight: 18,
      },
    },
    addSubstractButton: {
      title: {
        color: theme.primaryButtonTextColor,
        textTransform: "capitalize",
        fontFamily: theme.primaryMediumFont,
        fontSize: 16,
      },
      container: {
        minWidth: 100,
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 5,
        paddingVertical: 2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.secondaryButtonColor,
        // flex: 1,
        justifyContent: "space-evenly",
      },
      iconStyle: {
        color: theme.primaryButtonTextColor,
        fontSize: 20,
        fontWeight: "600",
        height: 20,
        lineHeight: 18,
      },
    },
    secondaryAddSubstractButton: {
      title: {
        color: theme.primaryButtonTextColor,
        textTransform: "capitalize",
        fontFamily: theme.primaryMediumFont,
      },
      container: {
        minWidth: 100,
        minHeight: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 5,
        paddingVertical: 2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.secondaryColor,
        flex: 1,
        justifyContent: "space-evenly",
      },
      iconStyle: {
        color: theme.primaryButtonTextColor,
        fontSize: 20,
        fontWeight: "600",
        height: 20,
        lineHeight: 18,
      },
    },
    primaryAddSubstractButton: {
      title: {
        color: theme.primaryButtonTextColor,
        textTransform: "capitalize",
        fontFamily: theme.primaryMediumFont,
      },
      container: {
        minWidth: 100,
        width: "100%",
        minHeight: 50,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 5,
        paddingVertical: 2,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.secondaryColor,
        flex: 1,
        justifyContent: "space-evenly",
      },
      iconStyle: {
        color: theme.primaryButtonTextColor,
        fontSize: 20,
        fontWeight: "600",
        height: 20,
        lineHeight: 18,
      },
    },
    tabButtons: {
      title: {
        color: theme.TertiaryButtonTextColor,
        fontSize: 16,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 145,
        height: 45,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: "transperant",
      },
    },
    primarySmallButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 100,
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.primaryButtonColor,
      },
    },
    secondarySmallButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 100,
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        shadowOpacity: 0,
        backgroundColor: theme.secondaryButtonColor,
      },
    },
    tertiaryButton: {
      title: {
        color: theme.primaryColor,
        fontSize: 18,
        fontWeight: "600",
        textTransform: "capitalize",
        fontFamily: theme.primaryFont,
      },
      container: {
        minWidth: 100,
        shadowOpacity: 0,
        height: 50,
        width: "100%",
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        shadowOpacity: 0,
        alignSelf: "center",
        borderWidth: 0.8,
        borderColor: theme.primaryColor,
        // backgroundColor: theme.tertiaryColor,
      },
    },

    tertiaryMediumButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 14,
        fontWeight: "500",
        textTransform: "capitalize",
        fontFamily: theme.primaryMediumFont,
      },
      container: {
        minWidth: 100,
        shadowOpacity: 0,
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        shadowOpacity: 0,
        alignSelf: "center",
        backgroundColor: theme.tertiaryColor,
      },
    },
    facebookButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 18,
        fontWeight: "600",
        textTransform: "capitalize",
        fontFamily: theme.primarySemiBoldFont,
      },
      container: {
        minWidth: 145,
        height: 50,
        width: "98%",
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "center",
        shadowOpacity: 0,
        alignSelf: "flex-start",
        backgroundColor: theme.facebookButtonBg,
      },
    },
    googleButton: {
      title: {
        color: theme.googleBtnBorderColor,
        fontSize: 18,
        fontWeight: "600",
        textTransform: "capitalize",
        fontFamily: theme.primaryBoldFont,
      },
      container: {
        minWidth: 145,
        height: 50,
        borderRadius: theme.primaryBorderRadius,
        width: "98%",
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: theme.googleButtonBg,
        shadowOpacity: 0,
      },
    },
    secondarySolidButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
        fontFamily: theme.primarySemiBoldFont,
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 50,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: theme.secondaryColor,
        shadowOpacity: 0,
      },
    },
    tertiarySolidButton: {
      title: {
        color: "#ffffff",
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
        fontFamily: theme.primarySemiBoldFont,
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 50,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: "#e3e3e3",
        shadowOpacity: 0,
      },
    },
    primarySolidButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 14,
        fontWeight: "600",
        textTransform: "capitalize",
        fontFamily: theme.primarySemiBoldFont,
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 50,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: theme.primaryColor,
        shadowOpacity: 0,
      },
    },
    secondaryMediumButton: {
      title: {
        color: theme.primaryButtonTextColor,
        fontSize: 12,
        fontWeight: "600",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 100,
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.secondaryColor,
        shadowOpacity: 0,
      },
    },
    textInputSubmitButton: {
      title: {
        color: theme.TertiaryButtonTextColor,
        fontSize: 12,
        fontFamily: theme.primaryFont,
      },
      container: {
        minWidth: 100,
        marginLeft: 15,
        marginRight: 5,
        height: 32,
        borderRadius: theme.primaryBorderRadius,
        shadowOpacity: 0,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        backgroundColor: theme.tertiaryColor,
      },
    },
    transperantButton: {
      title: {
        fontSize: 14,
        color: "#2B2B2B",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 38,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        borderWidth: 0.5,
        shadowOpacity: 0,
        borderColor: theme.transparentBtnBorderColor,
        backgroundColor: "transparent",
      },
    },
    transperantSmallButton: {
      title: {
        fontSize: 14,
        color: "#2B2B2B",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 60,
        width: "100%",
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 10,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        borderWidth: 0.5,
        shadowOpacity: 0,
        borderColor: theme.transparentBtnBorderColor,
        backgroundColor: "transparent",
      },
    },
    primaryDropdownButton: {
      title: {
        fontSize: 12,
        color: theme.tertiaryHeadingColor,
        textTransform: "capitalize",
        maxWidth: 70,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
      container: {
        minWidth: 100,
        width: "100%",
        height: 35,
        borderRadius: theme.primaryBorderRadius,
        paddingHorizontal: 15,
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "center",
        borderWidth: 0.5,
        shadowOpacity: 0,
        borderColor: theme.dropdownBtnBorderColor,
        backgroundColor: "transparent",
      },
    },
    secondaryTransperantButton: {
      title: {
        fontSize: 14,
        color: theme.secondaryColor,
        fontWeight: "500",
        textTransform: "capitalize",
      },
      container: {
        minWidth: 145,
        width: "100%",
        height: 38,
        borderRadius: theme.primaryBorderRadius,
        borderColor: theme.secondaryColor,
        paddingHorizontal: 15,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
        borderWidth: 0.8,
        shadowOpacity: 0,
        backgroundColor: "transparent",
      },
    },
    textInput: {
      container: {
        width: "100%",
      },
      labelContainer: {
        height: 15,
        width: "100%",
        flexDirection: "row",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        paddingLeft: theme.primaryBorderRadius,
      },
      label: {
        fontSize: 12,
        color: theme.textInputLabelColor,
      },
      textInputStyle: {
        width: "100%",
        height: 50,
        borderWidth: 0,
        borderRadius: theme.primaryBorderRadius,
        fontSize: 16,
        fontFamily: theme.primaryMediumFont,
        padding: theme.primaryBorderRadius,
        color: theme.textInputColor,
      },
      textinputContainer: {
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        height: 50,
        borderWidth: 1,
        borderRadius: theme.primaryBorderRadius,
        borderColor: theme.textInputBorderColor || "#CCCCCC",
      },
      placeHolder: {
        textColor: theme.placeHolderColor,
      },
    },
    plainOneLineTextButton: {
      title: {
        textTransform: "none",
        color: theme.variationDropDownColor,
        fontFamily: theme.primaryFont,
        fontSize: 14,
        paddingBottom: 5,
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
      container: {
        paddingHorizontal: theme.primaryHorizontalPadding,
        borderRadius: theme.primaryBorderRadius,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: theme.variationDropDownColor,
        minWidth: 45,
        flexDirection: "row",
      },
      itemCount: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
      subTitle: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
    },
    largePlainTextButton: {
      title: {
        textTransform: "none",
        color: theme.variationDropDownColor,
        fontFamily: theme.primaryFont,
        fontSize: 14,
        // paddingBottom: 5,
      },
      titleContainer: {
        maxWidth: "75%",
      },
      container: {
        paddingHorizontal: theme.primaryHorizontalPadding,
        borderRadius: theme.primaryBorderRadius,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: theme.variationDropDownColor,
        minWidth: 45,
        height: 45,
        flexDirection: "row",
      },
      itemCount: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
      subTitle: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
    },
    plainTextButton: {
      title: {
        textTransform: "none",
        color: theme.variationDropDownColor,
        fontFamily: theme.primaryFont,
        fontSize: 14,
        // paddingBottom: 5,
      },
      titleContainer: {
        maxWidth: "70%",
      },
      container: {
        paddingHorizontal: theme.primaryHorizontalPadding,
        borderRadius: theme.primaryBorderRadius,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: theme.variationDropDownColor,
        minWidth: 45,
        minHeight: 55,
        flexDirection: "row",
      },
      itemCount: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
      subTitle: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
    },
    boldTextButton: {
      title: {
        textTransform: "none",
        color: theme.variationDropDownColor,
        fontFamily: theme.primaryBoldFont,
        fontSize: 14,
        // paddingBottom: 5,
      },
      titleContainer: {
        maxWidth: "70%",
      },
      container: {
        paddingHorizontal: theme.primaryHorizontalPadding,
        borderRadius: theme.primaryBorderRadius,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: theme.variationDropDownColor,
        minWidth: 45,
        minHeight: 55,
        flexDirection: "row",
      },
      itemCount: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
      subTitle: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
    },
    darkTextButton: {
      title: {
        textTransform: "none",
        color: theme.TertiaryButtonTextColor,
        fontFamily: theme.primaryMediumFont,
        fontSize: 14,
        paddingBottom: 5,
      },
      container: {
        paddingHorizontal: theme.primaryHorizontalPadding,
        borderRadius: theme.primaryBorderRadius,
        width: "100%",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "transparent",
        borderWidth: 0,
        borderColor: theme.variationDropDownColor,
        minWidth: 45,
        flexDirection: "row",
      },
      itemCount: {
        color: theme.secondaryColor,
        fontSize: 12,
        textTransform: "none",
      },
      subTitle: {
        color: theme.secondaryColor,
        fontSize: 14,
        textTransform: "none",
      },
    },
    sectionSaperator: {
      height: 1,
      borderBottomWidth: 1,
      width: "110%",
      borderColor: theme.sectionDeviderColor,
      marginLeft: -10,
    },
    menuItemSaperator: {
      height: 1,
      borderTopWidth: 0.5,
      width: "110%",
      borderColor: theme.sectionDeviderColor,
      marginLeft: -10,
    },
    menuHeading: {
      color: theme.secondaryColor,
      fontWeight: "600",
      fontSize: 16,
    },
    textInputWithButton: {
      container: {
        width: "100%",
      },
      labelContainer: {
        height: 15,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingRight: 10,
        paddingRight: theme.primaryBorderRadius,
        marginBottom: 0,
        justifyContent: "space-between",
      },
      label: {
        fontSize: 10,
        paddingLeft: 15,
        color: theme.textInputLabelColor,
        marginBottom: 5,
      },
      textInputStyle: {
        flex: 1,
        height: 50,
        borderRadius: theme.primaryBorderRadius,
        fontSize: 14,
        fontFamily: theme.primaryMediumFont,
        padding: theme.primaryBorderRadius,
        marginRight: 10,
        color: theme.textInputColor,
        paddingLeft: 15,
      },
      textinputContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: 40,
        borderWidth: 1,
        borderRadius: theme.primaryBorderRadius,
        borderColor: theme.textInputBorderColor || "#CCCCCC",
      },
      placeHolder: {
        textColor: theme.placeHolderColor,
      },
    },
    primaryTextInputStyle: {
      container: {
        width: "100%",
      },
      labelContainer: {
        height: 15,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingRight: 10,
        paddingRight: theme.primaryBorderRadius,
        marginBottom: 0,
        justifyContent: "space-between",
      },
      label: {
        fontSize: 10,
        paddingLeft: 15,
        color: theme.textInputLabelColor,
        marginBottom: 5,
      },
      textInputStyle: {
        flex: 1,
        height: 40,
        borderRadius: theme.primaryBorderRadius,
        fontSize: 14,
        fontFamily: theme.primaryMediumFont,
        padding: theme.primaryBorderRadius,
        marginRight: 10,
        color: theme.textInputColor,
        paddingLeft: 15,
      },
      textinputContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: 40,
        borderWidth: 1,
        borderRadius: theme.primaryBorderRadius,
        borderColor: theme.textInputBorderColor || "#CCCCCC",
      },
      placeHolder: {
        textColor: theme.placeHolderColor,
      },
    },
    searchInputWithIcon: {
      paddingRight: 10,
      container: {
        width: "100%",
      },
      labelContainer: {
        height: 15,
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        paddingRight: 0,
        paddingLeft: 15,
        paddingRight: 0,
        marginBottom: 0,
        justifyContent: "space-between",
      },
      label: {
        fontSize: 10,
        color: theme.textInputLabelColor,
      },
      textInputStyle: {
        flex: 1,
        height: 35,
        borderRadius: theme.roundedSearchBorderRadius,
        fontSize: 16,
        fontFamily: theme.primaryFont,
        padding: 5,
        marginRight: 10,
        color: theme.textInputColor,
        paddingLeft: 15,
      },
      textinputContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: 35,
        borderWidth: 1,
        borderRadius: theme.roundedSearchBorderRadius,
        borderColor: theme.textInputBorderColor || "#CCCCCC",
      },
      placeHolder: {
        textColor: theme.placeHolderColor,
      },
    },
    drawerItem: {
      container: {
        flex: 1,
        flexDirection: "row",
        minHeight: 45,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingHorizontal: 10,
        paddingLeft: 25,
        borderBottomWidth: 1,
        borderColor: theme.textInputBorderColor || "#E3E3E3",
      },
      lastContainer: {
        flex: 1,
        height: 45,
        width: "100%",
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingHorizontal: 10,
        paddingLeft: 25,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: theme.textInputBorderColor || "#E3E3E3",
      },
      label: {
        color: "#000",
        fontWeight: "600",
      },
      rightIcon: {
        width: 24,
      },
    },
    categoryList: {
      container: {
        maxWidth: 140,
      },
      title: {
        color: theme.tabBarColor,
        fontFamily: theme.primaryFont,
        fontSize: 14,
        zIndex: 1,
        textAlign: "center",
        textTransform: "capitalize",
        paddingVertical: 10,
      },
    },
    activeCategoryItem: {
      container: {
        borderBottomWidth: 3,
        maxWidth: 140,
        borderBottomColor: theme.primaryColor,
      },
      title: {
        color: theme.primaryColor,
        fontFamily: theme.primaryMediumFont,
        fontSize: 14,
        zIndex: 1,
        elevation: 10,
        textAlign: "center",
        textTransform: "capitalize",
        paddingVertical: 10,
      },
    },
    labelAndButton: {
      label: {
        color: theme.tertiaryHeadingColor,
        fontSize: 14,
      },
      button: {
        title: {
          textTransform: "capitalize",
          color: theme.primaryButtonColor,
          fontSize: 14,
          fontWeight: "600",
        },
        container: {
          paddingHorizontal: 10,
          paddingVertical: 5,
          borderRadius: 15,
          textTransform: "uppercase",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
        },
      },
    },
    secondarylabelAndButton: {
      label: {
        color: theme.secondaryLinkColor,
        fontSize: 14,
      },
      button: {
        title: {
          color: theme.secondaryLinkColor,
          fontSize: 12,
          fontWeight: "500",
          textTransform: "capitalize",
        },
        container: {
          paddingHorizontal: 10,
          paddingVertical: 5,
          borderRadius: 15,
          textTransform: "capitalize",
          backgroundColor: "transparent",
        },
      },
    },
    cardTags: {
      color: "#fff",
      backgroundColor: theme.tertiaryColor,
      fontFamily: theme.primarySemiBoldFont,
      borderRadius: 10,
      paddingVertical: 2,
      overflow: "hidden",
      paddingHorizontal: 5,
      fontSize: 10,
      textTransform: "uppercase",
      letterSpacing: 1,
    },
    primaryItemPicker: {
      borderWidth: 1,
      height: 35,
    },
    modalDiscription: {
      color: theme.secondaryColor,
      fontSize: 14,
      paddingLeft: 5,
    },
    dividerStyle: {
      borderBottomWidth: 0.5,
      borderBottomColor: theme.dividerColor,
      width: "100%",
      height: 1,
    },
    flexRow: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    modalContainerStyle: {
      backgroundColor: "#fff",
      shadowColor: "black",
      width: "100%",
      shadowOffset: {
        width: 10,
        height: 10,
      },
      shadowOpacity: 1,
      borderTopWidth: 0.9,
      borderColor: "#fefefe",
      shadowRadius: 10,
      position: "absolute",
      bottom: 0,
      borderTopLeftRadius: 15,
      borderTopRightRadius: 15,
      minHeight: "89%",
      height: "89%",
      // elevation: 5,
      // borderTopWidth:1
    },
    defaultCard: {
      containerStyle: {
        width: "100%",
        borderRadius: theme.primaryBorderRadius,
        paddingBottom: 5,
        backgroundColor: theme.containerBackgroundColor,
        ...theme.cardShadow,
      },
      cardBodyText: {
        fontFamily: theme.primarySemiBoldFont,
        color: theme.cardBodyColor,
        marginBottom: 5,
      },
      cardBody: {
        padding: 10,
      },
      footerStyle: {
        flex: 1,
        alignItems: "flex-end",
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: 15,
      },
      saperator: {
        height: 0.1,
        width: "100%",
        backgroundColor: theme.sectionDeviderColor,
      },
      labelStyle: {
        color: theme.secondaryColor,
        fontFamily: theme.primarySemiBoldFont,
        fontSize: 16,
        fontWeight: "600",
        marginBottom: 10,
      },
    },
    secondaryCard: {
      labelStyle: {
        color: theme.secondaryColor,
        fontFamily: theme.primarySemiBoldFont,
        fontSize: 16,
        fontWeight: "600",
        marginBottom: 10,
      },
      containerStyle: {
        width: "100%",
        borderRadius: theme.primaryBorderRadius,
        paddingBottom: 5,
        backgroundColor: theme.containerBackgroundColor,
      },
      cardBodyText: {
        fontFamily: theme.primaryFont,
        color: theme.secondaryDiscription,
        marginBottom: 5,
      },
      cardBody: {
        padding: 10,
      },
      footerStyle: {
        flex: 1,
        alignItems: "flex-end",
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: 15,
      },
      saperator: {
        height: 0.1,
        width: "100%",
        backgroundColor: theme.sectionDeviderColor,
      },
      headerStyle: {
        marginBottom: 10,
        color: theme.cardHeadingColor,
      },
    },
    tableViewCard: {
      containerStyle: {
        width: "100%",
        backgroundColor: theme.containerBackgroundColor,
        borderRadius: theme.primaryBorderRadius,
        paddingBottom: 5,
        ...theme.cardShadow,
      },
      cardBodyText: {
        fontFamily: theme.primaryFont,
        color: theme.secondaryDiscription,
      },
      cardBody: {
        padding: 10,
      },
      saperator: {
        height: 0.1,
        width: "100%",
        backgroundColor: theme.sectionDeviderColor,
      },
      headerContainer: {
        width: "100%",
        paddingHorizontal: 10,
        paddingVertical: 15,
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      },
      bodyContainer: {
        width: "100%",
        paddingVertical: 5,
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      },
      headerStyle: {
        color: theme.cardHeadingColor,
      },
    },
    OrdersCard: {
      containerStyle: {
        width: "100%",
        borderRadius: theme.primaryBorderRadius,
        paddingBottom: 5,
        ...theme.cardShadow,
      },
      cardBodyText: {
        fontFamily: theme.primaryMediumFont,
        color: theme.cardSecondaryHeadingColor,
      },
      cardBody: {
        padding: 10,
      },
      saperator: {
        height: 1,
        width: "100%",
        backgroundColor: theme.secondaryColor,
      },
      headerContainer: {
        width: "100%",
        paddingHorizontal: 10,
        paddingTop: 15,
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      },
      bodyContainer: {
        width: "100%",
        paddingVertical: 5,
        flex: 1,
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row",
      },
      headerStyle: {
        color: theme.cardSecondaryHeadingColor,
      },
      footerStyle: {
        flex: 1,
        alignItems: "flex-end",
        width: "100%",
        flexDirection: "row",
        justifyContent: "flex-end",
        padding: 15,
        paddingTop: 0,
      },
    },
    cardHeadingTags: {
      color: "#fff",
      backgroundColor: theme.tertiaryColor,
      borderRadius: theme.primaryBorderRadius,
      paddingBottom: 2,
      overflow: "hidden",
      paddingHorizontal: 5,
      fontSize: 10,
      letterSpacing: 1,
      minWidth: 75,
      textAlign: "center",
      textTransform: "uppercase",
    },
    tabCounterStyle: {
      position: "absolute",
      top: -2,
      backgroundColor: theme.secondaryColor,
      borderRadius: 50,
      padding: 1,
      width: 15,
      height: 15,
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      right: -7,
      zIndex: 1,
    },
    outletStyle: {
      titleStyle: {
        fontFamily: theme.primaryFont,
        color: theme.cardSecondaryHeadingColor,
        fontSize: 13,
      },
      bodyStyle: {
        fontSize: 14,
        color: theme.cardSecondaryHeadingColor,
      },
      footerStyle: {
        fontSize: 14,
        color: "#818181",
      },
      footerHighLightText: {
        fontSize: 14,
        fontFamily: theme.primarySemiBoldFont,
      },
    },
    errorMessage: {
      color: theme.secondaryColor,
      width: "100%",
    },
    mapOverlayStyle: {
      minHeight: 400,
      width: "100%",
      position: "absolute",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffffff51",
    },
    stickyFooter: {
      bottom: 0,
      width: "100%",
      // position: "sticky",
      position: "relative",
      backgroundColor: theme.appBackgroundColor,
    },
    modalFooter: {
      bottom: 0,
      width: "100%",
      position: "absolute",
      backgroundColor: theme.appBackgroundColor,
      borderTopWidth: 0.5,
      borderColor: theme.tertiaryColor,
      ...theme.primaryShadow,
    },
    modalFooterContainer: {
      flex: 1,
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      backgroundColor: theme.appBackgroundColor,
      paddingVertical: 15,
      paddingHorizontal: 15,
      flexWrap: "wrap",
    },
    modalHeader: {
      paddingBottom: 20,
      paddingLeft: 15,
      paddingTop: 30,
      position: "relative",
      top: 0,
      left: 0,
      right: 0,
      background: theme.appBackgroundColor,
      zIndex: 1,
    },
    secondaryBannerHeader: {
      color: theme.appBackgroundColor,
      fontFamily: theme.specialFont,
      fontSize: 25,
    },
    secondaryBannerDisc: {
      color: theme.appBackgroundColor,
      fontSize: 12,
      paddingTop: 5,
    },
    stickyBtn: {
      paddingHorizontal: theme.primaryPadding,
      // alignItems: "flex-start",
      // position: "absolute",
      // top: 0,
      backgroundColor: theme.appBackgroundColor,
      zIndex: 1,
    },
    menuStickybtn: {
      alignItems: "flex-start",
      paddingHorizontal: theme.primaryPadding,
      backgroundColor: theme.secondaryBackground,
      zIndex: 1,
      paddingTop: 5,
    },
    headerRightText: {
      color: theme.primaryColor,
      fontWeight: "400",
      fontSize: 17,
      fontFamily: theme.primaryFont,
    },
    headerLeftText: {
      color: theme.sectionHeadingColor,
      fontWeight: "600",
      fontSize: 17,
      letterSpacing: 0.4,
    },
    headerSummaryText: {
      color: theme.sectionSummaryColor,
      fontWeight: "400",
      fontSize: 12,
    },
    appBar: {
      appBarStyles: {
        width: "100%",
        backgroundColor: theme.appBarBgColor,
        shadowOpacity: 0,
        paddingHorizontal: 10,
        paddingTop: 20,
        paddingBottom: 10,
        alignItems: "flex-end",
        borderColor: theme.lineDividerColor,
        borderWidth: 1,
      },
      appBarViewStyles: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        position: "relative",
      },
      leftTextStyle: {
        color: theme.sectionHeadingColor,
        fontWeight: "600",
        fontSize: 16,
        paddingLeft: 8,
      },
    },
    categoryItem: {
      container: {
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 150,
        justifyContent: "center",
      },
      titleStyle: {
        fontSize: 16,
        letterSpacing: 0.5,
      },
      imgContainer: {
        shadowOffset: { width: 0, height: 0 },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 10,
      },
      topImageStyle: {
        borderRadius: 60,
      },
      discriptionStyle: {
        color: theme.discriptionTextColor,
      },
      footerStyle: {
        color: theme.primaryColor,
      },
    },
    categorySquareItem: {
      container: {
        flexDirection: "column",
        width: "100%",
        maxWidth: 100,
      },
      titleStyle: {
        fontSize: 14,
        letterSpacing: 0.5,
        fontWeight: "400",
        fontFamily: theme.primaryFont,
      },
      imgContainer: {
        shadowOffset: { width: 0, height: 0 },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 2,
      },
      topImageStyle: {
        borderRadius: 8,
        resize: "contain",
        width: 80,
        height: 75,
      },
      discriptionStyle: {
        color: theme.discriptionTextColor,
      },
      footerStyle: {
        color: theme.primaryColor,
      },
    },
    storeCard: {
      container: {
        flexDirection: "column",
        width: "100%",
        paddingHorizontal: 10,
        justifyContent: "space-between",
      },
      titleStyle: {
        fontSize: 15,
        letterSpacing: 0.5,
      },
      imgContainer: {
        shadowOffset: { width: 0, height: 0 },
        shadowColor: "#000",
        shadowOpacity: 0.3,
        shadowRadius: 2,
        elevation: 10,
      },
      leftImageStyle: {
        borderRadius: 8,
      },
      descriptionStyle: {
        color: theme.discriptionTextColor,
      },
      footerStyle: {
        color: theme.discriptionTextColor,
      },
    },
  };

  return {
    ...currentStyles,
  };
}
