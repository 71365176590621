import React, { useCallback, useReducer, useContext, useEffect } from "react";
import { View, Dimensions, FlatList, Pressable, Image } from "react-native";
import SearchManager from "@presto-services/features/search/SearchManager";
import I18n from "i18n-js";
import { useFocusEffect } from "@react-navigation/native";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import ThemeContext from "@presto-contexts/ThemeContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import config from "@presto-common/config";
import { Surface } from "react-native-paper";
import utils from "../../../src/utils";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import GalleryManager from "@presto-services/features/gallery/GalleryManager";
import asyncify from "../../common/Asyncify";
const AsyncGalleryManager = asyncify(GalleryManager);

export function reducer(state, { payload, type }) {
  switch (type) {
    case "SET_REGIONS":
      return { ...state, region: payload };
    case "SET_MANUFACTURERS":
      return { ...state, manufacturers: payload };
  }
  return state;
}

var windowHeight = Dimensions.get("window").height;
var windowWidth = Dimensions.get("window").width;
const showViewAll = config.features?.show_view_all;
const isDesktop = utils.isDesktop();

export default function Manufacturers({ onNavigate, catalog, galleryName }) {
  const { Navigator } = useContext(NavigatorContext);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { theme } = useContext(ThemeContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const [state, dispatch] = useReducer(reducer, {
    region: null,
    manufacturers: [],
  });

  const { region, manufacturers } = state;

  const getGallery = async () => {
    const [error, response] = await AsyncGalleryManager.getGallery({
      name: galleryName,
    });
    dispatch({
      type: "SET_MANUFACTURERS",
      payload: response.data?.media_items,
    });
  };

  useEffect(() => {
    getGallery();
    SearchManager.getZipHealthFilteredOtherDataAttributes(
      {},
      "company_name",
      (response) => {
        const items = _.sortBy(
          _.get(response, "aggregations.distinct_company_name.buckets"),
          "key"
        );
        dispatch({
          type: "SET_REGIONS",
          payload: items,
        });
      },
      (error) => {}
    );
  }, []);

  const onPressRegionName = (key) => {
    if (isDesktop) {
      onNavigate({ company_name: key });
    } else {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "SEARCH_RESULTS_SCREEN",
          screenParams: {
            searchParams: {
              company_name: key,
              focus: false,
            },
            catalogId: catalog?.id,
          },
        },
      });
    }
  };

  const renderText = (item) => {
    return (
      <PrestoText
        fontStyle="500.medium"
        size={12}
        numberOfLines={2}
        color={theme.paragraph}
      >
        {item.name}
      </PrestoText>
    );
  };

  const onPressItem = (item) => {
    const deepLink = _.get(item, "target.id_value");
    if (deepLink == "deals") {
      Navigator.emit({
        event: "push",
        params: {
          screenName: "VIEW_ALL_DEALS",
          screenParams: { catalogId: catalog?.id },
        },
      });
    } else {
      var search_query = {};

      var parts = _.split(deepLink, "/");
      if (parts.length == 2) {
        search_query[parts[0]] = parts[1];
      } else {
        search_query.query = deepLink;
      }

      if (isDesktop) {
        onNavigate({ ...search_query, catalogId: catalog?.id });
      } else {
        Navigator.emit({
          event: "push",
          params: {
            screenName: "SEARCH_RESULTS_SCREEN",
            screenParams: {
              searchParams: search_query,
              catalogId: catalog?.id,
            },
          },
        });
      }
    }
  };

  const renderAppIcons = ({ item, index }) => {
    const noImage = _.get(item, "other_data.no_image");
    let isValidImage = noImage !== true;
    return (
      <Surface
        elevation={2}
        style={{
          height: 55,
          width: isValidImage?(29 / 100) * windowWidth:"auto",
          borderRadius: 8,
          marginRight: 10,
          backgroundColor: theme.white,
        }}
      >
        <Pressable
          onPress={() => onPressItem(item)}
          key={index}
          style={{
            flex: 1,
            height: 55,
            width: "100%",
            borderColor: "#CBE5D3",
            borderWidth: 1,
            borderRadius: 8,
            marginRight: 10,
            padding: 10,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isValidImage ? (
            <Image
              source={{ uri: item?.file_url }}
              style={{
                height: "100%",
                width: "100%",
              }}
              resizeMode="contain"
            />
          ) : (
            renderText(item)
          )}
        </Pressable>
      </Surface>
    );
  };

  const renderWebIcons = ({ item, index }) => {
    const noImage = _.get(item, "other_data.no_image");
    let isValidImage = noImage !== true;
    return (
      <Pressable
        onPress={() => onPressItem(item)}
        key={index}
        style={{
          flex: 1,
          height: 65,
          width: 150,
          borderColor: "#CBE5D3",
          borderWidth: 1,
          borderRadius: 8,
          marginRight: 10,
          padding: 10,
          alignItems: "center",
          justifyContent: "center",
          ...theme.darkShadow,
        }}
      >
        {isValidImage ? (
          <Image
            source={{ uri: item?.file_url }}
            style={{
              height: "100%",
              width: "100%",
            }}
            resizeMode="contain"
          />
        ) : (
          renderText(item)
        )}
      </Pressable>
    );
  };

  const onPressViewAll = () => {
    if (isDesktop) {
      setPaneState(true);
    }
    Navigator.emit({
      event: "push",
      params: {
        screenName: "KEYWORD_BROWSE_SCREEN",
        screenParams: {
          key: "company_name",
          values: region,
          name: I18n.t("screen_messages.manufacturers"),
          catalogId: catalog?.id,
          onPressRegionName: onPressRegionName,
        },
      },
    });
  };

  return region?.length > 0 ? (
    <View>
      <View
        style={{
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          paddingBottom: showViewAll ? 10 : 0,
        }}
      >
        <PrestoText
          fontStyle="500.semibold"
          color={theme.primary}
          size={16}
          extraStyle={{
            paddingHorizontal: isDesktop ? 15 : 20,
            paddingVertical: 15,
          }}
        >
          {"Popular Manufacturers"}
        </PrestoText>
        {showViewAll ? (
          <PrestoFramedButton
            buttonStyle="primary"
            size="small"
            title={I18n.t("screen_messages.common.view_all_uppercase")}
            titleColor={theme.primary}
            borderColor={theme.primary}
            width={100}
            extraStyle={{ marginRight: 20 }}
            borderRadius={6}
            onPress={() => onPressViewAll()}
            titleExtraStyle={{ textTransform: "uppercase" }}
          />
        ) : null}
      </View>
      <FlatList
        data={manufacturers}
        renderItem={isDesktop ? renderWebIcons : renderAppIcons}
        horizontal
        scrollEnabled={true}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          paddingHorizontal: 15,
          paddingBottom: 15,
        }}
      />
    </View>
  ) : null;
}
