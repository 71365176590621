import React, { useContext } from "react";
import {
  Text,
  View,
  ScrollView,
  StyleSheet,
  TouchableWithoutFeedback,
  Platform,
} from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import CartContext from "@presto-contexts/CartContext";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { useSafeArea } from "react-native-safe-area-context";
import I18n from "i18n-js";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import PrestoText from "@presto-components/PrestoText";
import { useAssets } from "presto-react-components";

const BOTTOM_INSET = -20;
function HomeScreen() {
  return (
    <ScrollView style={{}}>
      <View
        style={{
          width: "100%",
          height: 1000,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text>Home!</Text>
      </View>
    </ScrollView>
  );
}

const Tab = createBottomTabNavigator();

function HomeTab({ focussed }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const color = focussed ? theme.primary : theme.subHeader;
  let HomeIcon = (
    <svgs.HomeIconV2 primaryColor={color} height={45} width={45} />
  );
  return (
    <TabItem
      Icon={HomeIcon}
      title={I18n.t("screen_messages.tab_text.tab1")}
      focussed={focussed}
      textColor={color}
    />
  );
}

function CategoriesTab({ focussed }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const color = focussed ? theme.primary : theme.subHeader;
  let Icon = (
    <svgs.CategoriesIcon primaryColor={color} height={45} width={45} />
  );
  return (
    <TabItem
      Icon={Icon}
      title={I18n.t("screen_messages.tab_text.tab2")}
      focussed={focussed}
      textColor={color}
    />
  );
}

function CartTab({ focussed }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const { cart, getItemCount } = useContext(CartContext);
  const color = focussed ? theme.primary : theme.subHeader;
  let Icon = <svgs.CartIcon color={color} height={45} width={45} />;
  return (
    <TabItem
      Icon={Icon}
      title={I18n.t("screen_messages.tab_text.tab3")}
      focussed={focussed}
      textColor={color}
      count={getItemCount()}
    />
  );
}

function ProductsTab({ focussed, index }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const color = focussed ? theme.primary : theme.subHeader;
  let Icon = (
    <svgs.StarIcon
      color="transparent"
      stroke={color}
      height={40}
      strokeWidth={1.5}
      width={40}
    />
  );
  return (
    <TabItem
      Icon={Icon}
      title={I18n.t("screen_messages.tab_text.tab4")}
      focussed={focussed}
      textColor={color}
      index={index}
    />
  );
}

function ProfileTab({ focussed }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const color = focussed ? theme.primary : theme.subHeader;
  let Icon = (
    <svgs.ProfileIconOutlined primaryColor={color} height={45} width={45} />
  );
  return (
    <TabItem
      Icon={Icon}
      title={I18n.t("screen_messages.tab_text.tab5")}
      focussed={focussed}
      textColor={color}
    />
  );
}

function TabItem({ Icon, title, textColor, focussed, count, index }) {
  const { svgs } = useAssets();
  const { theme } = useContext(ThemeContext);
  const insets = useSafeArea();
  const BOTTOM_PADDING = 5;
  const bottomMargin = insets.bottom
    ? insets.bottom + BOTTOM_INSET + BOTTOM_PADDING
    : BOTTOM_PADDING;

  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        {
          display: "flex",
          flexDirection: "column-reverse",
          justifyContent: "flex-start",
          alignItems: "center",
          elevation: 5,
          shadowColor: "rgba(25,40,47, 1)",
          shadowOffset: {
            width: 0,
            height: 1,
          },
          shadowOpacity: Platform.OS != "web" ? 0.1 : 0,
          shadowRadius: 14,
        },
      ]}
    >
      <Text
        style={{
          fontFamily: focussed ? theme.primaryBoldFont : theme.primaryFont,
          fontSize: index && index == 3 ? 9 : 10,
          color: focussed ? theme.white : textColor,
          marginBottom: bottomMargin,
          marginTop: 12,
          paddingHorizontal: focussed ? 3 : 0,
          lineHeight: 14,
          height: 16,
          borderRadius: 8,
          backgroundColor: focussed ? theme.primary : "transparent",
          overflow: "hidden",
        }}
        numberOfLines={1}
      >
        {title}
      </Text>
      <View
        style={{
          width: Platform.OS === "web" ? 40 : 27,
          maxWidth: Platform.OS === "web" ? 40 : 27,
          height: Platform.OS === "web" ? 38 : 24,
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        {count ? (
          <View
            style={{
              position: "absolute",
              top: Platform.OS === "web" ? -2 : -15,
              right: Platform.OS === "web" ? -3 : -15,
              height: 20,
              minWidth: 20,
              backgroundColor: theme.primary,
              borderRadius: 10,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <PrestoText fontStyle="semibold" color={theme.white}>
              {count}
            </PrestoText>
          </View>
        ) : null}
        {Icon}
      </View>
    </View>
  );
}

function renderTab(isFocused, index) {
  if (index === 0) {
    return <HomeTab focussed={isFocused} index={index}></HomeTab>;
  } else if (index == 1) {
    return <CategoriesTab focussed={isFocused} index={index}></CategoriesTab>;
  } else if (index == 2) {
    return <CartTab focussed={isFocused} index={index}></CartTab>;
  } else if (index == 3) {
    return <ProductsTab focussed={isFocused} index={index}></ProductsTab>;
  } else if (index === 4) {
    return <ProfileTab focussed={isFocused} index={index}></ProfileTab>;
  }
}

function PrestoTabBar(props) {
  const { svgs } = useAssets();
  const { state, descriptors, navigation } = props;
  const { theme } = useContext(ThemeContext);
  const { Navigator } = useContext(NavigatorContext);
  const insets = useSafeArea();

  return (
    <View
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        backgroundColor: "transparent",
      }}
    >
      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          height: insets.bottom ? insets.bottom + 75 + BOTTOM_INSET : 75,
          shadowColor: theme.blackWithOpacityTwo,
          backgroundColor: theme.white,
          shadowOffset: {
            width: -0,
            height: -2,
          },
          shadowRadius: 5,
          shadowOpacity: 0.5,
          elevation: 25,
          borderTopRightRadius: 20,
          borderTopLeftRadius: 20,
          overflow: "visible",
        }}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const isFocused = state.index === index;
          const onPress = () => {
            if (!isFocused) {
              Navigator.emit({
                event: "switchTab",
                params: {
                  tabName: route.name,
                  pop: true,
                },
              });
            } else {
              Navigator.emit({
                event: "popToTop",
                params: {
                  tabName: route.name,
                },
              });
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };
          let height = insets.bottom ? insets.bottom + 75 + BOTTOM_INSET : 75;

          return (
            <TouchableWithoutFeedback
              accessibilityRole="button"
              accessibilityStates={isFocused ? ["selected"] : []}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              key={route.key}
              onLongPress={onLongPress}
            >
              <View
                style={{
                  flex: 1,
                  height,
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "transparent",
                }}
              >
                {renderTab(isFocused, index)}
              </View>
            </TouchableWithoutFeedback>
          );
        })}
      </View>
    </View>
  );
}

export default function TabsScreen(props) {
  const { screens, tabConfigs, hideTabbar } = props.route.params;
  const items = _.zip(screens, tabConfigs);
  return (
    <Tab.Navigator
      tabBar={(props) =>
        hideTabbar ? null : <PrestoTabBar {...props} configs={tabConfigs} />
      }
    >
      {_.map(items, ([screen, tabConfig]) => {
        return (
          <Tab.Screen
            key={tabConfig.screen}
            name={tabConfig.name}
            children={(props) => screen}
          />
        );
      })}
    </Tab.Navigator>
  );
}
