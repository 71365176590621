import React, { useContext } from "react";
import { View, Dimensions, Image, StatusBar } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import _ from "lodash";
import { useAssets } from "presto-react-components";
import { useSafeArea } from "react-native-safe-area-context";
import utils from "../../utils";

export default function GradientBackgroundForHeader({ user }) {
  const { images } = useAssets();
  const { theme } = useContext(ThemeContext);
  const insets = useSafeArea();
  const windowWidth = Dimensions.get("window").width;
  const tier = _.toLower(_.get(user, "loyalty_tier_name", ""));
  let userTierImage = null;
  if (tier === "gold") {
    userTierImage = images.loyaltyTierGoldBackground;
  } else if (tier === "silver") {
    userTierImage = images.loyaltyTierSilverBackground;
  } else if (tier === "bronze") {
    userTierImage = images.loyaltyTierBronzeBackground;
  }

  if (!userTierImage) {
    return null;
  }
  return (
    <View
      style={{
        position: "absolute",
        width: windowWidth,
        height: insets.top,
        ...(utils.isMobileWeb() && {
          top: 0,
        }),
      }}
    >
      <Image
        source={userTierImage}
        style={{
          width: "100%",
          height: insets.top + 50,
        }}
      />
    </View>
  );
}
