import RateResource from "./RateResource";
import ErrorConstants from "../../common/ErrorConstants";
import Utils from "../../common/Utils";
import PrestoSdk from "../../global/PrestoSdk";
import $ from "../../global/util";

const RateManager = function (RateResource, ErrorConstants, Utils, PrestoSdk) {
  function getRatingInfo(params, successCallback, errorCallback) {
    RateResource.getRatingInfo(params).then(
      function (getRatingInfoResponse) {
        successCallback(getRatingInfoResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function getAllRatingInfo(params, successCallback, errorCallback) {
    RateResource.getAllRatingInfo(params).then(
      function (getAllRatingInfoResponse) {
        successCallback(getAllRatingInfoResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function createRating(params, successCallback, errorCallback) {
    RateResource.createRating(params).then(
      function (createRatingResponse) {
        successCallback(createRatingResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  function updateRating(params, successCallback, errorCallback) {
    RateResource.updateRating(params).then(
      function (updateRatingResponse) {
        successCallback(updateRatingResponse);
      },
      function (error) {
        error = error.data;
        error = Utils.updateErrorObject(error);
        errorCallback(error);
      }
    );
  }

  return {
    getRatingInfo: getRatingInfo,
    createRating: createRating,
    updateRating: updateRating,
    getAllRatingInfo: getAllRatingInfo,
  };
};

export default RateManager(RateResource, ErrorConstants, Utils, PrestoSdk);
