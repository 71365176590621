import React, { useContext, useState, useEffect } from "react";
import { View, Dimensions, FlatList, Pressable, Platform } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import { SafeAreaView } from "react-native-safe-area-context";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import LoadingContainer from "@presto-components/LoadingContainer";
import moment from "moment";
import I18n from "i18n-js";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import PrestoText from "@presto-components/PrestoText";
import PrestoIcon from "@presto-components/PrestoIcon";
import TitleHeaderWithBackWithGradientBackground from "@presto-screen-components/Headers/TitleHeaderWithBackWithGradientBackground";
import UserContext from "@presto-contexts/UserContext";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { useAssets } from "presto-react-components";
import utils from "../../utils/index";
import OffersManager from "@presto-services/features/offers/OffersManager";
import asyncify from "../../common/Asyncify";
const AsyncOfferManager = asyncify(OffersManager);
import { PrestoFramedButton } from "@presto-components/PrestoButton";
import config from "@presto-common/config";
import CatalogManager from "@presto-services/features/catalog/CatalogManager";
import SearchManager from "@presto-services/features/search/SearchManager";
import AnalyticsManager from "../../common/AnalyticsManager";
import AppointmentManager from "@presto-services/features/appointment/AppointmentManager";

export default function TransactionScreen(props) {
  const { typeOfTransaction = null } = props.route?.params || {};
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
  const merchantId = config.merchant_id;
  const [transactions, setTransactions] = useState(null);
  const [wallet, setWallet] = useState(null);
  const [redeemText, setRedeemText] = useState("");
  const [giftItems, setGiftItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { theme } = useContext(ThemeContext);
  const { user } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const { svgs } = useAssets();
  const [transactionType, setTransactionType] = useState(
    typeOfTransaction ?? "ALL"
  );
  const windowWidth = Dimensions.get("window").width;
  const windowHeight = Dimensions.get("window").height;
  let isDesktop = windowWidth > 940 ? true : false;
  const styles = getStyles(theme, windowWidth, isDesktop);
  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  useEffect(() => {
    WalletManager.getTransactionHistory(
      {},
      (response) => {
        setLoading(false);
        setTransactions(response.transactions);
      },
      () => { }
    );

    getCatalog();
    getOffersText();
  }, []);

  useEffect(() => {
    if (user) {
      WalletManager.getWalletInfo(
        { walletId: user.wallet_id },
        (response) => {
          setWallet(response.data);
        },
        () => { }
      );
    }
  }, [user]);

  const getCatalog = () => {
    var params = {
      merchant_id: merchantId,
      filter: "gifts",
    };

    CatalogManager.getFilterBasedCatalog(
      params,
      (response) => {
        if (response.data.id) {
          SearchManager.searchCategoryItem(
            {
              catalog_id: response.data.id,
              merchant_id: merchantId,
            },
            (response) => {
              setGiftItems(response.hits.hits);
            },
            () => { }
          );
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
  };

  const showOrder = (orderId) => {
    Navigator.emit({
      event: "push",
      params: {
        screenName: "ORDER_DETAILS_SCREEN",
        screenParams: { orderId: orderId, isInvoice: false },
      },
    });
  };

  const showCompositeOrder = (orderId) => {
    AppointmentManager.getCompositePayment(
      orderId,
      (response) => {
        Navigator.emit({
          event: "push",
          params: {
            screenName: "ORDER_DETAILS_SCREEN",
            screenParams: { orderId: response.data?.targets[0]?.id, isInvoice: false },
          },
        });
      },
      (error) => {
        console.log("createCompositePayment error", error);
      }
    );
  }

  const getOffersText = async () => {
    const [error, response] = await AsyncOfferManager.getHomePageOffers();
    if (response) {
      setRedeemText(response.data.offer_text);
    }
  };

  const onRedeem = () => {
    AnalyticsManager.send("redeem_points");
    Navigator.emit({
      event: "push",
      params: {
        screenName: "REDEEM_POINTS_SCREEN",
        screenParams: {
          category_items: giftItems,
          wallet: wallet,
        },
      },
    });
  };

  const getTitle = (item) => {
    console.log(item?.target?.type, "item?.target?.type")
    if (item?.target?.type === "Order" && item.type === "CREDIT") {
      return I18n.t("screen_messages.cashback_title2");
    } else if (item?.target?.type === "LineItem" && item.type === "CREDIT") {
      return I18n.t("screen_messages.cashback_title2");
    } else if (item.type === "DEBIT" && item?.target?.type === "Order") {
      return I18n.t("screen_messages.order_payment");
    } else if (item.type === "DEBIT" && item?.target?.type === "CompositePayment") {
      return I18n.t("screen_messages.order_payment");
    } else if (item.type === "DEBIT") {
      return I18n.t("screen_messages.debit_text");
    } else {
      return I18n.t("screen_messages.cashback_title2");
    }
  };

  const _onSetActive = (str) => {
    setTransactionType(str);
  };

  const _isActive = (str) => {
    return str == transactionType;
  };

  const renderTransactionTypes = () => {
    return (
      <View
        style={[
          {
            overflow: "hidden",
            marginTop: 10,
            marginBottom: 10,
            maxWidth: 400,
          },
          styles.container,
        ]}
      >
        <TouchableOpacity
          onPress={() => _onSetActive("ALL")}
          style={{
            ...styles.buttonContainer,
            backgroundColor: _isActive("ALL") ? theme.primary : "#EDEDED",
            borderTopLeftRadius: _isActive("ALL") ? 30 : 0,
            borderBottomLeftRadius: _isActive("ALL") ? 30 : 0,
            borderTopRightRadius: _isActive("ALL") ? 30 : 0,
            borderBottomRightRadius: _isActive("ALL") ? 30 : 0,
          }}
        >
          <PrestoText
            fontStyle="medium"
            color={_isActive("ALL") ? theme.white : theme.defaultTextColor}
          >
            {I18n.t("screen_messages.all")}
          </PrestoText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => _onSetActive("CREDIT")}
          style={{
            ...styles.buttonContainer,
            backgroundColor: _isActive("CREDIT") ? theme.primary : "#EDEDED",
            borderTopLeftRadius: _isActive("CREDIT") ? 30 : 0,
            borderBottomLeftRadius: _isActive("CREDIT") ? 30 : 0,
          }}
        >
          <PrestoText
            fontStyle="medium"
            color={_isActive("CREDIT") ? theme.white : theme.defaultTextColor}
          >
            {I18n.t("screen_messages.earned")}
          </PrestoText>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => _onSetActive("DEBIT")}
          style={{
            ...styles.buttonContainer,
            backgroundColor: _isActive("DEBIT") ? theme.primary : "#EDEDED",
            borderTopLeftRadius: _isActive("DEBIT") ? 30 : 0,
            borderBottomLeftRadius: _isActive("DEBIT") ? 30 : 0,
          }}
        >
          <PrestoText
            fontStyle="medium"
            color={_isActive("DEBIT") ? theme.white : theme.defaultTextColor}
          >
            {I18n.t("screen_messages.spent")}
          </PrestoText>
        </TouchableOpacity>
      </View>
    );
  };

  const RowButton = (props) => {
    const { title, item, rightTitle, rightColor, onPress, showDisclosure } =
      props || {};
    return (
      <Pressable onPress={onPress} style={{ width: "100%" }}>
        <View
          style={{
            paddingHorizontal: 20,
            padding: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flexDirection: "column" }}>
            <PrestoText
              fontStyle={"600.semibold"}
              size={14}
              extraStyle={{ textTransform: "capitalize" }}
            >
              {title}
            </PrestoText>
            <PrestoText
              fontStyle={"400.normal"}
              size={12}
              color={theme.paragraph}
              extraStyle={{ marginTop: 5 }}
            >
              {item?.expiry_date != "" && item?.expiry_date != null
                ? I18n.t("screen_messages.Expires_on", {
                  date: moment(item?.expiry_date).format("llll"),
                })
                : moment(item?.time).format("llll")}
            </PrestoText>
          </View>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {rightTitle ? (
              <View style={{ alignContent: "flex-end" }}>
                <PrestoText
                  size={16}
                  fontStyle="medium"
                  color={rightColor || theme.primary}
                  extraStyle={{
                    textTransform: "uppercase",
                    marginRight: 10,
                  }}
                >
                  {rightTitle}
                </PrestoText>
              </View>
            ) : null}
            {showDisclosure ? (
              <View style={{ alignContent: "flex-end", width: 30 }}>
                <PrestoIcon
                  icon={<svgs.RightArrow primaryColor={theme.primary} />}
                />
              </View>
            ) : (
              <View style={{ width: 30, height: 40 }} />
            )}
          </View>
        </View>
        <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
      </Pressable>
    );
  };

  const renderTransaction = ({ item, index }) => {
    let amount = noRoundOff
      ? utils.formattedPriceRoundOff.call(
        {
          currency: "",
        },
        (item.amount / 100).toFixed(2),
        noRoundOff
      )
      : Math.round(item.amount / 100);
    if (transactionType === "ALL") {
      return (
        <RowButton
          item={item}
          title={getTitle(item)}
          rightTitle={amount}
          rightColor={item.type == "CREDIT" ? undefined : "red"}
          onPress={() =>
            item.target?.type === "Order"
              ? showOrder(item.target?.id)
              : item.target?.type === "CompositePayment"
                ? showCompositeOrder(item.target?.id)
                : item.target?.type === "LineItem"
                  ? showOrder(item.other_data?.order_id?.$oid)
                  : {}
          }
          showDisclosure={
            item.target?.type === "Order" || item.target?.type === "LineItem" || item.target?.type === "CompositePayment"
          }
        />
      );
    } else if (item?.type == transactionType) {
      return (
        <RowButton
          item={item}
          title={getTitle(item)}
          rightTitle={amount}
          rightColor={item.type == "CREDIT" ? undefined : "red"}
          onPress={() =>
            item.target?.type === "Order"
              ? showOrder(item.target?.id)
              : item.target?.type === "CompositePayment"
                ? showCompositeOrder(item.target?.id)
                : item.target?.type === "LineItem"
                  ? showOrder(item.other_data?.order_id?.$oid)
                  : {}
          }
          showDisclosure={
            item.target?.type === "Order" || item.target?.type === "LineItem" || item.target?.type === "CompositePayment"
          }
        />
      );
    }
  };

  return (
    <LoadingContainer loading={loading}>
      <SafeAreaView style={{ flex: 1 }}>
        <TitleHeaderWithBackWithGradientBackground
          user={user}
          title={I18n.t("screen_messages.transactions.title")}
          onBack={goBack}
        />
        <FlatList
          style={{ height: windowHeight - 100 }}
          contentContainerStyle={{
            paddingBottom: 100,
          }}
          keyExtractor={(_, index) => "index-" + index}
          data={transactions}
          renderItem={renderTransaction}
          swipeThreshold={10}
          layout={"default"}
          showsHorizontalScrollIndicator={false}
          ListHeaderComponent={() => {
            return (
              <View
                style={{
                  width: "100%",
                  paddingHorizontal: theme.primaryPadding,
                }}
              >
                <View
                  style={{
                    padding: 10,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    backgroundColor: theme.primary,
                    marginTop: 35,
                    borderRadius: 4,
                    marginBottom: 20,
                  }}
                >
                  <PrestoText
                    fontStyle="600.semibold"
                    color={theme.white}
                    size={16}
                  >
                    {I18n.t("screen_messages.wallet_current_balance")}:{" "}
                    {utils.formattedPriceRoundOff.call(
                      {
                        currency: "",
                      },
                      wallet?.current_balance > 0
                        ? wallet?.current_balance / 100
                        : 0,
                      noRoundOff
                    )}
                  </PrestoText>
                  {wallet?.current_balance > 0 ? (
                    <PrestoFramedButton
                      buttonStyle="primary"
                      size="small"
                      title={I18n.t("screen_messages.redeem_title")}
                      titleColor={theme.white}
                      borderColor={theme.white}
                      width={100}
                      borderRadius={6}
                      height={32}
                      onPress={() => onRedeem()}
                      titleExtraStyle={{ textTransform: "uppercase" }}
                    />
                  ) : null}
                </View>

                {redeemText ? (
                  <View style={{ paddingTop: 20 }}>
                    <PrestoText
                      fontStyle={"semibold"}
                      color={theme.title}
                      size={16}
                    >
                      {I18n.t(
                        "screen_messages.redeem_points_screen.how_to_use_zip_points"
                      )}
                    </PrestoText>

                    <PrestoText
                      fontStyle={"semibold"}
                      color={theme.paragraph}
                      size={14}
                      extraStyle={{ paddingTop: 15, paddingBottom: 25 }}
                    >
                      {redeemText}
                    </PrestoText>
                  </View>
                ) : null}

                {renderTransactionTypes()}
              </View>
            );
          }}
        />
      </SafeAreaView>
    </LoadingContainer>
  );
}

function getStyles(theme, windowWidth, isDesktop) {
  let minusWidth = theme.primaryPadding / 2;
  return {
    container: {
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "#EDEDED",
      borderRadius: 30,
    },
    buttonContainer: {
      paddingVertical: 12,
      height: 40,
      width: windowWidth / 3 - minusWidth,
      maxWidth: isDesktop ? 125 : "100%",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 30,
    },
  };
}
