import React, { useState, useContext } from "react";
import { Dimensions } from "react-native";
import UserContext from "@presto-contexts/UserContext";
import CartContext from "@presto-contexts/CartContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import utils from "../../utils/index";
import CategoryItemManager from "@presto-services/features/category_item/CategoryItemManager";
import _ from "lodash";
import I18n from "i18n-js";
import WebSoldOutItemCard from "@presto-cards/ItemCards/WebSoldOutItemCard";
import RightPaneContext from "@presto-contexts/RightPaneContext";
import WebItemCard from "@presto-cards/ItemCards/WebItemCard";
import WebInCartItemCard from "@presto-cards/ItemCards/WebInCartItemCard";
import ItemCardModal from "../../component-models/ItemCardModel";
import { prestoAlert, prestoConfirm } from "../../common/Alert";
import config from "@presto-common/config";

export default function WebItemCards({ item, priceAccessorMethod }) {
  const product = item;
  const {
    getCountOfItemInCart,
    updateItemInCart,
    cart,
    clearCart,
  } = useContext(CartContext);
  const { Navigator } = useContext(NavigatorContext);
  const { WebNavigator } = useContext(WebNavigatorContext);
  const { rightPaneOpen, setPaneState } = useContext(RightPaneContext);
  const { user } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);

  const hasOffers = product.variations && product.variations.length;
  const offersCount = product.variations?.length || 0;
  let unsoldVariations = _.filter(
    product.variations || [],
    (varitation) => !varitation.sold_out
  );

  const variation = _.first(unsoldVariations);

  const showLogin = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: { screenName: "LOGIN" },
    });
  };

  const onVerifyAndAddItem = async () => {
    if (updating) {
      return true;
    }

    let isGift = _.some(cart?.items, (ele) => ele.other_data.is_gift == "true");
    const giftString = isGift.toString();
    const totalItemPrice = Number(item.price.total_price);
    const newCartValue = Number(cart?.total_price) / 100 + totalItemPrice;

    if (
      item?.other_data?.is_gift &&
      isGift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      return true;
    }

    if (cart?.items.length) {
      if (giftString === _.get(item, "other_data.is_gift", "false")) {
        onAdd();
      } else {
        alertOnClearCart((cartCleared) => {
          if (cartCleared) {
            if (
              item?.other_data?.is_gift &&
              totalItemPrice > wallet?.current_balance / 100
            ) {
              alert(
                I18n.t(
                  "screen_messages.redeem_points_screen.information_title"
                ),
                I18n.t(
                  "screen_messages.redeem_points_screen.no_sufficient_points"
                )
              );
              return true;
            } else {
              onAdd();
            }
          }
        });
        return true;
      }
    } else {
      if (
        item?.other_data?.is_gift &&
        newCartValue > wallet?.current_balance / 100
      ) {
        alert(
          I18n.t("screen_messages.redeem_points_screen.information_title"),
          I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
        );
        return true;
      }
      onAdd();
    }
  };

  const onVerifyAndUpdateItem = async (variation, quantity) => {
    setUpdating(variation?.id || product.id);
    let isGift = _.some(cart?.items, (ele) => ele.other_data.is_gift == "true");

    const oldItemTotalPrice =
      _.find(cart?.items, ["item_id", variation?.id || item.id])?.total_price ||
      0;
    const totalItemPrice = Number(item.price.total_price);

    const newCartValue =
      Number(cart?.total_price) / 100 -
      oldItemTotalPrice / 100 +
      totalItemPrice * quantity;

    if (
      item?.other_data?.is_gift &&
      isGift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      setUpdating(undefined);
    } else {
      onUpdate(variation, quantity);
    }
  };

  const alertOnClearCart = (successCallback) => {
    prestoConfirm(
      I18n.t("screen_messages.clear_gift_cart.confirm_text"),
      I18n.t("screen_messages.clear_gift_cart.cleart_cart_warning"),
      [
        {
          text: I18n.t("screen_messages.clear_gift_cart.yes_text"),
          onPress: () => {
            clearCart();
            successCallback(true);
          },
        },
        {
          text: I18n.t("screen_messages.clear_gift_cart.no_text"),
          onPress: () => {
            successCallback(false);
          },
        },
      ],
      false,
      (userChoice) => {
        if (userChoice) {
          clearCart();
          successCallback(true);
        } else {
          successCallback(false);
        }
      }
    );
  };

  const onAdd = async () => {
    if (!user) {
      showLogin();
      return;
    }

    let variationId = null;
    if (unsoldVariations && unsoldVariations.length > 0) {
      variationId = variation.id;
    }
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count + 1
    );
    console.log("Error in Add", error, status);
    if (status) {
      const count = getCountOfItemInCart();
    } else {
      alert(utils.errorMessage(error));
    }
    setUpdating(false);
  };

  const count = getCountOfItemInCart(item.id, null);
  const onSubtract = async () => {
    if (!user) {
      showLogin();
      return;
    }
    if (updating) {
      return true;
    }
    let variationId = null;
    if (unsoldVariations && unsoldVariations.length > 0) {
      variationId = variation.id;
    }
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count - 1 > 0 ? count - 1 : 0
    );
    if (status) {
      const count = getCountOfItemInCart();
    } else {
      alert(I18n.t("error_messages.error_updating_cart"));
    }
    setUpdating(false);
  };

  const onPressItem = () => {
    setPaneState(true);
    Navigator.emit({
      event: "modal",
      params: { screenName: "DETAILED_ITEM", screenParams: { item: item } },
    });
  };

  const onNotifyMe = (product, variation) => {
    let params = {
      productId: product.id,
      itemId: product.id,
      requestor: {
        name: user.name,
        phone: user.phone_number,
      },
    };
    if (variation) {
      params.variation_id = variation?.id;
    }
    CategoryItemManager.notifyWhenAvailable(
      params,
      () => {
        alert(I18n.t("screen_messages.notifications.notify_thanks_messages"));
      },
      (error) => {
        alert(error.message);
      }
    );
  };

  const onUpdate = async (variation, quantity) => {
    setUpdating(variation?.id || product.id);
    const [error, status] = await updateItemInCart(
      product.id,
      variation?.id,
      quantity
    );
    if (!status) {
      alert(utils.errorMessage(error));
    }
    setUpdating(undefined);
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false,
      (userChoice) => {
        if (userChoice) {
          successCallback(true);
        } else {
          successCallback(false);
        }
      }
    );
  };

  let isSoldOut;
  if (config?.features?.show_all) {
    isSoldOut =
      (!hasOffers && !product.is_available) || (hasOffers && !variation);
  } else {
    isSoldOut = (!hasOffers && product.sold_out) || (hasOffers && !variation);
  }
  // const isSoldOut = product.sold_out || product.stock_count === 0;
  const windowWidth = Dimensions.get("window").width;
  let computedWidth = (windowWidth * 0.8 - 300) / 4;
  if (computedWidth < 190) {
    computedWidth = (windowWidth * 0.8 - 250) / 3;
  }

  if (isSoldOut) {
    return (
      <WebSoldOutItemCard
        product={new ItemCardModal({ product, variation })}
        onPress={onPressItem}
        onNotifyMe={onNotifyMe}
        priceAccessorMethod={priceAccessorMethod}
      />
    );
  }
  if (count > 0) {
    return (
      <WebInCartItemCard
        product={new ItemCardModal({ product, variation, count })}
        onPress={onPressItem}
        onSubtract={onSubtract}
        updating={updating}
        onUpdate={onVerifyAndUpdateItem}
        onAdd={onVerifyAndAddItem}
        priceAccessorMethod={priceAccessorMethod}
      />
    );
  } else {
    return (
      <WebItemCard
        product={new ItemCardModal({ product, variation, count })}
        onPress={onPressItem}
        onSubtract={onSubtract}
        updating={updating}
        onUpdate={onVerifyAndUpdateItem}
        onAdd={onVerifyAndAddItem}
        priceAccessorMethod={priceAccessorMethod}
      />
    );
  }
}
