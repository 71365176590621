import React, { useContext } from "react";
import { View, Text } from "react-native";
import _ from "lodash";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function UnSelectedCheckBox(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        width: props.size || 32,
        height: props.size || 32,
        backgroundColor: theme.appBackgroundColor,
        borderRadius: 16,
        borderWidth: 1,
        borderColor: theme.secondaryTextColor,
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      <Text></Text>
    </View>
  );
}
