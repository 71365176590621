import React, { useContext, useState, useCallback } from "react";
import { View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import WalletCheckoutCard from "@presto-cards/WalletCard/WalletCheckoutCard";
import WalletCard from "@presto-cards/WalletCard/WalletCard";
import WalletManager from "@presto-services/features/wallet/WalletManager";
import useFocusEffect from "@presto-common/useFocusEffect";
import _ from "lodash";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import AnalyticsManager from "../../common/AnalyticsManager";
import utils from "../../utils";
import config from "@presto-common/config";

export function WalletComponent(props) {
  const noRoundOff = _.get(config, "merchant_config.noRoundOff", false);
  const { actionTitle, onAction } = props;
  const { Navigator } = useContext(NavigatorContext);
  const [wallet, setWallet] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [lastCredit, setLastCredit] = useState(undefined);
  const { user } = useContext(UserContext);

  const onActionClicked = () => {
    AnalyticsManager.send("orders_list");
    if (onAction) {
      onAction();
      return;
    }
    Navigator.emit({
      event: "jumpTab",
      params: {
        tabName: "ACCOUNT",
        screenName: "LOYALTY_POINT_TRANSACTIONS_SCREEN",
      },
    });
  };

  useFocusEffect(
    useCallback(() => {
      WalletManager.getWalletInfo(
        { walletId: user.wallet_id },
        (response) => {
          let walletObject = response.data;
          walletObject.current_balance = walletObject.current_balance
            ? Math.round(walletObject.current_balance / 100)
            : 0;
          setWallet(walletObject);
        },
        () => {}
      );
    }, [user])
  );

  useFocusEffect(
    useCallback(() => {
      WalletManager.getTransactionHistory(
        {},
        (response) => {
          var creditTrans = _.filter(
            response.transactions,
            (trans) => trans.type == "CREDIT"
          );
          var lastCredit = _.first(creditTrans);
          setLastCredit(lastCredit);
        },
        () => {}
      );
    }, [user])
  );

  const { theme } = useContext(ThemeContext);
  let showWalletCard = wallet ? true : false;

  if (props.hideIfZeroPoints && wallet && wallet.current_balance === 0) {
    showWalletCard = false;
  }

  return showWalletCard ? (
    <View style={{ padding: theme.containerPadding, paddingLeft: 10 }}>
      <WalletCard
        wallet={wallet}
        lastCredit={lastCredit}
        actionTitle={actionTitle}
        onAction={onActionClicked}
        loading={loading}
        getWalletCurrentBalance={() => {
          return wallet?.current_balance
            ? utils.formattedPriceRoundOff.call(
                {
                  currency: "",
                },
                wallet?.current_balance,
                noRoundOff
              )
            : 0;
        }}
      />
    </View>
  ) : null;
}

export function WalletCheckoutComponent({ onAction, actionTitle, showAction }) {
  const [wallet, setWallet] = useState(undefined);
  const [lastCredit, setLastCredit] = useState(undefined);
  const { user } = useContext(UserContext);
  useFocusEffect(
    useCallback(() => {
      WalletManager.getWalletInfo(
        { walletId: user.wallet_id },
        (response) => {
          setWallet(response.data);
        },
        () => {}
      );
    }, [user])
  );

  useFocusEffect(
    useCallback(() => {
      WalletManager.getTransactionHistory(
        {},
        (response) => {
          var creditTrans = _.filter(
            response.transactions,
            (trans) => trans.type == "CREDIT"
          );
          var lastCredit = _.first(creditTrans);
          setLastCredit(lastCredit);
        },
        () => {}
      );
    }, [user])
  );

  const { theme } = useContext(ThemeContext);
  return wallet ? (
    <View style={{ padding: theme.containerPadding, paddingLeft: 10 }}>
      <WalletCheckoutCard
        wallet={wallet}
        lastCredit={lastCredit}
        actionTitle={actionTitle}
        onAction={onAction}
        showAction={showAction}
      />
    </View>
  ) : null;
}
