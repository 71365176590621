import React from "react";
import { View, TextInput } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
export default class PrestoSearchInput extends React.Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  focus() {
    this.inputRef.current.focus();
  }

  render() {
    const { theme } = this.context;
    const Icon = theme.searchIcon;
    const icon = <Icon />;
    const styles = getStyles(theme, icon);
    return (
      <View style={styles.container}>
        <View
          style={[
            styles.textinputContainer,
            {
              marginRight: icon ? 40 : theme.textInputBorderRadius,
            },
          ]}
        >
          <TextInput
            ref={this.inputRef}
            underlineColorAndroid="transparent"
            autoCapitalize={this.props.autoCapitalize || "none"}
            autoCompleteType={this.props.autoCompleteType || "off"}
            autoCorrect={this.props.autoCorrect || false}
            keyboardType={this.props.keyboardType || "default"}
            value={this.props.value}
            placeholder={this.props.placeholder}
            placeholderTextColor={
              this.props.textInputPlaceholderColor ||
              styles.placeHolder.textColor
            }
            returnKeyType={this.props.returnKeyType || "search"}
            {...this.props}
            style={{
              ...styles.textInputStyle,
            }}
            numberOfLines={this.props.numberOfLines || 1}
          ></TextInput>
        </View>
      </View>
    );
  }
}

const getStyles = (theme, icon) => {
  return {
    container: {
      width: "100%",
    },
    textInputStyle: {
      flex: 1,
      width: "100%",
      height: theme.textInputHeight - 4 || 50,
      borderWidth: 0,
      fontSize: 16,
      fontFamily: theme.primaryMediumFont,
      paddingHorizontal: theme.textInputBorderRadius || 25,
      color: theme.textInputColor,
      borderRadius: theme.textInputBorderRadius || 25,
      padding: 0,
      margin: 0,
    },
    textinputContainer: {
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      height: theme.textInputHeight,
      backgroundColor: theme.textInputBgColor,
      borderWidth: 2,
      borderRadius: theme.textInputBorderRadius || 25,
      borderColor: theme.textInputBorderColor || "#CCCCCC",
    },
    placeHolder: {
      textColor: theme.placeHolderColor,
    },
  };
};
