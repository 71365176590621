import React, { useContext, useState, useEffect, useRef } from "react";
import { StyleSheet, View } from "react-native";
import ThemeContext from "@presto-contexts/ThemeContext";
import UserContext from "@presto-contexts/UserContext";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import DefaultModal from "@presto-components/Modals/Modal.bookmychef";
import _ from "lodash";
import LoadingContainer from "@presto-components/LoadingContainer";
import I18n from "i18n-js";
import PrestoText from "@presto-components/PrestoText";
import { PrestoSolidButton } from "@presto-components/PrestoButton";
import OrderManager from "@presto-services/features/orders/OrderManager";
import { prestoAlert } from "../../common/Alert";
import utils from "../../utils";

export default function OnlinePayment(props) {
  const { order, clearCart } = props?.route?.params;

  const [loading, setLoading] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { user, isUserLoggedIn, currentAddress } = useContext(UserContext);
  const { Navigator } = useContext(NavigatorContext);
  const [timer, setTimer] = useState(null);
  const TimeRef = useRef(null);
  const modalRef = useRef();
  const TimerCount = 60 * 10;
  const refreshTime = 1000 * 20;
  const isDesktop = utils.isDesktop();

  const goBack = () => {
    Navigator.emit({ event: "goBack" });
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const clearTimer = (e) => {
    setTimer("10:00");
    if (TimeRef.current) clearInterval(TimeRef.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    TimeRef.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + TimerCount);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);

  useEffect(() => {
    if (timer <= "00:00") {
      alert("Time Out! Please try Again");
      goBack();
    }
  }, [timer]);

  useEffect(() => {
    const interval = setInterval(function () {
      OrderManager.getOrder(
        { id: order?.id },
        (response) => {
          if (
            response?.data?.status === "RECEIVED" ||
            response?.data?.payment_status === "SUCCESS"
          ) {
            clearCart({});
            Navigator.emit({
              event: "replace",
              params: {
                screenName: "ORDER_SUCCESSFUL_SCREEN",
              },
            });
          } else if (response?.data?.payment_status === "FAILED") {
            alert(
              I18n.t("screen_messages.alert"),
              I18n.t("screen_messages.payment_failed")
            );
            goBack();
          }
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log("Order error==>", error);
        }
      );
    }, refreshTime);

    return () => {
      clearInterval(interval);
    };
  }, [refreshTime]);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  return (
    <LoadingContainer
      bgColor={isDesktop ? theme.white : "#00000070"}
      loading={loading}
    >
      {!isDesktop ? (
        <>
          <DefaultModal modalRef={modalRef} goBack={() => {}} height={500}>
            <View
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  flex: 0.8,
                  alignItems: "center",
                  justifyContent: "center",
                  height: 800,
                }}
              >
                <PrestoText
                  fontStyle="600.semibold"
                  size={40}
                  color={theme.paragraph}
                >
                  {timer}
                </PrestoText>
                <PrestoText
                  fontStyle="600.semibold"
                  size={18}
                  color={theme.secondary}
                  extraStyle={{
                    textAlign: "center",
                    marginTop: 30,
                    paddingHorizontal: 20,
                  }}
                >
                  {I18n.t("screen_messages.appypay_payment_title")}
                </PrestoText>
              </View>
            </View>
            <View
              style={{
                width: "100%",
                height: 50,
                flex: 0.2,
                paddingHorizontal: theme.containerPadding,
              }}
            >
              <PrestoSolidButton
                size="extra-large"
                title={I18n.t("screen_messages.cancel_appyapy_button")}
                buttonStyle="primary"
                titleColor={theme.white}
                titleExtraStyle={{ textTransform: "uppercase" }}
                onPress={() => goBack()}
              />
            </View>
          </DefaultModal>
        </>
      ) : (
        <>
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <View
              style={{
                flex: 0.8,
                alignItems: "center",
                justifyContent: "center",
                height: 450,
              }}
            >
              <PrestoText
                fontStyle="600.semibold"
                size={40}
                color={theme.paragraph}
              >
                {timer}
              </PrestoText>
              <PrestoText
                fontStyle="600.semibold"
                size={18}
                color={theme.secondary}
                extraStyle={{
                  textAlign: "center",
                  marginTop: 30,
                  paddingHorizontal: 20,
                }}
              >
                Please Open Your AppyPay to make the Payment.
              </PrestoText>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              height: 50,
              flex: 0.2,
              paddingHorizontal: theme.containerPadding,
            }}
          >
            <PrestoSolidButton
              size="extra-large"
              title="Cancel"
              buttonStyle="primary"
              titleColor={theme.white}
              titleExtraStyle={{ textTransform: "uppercase" }}
              onPress={() => goBack()}
            />
          </View>
        </>
      )}
    </LoadingContainer>
  );
}

const styles = {};
