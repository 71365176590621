import React, { useContext } from "react";
import { View, StyleSheet, FlatList } from "react-native";
import PrestoText from "@presto-components/PrestoText";
import _ from "lodash";
import I18n from "i18n-js";
import ThemeContext from "@presto-contexts/ThemeContext";
export default function BenefitsSection({ user }) {
  const { theme } = useContext(ThemeContext);
  const styles = getStyles(theme);
  let benefits = [
    {
      tier: I18n.t("screen_messages.faq.tiers.no_tier_text"),
      tierTextColor: theme.primary,
      items: [
        {
          text: I18n.t("screen_messages.benefits.notier.list1"),
        },
      ],
    },
    {
      tier: I18n.t("screen_messages.faq.tiers.bronze_text"),
      tierTextColor: theme.loyaltyTier.bronze.titleColor,
      items: [
        {
          text: I18n.t("screen_messages.benefits.bronze.list1"),
        },
      ],
    },
    {
      tier: I18n.t("screen_messages.faq.tiers.silver_text"),
      tierTextColor: theme.loyaltyTier.silver.titleColor,
      items: [
        {
          text: I18n.t("screen_messages.benefits.silver.list1"),
        },
      ],
    },
    {
      tier: I18n.t("screen_messages.faq.tiers.gold_text"),
      tierTextColor: theme.loyaltyTier.gold.titleColor,
      items: [
        {
          text: I18n.t("screen_messages.benefits.gold.list1"),
        },
      ],
    },
  ];

  const renderItem = ({ item, index }) => {
    return (
      <View
        key={index}
        style={{
          flexDirection: "row",
          paddingTop: theme.primaryPadding,
        }}
      >
        <View>
          <PrestoText fontStyle="400.normal" color="#757474">
            {"* "}
          </PrestoText>
        </View>
        <View style={{ width: "100%" }}>
          <PrestoText fontStyle="400.normal" color="#757474">
            {item.text}
          </PrestoText>
        </View>
      </View>
    );
  };

  const renderItems = ({ items }) => {
    return (
      <View style={{ paddingTop: 10 }}>
        {_.map(items, (item1, index) => {
          return renderItem({ item: item1, index });
        })}
      </View>
    );
  };

  return (
    <View>
      <FlatList
        data={benefits}
        horizontal
        renderItem={({ item, index }) => {
          const isLast = benefits.length - 1 === index;
          return (
            <View
              key={index}
              style={{
                padding: theme.primaryPadding,
                width: 300,
                borderRightColor: theme.lightdark,
                borderLeftColor: theme.lightdark,
                borderRightWidth: 1,
                borderLeftWidth: index === 0 ? 1 : 0,
                ...(index > 0 && {
                  paddingLeft: 10,
                }),
                ...(index === 0 && {
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                }),
                ...(isLast && {
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                }),
              }}
            >
              <View>
                <PrestoText
                  size={16}
                  fontStyle="600.semibold"
                  color={item.tierTextColor}
                >
                  {_.toUpper(item.tier)}
                </PrestoText>
              </View>

              {renderItems({ items: item.items })}
            </View>
          );
        }}
      />
    </View>
  );
}
function getStyles(theme) {
  return StyleSheet.create({
    container: {
      padding: theme.primaryPadding,
      borderColor: "#D8D8D8",
      borderRadius: 12,
      borderWidth: 1,
    },
    row: {
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "space-between",
    },
    heading: {
      color: theme.darkText,
    },
  });
}
