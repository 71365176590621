import React, {
  useState,
  useContext,
  useReducer,
  useEffect,
  useRef,
} from "react";
import { View, ScrollView, Platform, Dimensions } from "react-native";
import TouchableOpacity from "@presto-components/TouchableOpacity/TouchableOpacity";
import SearchManager from "@presto-services/features/search/SearchManager";
import asyncify from "../../common/Asyncify";
const AsyncSearchManager = asyncify(SearchManager);
import ThemeContext from "@presto-contexts/ThemeContext";
import I18n from "i18n-js";
import { LineDivider } from "@presto-components/Dividers/Dividers";
import { RowButton } from "@presto-components/PrestoButton";
import _ from "lodash";
import PromotedGallery from "@presto-screen-components/PromotedGallery/PromotedGallery";
import WebSubCategories from "@presto-screens/SubCategoriesBrowse/WebSubCategoriesBrowse";
import PrestoText from "@presto-components/PrestoText";
import * as Linking from "expo-linking";
import config from "@presto-common/config";
import WebKeywordBrowse from "@presto-screens/KeywordBrowse/WebKeywordBrowse";
import { VerticalSpacing } from "@presto-components/Spacing";
import ProfileHighlight from "@presto-screen-components/ProfileHighlight/ProfileHighlight";
import WebNavigatorContext from "@presto-contexts/WebNavigatorContext";
import RegionFilter from "@presto-screen-components/Region/Region";
import { useCatalog } from "@presto-stores/CatalogStore";

export function reducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_TOP_CATEGORIES":
      return { ...state, top_level_categories: payload };

    case "SET_CATEGORY_COUNT":
      return {
        ...state,
        category_count: {
          ...state.category_count,
          [payload.cat_id]: payload.count,
        },
      };
    case "SET_BRANDS":
      return {
        ...state,
        brands: payload,
      };
    case "SET_MANUFACTURERS":
      return {
        ...state,
        manufacturers: payload,
      };
    case "SET_MOLECULES":
      return {
        ...state,
        molecules: payload,
      };
    case "SET_THERAPIES":
      return {
        ...state,
        therapies: payload,
      };
    case "SET_NEWITEMS":
      return {
        ...state,
        newItems: payload,
      };
    case "BEST_SELLERS":
      return {
        ...state,
        bestsellers: payload,
      };
    case "SET_REGIONS":
      return {
        ...state,
        region: payload,
      };
  }
  return state;
}

export default function SideNavigation({
  onNavigate,
  loyaltyTiers = [],
  setParentLoading,
  orderNowClicked,
}) {
  const catalog = useCatalog((state) => state.catalog);
  const { theme } = useContext(ThemeContext);
  const [state, dispatch] = useReducer(reducer, {
    category_count: {},
  });
  const [category, setCategory] = useState(null);
  const [showSubCategories, setShowSubCategory] = useState(false);
  const [keywordItems, setKeywordItems] = useState(null);
  const eventRef = useRef({
    ignoreInitialExternalClick: false,
  });
  const hideRegion = config.merchant_config?.hide_region;

  let isDesktop = Dimensions.get("window").width > 940;

  useEffect(() => {
    SearchManager.searchCategory(
      { top_level: true },
      (response) => {
        dispatch({
          type: "SET_TOP_CATEGORIES",
          payload: response.hits.hits,
        });
      },
      (error) => {}
    );

    if (Platform.OS === "web") {
      handleWebEvents();
    }
  }, []);

  function handleWebEvents() {
    const handleClick = (event) => {
      const element1 = document.getElementById("subcategory-popup-menu");
      if (element1) {
        if (!element1.contains(event.target)) {
          if (!eventRef.current.ignoreInitialExternalClick) {
            setShowSubCategory(false);
            setCategory(null);
            setKeywordItems(null);
          } else {
            eventRef.current.ignoreInitialExternalClick = false;
          }
        }
      }
    };
    document.addEventListener("click", handleClick);
  }

  const onPressSignUp = () => {
    Platform.OS === "web"
      ? window.open(config.hubspotUrl, "_blank")
      : Linking.openURL(config.hubspotUrl);
  };

  useEffect(() => {
    if (catalog?.id) {
      var params = { catalog_id: catalog?.id };
      SearchManager.getZipHealthFilteredOtherDataAttributes(
        params,
        "brand_name",
        (response) => {
          let sortedList = _.sortBy(
            response.aggregations.distinct_brand_name.buckets,
            "key"
          );
          dispatch({
            type: "SET_BRANDS",
            payload: sortedList,
          });
        },
        (error) => {}
      );

      SearchManager.getZipHealthFilteredOtherDataAttributes(
        params,
        "distributor",
        (response) => {
          let sortedList = _.sortBy(
            response.aggregations.distinct_distributor.buckets,
            "key"
          );
          dispatch({
            type: "SET_MANUFACTURERS",
            payload: sortedList,
          });
        },
        (error) => {}
      );

      SearchManager.getZipHealthFilteredOtherDataAttributes(
        params,
        "molecule",
        (response) => {
          let sortedList = _.sortBy(
            response.aggregations.distinct_molecule.buckets,
            "key"
          );
          dispatch({
            type: "SET_MOLECULES",
            payload: sortedList,
          });
        },
        (error) => {}
      );

      SearchManager.getZipHealthFilteredOtherDataAttributes(
        params,
        "therapy_area_simple",
        (response) => {
          let sortedList = _.sortBy(
            response.aggregations.distinct_therapy_area_simple.buckets,
            "key"
          );
          dispatch({
            type: "SET_THERAPIES",
            payload: sortedList,
          });
        },
        (error) => {}
      );

      SearchManager.getZipHealthFilteredOtherDataAttributes(
        params,
        "is_new",
        (response) => {
          let sortedList = _.sortBy(
            response.aggregations.distinct_is_new.doc_count,
            "key"
          );
          dispatch({
            type: "SET_NEWITEMS",
            payload: sortedList,
          });
        },
        (error) => {}
      );

      SearchManager.getZipHealthFilteredOtherDataAttributes(
        params,
        "bestsellers",
        (response) => {
          let sortedList = _.sortBy(
            response.aggregations.distinct_bestsellers.buckets,
            "key"
          );
          dispatch({
            type: "BEST_SELLERS",
            payload: sortedList,
          });
        },
        (error) => {}
      );
    }
  }, [catalog?.id]);

  const fetchCount = async (categoryId) => {
    const [error, categoryResponse] = await AsyncSearchManager.searchCategory({
      category_id: categoryId,
    });
    if (error) {
      return;
    }
    if ((categoryResponse.hits?.hits?.length || 0) > 0) {
      const [error, response] = await AsyncSearchManager.searchCategoryItem({
        category_ids: _.map(
          categoryResponse.hits.hits,
          (cat) => cat._source.id
        ),
      });
      if (!error) {
        dispatch({
          type: "SET_CATEGORY_COUNT",
          payload: {
            cat_id: categoryId,
            count: response.hits.total,
          },
        });
      }
    } else {
      const [error, response] = await AsyncSearchManager.searchCategoryItem({
        category_id: categoryId,
      });

      if (!error) {
        dispatch({
          type: "SET_CATEGORY_COUNT",
          payload: {
            cat_id: categoryId,
            count: response.hits.total,
          },
        });
      }
    }
  };

  useEffect(() => {
    _.forEach(state.top_level_categories, (cat) => {
      fetchCount(cat._source.id);
    });
  }, [state.top_level_categories]);

  const onSubNavigate = (params) => {
    setShowSubCategory(false);
    onNavigate(params);
  };

  const onKeywordNavigate = (params) => {
    setKeywordItems(null);
    onNavigate(params);
  };

  const onCategoryPress = (cat) => {
    setCategory(cat._source);
    setShowSubCategory(false);
    setKeywordItems(null);
    SearchManager.searchCategory(
      { category_id: cat._id },
      (response) => {
        setShowSubCategory(true);
        if (response.hits.hits.length === 0) {
          onNavigate({
            category_id: cat._source.id,
            catalogId: catalog?.id,
          });
        }
      },
      (error) => {
        console.log("onCategoryPress Error : ", error);
      }
    );
  };

  const onKeywordPress = (keyword, items, title) => {
    eventRef.current.ignoreInitialExternalClick = true;
    setCategory(null);
    setShowSubCategory(false);
    setKeywordItems({ keyword, items, title });
  };

  const onBrowseItemPressed = (keyword) => {
    let screenParams = {};
    let searchParams = { is_new: true };
    if (keyword === "bestsellers") {
      searchParams = { bestsellers: true };
      screenParams = { ...searchParams, catalogId: catalog?.id };
    }
    onNavigate(screenParams);
  };
  return (
    <>
      {showSubCategories ? (
        <View
          style={{
            position: "absolute",
            right: -300,
            width: 300,
            zIndex: 1,
            backgroundColor: theme.white,
            ...theme.primaryCardShadow,
          }}
          nativeID="subcategory-popup-menu"
        >
          <WebSubCategories category={category} onNavigate={onSubNavigate} />
        </View>
      ) : null}
      {keywordItems ? (
        <View
          style={{
            position: "absolute",
            right: -300,
            width: 300,
            maxHeight: 500,
            zIndex: 1,
            backgroundColor: theme.white,
            ...theme.primaryCardShadow,
          }}
          nativeID="subcategory-popup-menu"
        >
          <WebKeywordBrowse
            keyword={keywordItems.keyword}
            items={keywordItems.items}
            title={keywordItems.title}
            onNavigate={onKeywordNavigate}
          />
        </View>
      ) : null}
      <ScrollView style={{ height: "100%", paddingBottom: 120 }}>
        <>
          <View>
            <ProfileHighlight
              isDesktop={true}
              loyaltyTiers={loyaltyTiers}
              setParentLoading={setParentLoading}
              orderNowClicked={orderNowClicked}
            />
          </View>

          {_.map(state.top_level_categories, (cat) => {
            return (
              <>
                <View key={cat._source.id}>
                  {state.category_count[cat._source.id] > 0 ? (
                    <RowButton
                      title={cat._source.name}
                      rightTitle={state.category_count[cat._source.id] ?? ""}
                      onPress={() => {
                        onCategoryPress(cat);
                      }}
                      showDisclosure={true}
                    />
                  ) : null}
                  <LineDivider
                    dividerColor="#DADADA"
                    width="100%"
                    theme={theme}
                  />
                </View>
              </>
            );
          })}
          {hideRegion ? null : <RegionFilter navigate={onNavigate} />}
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <VerticalSpacing size={20} />
          <View
            style={{
              paddingHorizontal: theme.primaryPadding,
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
              paddingBottom: 10,
            }}
          >
            <PrestoText
              fontStyle="semibold"
              size={theme.h5FontSize}
              color={theme.primary}
            >
              {I18n.t("screen_messages.common.browse_by")}
            </PrestoText>
          </View>
          {state.newItems && state.newItems > 0 ? (
            <RowButton
              title={I18n.t("screen_messages.new_items_str")}
              rightTitle={
                state.newItems && state.newItems > 0 ? state.newItems : ""
              }
              onPress={() => onBrowseItemPressed("is_new")}
              showDisclosure={true}
            />
          ) : null}
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.vendors.best_seller")}
            rightTitle={
              state.bestsellers && state.bestsellers.length > 0
                ? state.bestsellers[0].doc_count
                : ""
            }
            onPress={() => onBrowseItemPressed("bestsellers")}
            showDisclosure={true}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />

          <RowButton
            title={I18n.t("screen_messages.brands.title")}
            rightTitle={state.brands ? state.brands?.length : ""}
            onPress={() =>
              onKeywordPress(
                "brand_name",
                state.brands,
                I18n.t("screen_messages.brands.title")
              )
            }
            showDisclosure={true}
          />
          {/* <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.distributors_label")}
            rightTitle={state.manufacturers ? state.manufacturers?.length : ""}
            onPress={() =>
              onKeywordPress(
                "distributor",
                state.manufacturers,
                I18n.t("screen_messages.distributors_label")
              )
            }
            showDisclosure={true}
          /> */}
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.browse.molecule_label")}
            rightTitle={state.molecules ? state.molecules?.length : ""}
            onPress={() =>
              onKeywordPress(
                "molecule",
                state.molecules,
                I18n.t("screen_messages.browse.molecule_label")
              )
            }
            showDisclosure={true}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <RowButton
            title={I18n.t("screen_messages.browse.therapy_label")}
            rightTitle={state.therapies ? state.therapies?.length : ""}
            onPress={() =>
              onKeywordPress(
                "therapy_area_simple",
                state.therapies,
                I18n.t("screen_messages.browse.therapy_label")
              )
            }
            showDisclosure={true}
          />
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
          <TouchableOpacity
            style={{ margin: 0 }}
            onPress={() => onPressSignUp()}
            activeOpacity={0.5}
          >
            <RowButton
              title={I18n.t(
                "screen_messages.merchant_register.register_withus"
              )}
              onPress={() => onPressSignUp()}
              showDisclosure={true}
            />
          </TouchableOpacity>
          <LineDivider dividerColor="#DADADA" width="100%" theme={theme} />
        </>
      </ScrollView>
    </>
  );
}
