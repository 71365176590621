import React, { useState, useContext, useEffect, useRef } from "react";
import CartContext from "@presto-contexts/CartContext";
import utils from "../../utils/index";
import I18n from "i18n-js";
import NavigatorContext from "@presto-contexts/NavigatorContext";
import CartItemCardComponent from "@presto-cards/ItemCards/CartItem";
import ItemCardModal from "../../component-models/ItemCardModel";
import { prestoAlert } from "../../common/Alert";
import _ from "lodash";
export default function CartItemCard({
  item,
  variation,
  wallet,
  itemPromotions,
}) {
  const { getCountOfItemInCart, updateItemInCart, cart } = useContext(
    CartContext
  );
  const { Navigator } = useContext(NavigatorContext);
  const [updating, setUpdating] = useState(false);

  const onVerifyAndAddItem = async (item, variation) => {
    if (updating) {
      return true;
    }

    const totalItemPrice = Number(item.price.total_price);
    const newCartValue = Number(cart?.total_price) / 100 + totalItemPrice;

    if (
      item?.other_data?.is_gift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      return true;
    }
    onAdd(item, variation);
  };

  const onAdd = async (item, variation) => {
    const variationId = variation?.id || null;
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count + 1
    );
    console.log("Error in Add", error, status);
    if (status) {
      const count = getCountOfItemInCart();
    } else {
      alert(I18n.t("error_messages.error_updating_cart"));
    }
    setUpdating(false);
  };

  const count = getCountOfItemInCart(item.id, variation?.id);
  const onSubtract = async (item, variation) => {
    if (updating) {
      return true;
    }
    const variationId = variation?.id || null;
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variationId,
      count - 1 > 0 ? count - 1 : 0
    );
    if (status) {
      const count = getCountOfItemInCart();
    } else {
      alert(I18n.t("error_messages.error_updating_cart"));
    }
    setUpdating(false);
  };

  const onPressItem = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "DETAILED_ITEM",
        screenParams: { item: item, wallet: wallet },
      },
    });
  };

  const onViewItems = () => {
    Navigator.emit({
      event: "modal",
      params: {
        screenName: "LIST_ITEMS_AVAILABLE_FOR_DISCOUNT",
        screenParams: {
          product: item,
          availableDiscountItemIds: getAvailableDiscountItemIds(),
        },
      },
    });
  };

  const getAvailableDiscountItemIds = () => {
    let entitledItemsId = [];

    let selectedItem = _.find(cart?.items, { item_id: item.id });

    let itemPromotionObject = itemPromotions[selectedItem?.id];

    if (itemPromotionObject && itemPromotionObject?.promotions_available) {
      entitledItemsId = itemPromotionObject.entitled_item_ids;
    }
    return entitledItemsId;
  };

  const onVerifyAndUpdateItem = async (variation, quantity) => {
    if (updating) {
      return true;
    }
    const oldItemTotalPrice =
      _.find(cart?.items, ["item_id", variation?.id || item.id])?.total_price ||
      0;
    const totalItemPrice = Number(item.price.total_price);

    const newCartValue =
      Number(cart?.total_price) / 100 -
      oldItemTotalPrice / 100 +
      totalItemPrice * quantity;

    if (
      item?.other_data?.is_gift &&
      newCartValue > wallet?.current_balance / 100
    ) {
      alert(
        I18n.t("screen_messages.redeem_points_screen.information_title"),
        I18n.t("screen_messages.redeem_points_screen.no_sufficient_points")
      );
      return true;
    } else {
      onUpdate(variation, quantity);
    }
  };

  const onUpdate = async (variation, quantity) => {
    setUpdating(true);
    const [error, status] = await updateItemInCart(
      item.id,
      variation?.id,
      quantity
    );
    console.log("Error in onAmountEdit", error, status);
    if (!status) {
      alert(utils.errorMessage(error));
    }
    setUpdating(false);
  };

  const alert = (title, message) => {
    prestoAlert(
      title,
      message,
      [
        {
          text: I18n.t("screen_messages.common.button_ok"),
          style: "cancel",
        },
      ],
      false
    );
  };

  console.log("rendering : ", item.id);
  return (
    <CartItemCardComponent
      product={
        new ItemCardModal({
          product: item,
          variation,
          count,
          itemPromotions,
          cart,
        })
      }
      updating={updating}
      onAdd={onVerifyAndAddItem}
      onSubtract={onSubtract}
      onPress={onPressItem}
      onUpdate={onVerifyAndUpdateItem}
      cart={cart}
      onViewItems={onViewItems}
    />
  );
}
