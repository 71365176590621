import React, { useContext } from "react";
import { View } from "react-native";
import DropShadow from "@presto-components/DropShadow/DropShadow";
import ThemeContext from "@presto-contexts/ThemeContext";

export function Card({ children, padding, bgColor, borderRadius, color }) {
  const { theme } = useContext(ThemeContext);
  return (
    <DropShadow style={{ borderRadius: theme.cardRadius, ...theme.darkShadow }}>
      <View
        style={[
          {
            padding: padding || theme.primaryPadding,
            width: "100%",
            backgroundColor: bgColor || theme.white,
            borderRadius: borderRadius ? borderRadius : theme.cardRadius,
          },
        ]}
      >
        {children}
      </View>
    </DropShadow>
  );
}

export function TransparentCard({ children, padding }) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        padding: padding || theme.primaryPadding,
        width: "100%",
        backgroundColor: "transparent",
      }}
    >
      {children}
    </View>
  );
}
