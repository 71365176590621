import React, { useContext } from "react";
import { View } from "react-native";
import { FullWidthHorizontalPaddedBox } from "@presto-components/Boxes/Boxes";
import SearchComponent from "@presto-screen-components/SearchComponent/SearchComponent";
import ThemeContext from "@presto-contexts/ThemeContext";

export default function SearchHeader({
  onSearch,
  onSearchFocus,
  placeholder,
  onEndSearchFocusEnd,
  query,
  inputref,
  showSoftInputOnFocus,
  onSearchChange,
  icon,
  containerStyle,
  closeIcon,
}) {
  const { theme } = useContext(ThemeContext);
  return (
    <View
      style={{
        width: "100%",
        height: 50,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 0,
      }}
    >
      <FullWidthHorizontalPaddedBox>
        <SearchComponent
          onSearchChange={onSearchChange}
          query={query}
          onSearch={onSearch}
          onSearchFocus={onSearchFocus}
          onEndSearchFocusEnd={onEndSearchFocusEnd}
          placeholder={placeholder}
          inputref={inputref}
          showSoftInputOnFocus={showSoftInputOnFocus}
          icon={icon}
          closeIcon={closeIcon}
          containerStyle={containerStyle}
        />
      </FullWidthHorizontalPaddedBox>
    </View>
  );
}
