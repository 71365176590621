import PrestoSdk from "../../global/PrestoSdk";
import $http from "../../global/http";
import $ from "../../global/util";

const RateResource = function (PrestoSdk, $http) {
  function getRatingInfo(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/ratings/my_ratings.json?entity_id=" +
      params.entity_id +
      "&entity_type=" +
      params.entity_type;
    return $http.get(url);
  }

  function getAllRatingInfo(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v0/ratings.json?entity_id=" +
      params.entity_id +
      "&entity_type=" +
      params.entity_type;
    return $http.get(url);
  }

  function createRating(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/ratings.json";
    return $http.post(url, params);
  }

  function updateRating(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/ratings/" + params.id + ".json";
    return $http.put(url, params);
  }

  return {
    getRatingInfo: getRatingInfo,
    createRating: createRating,
    updateRating: updateRating,
    getAllRatingInfo: getAllRatingInfo,
  };
};

export default RateResource(PrestoSdk, $http);
