import $http from "../../global/http";
import PrestoSdk from "../../global/PrestoSdk";
import AppointmentConstants from "./AppointmentConstants";
import $ from "../../global/util";

const AppointmentResource = function ($http, PrestoSdk, AppointmentConstants) {
  function bookAppointment(appointment) {
    var url =
      PrestoSdk.getHostName() + AppointmentConstants.BOOK_APPOINTMENT.PATH;
    return $http.post(url, appointment);
  }

  function updateAppointment(appointment) {
    var url =
      PrestoSdk.getHostName() +
      AppointmentConstants.UPDATE_APPOINTMENT.PATH +
      "/" +
      appointment.id +
      ".json";
    return $http.put(url, { status: appointment.status });
  }

  function cancelAppointment(appointment) {
    var url =
      PrestoSdk.getHostName() +
      AppointmentConstants.CANCEL_APPOINTMENT.PATH +
      "/" +
      appointment.id +
      "/cancel_appointment.json";
    return $http.post(url, { reason: appointment.reason });
  }

  function cancelAppointmentByCustomer(appointment) {
    var url = `${PrestoSdk.getHostName()}/user/v1/appointments/${appointment.id
      }/cancel.json`;
    return $http.put(url, {
      appointment: {
        cancel_reason: appointment.cancel_reason,
      },
    });
  }

  function clearAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      AppointmentConstants.CLEAR_APPOINTMENT.PATH +
      "/" +
      params.userId +
      "/clear_current_appointment.json";
    return $http.post(url);
  }

  function getAppointment() {
    var url =
      PrestoSdk.getHostName() + AppointmentConstants.GET_APPOINTMENT.PATH;
    return $http.get(url);
  }

  function getAllAppointments(params) {
    var url = PrestoSdk.getHostName() + "/users/my_appointments.json";
    if (params?.page) {
      url += "?page_no=" + params.page;
    }
    return $http.get(url);
  }

  function getMoreAppointments(params) {
    var url =
      PrestoSdk.getHostName() +
      "/users/my_appointments.json?older_than=" +
      params.older_than;
    return $http.get(url);
  }

  function getAppointmentDetials(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/appointments/" + params.id + ".json";

    return $http.get(url);
  }

  function getUserAppointmentDetials(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v1/appointments/" + params.id + ".json";
    return $http.get(url);
  }

  function acceptReceivedBill(params) {
    var url =
      PrestoSdk.getHostName() +
      "/appointments/" +
      params.id +
      "/confirm_bill.json";
    return $http.post(url);
  }

  function rejectReceivedBill(params) {
    var url =
      PrestoSdk.getHostName() +
      "/appointments/" +
      params.id +
      "/reject_bill.json";
    return $http.post(url);
  }

  function payByCash(params) {
    var url =
      PrestoSdk.getHostName() + "/appointments/" + params.id + "/pay_cash.json";
    return $http.post(url);
  }

  function createCompositePayment(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/composite_payments.json";
    return $http.post(url, params);
  }

  function getCompositePayment(id) {
    var url = PrestoSdk.getHostName() + `/user/v0/composite_payments/${id}.json`;
    return $http.get(url);
  }

  function getResourceSchedule(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/resources/schedule.json";
    return $http.get(url, { params: params });
  }

  function bookResource(params) {
    var url = PrestoSdk.getHostName() + "/user/v0/resources/book_resource.json";
    return $http.post(url, params);
  }

  function updateEmployeeAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v1/appointments/" +
      params.appointment.id +
      ".json";
    return $http.put(url, params);
  }

  function rejectCustomer(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/appointments/" +
      params.appointment.id +
      "/operator_reject.json";
    return $http.post(url, { reason: params.reason, status: "REJECTED" });
  }

  function getEmployeeAppointments(startTime, endTime) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/resources/schedule.json?start_time=" +
      startTime.toISOString() +
      "&end_time=" +
      endTime.toISOString();
    return $http.get(url);
  }

  function acceptAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/appointments/" +
      params.appointment.id +
      "/accept.json";
    return $http.post(url, params);
  }

  function createSubscription(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/appointments/create_recurring.json";
    return $http.post(url, params);
  }

  function cancelSubscription(params) {
    var url =
      PrestoSdk.getHostName() + "/user/v0/appointments/cancel_recurring.json";
    return $http.post(url, params);
  }

  function acceptEmployeeAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/appointments/" +
      params.appointment.id +
      "/operator_accept.json";
    return $http.post(url, params);
  }

  function completeOtpAppointment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/employee/v0/appointments/" +
      params.appointment.id +
      "/complete.json";
    return $http.post(url, params);
  }

  function getUserAppointments(params) {
    var url = PrestoSdk.getHostName() + "/user/v1/appointments.json";
    if (params?.start_time) {
      url += "?start_time=" + params.start_time;
    }
    if (params?.end_time) {
      url += "&end_time=" + params.end_time;
    }
    if (params?.status) {
      url += "&status=" + params.status;
    }
    if (params?.time_type) {
      url += "&time_type=" + params.time_type;
    }
    if (params?.records_per_page) {
      url += "&records_per_page=" + params.records_per_page;
    }

    if (params?.page) {
      url += "&page=" + params.page;
    }
    return $http.get(url);
  }

  function updateService(appointment) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v1/appointments/" +
      appointment.id +
      "/update_service.json";

    return $http.put(url, { appointment: appointment });
  }

  function updateAppointmentDetails(params) {
    var url =
      PrestoSdk.getHostName() + `/appointments/${params.appointment_id}.json`;
    return $http.put(url, params);
  }

  function createPayment(params) {
    var url =
      PrestoSdk.getHostName() +
      "/user/v1/payments/initiate_manual_payment.json";
    return $http.post(url, params);
  }

  return {
    bookAppointment: bookAppointment,
    updateAppointment: updateAppointment,
    cancelAppointment: cancelAppointment,
    getAppointment: getAppointment,
    getAllAppointments: getAllAppointments,
    clearAppointment: clearAppointment,
    acceptReceivedBill: acceptReceivedBill,
    rejectReceivedBill: rejectReceivedBill,
    payByCash: payByCash,
    createCompositePayment: createCompositePayment,
    getResourceSchedule: getResourceSchedule,
    bookResource: bookResource,
    updateEmployeeAppointment: updateEmployeeAppointment,
    rejectCustomer: rejectCustomer,
    getEmployeeAppointments: getEmployeeAppointments,
    acceptAppointment: acceptAppointment,
    getAppointmentDetials: getAppointmentDetials,
    createSubscription: createSubscription,
    cancelSubscription: cancelSubscription,
    completeOtpAppointment: completeOtpAppointment,
    acceptEmployeeAppointment: acceptEmployeeAppointment,
    getMoreAppointments: getMoreAppointments,
    getUserAppointments: getUserAppointments,
    getUserAppointmentDetials: getUserAppointmentDetials,
    updateService: updateService,
    updateAppointmentDetails: updateAppointmentDetails,
    cancelAppointmentByCustomer,
    createPayment: createPayment,
    getCompositePayment: getCompositePayment,
  };
};

export default AppointmentResource($http, PrestoSdk, AppointmentConstants);
